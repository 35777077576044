/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-undef */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { ZoomableGroup, ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// import ReactTooltip from 'react-tooltip';
import stateTopo from './state.json';
import Typography from '../../atoms/Typography';
import { getTopoUrl } from './Url';
import colors from '../../../utils/colors';
import './MapStyle.css';

/**
 *
 * @param  {object} props - required props in table component
 * @param {Array} props.activityStatusArr - activityStatusArr
 * @param {string} props.individualState - individual state
 * @param {string} props.stateInfo stateInfo
 * @param {string} props.activityInfo activityInfo
 * @param {string} props.getMapLegends array
 * @returns {React.ReactElement} - returns the Table component
 */
const TopoMap = ({ activityStatusArr, individualState, stateInfo, activityInfo, getMapLegends }) => {
  const { activityLegends } = useSelector((state) => state.dashboardDetail);
  const [stateDistrict, setStateDistrict] = useState();
  const [toolBox, setToolBox] = useState({});
  const [activityLegendsColor, setActivityLegendsColor] = useState({});
  const [toolTip, setToolTip] = useState(false);
  const geoJson = 'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/india.json';
  const labels = Object.keys(toolBox);
  useEffect(() => {
    if (activityLegends?.mapLegends && getMapLegends && getMapLegends.length > 0) {
      const legends = JSON.parse(JSON.stringify(activityLegends?.mapLegends));
      delete legends['Total Endemic Districts'];
      delete legends['Total Endemic Blocks'];

      const keys = Object.keys(legends);
      const finalLegends = {};
      keys.map((keyData) => {
        if (getMapLegends.indexOf(keyData) > -1) {
          finalLegends[keyData] = legends[keyData];
        }
        return true;
      });
      setActivityLegendsColor(finalLegends);
    } else {
      setActivityLegendsColor({});
    }
  }, [activityLegends, getMapLegends]);

  /**
   * @function getTypeColor - return
   * @param {string} type - params
   * @returns {React.ReactElement} - background color
   */
  const getTypeColor = (type) => {
    type = type === 'Repeat MDA' ? 'DA' : type;
    return type ? activityLegendsColor?.[type] || colors.typeCode.default : colors.typeCode.transparent;
  };
  /**
   * @function toolContent - return
   * @param {object} data - params
   */
  const toolContent = (data) => {
    if (individualState) setStateDistrict(data.district);
    activityStatusArr.filter((val) => {
      if (val.district === data.district || val.state === data.ST_NM) {
        const data = JSON.parse(JSON.stringify(val));
        if (!individualState) setStateDistrict(data.state);
        delete data.type;
        delete data.state;
        delete data.state_code;
        delete data.district;
        setToolBox(data);
        setToolTip(JSON.stringify(data) !== '{}');
      }
      return false;
    });
  };

  return (
    <div className="mapContainer">
      {stateInfo && <Typography text={stateInfo} type="title" colorType="text" customClass="top-left" />}
      {activityInfo && <Typography text={activityInfo} type="title" colorType="text" customClass="top-right" />}
      <div className={`${individualState && 'legendIndividual'} legendBox`}>
        {Object.keys(activityLegendsColor)?.map((data) => {
          return (
            <div className="lengedContent">
              <div
                style={{ borderRadius: '0px', backgroundColor: activityLegendsColor[data], width: 17, height: 10 }}
              />
              <Typography text={data} type="body2" colorType="text" />
            </div>
          );
        })}
      </div>
      <div className={`oom-map__map geo-tansform-${individualState}`}>
        <ComposableMap
          projection="geoMercator"
          projectionConfig={{
            scale: 350,
            center: [78.9629, 22.5937],
          }}
          width={230}
          height={200}
          viewBox="40 -5 230 200"
          data-tip=""
          style={{
            width: '100%',
            height: '100%',
            border: 'blue',
          }}
        >
          {/* <ZoomableGroup> */}
          {individualState ? (
            <Geographies geography={getTopoUrl(individualState)}>
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Geography
                    key={geo.rsmKey}
                    data-tip="TEST"
                    stroke="#EBEBEB"
                    strokeWidth="0.2"
                    onMouseEnter={() => toolContent(geo.properties)}
                    onMouseLeave={() => setToolTip(false)}
                    geography={geo}
                    style={{
                      default: {
                        outline: 'none',
                      },
                      hover: {
                        outline: 'black',
                      },
                      pressed: {
                        outline: 'none',
                      },
                    }}
                    fill={getTypeColor(
                      activityStatusArr?.find(
                        (stateVal) =>
                          stateVal.state === geo.properties.st_nm && stateVal.district === geo.properties.district,
                      )?.type,
                    )}
                  />
                ))
              }
            </Geographies>
          ) : (
            <Geographies geography={geoJson}>
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Geography
                    key={geo.rsmKey}
                    data-tip="TEST"
                    stroke="#EBEBEB"
                    strokeWidth="0.2"
                    geography={geo}
                    style={{
                      hover: {
                        outline: 'none',
                      },
                      pressed: {
                        outline: 'none',
                      },
                    }}
                    fill={getTypeColor(
                      activityStatusArr?.find(
                        (stateVal) =>
                          stateVal.state === geo.properties.st_nm && stateVal.district === geo.properties.district,
                      )?.type,
                    )}
                  />
                ))
              }
            </Geographies>
          )}
          {!individualState && (
            <Geographies geography={stateTopo}>
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Geography
                    key={geo.rsmKey}
                    data-tip="TEST"
                    stroke="#646464"
                    strokeWidth="0.3"
                    geography={geo}
                    fill="transparent"
                    onMouseEnter={() => toolContent(geo.properties)}
                    onMouseLeave={() => setToolTip(false)}
                    style={{
                      default: {
                        outline: 'none',
                      },
                      hover: {
                        // opacity: 0.5,
                        outline: 'none',
                      },
                      pressed: {
                        outline: 'none',
                      },
                    }}
                  />
                ))
              }
            </Geographies>
          )}
          {/* </ZoomableGroup> */}
        </ComposableMap>
      </div>
      {toolTip && (
        // <ReactTooltip>
        <div className={`${individualState && 'toolIndividual'} toolContent`}>
          <div className="toolHeader">{stateDistrict}</div>
          <div className="toolBox">
            {toolBox &&
              labels?.map((data, index) => {
                return (
                  <div className="insideContent">
                    <div className={labels.length - 1 === index ? 'toolDescription' : 'toolDescript'}>{data}</div>
                    <div>:</div>
                    <div className="toolCount">{`${toolBox[data]}`}</div>
                  </div>
                );
              })}
          </div>
        </div>
        // </ReactTooltip>
      )}
    </div>
  );
};
export default TopoMap;

TopoMap.propTypes = {
  activityStatusArr: PropTypes.array,
  individualState: PropTypes.string,
  stateInfo: PropTypes.string,
  activityInfo: PropTypes.string,
  getMapLegends: PropTypes.arrayOf.isRequired,
};

TopoMap.defaultProps = {
  activityStatusArr: [],
  individualState: null,
  stateInfo: null,
  activityInfo: null,
};
