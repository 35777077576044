import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import colors from '../../../utils/colors';

/**
 *
 * @param {object} props - required props in custom typography
 * @returns {React.ReactElement} - returns the typography component
 */
const CustomTypography = (props) => {
  const { type, text, colorType, customClass, customStyle } = props;
  /**
   * @name getFontType
   * @returns {React.ReactElement}  - returns the required  font type of text
   */
  const getFontType = () => {
    switch (type) {
      case 'h1':
        return 'h1';
      case 'h2':
        return 'h2';
      case 'h3':
        return 'h3';
      case 'h4':
        return 'h4';
      case 'header':
        return 'h5';
      case 'subHeading':
        return 'h6';
      case 'caption':
        return 'subtitle1';
      case 'title':
        return 'subtitle2';
      case 'link':
        return 'caption';
      case 'body1':
        return 'body1';
      case 'body2':
        return 'body2';
      default:
        return 'subtitle1';
    }
  };
  /**
   *@name getColorType
   *@returns {React.ReactElement}- returns the required type of text  colors
   */
  const getColorType = () => {
    switch (colorType) {
      case 'primary':
        return colors.blue.secondary;
      case 'secondary':
        return colors.white.primary;
      case 'tertiary':
        return colors.yellow.tertiary;
      case 'quaternary':
        return colors.blue.tertiary;
      case 'quinary':
        return colors.gray.tertiary;
      case 'senary':
        return colors.gray.primary;
      case 'text':
        return colors.navyBlue;
      case 'error':
        return colors.error.primary;
      case 'black':
        return colors.black.primary;
      default:
        return colors.blue.secondary;
    }
  };
  return (
    <Typography variant={getFontType(type)} color={getColorType(colorType)} sx={customStyle} className={customClass}>
      {text}
    </Typography>
  );
};

export default CustomTypography;
CustomTypography.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  colorType: PropTypes.string,
  customClass: PropTypes.string,
  customStyle: PropTypes.objectOf(),
};
CustomTypography.defaultProps = {
  colorType: '',
  customClass: '',
  customStyle: {},
};
