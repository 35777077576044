import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
/**
 * @name CustomCheckbox
 * @param {*} props -- required props
 * @returns {React.ReactElement} --
 */
const CustomCheckbox = (props) => {
  const { onHandleChange, value, checked, disabled } = props;

  return (
    <Grid>
      <Checkbox
        onChange={(e) => onHandleChange(e.target.checked)}
        value={value}
        sx={{ padding: '0px !important' }}
        checked={checked}
        disabled={disabled}
      />
    </Grid>
  );
};
export default CustomCheckbox;
CustomCheckbox.propTypes = {
  onHandleChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};
CustomCheckbox.defaultProps = {
  checked: false,
  disabled: false,
};
