/* eslint-disable no-nested-ternary */
import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Line,
  Tooltip,
  ComposedChart,
  CartesianGrid,
  Legend,
  Bar,
  XAxis,
  YAxis,
  LabelList,
  Label,
  ResponsiveContainer,
} from 'recharts';
import Typography from '../../atoms/Typography';
import colors from '../../../utils/colors';

/**
 * @param {string} value legend text
 * @returns {React.Component} customized legend span
 */
const LegendText = (value) => {
  return <span className="legend-text">{value}</span>;
};

/**
 * @param {*} props props
 * @returns {React.ReactComponent} ComposedBarChart
 */
const ComposedBarChart = (props) => {
  const { title, chartData, XKey, barKey, LineKeys, ToolTip, selectedLabel, Y1Label, Y2Label, BarValuePosition } =
    props;
  return (
    <>
      {title && (
        <center>
          <Typography
            type="header"
            colorType="text"
            text={`${title} ${selectedLabel && selectedLabel}`}
            customStyle={{ paddingLeft: 2 }}
          />
        </center>
      )}

      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart data={chartData} margin={{ top: 70, right: 20, left: 20, bottom: 15 }} stackOffset="sign">
          <XAxis dataKey={XKey} tickLine={false} style={{ fill: colors.graph }} tick={chartData?.length > 0} />
          <YAxis
            yAxisId={0}
            tickLine={false}
            style={{ fill: colors.graph }}
            tick={chartData?.length > 0}
            tickFormatter={(tick) => {
              return `${tick}%`;
            }}
          >
            <Label value={Y1Label} angle={-90} dx={-16} style={{ fill: colors.graph }} />
          </YAxis>
          <YAxis
            yAxisId={1}
            tickLine={false}
            orientation="right"
            style={{ fill: colors.graph }}
            type="number"
            // tickFormatter={(value) => value.toFixed()}
            allowDecimals={false}
          >
            <Label value={Y2Label} angle={-90} dx={10} style={{ fill: colors.graph }} />
          </YAxis>
          {ToolTip && <Tooltip cursor={false} />}
          <Legend formatter={LegendText} />
          <CartesianGrid stroke="transparent" vertical={false} horizontal={false} />
          <Bar isAnimationActive={false} yAxisId={0} dataKey={barKey} barSize={30} fill="#00ccf2">
            <LabelList
              isAnimationActive={false}
              dataKey={barKey}
              position={BarValuePosition}
              style={{ fill: '#6D6E6F' }}
              formatter={(tick) => {
                return `${tick}%`;
              }}
            />
          </Bar>
          {LineKeys.map((key) => (
            <Line
              isAnimationActive={false}
              yAxisId={key.axis === 1 ? 1 : 0}
              dataKey={key.label}
              stroke={key.color}
              strokeWidth={2}
              strokeDasharray={
                key.label === 'No. of sites with > 1mF rate'
                  ? '3 5'
                  : key.label === 'TAS2'
                  ? '3 3'
                  : key.label === 'TAS3'
                  ? '3 2'
                  : false
              }
              dot={
                key.label === 'TAS2'
                  ? { strokeWidth: 1, fill: key.color }
                  : key.label === 'Pre-TAS'
                  ? { stroke: key.color, strokeWidth: 2, r: 3, strokeDasharray: '' }
                  : { stroke: key.color, strokeWidth: 3, r: 4, strokeDasharray: '' }
              }
            >
              <LabelList isAnimationActive={false} dataKey={key.label} position="right" style={{ fill: 'black' }} />
            </Line>
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
};

export default ComposedBarChart;
ComposedBarChart.propTypes = {
  title: PropTypes.string,
  chartData: PropTypes.arrayOf.isRequired,
  XKey: PropTypes.string.isRequired,
  barKey: PropTypes.string.isRequired,
  LineKeys: PropTypes.arrayOf.isRequired,
  ToolTip: PropTypes.bool,
  selectedLabel: PropTypes.string,
  Y1Label: PropTypes.string,
  Y2Label: PropTypes.string,
  BarValuePosition: PropTypes.string,
};
ComposedBarChart.defaultProps = {
  title: '',
  ToolTip: false,
  selectedLabel: '',
  Y1Label: '',
  Y2Label: '',
  BarValuePosition: 'top',
};
