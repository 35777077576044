/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import './Modal.css';
import ClickAwayListener from '@mui/material/ClickAwayListener';

/**
 *  @param {object} props - required to define props of modal
 * @name  CustomModal
 * @returns {React.ReactElement} - returns Typography component
 */
const CustomModal = (props) => {
  const { openModal, modalTitle, modalContent, actionType, verticalModal } = props;
  return (
    <ClickAwayListener onClickAway={null}>
      <Dialog open={openModal} fullWidth>
        <Grid className={verticalModal && 'VerticalModalStyling'}>
          <DialogTitle className="modTitle">{modalTitle}</DialogTitle>
          <DialogContent className={verticalModal && 'verticalContent'}>{modalContent}</DialogContent>
          <DialogActions className={verticalModal && 'verticalFooter'}>{actionType}</DialogActions>
        </Grid>
      </Dialog>
    </ClickAwayListener>
  );
};
export default CustomModal;
CustomModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string.isRequired,
  verticalModal: PropTypes.bool,
  modalContent: PropTypes.objectOf(PropTypes.any).isRequired,
  actionType: PropTypes.string,
};
CustomModal.defaultProps = {
  verticalModal: false,
  actionType: '',
};
