/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import HeaderSection from '../../molecules/HeaderSection';
import FileGenerator from '../FileGenerator';
// import NationalMap from '../../../assets/icon/nationalMap.png';
import Table from '../Table';
import ProgressChart from '../../molecules/ProgressChart';
import './chartBlock.css';
/**
 * @name DynChartBlock
 * @param {object} props required props
 * @returns {React.ReactElement} -- returns the file generator with dropdowns
 */
const DynChartBlock = (props) => {
  const {
    getSelectValue,
    getTableSelect,
    children,
    tableContent,
    tableHeader,
    title,
    tableSortList,
    DropdownList,
    labelText,
    progressLine,
    progressBarGraph,
    chartDatas,
    districtDropdown,
    getDistrictList,
    getTableDistrictList,
    hideFields,
    table,
    progressChartType,
    ChartDropdownSelectedValues,
    TableDropdownSelectedValues,
    getProgressLegends,
    Disclaimer,
    strategyType,
  } = props;
  const [isTableEdit, setTableEdit] = React.useState(false);

  return (
    <>
      {title && <HeaderSection label={title} />}
      <div className="subhead">{labelText}</div>
      <Grid container item md={12} xs={12} sm={12} className="inside-spacing">
        <Grid item md={6} xs={12} sm={12}>
          <FileGenerator
            DropdownList={DropdownList}
            DropdownSelectedValues={ChartDropdownSelectedValues}
            showExporter={false}
            getSelectValue={(obj) => getSelectValue(obj)}
            districtDropdown={districtDropdown}
            getDistrictList={getDistrictList}
            fileData={chartDatas}
          />
          <Grid item spacing={2} className="inside-spacing">
            {children}
          </Grid>
        </Grid>
        {progressLine != null && (
          <Grid item md={6} xs={12} sm={12} className="fixed-progress-chart">
            <ProgressChart
              getProgressLegends={getProgressLegends}
              data={chartDatas}
              type={progressChartType}
              strategyType={strategyType}
            />
          </Grid>
        )}
        {progressBarGraph && (
          <Grid item md={12} xs={12} sm={12}>
            {progressBarGraph}
          </Grid>
        )}

        {table && (
          <Grid item md={progressLine || 6} xs={12} sm={12}>
            <FileGenerator
              DropdownList={tableSortList}
              DropdownSelectedValues={TableDropdownSelectedValues}
              getSelectValue={(obj) => getTableSelect(obj)}
              districtDropdown={districtDropdown}
              getDistrictList={(stateId) => getTableDistrictList(stateId, 'table')}
              handleUpdate={(isEdit) => setTableEdit(!isEdit)}
              fileData={tableContent}
            />
            <Table header={tableHeader} rows={tableContent} hideKeyFields={hideFields} dashboardEdit={isTableEdit} />
            {Disclaimer && (
              <label className="DisclaimerText">
                Disclaimer: PMS and NO ACTIVITY PLANNED were not captured in this table
              </label>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};
export default DynChartBlock;
DynChartBlock.propTypes = {
  children: PropTypes.node.isRequired,
  tableContent: PropTypes.arrayOf.isRequired,
  tableHeader: PropTypes.arrayOf.isRequired,
  chartDatas: PropTypes.arrayOf.isRequired,
  DropdownList: PropTypes.array,
  title: PropTypes.string,
  tableSortList: PropTypes.array,
  getSelectValue: PropTypes.func,
  getTableSelect: PropTypes.func,
  labelText: PropTypes.string,
  progressLine: PropTypes.number,
  progressBarGraph: PropTypes.objectOf(),
  districtDropdown: PropTypes.objectOf(),
  getDistrictList: PropTypes.func,
  getTableDistrictList: PropTypes.func,
  hideFields: PropTypes.array,
  table: PropTypes.bool,
  progressChartType: PropTypes.string,
  TableDropdownSelectedValues: PropTypes.objectOf(),
  ChartDropdownSelectedValues: PropTypes.objectOf(),
  Disclaimer: PropTypes.string,
  getProgressLegends: PropTypes.array,
  strategyType: PropTypes.objectOf(),
};
DynChartBlock.defaultProps = {
  title: null,
  tableSortList: [],
  DropdownList: [],
  getSelectValue: () => {},
  getTableSelect: () => {},
  labelText: null,
  progressLine: null,
  progressBarGraph: null,
  districtDropdown: null,
  getDistrictList: () => {},
  getTableDistrictList: () => {},
  hideFields: null,
  table: true,
  progressChartType: null,
  TableDropdownSelectedValues: {},
  ChartDropdownSelectedValues: {},
  Disclaimer: false,
  getProgressLegends: [],
  strategyType: null,
};
