import { takeLatest } from 'redux-saga/effects';
import { PRETAS_OVERVIEW, MDA_COVERAGE, GET_DISTRICT_MOVEDTO, GET_TAS_SUMMERY } from '../../constants/actionType/index';
import {
  nationalPretasOverviewHandler,
  nationalMdaCoverageHandler,
  getDistrictMovedtoHandler,
  getTasSummeryHandler,
} from '../handler/nationalDashboardHandler';

/**
 * @name nationalBoardSaga used for dashboard api call
 * @yields nationalPretasOverviewHandler
 */
function* nationalBoardSaga() {
  yield takeLatest(PRETAS_OVERVIEW, nationalPretasOverviewHandler);
  yield takeLatest(MDA_COVERAGE, nationalMdaCoverageHandler);
  yield takeLatest(GET_DISTRICT_MOVEDTO, getDistrictMovedtoHandler);
  yield takeLatest(GET_TAS_SUMMERY, getTasSummeryHandler);
}
export default nationalBoardSaga;
