/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
import { Grid, Paper } from '@mui/material';
import * as XLSX from 'xlsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'loadsh';
import Typography from '../../../components/atoms/Typography';
import Form from './Form';
import Table from './Table';
import FileUpload from '../../../components/molecules/FileUpload';
import DatePicker from '../../../components/atoms/DatePicker';
import Toaster from '../../../components/atoms/ToastMessage';
import { geoGraphicDimensionEntries, geoGraphicDimensionDefaultValues } from './formEntries';
import { tableHeader } from './Table/tableData';
import {
  GET_GEOGRAPHIC_DIMENSION,
  UPDATE_GEOGRAPHIC_DIMENSION,
  INSERT_GEOGRAPHIC_DIMENSION,
  BULK_UPLOAD_GEOGRAPHIC_DIMENSION,
  GET_STATE_DROPDOWN,
  GET_DISTRICT_DROPDOWN,
  GET_MASTER_TEMPLATES,
  GET_STRATEGY_DROPDOWN,
} from '../../../constants/actionType';

/**
 *
 * @returns {React.ReactElement} returns the GeoGraphic Dimension screen
 */
const GeographicDimension = () => {
  const dispatch = useDispatch();
  const { getGeographicDimension, status } = useSelector((state) => state.geographic);
  const { getMasterTemplate } = useSelector((state) => state.masterTemplate);
  const dropdownList = useSelector((state) => state.dropDownReducer);
  const [formMode, setFormMode] = React.useState(true);
  const [rowData, setRowData] = useState([]);
  const [file, setFile] = useState();
  const [handleUploaderView, setHandleUploaderView] = React.useState(false);
  const [stateDropDownValue, setStateDropDownValue] = useState([]);
  const [districtDropDownValue, setDistrictDropDownValue] = useState([]);
  const [toaster, setToaster] = React.useState(null);
  const [defaultFormValues, setDefaultForm] = React.useState(geoGraphicDimensionDefaultValues);
  const [date, setDate] = React.useState(new Date());
  const [jsonFinalData, setJsonFinalData] = React.useState([]);
  const [jsonData, setJsonData] = React.useState();
  const [formControl, setFormControl] = React.useState();
  const ValidationGroup = localStorage.getItem('userGroupId');
  const ValidationType = localStorage.getItem('userTypeId');
  const InputActionValidation = localStorage.getItem('ActionAccess');
  const Actionvalue = JSON.parse(InputActionValidation);
  const { BulkUpload, Download, Edit, ManualDataInput, View } = Actionvalue?.masters?.[0];
  console.log(BulkUpload, Download, Edit, ManualDataInput, View, 'aslkdjqqfasoihr');
  console.log(jsonFinalData, 'jsqwwonData');
  useEffect(() => {
    dispatch({ type: GET_GEOGRAPHIC_DIMENSION });
    dispatch({ type: GET_STATE_DROPDOWN });
    dispatch({ type: GET_MASTER_TEMPLATES });
    dispatch({ type: GET_STRATEGY_DROPDOWN });
  }, [dispatch]);
  useEffect(() => {
    if (getGeographicDimension) {
      const row = [];
      const tmpStateDropDownValue = [];
      const tmpDistrictDropDownValue = [];
      getGeographicDimension?.map((data, index) => {
        tmpStateDropDownValue.push(data.state_id);
        tmpDistrictDropDownValue.push(data.district_id);
        return row.push({
          S_no: index + 1,
          Year: data.year,
          State: data.state,
          District: data.district,
          Strategy: data.strategy,
          Form_Date: data.from_date,
          To_Date: data.to_date,
          geographic_dimension_id: data.geographic_dimension_id,
          state_id: data.state_id,
          district_id: data.district_id,
        });
      });
      setDistrictDropDownValue(tmpDistrictDropDownValue);
      setStateDropDownValue(tmpStateDropDownValue);
      setRowData(row);
    }
  }, [getGeographicDimension]);
  React.useEffect(() => {
    const arr = [];
    jsonData?.map((item, index) => {
      console.log(item, 'skdlksjrr');
      arr.push({
        S_no: index + 1,
        Year: item?.Year,
        State: item?.State,
        District: item?.District,
        Strategy: item?.Strategy,
        Form_Date: item?.Form_Date,
        To_Date: item?.To_Date,
        geographic_dimension_id: item?.geographic_dimension_id,
        state_id: item?.state_id,
        district_id: item?.district_id,
      });
    });

    if (handleUploaderView === true) setJsonFinalData(arr);
  }, [handleUploaderView, jsonData]);

  // active_status: 'Active';
  // district: 'example district';
  // drug_regime: 'example';
  // drug_type: 'example';
  // from_date: 44866;
  // state: 'example state';
  // to_date: 44867;
  useEffect(() => {
    if (status && status?.message !== '') setToaster(true);
    else setToaster(false);
  }, [status]);

  /**
   *
   * @param {*} data returns form data
   * @function handleFormSubmit
   */
  const handleSubmit = React.useCallback(
    async (data) => {
      if (formMode) {
        await dispatch({ type: INSERT_GEOGRAPHIC_DIMENSION, payload: data });
        dispatch({ type: GET_GEOGRAPHIC_DIMENSION });
        setDefaultForm(geoGraphicDimensionDefaultValues);
      } else {
        await dispatch({ type: UPDATE_GEOGRAPHIC_DIMENSION, payload: data });
        dispatch({ type: GET_GEOGRAPHIC_DIMENSION });
        setFormMode(true);
        setDefaultForm(geoGraphicDimensionDefaultValues);
      }
    },
    [dispatch, formMode],
  );
  /**
   * @param {object} data - passing data
   */
  const handleTableDataEdit = (data) => {
    const strategy = geoGraphicDimensionEntries.find((items) => {
      return items.entryName === 'Strategy' ? items.data : '';
    });
    const strategyType = strategy.data.find((val) => {
      return val.value === data.Strategy ? val.id : '';
    });
    setDefaultForm((prevState) => ({
      ...prevState,
      Year: data.Year,
      Strategy: strategyType?.id,
      state: data.state,
      district: data.district,
      state_id: data.state_id,
      district_id: data.district_id,
      from_date: data.Form_Date,
      to_date: data.To_Date,
      status: data.status,
      geographic_dimension_id: data.geographic_dimension_id,
    }));
    setFormMode(false);
  };
  /**
   * @param {number} data - data
   * @param {string} name - name
   * @function getSelectedDropdown
   */
  const getSelectedDropdownValue = React.useCallback(
    (data, name) => {
      if (name === 'state_id') {
        const param = { state: data };
        dispatch({ type: GET_DISTRICT_DROPDOWN, payload: param });
      }
    },
    [dispatch],
  );
  React.useEffect(() => {
    if ((ValidationGroup === '0' && ValidationType === '4') || (ValidationGroup === '8' && ValidationType === '5')) {
      setFormControl(true);
    }
  }, [ValidationGroup, ValidationType]);
  const getEntries = React.useMemo(() => {
    return geoGraphicDimensionEntries.map((value) => {
      if (value.entryName === 'state_id') {
        value.data = _.assign(dropdownList.stateData);
      }
      if (value.entryName === 'district_id') {
        if (dropdownList.districtData) value.disabled = false;
        value.data = _.assign(dropdownList.districtData);
      }
      if (value.entryName === 'Strategy') {
        if (dropdownList.strategy) value.disabled = false;
        value.data = _.assign(dropdownList.strategy);
      }

      return value;
    });
  }, [dropdownList]);
  // Memorized form (desponds every entry change)
  const form = React.useMemo(() => {
    return (
      <Form
        onSubmitData={(data) => handleSubmit(data)}
        geoGraphicDimensionEntries={getEntries}
        geoGraphicDimensionDefaultValues={defaultFormValues}
        SubmitBtnTitle={formMode ? 'Submit' : 'Update'}
        getSelectedDropdown={(data, name) => getSelectedDropdownValue(data, name)}
      />
    );
  }, [getEntries, formMode, defaultFormValues, getSelectedDropdownValue, handleSubmit]);

  /**
   * @param {*} files - sending files
   */
  const sendSelectedFiles = async (files) => {
    setFile(files);
    const data = await files.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const json = XLSX.utils.sheet_to_json(worksheet);
    setJsonData(json);
  };
  /**
   *
   */
  const finalUploaderValue = (val) => {
    const array = [];
    val?.map((item) => {
      console.log(item, 'jsonFinalData');

      array.push({
        state: item?.State,
        district: item?.District,
        strategy: item?.Strategy,
        year: item?.Year,
        from_date: item?.Form_Date,
        to_date: item?.To_Date,
        active_status: 'active',
      });
    });
    return array;
  };

  /**
   *
   */
  const handleUploadValues = async () => {
    const res = finalUploaderValue(jsonFinalData);
    await dispatch({
      type: BULK_UPLOAD_GEOGRAPHIC_DIMENSION,
      payload: { bulkInsertGeographicDimension: res, created_by: '1' },
    });
  };

  /**
   *
   */
  const onSubmitFiles = async () => {
    await dispatch({ type: BULK_UPLOAD_GEOGRAPHIC_DIMENSION, payload: file });
    dispatch({ type: GET_GEOGRAPHIC_DIMENSION });
  };
  return (
    <Grid item container md={12} sm={12} xs={12} spacing={3} sx={{ paddingTop: 2 }} className="dataLayout">
      {toaster && <Toaster open severity={status?.type} message={status?.message} close={() => setToaster(false)} />}
      <Grid item md={BulkUpload === 1 ? 9 : 12} sm={12} xs={12} className="noPadding">
        <Paper elevation={3} className="splitPage">
          {formControl && (
            <Grid>
              <Typography
                text="Create Geography Dimension"
                type="caption"
                customClass="userText"
                customStyle={{ fontWeight: 'bold' }}
              />
              <Grid sx={{ padding: 2 }}>{form}</Grid>
            </Grid>
          )}

          {View === 1 && (
            <Grid>
              <Table
                tableHeader={tableHeader}
                tableData={jsonFinalData?.length ? jsonFinalData : rowData}
                handleEdit={(data) => handleTableDataEdit(data, stateDropDownValue, districtDropDownValue)}
                handleUploaderView={handleUploaderView}
                handleUploadValues={handleUploadValues}
              />
            </Grid>
          )}
        </Paper>
      </Grid>
      {BulkUpload === 1 && (
        <Grid item md={3} sm={12} xs={12} sx={{ height: '100%' }} className="paddingView">
          <Paper elevation={3} className="splitPage">
            <FileUpload
              downloadTemplate
              templateUrl={getMasterTemplate.geographyDimension}
              excelUploader
              sendSelectedFile={(files) => sendSelectedFiles(files)}
              onSubmitFiles={() => onSubmitFiles()}
              masterSetup
              onClickHandleView={() => {
                jsonData?.length > 0 && setHandleUploaderView(true);
              }}
            />
            <Grid sx={{ pt: 4 }}>
              <DatePicker calendarPicker date={date} onHandleChange={(newDate) => setDate(newDate)} />
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};
export default GeographicDimension;
