import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import CustomForm from '../../../../components/organisms/CustomForm';

/**
 *
 * @param  {object} props - required props
 * @returns {React.ReactElement} --GeographicDimension Form
 */
const GeographicDimensionForm = (props) => {
  const {
    onSubmitData,
    onCancel,
    geoGraphicDimensionEntries,
    geoGraphicDimensionDefaultValues,
    SubmitBtnTitle,
    getSelectedDropdown,
  } = props;
  // const countRange = [{ labelText: 'Sentinel Site' }, { labelText: 'Random Site' }];

  return (
    <Grid>
      <CustomForm
        masterSetup
        entries={geoGraphicDimensionEntries}
        defaultValues={geoGraphicDimensionDefaultValues}
        onReceiveData={onSubmitData}
        onCancelAction={onCancel}
        SubmitBtnTitle={SubmitBtnTitle}
        getSelectedDropdown={(data, name) => getSelectedDropdown(data, name)}
        screen="masters"
      />
    </Grid>
  );
};
export default GeographicDimensionForm;
GeographicDimensionForm.propTypes = {
  onSubmitData: PropTypes.func,
  onCancel: PropTypes.func,
  geoGraphicDimensionEntries: PropTypes.arrayOf(PropTypes.objectOf),
  geoGraphicDimensionDefaultValues: PropTypes.objectOf,
  getSelectedDropdown: PropTypes.func,
  SubmitBtnTitle: PropTypes.string,
};
GeographicDimensionForm.defaultProps = {
  onSubmitData: () => null,
  onCancel: () => null,
  geoGraphicDimensionEntries: [],
  geoGraphicDimensionDefaultValues: {},
  getSelectedDropdown: () => null,
  SubmitBtnTitle: '',
};
