import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, CardContent } from '@mui/material';
import { useSelector } from 'react-redux';
import Typography from '../Typography';
// import CustomIcons from '../../../utils/icons';
import './DashboardCard.css';

/**
 *
 * @param {object} props - required props in dashboard card component
 * @returns {React.ReactElement} - returns the dashboard card component
 */
const DashboardCard = (props) => {
  const {
    stateView,
    cardTitle,
    backgroundColor,
    firstContent,
    firstValue,
    secondValue,
    blockValue,
    blockContent,
    districtContent,
    districtValue,
    districtPassContent,
    districtPassValue,
    districtFailContent,
    districtFailValue,
    NationalDashboard,
    districtPendingContent,
    districtPendingValue,
  } = props;

  const { activityLegends } = useSelector((state) => state.dashboardDetail);
  const [activityLegendsColor, setActivityLegendsColor] = useState({});

  useEffect(() => {
    if (activityLegends && activityLegends?.mapLegends)
      setActivityLegendsColor(JSON.parse(JSON.stringify(activityLegends?.mapLegends)));
  }, [activityLegends]);

  /**
   *@name getBackgroundColor
   * @returns {React.ReactElement} - the background color of dashboard card
   */
  const getBackgroundColor = () => {
    return activityLegendsColor[backgroundColor] || '#DBDBDB';
  };
  return (
    <Grid sx={{ width: ' 100%', padding: '20px' }} item md={12} xs={12} sm={12}>
      <Card
        sx={{
          '&.MuiCard-root': {
            backgroundColor: () => getBackgroundColor(backgroundColor),
            borderRadius: '0.375rem',
            display: 'inline-flex',
            alignItems: 'center',
            width: '100%',
            boxShadow: 'unset',
          },
        }}
      >
        <CardContent
          sx={{
            '&.MuiCardContent-root': {
              width: '100%',
              margin: '5%',
              padding: 0,
            },
          }}
        >
          {/* <Grid direction='row' container justifyContent={stateView ? 'flex-end' : 'flex-start'}> */}
          <Grid direction='row' container>
            <Typography type='caption' text={cardTitle || new Date().getFullYear()} colorType='black' />
            {/* {!firstContent.toLowerCase().includes('completed') && (
              <img src={CustomIcons.Disclaimer} alt='Disclaimer icon' className='Disclaimer' />
            )} */}
          </Grid>
          {stateView ? (
            <Grid item xs={12} md={12} sm={12} direction='column' container className='iddle_label'>
              <Grid className='contentGrid'>
                <Grid item xs={6} md={6} sm={6}>
                  <Typography type='title' text={firstContent} colorType='black' customClass='card-label' />
                </Grid>
                <Grid item xs={1} md={1} sm={1}>
                  <Typography type='title' text=':' colorType='black' customClass='colon' />
                </Grid>
                <Grid item xs={5} sm={5} md={5}>
                  <Typography type='title' text={firstValue} colorType='black' />
                </Grid>
              </Grid>
              <Grid className='contentGrid'>
                <Grid item xs={6} md={6} sm={6} className='block_content'>
                  <Typography type='title' text={blockContent} colorType='black' customClass='card-label' />
                </Grid>
                <Grid item xs={1} md={1} sm={1}>
                  <Typography type='title' text=':' colorType='black' customClass='colon' />
                </Grid>
                <Grid item xs={5} sm={5} md={5}>
                  <Typography type='title' text={blockValue} colorType='black' />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid className='contentGrid'>
              <Grid item xs={6} md={6} sm={6}>
                <Typography type='title' text={firstContent} colorType='black' customClass='card-label' />
              </Grid>
              <Grid item xs={1} md={1} sm={1}>
                <Typography type='title' text=':' colorType='black' customClass='colon' />
              </Grid>
              <Grid item xs={5} sm={5} md={5}>
                <Typography type='title' text={firstValue} colorType='black' />
              </Grid>
            </Grid>
          )}

          {districtContent && (
            <Grid className='contentGrid'>
              <Grid item md={6} xs={6} sm={6}>
                <Typography type='title' text={districtContent} colorType='black' customClass='card-label' />
              </Grid>
              <Grid item md={1} xs={1} sm={1}>
                <Typography type='title' text=':' colorType='black' customClass='colon' />
              </Grid>
              <Grid item md={5} xs={5} sm={5}>
                <Typography type='title' text={districtValue} colorType='black' />
              </Grid>
            </Grid>
          )}

          {NationalDashboard && (
            <Grid>
              <Grid className='contentGrid'>
                <Grid item xs={6} md={6} sm={6}>
                  <Typography type='title' text={districtPassContent} colorType='black' customClass='card-label' />
                </Grid>
                <Grid item xs={1} md={1} sm={1}>
                  <Typography type='title' text=':' colorType='black' customClass='colon' />
                </Grid>
                <Grid item xs={5} sm={5} md={5}>
                  <Typography type='title' text={districtPassValue} colorType='black' />
                </Grid>
              </Grid>
              <Grid className='contentGrid'>
                <Grid item xs={6} md={6} sm={6}>
                  <Typography type='title' text={districtFailContent} colorType='black' customClass='card-label' />
                </Grid>
                <Grid item xs={1} md={1} sm={1}>
                  <Typography type='title' text=':' colorType='black' customClass='colon' />
                </Grid>
                <Grid item xs={5} sm={5} md={5}>
                  <Typography type='title' text={districtFailValue} colorType='black' />
                </Grid>
              </Grid>
              <Grid className='contentGrid'>
                <Grid item xs={6} md={6} sm={6}>
                  <Typography type='title' text={districtPendingContent} colorType='black' customClass='card-label' />
                </Grid>
                <Grid item xs={1} md={1} sm={1}>
                  <Typography type='title' text=':' colorType='black' customClass='colon' />
                </Grid>
                <Grid item xs={5} sm={5} md={5}>
                  <Typography type='title' text={districtPendingValue} colorType='black' />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid className='contentGrid'>
            <Grid item md={6} xs={6} sm={6}>
              <Typography
                type='title'
                // text={`${stateView ? ' Block ' : ' '} Population at Risk`}
                text='Population at Risk'
                colorType='black'
                customClass='card-label'
              />
            </Grid>
            <Grid item md={1} xs={1} sm={1}>
              <Typography type='title' text=':' colorType='black' customClass='colon' />
            </Grid>
            <Grid item md={5} xs={5} sm={5}>
              <Typography type='title' text={secondValue} colorType='black' />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default DashboardCard;
DashboardCard.propTypes = {
  cardTitle: PropTypes.string,
  backgroundColor: PropTypes.string,
  firstContent: PropTypes.string,
  firstValue: PropTypes.string,
  secondValue: PropTypes.string,
  stateView: PropTypes.bool,
  NationalDashboard: PropTypes.bool,
  blockContent: PropTypes.string,
  blockValue: PropTypes.string,
  districtPassValue: PropTypes.string,
  districtPassContent: PropTypes.string,
  districtFailContent: PropTypes.string,
  districtPendingContent: PropTypes.string,
  districtPendingValue: PropTypes.string,
  districtFailValue: PropTypes.string,
  districtContent: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  districtValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};
DashboardCard.defaultProps = {
  cardTitle: '',
  backgroundColor: '',
  firstContent: '',
  firstValue: '',
  secondValue: '',
  stateView: false,
  NationalDashboard: false,
  blockContent: '',
  blockValue: '',
  districtPassValue: '',
  districtPassContent: '',
  districtFailContent: '',
  districtFailValue: '',
  districtPendingValue: '',
  districtPendingContent: '',
  districtContent: false,
  districtValue: false,
};