import { takeLatest } from 'redux-saga/effects';
import { GET_PROGRAM_ACTIVITY, UPDATE_PROGRAM_ACTIVITY, INSERT_PROGRAM_ACTIVITY } from '../../constants/actionType';
import { INITIAL_LOAD } from '../../constants/common';
import {
  getProgramActivitesHandler,
  insertProgramActivitiesHandler,
  statusClear,
  updateProgramActivitesHandler,
} from '../handler/programActivitySaga';

/**
 * @name programActivitySaga used for program activities api call
 * @yields programActivitySaga
 */
function* programActivitySaga() {
  yield takeLatest(INITIAL_LOAD, statusClear);

  yield takeLatest(GET_PROGRAM_ACTIVITY, getProgramActivitesHandler);
  yield takeLatest(INSERT_PROGRAM_ACTIVITY, insertProgramActivitiesHandler);
  yield takeLatest(UPDATE_PROGRAM_ACTIVITY, updateProgramActivitesHandler);
}
export default programActivitySaga;
