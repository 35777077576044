/* eslint-disable default-param-last */
import {
  GET_PROGRAM_STATUS_RESULT,
  UPDATE_PROGRAM_STATUS_RESULT,
  INSERT_PROGRAM_STATUS_RESULT,
  PROGRAM_STATUS,
} from '../../constants/actionType';
import { programStatusState } from '../initialStates';

/**
 * @param {string} state defines the state
 * @param {object} action used for payload and type
 * @returns {Array} action response state
 */
const programStatusReducer = (state = programStatusState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PROGRAM_STATUS_RESULT:
      return {
        ...state,
        getProgramStatus: payload.data,
      };
    case UPDATE_PROGRAM_STATUS_RESULT:
      return {
        ...state,
        updateProgramStatus: payload.data,
      };
    case INSERT_PROGRAM_STATUS_RESULT:
      return {
        ...state,
        status: payload.data,
      };
    case PROGRAM_STATUS:
      return {
        ...state,
        status: payload,
        loading: false,
      };
    default:
      return state;
  }
};
export default programStatusReducer;
