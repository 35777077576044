/* eslint-disable no-empty */
import { call, put } from 'redux-saga/effects';
import {
  GET_PROGRAM_ACTIVITY_RESULT,
  PROGRAM_ACTIVITY_TYPE_LOADING,
  PROGRAM_ACTIVITY_TYPE_STATUS,
} from '../../constants/actionType';
import {
  getProgramActivitesRequest,
  updateProgramActivitesRequest,
  insertProgramActivitesRequest,
} from '../request/programActivityRequest';
import {
  CATCH,
  ERROR,
  SUCCESS,
  FAILED,
  UPDATED,
  SUBMITTED,
  ACTIVITY_EXIST,
  INITIAL,
  INITIAL_LOAD,
} from '../../constants/common';

/**
 * @name statusClear used for clear the status
 * @yields statusClear
 */
export function* statusClear() {
  // alert('testt');
  yield put({
    type: PROGRAM_ACTIVITY_TYPE_STATUS,
    payload: { type: INITIAL, message: INITIAL_LOAD },
  });
}
/**
 * @function getProgramActivitesHandler handler function to handle program activity get request and dispatch response data to reducer state
 * @yields getProgramActivitesHandler
 */
export function* getProgramActivitesHandler() {
  try {
    yield put({ type: PROGRAM_ACTIVITY_TYPE_LOADING, payload: true });
    const getProgram = yield call(getProgramActivitesRequest);
    yield put({
      type: GET_PROGRAM_ACTIVITY_RESULT,
      payload: getProgram?.data,
    });
  } catch (err) {
    yield put({
      type: PROGRAM_ACTIVITY_TYPE_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
/**
 * @param {object} action payload data
 * @function insertProgramActivitiesHandler handler function to handle program activity post request
 * @yields insertProgramActivitiesHandler
 */
export function* insertProgramActivitiesHandler(action) {
  const { payload } = action;
  try {
    yield put({ type: PROGRAM_ACTIVITY_TYPE_LOADING, payload: true });
    const response = yield call(insertProgramActivitesRequest, payload);
    if (response.status === 200) {
      if (response?.data.status === 0) {
        yield put({
          type: PROGRAM_ACTIVITY_TYPE_STATUS,
          payload: { type: ERROR, message: ACTIVITY_EXIST },
        });
      } else {
        yield put({
          type: PROGRAM_ACTIVITY_TYPE_STATUS,
          payload: { type: SUCCESS, message: SUBMITTED },
        });
      }

      const getProgram = yield call(getProgramActivitesRequest);
      yield put({
        type: GET_PROGRAM_ACTIVITY_RESULT,
        payload: getProgram?.data,
      });
    } else {
      yield put({
        type: PROGRAM_ACTIVITY_TYPE_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    }
  } catch (err) {
    yield put({
      type: PROGRAM_ACTIVITY_TYPE_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
/**
 * @param {object} action payload data
 * @function updateProgramActivitesHandler handler function to handle program activity update request
 * @yields updateProgramActivitesHandler
 */
export function* updateProgramActivitesHandler(action) {
  const { payload } = action;
  try {
    yield put({ type: PROGRAM_ACTIVITY_TYPE_LOADING, payload: true });
    const response = yield call(updateProgramActivitesRequest, payload);
    if (response.status === 200) {
      if (response?.data.status === 0) {
        yield put({
          type: PROGRAM_ACTIVITY_TYPE_STATUS,
          payload: { type: ERROR, message: ACTIVITY_EXIST },
        });
      } else {
        yield put({
          type: PROGRAM_ACTIVITY_TYPE_STATUS,
          payload: { type: SUCCESS, message: UPDATED },
        });
      }

      const getProgram = yield call(getProgramActivitesRequest);
      yield put({
        type: GET_PROGRAM_ACTIVITY_RESULT,
        payload: getProgram?.data,
      });
    } else {
      yield put({
        type: PROGRAM_ACTIVITY_TYPE_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    }
  } catch (err) {
    yield put({
      type: PROGRAM_ACTIVITY_TYPE_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
