/* eslint-disable spaced-comment */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import BaseUrl from '../../config/config';
import {
  getPreTas,
  insertPreTasDistrictStrategy,
  updatePreTasId,
  updatePreTas,
  getProposedDatePreTas,
  bulkUploadPreTAS,
  insertPreTasBlockStrategy,
  getBlockName,
  getEuName,
  bulkUploadPreTasBlock,
} from '../../utils/api';

const userAurhId = localStorage.getItem('UserLoginId');
console.log(userAurhId, 'userAurhId');
/**
 * @param {object} data payload
 * @name getPreTasRequest used for dashboard api call
 * @returns {Array} returns the axios output
 */
export const getPreTasRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getPreTas}`,
    data,
  });
};
/**
 * @param {object} data payload
 * @name insertPreTasRequest used for dashboard api call
 * @returns {Array} returns the axios output
 */
export const insertPreTasRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${insertPreTasDistrictStrategy}`,
    data: {
      proposed_program_activities: data?.activity,
      state_id: data?.State.toString(),
      district_id: data?.District.toString(),
      block_strategy_id: data?.Strategy.toString(),
      random: data?.randomCount,
      block_count: '0',
      population: data.Total_Population,
      date_proposed: data?.Date_Proposed_by_NVBDCP,
      date_planned: data?.Date_Planned_by_state,
      date_completed: data?.Pre_Tas_Date_Completed,
      test_type_id: data?.Test_Type.toString(),
      survey_date: data?.survey_Date_AutoPopulate,
      preTasDistrictSurvey: data?.preTasDistrictSurvey,
      created_by: userAurhId && userAurhId,
    },
  });
};

/**
 * @param {object} data payload
 * @name insertPreTasBlockStrategyRequest used for dashboard api call
 * @returns {Array} returns the axios output
 */
export const insertPreTasBlockStrategyRequest = (data) => {
  console.log(data, 'as;dkfjsoidhro');
  return axios({
    method: 'post',
    url: `${BaseUrl}/${insertPreTasBlockStrategy}`,
    data: {
      proposed_program_activities: 'Pre-TAS',
      state_id: data?.State,
      district_id: data?.District,
      block_strategy_id: data?.Strategy,
      block_count: data?.countBlock,
      random: '3',
      // population: '2000',
      date_proposed: data?.Date_Proposed_by_NVBDCP,
      date_planned: data?.Date_Planned_by_state,
      population: data.Population_Under_Pre_TAS || 0,
      date_completed: data?.Survey_Date_Completed,
      preTasBlockSurvey: data?.preTasDistrictSurvey,
      created_by: userAurhId && userAurhId,
    },
  });
};

/**
 * @param {object} data payload data
 * @name updatepreTasIdRequest function which make api request to update Demographics.
 * @returns {*} returns axios response data
 */
export const updatepreTasIdRequest = (data) => {
  return axios({
    method: 'put',
    url: `${BaseUrl}/${updatePreTasId}`,
    data: {
      proposed_program_activities: 'Pre-TAS',
      state: data?.data[0].State,
      district: data?.data[0].district,
      block_strategy: data?.data[0].Strategy,
      block: data?.data[0].Block,
      random: '1',
      date_proposed: data?.data[0].DateProposed,
      date_planned: data?.data[0].DatePlanned,
      date_completed: data?.data[0].Datecomplete,
      pre_tas_id: data?.id,
      preTasSurvey: [
        {
          test_type: data?.data[0].Testtype,
          pre_tas_survey_date: data?.data[0].NBS_Survey_Date,
          random_name: data?.data[0].Positive_R3,
          random_blood_count: data?.data[0].Positive_R4,
          random_possitive: data?.data[0].Positive_R5,
          pre_tas_sites_blocks_id: '6',
          delete_status: '1',
        },
      ],
      updated_by: userAurhId && userAurhId,
    },
  });
};
/**
 * @param {object} data payload data
 * @name updatepreTasRequest function which make api request to update Demographics.
 * @returns {*} returns axios response data
 */
export const updatePreTasRequest = (data) => {
  return axios({
    method: 'put',
    url: `${BaseUrl}/${updatePreTas}`,
    data: {
      overAllPreTasUpdate: data,
      updated_by: userAurhId && userAurhId,
    },
  });
};

/**
 * @param {object} data payload
 * @name getProposedDatePreTasRequest used for dashboard api call
 * @returns {Array} returns the axios output
 */
export const getProposedDatePreTasRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getProposedDatePreTas}`,
    data: {
      state: data.params.State,
      district: data.params.District,
      strategy: data.params.Strategy,
    },
  });
};
// /**
//  *
//  * @param  {object} data payload data
//  * @returns {*} returns axios response data
//  */
// export const bulkUploadPreTasRequest = (data) => {
//   const formData = new FormData();
//   formData.append('file_upload', data);
//   formData.set('created_by', 1);
//   return axios({
//     method: 'post',
//     url: `${BaseUrl}/${bulkUploadPreTAS}`,
//     data: formData,
//   });
// };

/**
 *
 * @param  {object} data payload data
 * @returns {*} returns axios response data
 */
export const bulkUploadPreTasRequest = (data) => {
  console.log(data?.block_strategy_id, 'llskkeksdfsdf');
  // const formData = new FormData();
  // formData.append('file_upload', data);
  // formData.set('created_by', 1);
  return axios({
    method: 'post',
    url: `${BaseUrl}/${
      data?.data?.[0]?.block_strategy_id.toLowerCase() === 'Block Strategy'.toLowerCase()
        ? bulkUploadPreTasBlock
        : bulkUploadPreTAS
    }`,
    data: {
      created_by: userAurhId && userAurhId,
      bulk_insert_preTas: data?.data,
    },
  });
};

////Block Name /////
/**
 * @param {object} data payload
 * @name getBlockNameRequest used for dashboard api call
 * @returns {Array} returns the axios output
 */
export const getBlockNameRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getBlockName}`,
    data,
  });
};

//EUname///
/**
 * @param {object} data payload
 * @name getBlockNameRequest used for dashboard api call
 * @returns {Array} returns the axios output
 */
export const getEUNameRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getEuName}`,
    data,
  });
};
