import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as _ from 'loadsh';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CustomForm from '../../../../components/organisms/CustomForm';
import {
  INSERT_DRUG_MASTERS,
  UPDATE_DRUG,
  GET_DRUG,
  GET_DISTRICT_DROPDOWN,
  GET_STATE_DROPDOWN,
  GET_DRUG_REGIME,
  GET_DRUG_TYPE,
} from '../../../../constants/actionType';
import './Form.css';
/**
 * @param {object} props required props in Form input
 * @returns {React.ReactComponent} BuyForm
 */
const FormInput = (props) => {
  const { entries, defaultValues, isEdit, setIsFormEdited } = props;
  const dispatch = useDispatch();
  const [regimeData, setRegimeData] = useState();
  const [typeData, setTypeData] = useState();
  const dropdownList = useSelector((state) => state.dropDownReducer);
  const getDrugRegime = useSelector((state) => state.drugTypeDetails);
  const getDrugTypeDropdown = useSelector((state) => state.drugTypeDetails);

  const getEntries = React.useMemo(() => {
    return entries.map((value) => {
      if (value.entryName === 'state') {
        value.data = _.assign(dropdownList.stateData);
      }
      if (value.entryName === 'district') {
        if (dropdownList.districtData) value.disabled = false;
        value.data = _.assign(dropdownList.districtData);
      }
      if (value.entryName === 'drug_regime') {
        value.data = _.assign(regimeData);
      }
      if (value.entryName === 'drug_type') {
        value.data = _.assign(typeData);
      }
      return value;
    });
  }, [entries, dropdownList, regimeData, typeData]);
  React.useEffect(() => {
    dispatch({ type: GET_STATE_DROPDOWN });
  }, [dispatch]);
  React.useEffect(() => {
    dispatch({ type: GET_DRUG_REGIME });
    dispatch({ type: GET_DRUG_TYPE });
    dispatch({ type: GET_DRUG });
  }, [dispatch]);

  useEffect(() => {
    if (getDrugRegime) {
      const regime = [];
      getDrugRegime?.getDrugRegime?.map((data) => {
        regime.push({
          id: data?.drug_regime_id,
          value: data?.drug_regime,
        });
        return null;
      });
      setRegimeData(regime);
    }
  }, [getDrugRegime]);
  const countRangeData = React.useCallback(() => {
    return [({ labelText: 'EU Count' }, { labelText: 'Block Count' })];
  }, []);

  useEffect(() => {
    if (getDrugTypeDropdown) {
      const type = [];
      getDrugTypeDropdown?.getDrugTypeDropdown?.map((data) => {
        type.push({
          id: data?.drug_type_id,
          value: data?.drug_type,
        });
        return null;
      });
      setTypeData(type);
    }
  }, [getDrugTypeDropdown]);

  /**
   *
   * @param {*} data -- Form Entries data
   */
  const formSubmitHandle = React.useCallback(
    async (data) => {
      if (isEdit) {
        await dispatch({ type: UPDATE_DRUG, payload: data });
        await setIsFormEdited();
        await dispatch({ type: GET_DRUG });
      } else {
        await dispatch({ type: INSERT_DRUG_MASTERS, payload: data });
      }
      await dispatch({ type: GET_DRUG });
    },
    [dispatch, isEdit, setIsFormEdited],
  );

  /**
   * @param {number} data - data
   * @param {string} name - name
   * @function getSelectedDropdown
   */
  const getSelectedDropdown = React.useCallback(
    (data, name) => {
      if (name === 'state') {
        const param = { state: data };
        dispatch({ type: GET_DISTRICT_DROPDOWN, payload: param });
      }
    },
    [dispatch],
  );

  return (
    <Grid container item md={12}>
      <Grid item md={12} className="formContainer" sx={{ paddingTop: 2, paddingBottom: 4 }}>
        <CustomForm
          item
          entries={getEntries}
          defaultValues={defaultValues}
          countRange={countRangeData}
          masterSetup
          onReceiveData={(d) => formSubmitHandle(d)}
          getSelectedDropdown={(data, name) => getSelectedDropdown(data, name)}
          SubmitBtnTitle={!isEdit ? 'Submit' : 'Update'}
          screen="masters"
        />
      </Grid>
    </Grid>
  );
};
export default FormInput;
FormInput.propTypes = {
  entries: PropTypes.shape(),
  defaultValues: PropTypes.shape(),
  isEdit: PropTypes.bool,
  setIsFormEdited: PropTypes.func.isRequired,
};

FormInput.defaultProps = {
  entries: {},
  defaultValues: {},
  isEdit: false,
};
