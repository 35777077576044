import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'loadsh';
import PropTypes from 'prop-types';
import CustomForm from '../../../../components/organisms/CustomForm';
import { formDefaultValues } from './formEntries-Dossier';
import {
  GET_STATE_DROPDOWN,
  GET_DISTRICT_DROPDOWN,
  GET_DOSSIER,
  INSERT_DOSSIER,
} from '../../../../constants/actionType';
import './Dossier-Form.css';
/**
 * @param {object} props - required props in IDA form input
 * @returns {React.ReactComponent} BuyForm
 */
const DossierFormInput = (props) => {
  const { onViewHandle, formEntries } = props;
  const [defaultFormValues, setDefaultForm] = useState(formDefaultValues);
  const dropdownList = useSelector((state) => state.dropDownReducer);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch({ type: GET_STATE_DROPDOWN, GET_DOSSIER });
  }, [dispatch]);

  /**
   * @param {number} data - data
   * @param {string} name - name
   * @function getSelectedDropdown
   */
  const getSelectedDropdownValue = React.useCallback(
    (data, name) => {
      if (name === 'state') {
        const param = { state: data };
        dispatch({ type: GET_DISTRICT_DROPDOWN, payload: param });
      }
    },
    [dispatch],
  );

  /**
   *
   * @param {*} data returns form data
   * @function handleFormSubmit
   */
  const handleSubmit = React.useCallback(
    async (data) => {
      await dispatch({ type: INSERT_DOSSIER, payload: data });
      dispatch({ type: GET_DOSSIER });
      setDefaultForm(defaultFormValues);
    },
    [dispatch, defaultFormValues],
  );
  const getEntries = React.useMemo(() => {
    return formEntries.map((value) => {
      if (value.entryName === 'state') {
        value.data = _.assign(dropdownList.stateData);
      }
      if (value.entryName === 'district') {
        if (dropdownList.districtData) value.disabled = false;
        value.data = _.assign(dropdownList.districtData);
      }

      return value;
    });
  }, [formEntries, dropdownList]);
  const form = React.useMemo(() => {
    return (
      <CustomForm
        entries={getEntries}
        defaultValues={defaultFormValues}
        onViewHandle={onViewHandle}
        dataInput
        onReceiveData={(d) => handleSubmit(d)}
        getSelectedDropdown={(data, name) => getSelectedDropdownValue(data, name)}
        screen="input"
      />
    );
  }, [defaultFormValues, handleSubmit, getEntries, onViewHandle, getSelectedDropdownValue]);

  return (
    <Grid container item md={12}>
      <Grid item md={12} className="formContainer">
        {form}
      </Grid>
    </Grid>
  );
};
export default DossierFormInput;
DossierFormInput.propTypes = {
  onViewHandle: PropTypes.func,
  formEntries: PropTypes.arrayOf({}),
};
DossierFormInput.defaultProps = {
  onViewHandle: () => {},
  formEntries: [],
};
