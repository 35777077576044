/* eslint-disable no-unused-vars */
import * as React from 'react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import './headerStyles.css';
import { useDispatch } from 'react-redux';
import {
  AppBar,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Avatar,
  Box,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import ListingItem from '../../components/molecules/ListItems/ListItems';
import CustomIcons from '../../utils/icons/index';
import Typography from '../../components/atoms/Typography';
import ChangePassword from '../../components/molecules/ChangePassword';
import { USER_LOGOUT } from '../../constants/actionType';

/**
 * @name CustomAppBar
 * @returns {React.ReactElement} returns the CustomAppBar component
 */
const CustomAppBar = () => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [showChangePassword, setShowChangePassword] = React.useState(false);
  const [closeModel, setCloseModel] = React.useState(false);
  const [controlDataInput, setControlDataInput] = React.useState(true);
  const [controlMasters, setControlMasters] = React.useState(false);
  const dispatch = useDispatch();
  const ValidationGroup = localStorage.getItem('userGroupId');
  const ValidationType = localStorage.getItem('userTypeId');
  const userId = localStorage.getItem('userId');
  const ScreenAccessData = localStorage.getItem('ScreenAccessRole');
  React.useEffect(() => {
    if (
      (ValidationGroup === '5' && ValidationType === '2') ||
      (ValidationGroup === '4' && ValidationType === '3') ||
      (ValidationGroup === '0' && ValidationType === '4')
    ) {
      setControlDataInput(false);
    }
    if (
      (ValidationGroup === '5' && ValidationType === '2') ||
      (ValidationGroup === '8' && ValidationType === '5') ||
      (ValidationGroup === '0' && ValidationType === '4')
    ) {
      setControlMasters(true);
    }
  }, [ValidationGroup, ValidationType]);

  const content = [
    ScreenAccessData?.includes('output_dashboard') && { label: 'Dashboard', path: '/dashboard' },
    ScreenAccessData?.includes('master_setup') && {
      label: 'Master SetUp',
      path: '/master-setup/user-role',
    },
    ScreenAccessData?.includes('data_input') && {
      label: 'Data Input',
      path: '/data-input/DrugAvailability',
    },
  ];
  const profileData = [
    // {
    //   id: 1,
    //   menuIcon: <img src={CustomIcons.Switch} alt="icon" className="iconStyling" />,
    //   menuText: 'Switch to Executives',
    // },
    {
      id: 2,
      menuIcon: <img src={CustomIcons.ChangePassword} alt="icon" className="iconStyling" />,
      menuText: 'Change Password',
    },
    {
      id: 3,
      menuIcon: <img src={CustomIcons.Help} alt="icon" className="iconStyling" />,
      menuText: 'Help',
    },
    {
      id: 4,
      menuIcon: <img src={CustomIcons.Logout} alt="icon" className="iconStyling" />,
      menuText: 'Logout',
    },
  ];

  /**
   * @name handleProfileMenuOpen
   * @param {*} event describes the event
   */
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    const rotate = document.querySelector('.box');
    rotate.classList.add('transform');
  };
  /**
   * @name handleMobileMenuClose
   */
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  /**
   * @name handleMenuClose
   */
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    const menuClose = document.querySelector('.box');
    menuClose.classList.remove('transform');
  };

  /**
   * @name handleClose
   */
  const handleClose = () => {
    setAnchorE2(null);
  };
  /**
   *
   * @param {*} id - passing data id
   */
  const onChooseListItems = (id) => {
    if (id === 2) {
      setShowChangePassword(true);
    }
    if (id === 4) {
      dispatch({ type: USER_LOGOUT, payload: userId });
      localStorage.setItem('loggedIn', 2);
      localStorage.clear();
      history.push('/');
    }
  };
  /**
   *
   */
  const onClickCancelModel = () => {
    setCloseModel(false);
  };
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose} className="profile">
        <ListItemIcon>
          <Avatar
            alt="Image"
            src="http://www.goodmorningimagesdownload.com/wp-content/uploads/2020/06/Alone-Boys-Girls-Images-6.jpg"
            className="profileICon"
            style={{ height: '30px', width: '30px' }}
          />
        </ListItemIcon>
        <ListItemText>{JSON.parse(localStorage.getItem('userName'))}</ListItemText>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <ListingItem profileItems={profileData} onChooseListItem={(id) => onChooseListItems(id)} />
      </MenuItem>
    </Menu>
  );
  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton color="inherit">
          <AccountCircle />
        </IconButton>
      </MenuItem>
    </Menu>
  );

  /**
   * @param {string} data passing the path
   * @function onMenuItemClick handle active menu
   */
  const onMenuItemClick = (data) => {
    history.push(data);
  };
  return (
    <>
      <AppBar position="relative">
        <Grid item md={12} xs={12} sm={12} className="appBarBackgroundColor">
          <Toolbar className="noPadding" sx={{ display: { xs: 'block', sm: 'block', md: 'flex' } }}>
            <Grid className="partOne" item md={4} xs={12} sm={12}>
              <div className="dash-icon">
                <img src={CustomIcons.Dashboard} alt="menu" className="iconStyling" />
              </div>
              <Typography
                text="Lymphatic Filariasis Tracking Tool"
                colorType="secondary"
                customClass="title-font"
                type="h4"
              />
            </Grid>
            <Grid item md={8} xs={12} sm={12} className="secondHalf">
              <Grid className="dashboardContent">
                <ListingItem content={content} menuClick={(e) => onMenuItemClick(e)} />
              </Grid>

              <Box sx={{ display: { xs: 'flex', md: 'flex', sm: 'flex' } }}>
                {/* <div className="text">
                  <img src={CustomIcons.Mail} alt="icon" className="mailIcon" />
                </div> */}
                <Grid className="openMenu" onClick={handleProfileMenuOpen}>
                  <Typography
                    text={JSON.parse(localStorage.getItem('userName'))}
                    colorType="secondary"
                    customClass="profileHeader"
                    type="caption"
                    customStyle={{ display: { xs: 'none', sm: 'block', md: 'block' } }}
                  />
                  <Grid className="openMenuItem">
                    <Grid className="grade" sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}>
                      <Typography
                        text={JSON.parse(localStorage.getItem('userType'))}
                        colorType="secondary"
                        customClass="option"
                        type="link"
                      />
                    </Grid>
                    <Grid className="downArrowGrid" sx={{ display: { xs: 'block' }, pt: { xs: '14px', sm: 0, md: 0 } }}>
                      <img src={CustomIcons.DownArrowSvg} alt="arrow" aria-hidden="true" className="box" />
                    </Grid>
                  </Grid>
                </Grid>
                <Menu
                  id="menu-appBar"
                  anchorEl={anchorE2}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorE2)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose} />
                </Menu>
                {/* <IconButton color="inherit" sx={{ display: { xs: 'none', md: 'block', sm: 'none' } }}>
                  <SettingsApplicationsIcon />
                </IconButton> */}
                <IconButton edge="end" aria-controls={menuId} aria-haspopup="true" className="avatarBtn">
                  <Avatar
                    alt="Image"
                    style={{ height: '30px', width: '30px' }}
                    className="avatarIcon"
                    src="http://www.goodmorningimagesdownload.com/wp-content/uploads/2020/06/Alone-Boys-Girls-Images-6.jpg"
                  />
                </IconButton>
              </Box>
            </Grid>
          </Toolbar>
        </Grid>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {showChangePassword && <ChangePassword onClickCancel={(data) => setShowChangePassword(data)} />}
    </>
  );
};
export default CustomAppBar;
