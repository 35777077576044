/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useEffect } from 'react';
import { Grid, Divider } from '@mui/material';
import { useLocation } from 'react-router-dom';
import MasterData from './masterData';
import ListingItem from '../../components/molecules/ListItems/ListItems';
import Typography from '../../components/atoms/Typography';
import './MasterSetup.css';
/**
 *
 * @returns {React.ReactElement} - returns the data input screens
 */
const MasterSetup = () => {
  const Location = useLocation();
  /**
   *
   */
  const masterSelect = () => {};
  useEffect(() => {
    MasterData.find((item) => {
      const val = item.nav === Location.pathname;
      if (val) {
        masterSelect(item.id);
      }
    });
  }, []);
  return (
    <Grid className="breadCrumbsView" container item md={12} sx={{ display: 'flex' }}>
      <Grid className="heading " item md={1} sm={12} xs={12}>
        <Typography type="subHeading" text="Master" customClass="divider" colorType="quinary" />
      </Grid>
      <Divider orientation="vertical" flexItem sx={{ border: 1, opacity: 0.1 }} item md={1} />

      <Grid item md={10} sm={12} xs={12} sx={{ display: 'flex' }}>
        <ListingItem masterDataItem={MasterData} masterSelect={masterSelect} />
      </Grid>
    </Grid>
  );
};

export default MasterSetup;
