import { takeLatest } from 'redux-saga/effects';
import {
  GET_NEWUSER_LIST,
  UPDATE_NEWUSER_LIST,
  INSERT_NEWUSER_LIST,
  DELETE_NEWUSER_LIST,
  USER_TYPE,
  USER_GROUP,
  GET_USER_MASTER,
  INSERT_USER_MASTER,
  UPDATE_USER_MASTER,
} from '../../constants/actionType';
import { INITIAL_LOAD } from '../../constants/common';
import {
  getNewUserHandler,
  insertNewUserHandler,
  updateNewUserHandler,
  deleteNewUserHandler,
  getUserTypeHandler,
  getUserGroupHandler,
  getUserMasterHandler,
  insertUserMasterHandler,
  updateUserMasterHandler,
  statusClear,
} from '../handler/newUserHandler';

/**
 * @name newUserSaga used for program activities api call
 * @yields newUserSaga
 */
function* newUserSaga() {
  yield takeLatest(INITIAL_LOAD, statusClear);
  yield takeLatest(GET_NEWUSER_LIST, getNewUserHandler);
  yield takeLatest(INSERT_NEWUSER_LIST, insertNewUserHandler);
  yield takeLatest(UPDATE_NEWUSER_LIST, updateNewUserHandler);
  yield takeLatest(DELETE_NEWUSER_LIST, deleteNewUserHandler);
  yield takeLatest(USER_TYPE, getUserTypeHandler);
  yield takeLatest(USER_GROUP, getUserGroupHandler);
  yield takeLatest(GET_USER_MASTER, getUserMasterHandler);
  yield takeLatest(INSERT_USER_MASTER, insertUserMasterHandler);
  yield takeLatest(UPDATE_USER_MASTER, updateUserMasterHandler);
}
export default newUserSaga;
