import { takeLatest } from 'redux-saga/effects';
import {
  GET_IDA,
  INSERT_IDA,
  UPDATE_IDA,
  GET_PROPOSED_DATE_IDA,
  UPDATE_IDA_BY_ID,
  UPLOAD_MDA_IDA,
  INSERT_BLOCK,
  INSERT_MDA_CHECK,
} from '../../constants/actionType';
import {
  getIDAHandler,
  insertIDAHandler,
  updateIDAHandler,
  getProposedDateIDAHandler,
  updateIDAByIdHandler,
  uploadMdaIdaHandler,
  insertBlockHandler,
  statusClear,
} from '../handler/IDAHandler';

/**
 * @name IDASaga used for IDA input api call
 * @yields IDASaga
 */
function* IDASaga() {
  yield takeLatest(INSERT_MDA_CHECK, statusClear);
  yield takeLatest(GET_IDA, getIDAHandler);
  yield takeLatest(INSERT_IDA, insertIDAHandler);
  yield takeLatest(UPDATE_IDA, updateIDAHandler);
  yield takeLatest(GET_PROPOSED_DATE_IDA, getProposedDateIDAHandler);
  yield takeLatest(UPDATE_IDA_BY_ID, updateIDAByIdHandler);
  yield takeLatest(UPLOAD_MDA_IDA, uploadMdaIdaHandler);
  yield takeLatest(INSERT_BLOCK, insertBlockHandler);
}
export default IDASaga;
