const ActivityData = [
  {
    text: 'Drug Availability',
    sub: [
      {
        color: 'warning',
        title: 'Drug Availability',
        NavLink: '/data-input/DrugAvailability',
        id: 8,
      },
    ],
  },
  {
    text: 'MDA',
    sub: [
      {
        color: 'quaternary',
        title: 'IDA',
        NavLink: '/data-input/IDA',
        id: 1,
      },
      {
        color: 'quaternary',
        title: 'DA',
        NavLink: '/data-input/DA',
        id: 2,
      },
    ],
  },
  {
    text: 'Pre-TAS',
    sub: [
      {
        color: 'senary',
        title: 'Pre-TAS',
        NavLink: '/data-input/Pre-TAS',
        id: 3,
      },
    ],
  },
  {
    text: 'TAS',
    sub: [
      {
        color: 'info',
        title: 'TAS1',
        NavLink: '/data-input/TAS1?type=TAS1',
        id: 4,
      },
      {
        color: 'info',
        title: 'TAS2',
        NavLink: '/data-input/TAS2?type=TAS2',
        id: 5,
      },
      {
        color: 'info',
        title: 'TAS3',
        NavLink: '/data-input/TAS3?type=TAS3',
        id: 6,
      },
    ],
  },
  {
    text: 'Dossier',
    sub: [
      {
        color: 'success',
        title: 'Dossier',
        NavLink: '/data-input/Dossier',
        id: 7,
      },
    ],
  },
  {
    text: 'Lymphedema',
    sub: [
      {
        color: 'quinary',
        title: 'Lymphedema/Hydrocele Cases',
        NavLink: '/data-input/Lymphedema',
        id: 9,
      },
    ],
  },
  // {
  //   text: 'Drug Availability',
  //   sub: [
  //     {
  //       color: 'warning',
  //       title: 'Drug Availability',
  //       NavLink: '/data-input/DrugAvailability',
  //       id: 8,
  //     },
  //   ],
  // },
  // {
  //   text: 'New',
  //   sub: [
  //     {
  //       color: 'quinary',
  //       title: 'New',
  //       NavLink: '/data-input/New',
  //       id: 9,
  //     },
  //   ],
  // },
];
export default ActivityData;
