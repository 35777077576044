import React from 'react';
import './main.css';
import { Route } from 'react-router-dom';
import HeaderNavBar from '../header';
import Dashboard from '../../pages/Dashboard';
import DataInput from '../../pages/DataInput';
import IDAInput from '../../pages/DataInput/MDA/IDA';
import DAInput from '../../pages/DataInput/MDA/DA';
import PreTASInput from '../../pages/DataInput/Pre-TAS';
import TAS1Input from '../../pages/DataInput/TAS/TAS1';
import TAS2Input from '../../pages/DataInput/TAS/TAS2';
import TAS3Input from '../../pages/DataInput/TAS/TAS3';
import DossierInput from '../../pages/DataInput/Dossier';
import LymphedemaInput from '../../pages/DataInput/Lymphedema';
import DrugAvailability from '../../pages/DataInput/DrugAvailability';
import MasterSetup from '../../pages/MasterSetup';
import UserRole from '../../pages/MasterSetup/UserRole/Index';
import NewUserCreation from '../../pages/MasterSetup/CreateUser';
import CreateProgram from '../../pages/MasterSetup/CreateProgramActivity';
import Threshold from '../../pages/MasterSetup/Threshold';
import GeographicDimension from '../../pages/MasterSetup/GeographicDimension';
import FormulaConfiguration from '../../pages/MasterSetup/FormulaConfigure';
import DemographicConfiguration from '../../pages/MasterSetup/DemoGraphic';
import DrugType from '../../pages/MasterSetup/CreateDrugType';
import CreateProgramStatus from '../../pages/MasterSetup/ProgramStatus';
import New from '../../pages/DataInput/New';
/**
 *
 * @returns {React.ReactElement} - returns the main layout
 */
const Main = () => {
  return (
    <div className="layout">
      <div className="header">
        <HeaderNavBar />
      </div>
      <div className="main_content">
        <Route path="/dashboard" component={Dashboard} exact />
        <Route path="/data-input" component={DataInput} />
        <Route path="/data-input/IDA" component={IDAInput} />
        <Route path="/data-input/DA" component={DAInput} />
        <Route path="/data-input/Pre-TAS" component={PreTASInput} />
        <Route path="/data-input/TAS1" component={TAS1Input} />
        <Route path="/data-input/TAS2" component={TAS2Input} />
        <Route path="/data-input/TAS3" component={TAS3Input} />
        <Route path="/data-input/Dossier" component={DossierInput} />
        <Route path="/data-input/Lymphedema" component={LymphedemaInput} />
        <Route path="/data-input/DrugAvailability" component={DrugAvailability} />
        <Route path="/data-input/New" component={New} />
        <Route path="/master-setup" component={MasterSetup} />
        <Route path="/master-setup/user-role" component={UserRole} />
        <Route path="/master-setup/new-user" component={NewUserCreation} />
        <Route path="/master-setup/program-activity" component={CreateProgram} />
        <Route path="/master-setup/threshold" component={Threshold} />
        <Route path="/master-setup/geography" component={GeographicDimension} />
        <Route path="/master-setup/formula" component={FormulaConfiguration} />
        <Route path="/master-setup/demographic" component={DemographicConfiguration} />
        <Route path="/master-setup/drug-type" component={DrugType} />
        <Route path="/master-setup/program-status" component={CreateProgramStatus} />
      </div>
    </div>
  );
};
export default Main;
