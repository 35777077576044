import { takeLatest } from 'redux-saga/effects';
import { GET_MASTER_TEMPLATES } from '../../constants/actionType';
import getMasterTemplateHandler from '../handler/masterTemplateHandler';

/**
 * @name masterTemplateSaga used for program activities api call
 * @yields masterTemplateSaga
 */
function* masterTemplateSaga() {
  yield takeLatest(GET_MASTER_TEMPLATES, getMasterTemplateHandler);
}
export default masterTemplateSaga;
