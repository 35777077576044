import { takeLatest } from 'redux-saga/effects';
import { FILTER_DRIVEN_EXTRACT_REPORT, CUSTOMREPORT_BY_TYPE } from '../../constants/actionType';
import { filterDrivenExtractReportsHandler, customReportByTypeHandler } from '../handler/customReportHandler';

/**
 * @name CustomReportSaga used for program activities api call
 * @yields CustomReportSaga
 */
function* CustomReportSaga() {
  yield takeLatest(FILTER_DRIVEN_EXTRACT_REPORT, filterDrivenExtractReportsHandler);
  yield takeLatest(CUSTOMREPORT_BY_TYPE, customReportByTypeHandler);
}
export default CustomReportSaga;
