import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '../../atoms/Typography';
import CustomIcons from '../../../utils/icons';
import './headerSectionStyle.css';

/**
 * @name HeaderSection
 * @param {object} props - required props in custom typography
 * @returns {React.ReactElement} return the header section component
 */
const HeaderSection = (props) => {
  const { label } = props;
  return (
    <Grid container item md={12} xs={12} sm={12} style={{ width: 'fullWidth' }} className="mainContainer">
      <Typography text={label} type="subHeading" colorType="secondary" customClass="textStyle" />
      {(label === 'Report Status' || label === 'District MDA Profile') && (
        <img src={CustomIcons.Disclaimer} alt="Disclaimer icon" className="Disclaimer" style={{ width: 30 }} />
      )}
    </Grid>
  );
};
export default HeaderSection;
HeaderSection.propTypes = {
  label: PropTypes.string.isRequired,
};
