import axios from 'axios';
import BaseUrl from '../../config/config';
import {
  getDemoghrapicDimension,
  insertDemoghrapicDimension,
  updateDemoghrapicDimension,
  uploadDemoghrapicDimension,
} from '../../utils/api';

const userAurhId = localStorage.getItem('UserLoginId');

/**
 * @name getDemoghrapicDimension function which make api request to get Demographics.
 * @returns {*} returns axios response data
 */
export const getDemoghrapicDimensions = () => {
  return axios.get(`${BaseUrl}/${getDemoghrapicDimension}`);
};

/**
 * @param {object} data payload data
 * @name updateProgramActivitesRequest function which make api request to update Demographics.
 * @returns {*} returns axios response data
 */
export const updateDemoghrapicDimensions = (data) => {
  data.updated_by = userAurhId && userAurhId;

  if (data.status === 'Active') {
    data.status = 1;
  } else {
    data.status = 0;
  }
  return axios({
    method: 'put',
    url: `${BaseUrl}/${updateDemoghrapicDimension}`,
    data,
  });
};

/**
 * @param {object} data payload data
 * @name insertDemoghrapicDimension function which make api request to add  Demographics.
 * @returns {*} returns axios response data
 */
export const insertDemoghrapicDimensions = (data) => {
  data.created_by = userAurhId && userAurhId;
  if (data.status === 'Inactive') {
    data.status = 0;
  } else {
    data.status = 1;
  }
  return axios({
    method: 'post',
    url: `${BaseUrl}/${insertDemoghrapicDimension}`,
    data,
  });
};
/**
 * @param {object} data payload data
 * @name insertFormulaConfigRequest function which make api request to add  formula configuration.
 * @returns {*} returns axios response data
 */
export const uploadDemographicDimensionRequest = (data) => {
  // const formData = new FormData();
  // formData.append('file_upload', data);
  // formData.set('created_by', 1);
  return axios({
    method: 'post',
    url: `${BaseUrl}/${uploadDemoghrapicDimension}`,
    data,
  });
};
