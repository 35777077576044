/* eslint-disable no-unused-vars */
/* eslint-disable radix */
/* eslint-disable require-jsdoc */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-dupe-else-if */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-return-assign */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
// import Tooltip from '@mui/material/Tooltip';
// import TooltipTitle from '../../../components/atoms/Tooltip/TooltipContent';
import FileGenerator from '../../../components/organisms/FileGenerator';
import headers from './tableEntries';
import CustomRadioButton from '../../../components/atoms/RadioButton';
import Table from '../../../components/organisms/Table';
import {
  GET_STATE_DROPDOWN,
  GET_DISTRICT_DROPDOWN,
  FILTER_DRIVEN_EXTRACT_REPORT,
  CUSTOMREPORT_BY_TYPE,
  GET_LYMPHEDEMA,
} from '../../../constants/actionType';
import './style.css';
/**
 * @param  {object} props - required props
 * @param {Array} props.showDisplay - showDisplay
 * @returns {React.ReactElement} -  returns the custom report component
 */
const CustomReport = ({ showDisplay }) => {
  const [customRadioData, setCustomRadioData] = useState('Filter Driven Extract Reports');
  const customReportData = [{ name: 'Filter Driven Extract Reports' }, { name: 'Standard Reports' }];
  const [districtDetails, setDistrictDetails] = useState([]);
  const [blockDetails, setBlockDetails] = useState([]);
  const [filterDetails, setFilterDetails] = useState({
    year: '',
    strategy: '',
    state: '',
    district: '',
    activity: '',
    status: '',
    customReportId: 1,
  });

  const dispatch = useDispatch();
  const dropdownList = useSelector((state) => state.dropDownReducer);
  const { customReportsType, fitlerExtractReports } = useSelector((state) => state.CustomReportReducer);

  const isNaNFixed = (value) => {
    return isNaN(value) || value === null || value === undefined ? '' : Number(value.toFixed(2));
  };

  useEffect(() => {
    dispatch({ type: GET_STATE_DROPDOWN });
    dispatch({ type: FILTER_DRIVEN_EXTRACT_REPORT, payload: filterDetails, filterType: customRadioData });
    dispatch({ type: CUSTOMREPORT_BY_TYPE, payload: customRadioData === 'Standard Reports' ? 'Standard' : 'FDER' });
  }, [dispatch]);
  useEffect(() => {
    const arrList = [];
    const arrSublist = [];
    if (fitlerExtractReports) {
      if (
        filterDetails?.customReportId !== 18 &&
        filterDetails?.customReportId !== 20 &&
        filterDetails?.customReportId !== 21 &&
        customRadioData === 'Standard Reports'
      ) {
        setDistrictDetails(fitlerExtractReports);
      } else if ([3, 4, 5, 6, 18, 20, 21].includes(filterDetails?.customReportId)) {
        const nbsHeader = [
          'state',
          'district',
          'details',
          'test_type',
          'nbs_survey_date',
          'proposed_program_activities',
          'date_completed',
          // 'MDA_IDA_id',
          'percentage',
          'mf_criteria_rate',
        ];
        // const nbsBlock = ['state', 'district', 'block_name', 'proposed_program_activities', 'details'];

        const district = filterDetails?.customReportId === 20 ? fitlerExtractReports : fitlerExtractReports[0];
        const blocklist =
          filterDetails?.customReportId === 18
            ? null
            : filterDetails?.customReportId === 21
            ? fitlerExtractReports
            : fitlerExtractReports[1];
        if (filterDetails?.customReportId === 3 || filterDetails?.customReportId === 18) {
          district &&
            district.length > 0 &&
            district.forEach((toresult) => {
              let obj = {};
              nbsHeader.forEach((val) => {
                if (
                  val === 'state' ||
                  val === 'district' ||
                  val === 'proposed_program_activities' ||
                  val === 'date_completed' ||
                  val === 'MDA_IDA_id'
                ) {
                  obj[val] = toresult[val];
                } else if (val === 'test_type') {
                  obj[val] = (toresult.details[0] && toresult.details[0][val]) || '';
                } else if (val === 'nbs_survey_date') {
                  obj[val] = toresult.details.length > 0 && toresult.details[0][val];
                  obj.year = moment(new Date(toresult.year)).format('YYYY');
                } else if (val === 'percentage') {
                  let mf = 0;
                  for (let index = 0; index <= 7; index++) {
                    const datas = toresult.details[index] && toresult.details[index];
                    obj[`site${index + 1}%`] = isNaNFixed(
                      (Number(datas?.site_positive) / Number(datas?.site_bse)) * 100,
                    );
                    // eslint-disable-next-line radix
                    mf += parseInt((Number(datas?.site_positive) / Number(datas?.site_bse)) * 100);
                  }
                  // obj.mf_criteria_rate = mf >= 1 ? 'Failed' : 'Qualified';
                  // obj.mf_criteria_rate = mf >= 1 ? 'Failed' : 'Qualified';
                  obj.mf_criteria_rate = toresult?.status;
                } else if (val === 'details') {
                  if (toresult) {
                    const objBN = {};
                    const objSP = {};
                    const objBSE = {};
                    for (let index = 0; index < 8; index++) { const datas = toresult[val][index] && toresult[val][index];
                      if (index < 4) {
                        objBN[`block_name${index + 1}`] = datas?.block_name || '';
                        objBN[`site_name${index + 1}`] = datas?.site_name || '';
                      } else {
                        objBN[`block_name${index + 1}`] = datas?.block_name || '';
                        objBN[`random_name${index + 1}`] = datas?.site_name || '';
                      }

                      objSP[`site_positive${index + 1}`] = datas?.site_positive;

                      if (index < 4) {
                        objBSE[`s${index + 1}_bse`] = isNaNFixed(datas?.site_bse && Number(datas?.site_bse));
                        if (index === 3)
                          objBSE.bse_fixed = isNaNFixed(
                            parseInt(objBSE.s1_bse + objBSE.s2_bse + objBSE.s3_bse + objBSE.s4_bse),
                          );
                      } else {
                        objBSE[`r${index + 1}_bse`] = isNaNFixed(datas?.site_bse && Number(datas?.site_bse));
                        if (index === 7) {
                          objBSE.bse_random = isNaNFixed(
                            parseInt(objBSE.r5_bse + objBSE.r6_bse + objBSE.r7_bse + objBSE.r8_bse),
                          );
                        }
                      }
                    }
                    obj = { ...obj, ...objBN, ...objSP, ...objBSE };
                  }
                }
              });
              arrList.push(obj);
              console.log("arrList",arrList);
            });
         

          setDistrictDetails(arrList);
          blocklist && setBlockDetails(blocklist);
        } else if (filterDetails?.customReportId === 4 || filterDetails?.customReportId === 5) {
          setDistrictDetails(fitlerExtractReports[0]);
          setBlockDetails(fitlerExtractReports[1]);
        } else if (
          filterDetails?.customReportId === 6 ||
          filterDetails?.customReportId === 20 ||
          filterDetails?.customReportId === 21
        ) {
          const preHeader = [
            'state',
            'district',
            'block_name',
            'date_completed',
            'test_type',
            'details',
            'pre_tas_status',
          ];
          if (filterDetails?.customReportId === 6 || filterDetails?.customReportId === 20) {
            district &&
              district.length > 0 &&
              district.forEach((toresult) => {
                let obj = {};
                preHeader.forEach((val) => {
                  if (val === 'state' || val === 'district') {
                    obj[val] = toresult[val];
                  } else if (val === 'test_type') {
                    obj[val] = (toresult.details[0] && toresult.details[0][val]) || '';
                  } else if (val === 'date_completed') {
                    obj.month = moment(toresult[val]).format('MMM');
                    // obj.year = moment(toresult[val]).format('YYYY');
                    obj.year = moment(toresult?.year).format('YYYY');
                  } else if (val === 'pre_tas_status') {
                    // obj[val] =
                    //   Number(toresult[val]) === 0 ? 'Normal' : Number(toresult[val]) === 1 ? 'Completed' : 'Pending';
                    obj[val] = toresult?.Status;
                  } else if (val === 'details') {
                    const obj1 = {};
                    const obj2 = {};
                    const obj3 = {};
                    for (let index = 0; index < 10; index++) {
                      // toresult &&
                      //   toresult[val].forEach((data, index) => {
                      obj1[`positive_AR${index + 1}`] = toresult[val][index]?.random_possitive || '';
                      // });

                      // toresult &&
                      //   toresult[val].forEach((data, index) => {
                      obj2[`BSE_AR${index + 1}`] = toresult[val][index]?.random_blood_count || '';
                      // });

                      // toresult &&
                      //   toresult[val].forEach((data, index) => {
                      obj3[`Rate_AR${index + 1}`] = isNaNFixed(
                        (Number(toresult[val][index]?.random_possitive) /
                          Number(toresult[val][index]?.random_blood_count)) *
                          100,
                      );
                      // });
                    }
                    obj = { ...obj, ...obj1, ...obj2, ...obj3 };
                  }
                });
                arrList.push(obj);
              });
          }
          if (filterDetails?.customReportId === 6 || filterDetails?.customReportId === 21) {
            blocklist &&
              blocklist.length > 0 &&
              blocklist.forEach((toresult) => {
                const obj = {};
                preHeader.forEach((val) => {
                  if (val === 'state' || val === 'district') {
                    obj[val] = toresult[val];
                  } else if (val === 'test_type' || val === 'block_name') {
                    obj[val] = toresult.details[0][val];
                  } else if (val === 'date_completed') {
                    obj.month = moment(toresult[val]).format('MMM');
                    obj.year = moment(toresult[val]).format('YYYY');
                  } else if (val === 'pre_tas_status') {
                    obj[val] =
                      // Number(toresult[val]) === 0 ? 'Normal' : Number(toresult[val]) === 1 ? 'Completed' : 'Pending';
                      toresult?.Status;
                  } else if (val === 'details') {
                    toresult &&
                      toresult[val].forEach((data, index) => {
                        if (index <= 2) obj[`positive_AR${index + 1}`] = data.random_possitive;
                      });

                    toresult &&
                      toresult[val].forEach((data, index) => {
                        if (index <= 2) obj[`BSE_AR${index + 1}`] = data.random_blood_count;
                      });

                    toresult &&
                      toresult[val].forEach((data, index) => {
                        if (index <= 2)
                          obj[`Rate_AR${index + 1}`] = isNaNFixed(
                            (Number(data.random_possitive) / Number(data.random_blood_count)) * 100,
                          );
                      });
                  }
                });
                arrSublist.push(obj);

                // arrList.push();
              });
          }
          setDistrictDetails(arrList);
          setBlockDetails(arrSublist);
        }
      }
      // else if (filterDetails?.customReportId === 7) {
      //   if (fitlerExtractReports && fitlerExtractReports.length > 0) {
      //     const tasReportTooltip = fitlerExtractReports.map((data) => {
      //       return {
      //         ...data,
      //         block_name:
      //           data?.block_name !== 'NA' ? (
      //             <Tooltip
      //               title={<TooltipTitle contentTitle="Block Names" districts={data?.block_name?.split(',')} />}
      //               placement="right"
      //               className="toolHeader"
      //             >
      //               <div>{data?.block_name?.split(',').length}</div>
      //             </Tooltip>
      //           ) : (
      //             data?.block_name
      //           ),
      //       };
      //     });
      //     setDistrictDetails(tasReportTooltip);
      //   } else {
      //     setDistrictDetails(fitlerExtractReports);
      //   }
      // }
      else {
        setDistrictDetails(fitlerExtractReports);
      }
    }
  }, [fitlerExtractReports]);
  const header = [
    'S.No.',
    'State',
    'District',
    'Lymphedema Cases',
    'Hydrocele Cases',
    'Hydrocele Surgeries',
    // 'Date',
  ];
  const [table, setTable] = React.useState([]);
  React.useEffect(() => {
    dispatch({ type: GET_LYMPHEDEMA });
  }, [dispatch]);

  const { getLymphedema } = useSelector((state) => state.lymphedema);

  useEffect(() => {
    if (filterDetails.customReportId === 27) {
      const rowValue = getLymphedema?.map((item) => ({
        state: item?.state,
        district: item?.district,
        lymphedema_cases: item?.lymphedema_cases,
        hydrocele_case: item?.hydrocele_case,
        hydrocele_surgeries: item?.hydrocele_surgeries,
      }));
      setTable(rowValue);
    }
  }, [getLymphedema, filterDetails]);

  /**
   * @param {string} e - onChange event
   */
  const onChangeRadioValue = (e) => {
    setCustomRadioData(e.target.value);
    const details = {
      year: '',
      strategy: '',
      state: '',
      district: '',
      activity: '',
      status: '',
      customReportId: e.target.value === 'Standard Reports' ? 9 : 1,
    };
    dispatch({ type: CUSTOMREPORT_BY_TYPE, payload: e.target.value === 'Standard Reports' ? 'Standard' : 'FDER' });
    dispatch({ type: FILTER_DRIVEN_EXTRACT_REPORT, payload: details, filterType: e.target.value });
    setFilterDetails(details);
  };
  /**
   *
   * @param {object} obj - passing an object of values
   */
  const getCustomSelectValue = (obj) => {
    if (obj.fieldName !== 'Reports') {
      dispatch({
        type: FILTER_DRIVEN_EXTRACT_REPORT,
        payload: { ...filterDetails, [obj.fieldName?.replace(' ', '_')?.toLowerCase()]: obj.value },
        filterType: customRadioData,
      });
      setFilterDetails({ ...filterDetails, [obj.fieldName?.replace(' ', '_')?.toLowerCase()]: obj.value });
    }
  };
  /**
   * @param {number} id selected dropdown id
   * @param {number} key dropdown name
   */
  const handleWithID = (id, key) => {
    if (key === 'State') dispatch({ type: GET_DISTRICT_DROPDOWN, payload: { state: id } });
    if (key === 'Reports') {
      const Data =
        customRadioData === 'Standard Reports' ? { customReportId: id } : { ...filterDetails, customReportId: id };
      setFilterDetails({ ...filterDetails, customReportId: Number(id) });
      dispatch({
        type: FILTER_DRIVEN_EXTRACT_REPORT,
        payload: Data,
        filterType: customRadioData,
      });
    }
  };
  const customReportsTypeFilter = () => {
    if (customReportsType) {
      const filteredData = customReportsType.filter((data) => data.id === filterDetails.customReportId);
      return filteredData.length > 0 ? filteredData[0].value : null;
    }
    return null;
  };

  return (
    <Grid container item className="title-national-dash" style={{ display: showDisplay ? '' : 'none' }}>
      <Grid container item md={12} sm={12} xs={12}>
        <Grid item md={8} sm={12} xs={12} sx={{ pt: 1 }}>
          {customRadioData !== 'Standard Reports' && (
            <FileGenerator
              DropdownList={['Year', 'State', 'District', 'Status', 'Activity']}
              showExporter={false}
              getSelectValue={(obj) => getCustomSelectValue(obj)}
              getDistrictList={(stateId) => handleWithID(stateId, 'State')}
              districtDropdown={dropdownList.districtData || []}
              status="CustomReport"
            />
          )}
          <FileGenerator
            DropdownList={['Reports']}
            DropdownSelectedValues={{ Reports: Number(filterDetails.customReportId) }}
            showExporter={false}
            getSelectValue={(obj) => getCustomSelectValue(obj)}
            getDistrictList={(id) => handleWithID(id, 'Reports')}
            blockDropdown={customReportsType}
          />
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <Grid item className="radioGrid" justifyContent="flex-end">
            <CustomRadioButton
              radioData={customReportData}
              handleChange={(e) => onChangeRadioValue(e)}
              selectValue={customRadioData}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid className="reportTableGrid">
        <FileGenerator showExporter fileData={districtDetails} title={customReportsTypeFilter()} />
{ filterDetails?.customReportId === 27?(
   <Table  
   header={
    
        header
   }
   rows={table}
   showSno
 />

):(<Table  
  header={
   
     
      [2, 3, 4, 5].includes(filterDetails.customReportId - 1)
      ? headers[filterDetails.customReportId - 1][0]
      : headers[filterDetails.customReportId - 1]
  }
  rows={filterDetails?.customReportId === 21 ? blockDetails : districtDetails}
  showSno
/>)}
        

        {[2, 3, 4, 5].includes(filterDetails.customReportId - 1) && (
          <>
            <FileGenerator showExporter fileData={blockDetails} title={customReportsTypeFilter()} />
            <Table header={headers[filterDetails.customReportId - 1][1]} rows={blockDetails} showSno />
          </>
        )}
      </Grid>
    </Grid>
  );
};
CustomReport.propTypes = {
  showDisplay: PropTypes.bool.isRequired,
};
export default CustomReport;