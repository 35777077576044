/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import CalendarPicker from '@mui/lab/CalendarPicker';
import './DatePicker.css';

/**
 *
 * @param {object} props -required props in date picker component
 * @returns {React.ReactElement} - returns the date picker
 */
const CustomDatePicker = (props) => {
  const { label, year, calendarPicker, onHandleChange, date, disabled } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {!calendarPicker && (
        <DatePicker
          // minDate={minDate && new Date(minDate)}
          // maxDate={maxDate && new Date(maxDate)}
          inputFormat="dd MMM yyyy"
          disableMaskedInput
          views={year ? ['year'] : ['year', 'month', 'day']}
          label={label}
          value={date !== undefined ? (date !== '' ? date : null) : null}
          onChange={(e) => {
            onHandleChange(e);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              color="secondary"
              sx={{ width: '100%' }}
            />
          )}
          disabled={disabled}
        />
      )}

      {calendarPicker && <CalendarPicker date={date} onChange={onHandleChange} className="Calender_customize" />}
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
CustomDatePicker.propTypes = {
  label: PropTypes.string,
  year: PropTypes.bool,
  calendarPicker: PropTypes.bool,
  onHandleChange: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  disabled: PropTypes.bool,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
};
CustomDatePicker.defaultProps = {
  label: '',
  year: false,
  calendarPicker: false,
  disabled: false,
  minDate: '',
  maxDate: '',
};
