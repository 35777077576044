/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UserRightPanel from '../../../../components/molecules/UserRightPanel';
import CustomTabBar from '../../../../components/molecules/CustomTabBar';
import {
  NationalDashboard,
  StateDashboard,
  PredictionDashboard,
  NationalDashWidgets,
  StateDashWidgets,
  PredictionDashWidgets,
} from '../entries';

/**
 *
 * @param {*} props props
 * @returns {*} null
 */
export const NationalTabPanel = (props) => {
  const { showCheckbox, userMasterData, fieldItems } = props;
  const [selectedFields, setSelectedFields] = useState({});
  /**
   *
   * @param {*} index index
   * @param {*} label label
   * @param {*} checked checked
   * @param {*} header header
   */
  const handleCheck = (index, label, checked, header) => {
    const labelIndex = selectedFields[header] ? selectedFields[header].indexOf(label) : -1;
    if (header === 'National Dashboard') {
      NationalDashWidgets[index - 1].checked = checked;
    }
    if (labelIndex === -1) {
      setSelectedFields({
        ...selectedFields,
        [header]: selectedFields[header] ? [...selectedFields[header], label] : [label],
      });
    } else {
      selectedFields[header].splice(labelIndex, 1);
      setSelectedFields({ ...selectedFields });
    }
  };
  React.useMemo(() => {
    let widgetFields = {};
    const widgetsApiKeys = [
      { name: 'x  ', fields: 'country_profile_fields' },
      { name: 'progress_status', fields: 'progress_status_fields' },
      { name: 'activity_calender', fields: 'activity_calender_fields' },
      { name: 'pre_tas_overview', fields: 'pre_tas_overview_fields' },
      { name: 'tas_decision_matrix', fields: 'tas_decision_matrix_fields' },
      { name: 'tas_summary', fields: 'tas_summary_fields' },
      { name: 'mda_coverage', fields: 'mda_coverage_fields' },
      { name: 'data_quality', fields: 'data_quality_fields' },
    ];
    widgetsApiKeys.forEach((widget, index) => {
      if (userMasterData[0]?.dashboard_screen[0][widget.name] === 1) {
        const { header } = NationalDashboard[index + 1];
        NationalDashboard[index + 1].values[0].checked = true;
        NationalDashWidgets[index].checked = true;
        NationalDashWidgets[index].panel.forEach((fields, i) => {
          if (userMasterData[0]?.dashboard_screen[0][widget.fields].split(',').includes(fields.header)) {
            NationalDashWidgets[index].panel[i].values[0].checked = true;
            const label = NationalDashWidgets[index].panel[i].header;
            widgetFields = {
              ...widgetFields,
              [header]: widgetFields[header] ? [...widgetFields[header], label] : [label],
            };
          }
        });
        setSelectedFields(widgetFields);
      }
    });
  }, [userMasterData]);

  React.useEffect(() => {
    const dashboard = {
      screen_name: 'national dashboard',
      country_profile: selectedFields['Country Profile']?.length > 0 ? 1 : 0,
      country_profile_fields: selectedFields['Country Profile']?.join() || '',
      progress_status: selectedFields['Progress Status']?.length > 0 ? 1 : 0,
      progress_status_fields: selectedFields['Progress Status']?.join() || '',
      activity_calender: selectedFields['Activity Calendar']?.length > 0 ? 1 : 0,
      activity_calender_fields: selectedFields['Activity Calendar']?.join() || '',
      pre_tas_overview: selectedFields['Pre-TAS/TAS OverView']?.length > 0 ? 1 : 0,
      pre_tas_overview_fields: selectedFields['Pre-TAS/TAS OverView']?.join() || '',
      tas_decision_matrix: selectedFields['TAS Decision Matrix']?.length > 0 ? 1 : 0,
      tas_decision_matrix_fields: selectedFields['TAS Decision Matrix']?.join() || '',
      tas_summary: selectedFields['TAS Summary']?.length > 0 ? 1 : 0,
      tas_summary_fields: selectedFields['TAS Summary']?.join() || '',
      tas_coverage: selectedFields['MDA Coverage']?.length > 0 ? 1 : 0,
      tas_coverage_fields: selectedFields['MDA Coverage']?.join() || '',
      data_quality: selectedFields['Data Quality']?.length > 0 ? 1 : 0,
      data_quality_fields: selectedFields['Data Quality']?.join() || '',
    };
    if (userMasterData[0]?.input_screen[0]?.role_privilege_access_id) fieldItems(dashboard);
    else
      fieldItems({
        ...dashboard,
        user_access_dashboard_id: userMasterData[0]?.dashboard_screen[0].user_access_dashboard_id,
      });
  }, [selectedFields]);
  return (
    <>
      <UserRightPanel
        header={NationalDashboard}
        onCheckValues={handleCheck}
        onChangeCheckbox={showCheckbox}
        hideButton
      />
      {NationalDashWidgets.map(
        (data) =>
          data.checked && (
            <UserRightPanel
              header={data.panel}
              onCheckValues={handleCheck}
              onChangeCheckbox={showCheckbox}
              hideButton
            />
          ),
      )}
    </>
  );
};
NationalTabPanel.propTypes = {
  userMasterData: PropTypes.objectOf,
  showCheckbox: PropTypes.bool,
  fieldItems: PropTypes.func,
};
NationalTabPanel.defaultProps = {
  userMasterData: null,
  showCheckbox: false,
  fieldItems: () => null,
};
/**
 *
 * @param {*} props props
 * @returns {*} null
 */
export const StateTabPanel = (props) => {
  const { showCheckbox, userMasterData, fieldItems } = props;
  const [selectedFields, setSelectedFields] = useState({});
  /**
   *
   * @param {*} index index
   * @param {*} label label
   * @param {*} checked checked
   * @param {*} header header
   */
  const handleCheck = (index, label, checked, header) => {
    const labelIndex = selectedFields[header] ? selectedFields[header].indexOf(label) : -1;
    if (header === 'State Dashboard') {
      StateDashWidgets[index - 1].checked = checked;
    }
    if (labelIndex === -1) {
      setSelectedFields({
        ...selectedFields,
        [header]: selectedFields[header] ? [...selectedFields[header], label] : [label],
      });
    } else {
      selectedFields[header].splice(labelIndex, 1);
      setSelectedFields({ ...selectedFields });
    }
  };
  React.useEffect(() => {
    const dashboard = {
      state_profile: selectedFields['State Profile']?.length > 0 ? 1 : 0,
      state_profile_fields: selectedFields['State Profile']?.join() || '',
      state_progress_status: selectedFields['Progress Status']?.length > 0 ? 1 : 0,
      state_progress_status_fields: selectedFields['Progress Status']?.join() || '',
      state_activity_calender: selectedFields['Activity Calendar']?.length > 0 ? 1 : 0,
      state_activity_calender_fields: selectedFields['Activity Calendar']?.join() || '',
      state_tasDecisionMatrix: selectedFields['TAS Decision Matrix']?.length > 0 ? 1 : 0,
      state_tasDecisionMatrix_fields: selectedFields['TAS Decision Matrix']?.join() || '',
      state_districtProfileWidget: selectedFields['District Profile Widget']?.length > 0 ? 1 : 0,
      state_districtProfileWidget_fields: selectedFields['District Profile Widget']?.join() || '',
      state_districtMdaProfile: selectedFields['District MDA Profile']?.length > 0 ? 1 : 0,
      state_districtMdaProfile_fields: selectedFields['District MDA Profile']?.join() || '',
      state_districtTasProfile: selectedFields['District TAS Profile']?.length > 0 ? 1 : 0,
      state_districtTasProfile_fields: selectedFields['District TAS Profile']?.join() || '',
      state_districtDecisionMatrix: selectedFields['District Decision Matrix']?.length > 0 ? 1 : 0,
      state_districtDecisionMatrix_fields: selectedFields['District Decision Matrix']?.join() || '',
      state_dataQuality: selectedFields['Data Quality']?.length > 0 ? 1 : 0,
      state_dataQuality_fields: selectedFields['Data Quality']?.join() || '',
      // state_districtMdaProfile: selectedFields['Data Quality']?.length > 0 ? 1 : 0,
      // state_districtMdaProfile_fields: selectedFields['District TAS Profile']?.join() || '',
    };
    if (userMasterData[0]?.input_screen[0]?.role_privilege_access_id) fieldItems(dashboard);
    else
      fieldItems({
        ...dashboard,
        user_access_dashboard_id: userMasterData[0]?.dashboard_screen[0].user_access_dashboard_id,
      });
  }, [selectedFields]);
  React.useMemo(() => {
    let widgetFields = {};
    const widgetsApiKeys = [
      { name: 'state_profile', fields: 'state_profile_fields' },
      { name: 'state_progress_status', fields: 'state_progress_status_fields' },
      { name: 'state_activity_calender', fields: 'state_activity_calender_fields' },
      { name: 'state_tasDecisionMatrix', fields: 'state_tasDecisionMatrix_fields' },
      { name: 'state_districtProfileWidget', fields: 'state_districtProfileWidget_fields' },
      { name: 'mda_coverage', fields: 'mda_coverage_fields' },
      { name: 'state_districtTasProfile', fields: 'state_districtTasProfile_fields' },
      { name: 'state_districtDecisionMatrix', fields: 'state_districtDecisionMatrix_fields' },
      { name: 'state_dataQuality', fields: 'state_dataQuality_fields' },
    ];
    widgetsApiKeys.forEach((widget, index) => {
      if (userMasterData[0]?.state_dashboard[0][widget.name] === 1) {
        const { header } = StateDashboard[index + 1];
        StateDashboard[index + 1].values[0].checked = true;
        StateDashWidgets[index].checked = true;
        StateDashWidgets[index].panel.forEach((fields, i) => {
          if (userMasterData[0]?.state_dashboard[0][widget.fields].split(',').includes(fields.header)) {
            StateDashWidgets[index].panel[i].values[0].checked = true;
            const label = StateDashWidgets[index].panel[i].header;
            widgetFields = {
              ...widgetFields,
              [header]: widgetFields[header] ? [...widgetFields[header], label] : [label],
            };
          }
        });
        setSelectedFields(widgetFields);
      }
    });
  }, [userMasterData]);
  return (
    <>
      <UserRightPanel header={StateDashboard} onCheckValues={handleCheck} onChangeCheckbox={showCheckbox} hideButton />
      {StateDashWidgets.map(
        (data) =>
          data.checked && (
            <UserRightPanel
              header={data.panel}
              onCheckValues={handleCheck}
              onChangeCheckbox={showCheckbox}
              hideButton
            />
          ),
      )}
    </>
  );
};
StateTabPanel.propTypes = {
  userMasterData: PropTypes.objectOf,
  showCheckbox: PropTypes.bool,
  fieldItems: PropTypes.func,
};
StateTabPanel.defaultProps = {
  userMasterData: null,
  showCheckbox: false,
  fieldItems: () => null,
};
/**
 *
 * @param {*} props props
 * @returns {*} null
 */
export const PredictionTabPanel = (props) => {
  const { showCheckbox, userMasterData, fieldItems } = props;
  const [selectedFields, setSelectedFields] = useState({});
  /**
   *
   * @param {*} index index
   * @param {*} label label
   * @param {*} checked checked
   * @param {*} header header
   */
  const handleCheck = (index, label, checked, header) => {
    const labelIndex = selectedFields[header] ? selectedFields[header].indexOf(label) : -1;
    if (header === 'Prediction Dashboard') {
      NationalDashWidgets[index - 1].checked = checked;
    }
    if (labelIndex === -1) {
      setSelectedFields({
        ...selectedFields,
        [header]: selectedFields[header] ? [...selectedFields[header], label] : [label],
      });
    } else {
      selectedFields[header].splice(labelIndex, 1);
      setSelectedFields({ ...selectedFields });
    }
  };
  React.useEffect(() => {
    const dashboard = {
      prediction_countryProfile: selectedFields['Country Profile']?.length > 0 ? 1 : 0,
      prediction_countryProfile_fields: selectedFields['Country Profile']?.join() || '',
      prediction_expectedActivites: selectedFields['Expected Activities']?.length > 0 ? 1 : 0,
      prediction_expectedActivites_fields: selectedFields['Expected Activities']?.join() || '',
      prediction_activityCalender: selectedFields['Activity Calendar']?.length > 0 ? 1 : 0,
      prediction_activityCalender_fields: selectedFields['Activity Calendar']?.join() || '',
    };
    if (userMasterData[0]?.input_screen[0]?.role_privilege_access_id) fieldItems(dashboard);
    else
      fieldItems({
        ...dashboard,
        user_access_dashboard_id: userMasterData[0]?.dashboard_screen[0].user_access_dashboard_id,
      });
  }, [selectedFields]);
  React.useMemo(() => {
    let widgetFields = {};
    const widgetsApiKeys = [
      { name: 'prediction_countryProfile', fields: 'prediction_countryProfile_fields' },
      { name: 'prediction_expectedActivites', fields: 'prediction_expectedActivites_fields' },
      { name: 'prediction_activityCalender', fields: 'prediction_activityCalender_fields' },
    ];
    widgetsApiKeys.forEach((widget, index) => {
      if (userMasterData[0]?.prediction_dashboard[0][widget.name] === 1) {
        PredictionDashboard[index + 1].values[0].checked = true;
        const { header } = PredictionDashboard[index + 1];
        PredictionDashWidgets[index].checked = true;
        PredictionDashWidgets[index].panel.forEach((fields, i) => {
          if (userMasterData[0]?.prediction_dashboard[0][widget.fields].split(',').includes(fields.header)) {
            PredictionDashWidgets[index].panel[i].values[0].checked = true;
            const label = PredictionDashWidgets[index].panel[i].header;
            widgetFields = {
              ...widgetFields,
              [header]: widgetFields[header] ? [...widgetFields[header], label] : [label],
            };
          }
        });
        setSelectedFields(widgetFields);
      }
    });
  }, [userMasterData]);
  return (
    <>
      <UserRightPanel
        header={PredictionDashboard}
        onCheckValues={handleCheck}
        onChangeCheckbox={showCheckbox}
        hideButton
      />
      {PredictionDashWidgets.map(
        (data) =>
          data.checked && (
            <UserRightPanel
              header={data.panel}
              onCheckValues={handleCheck}
              onChangeCheckbox={showCheckbox}
              hideButton
            />
          ),
      )}
    </>
  );
};
PredictionTabPanel.propTypes = {
  userMasterData: PropTypes.objectOf,
  showCheckbox: PropTypes.bool,
  fieldItems: PropTypes.func,
};
PredictionTabPanel.defaultProps = {
  userMasterData: null,
  showCheckbox: false,
  fieldItems: () => null,
};
/**
 *
 * @param {*} props props
 * @returns {React.Component} Tab Content for providing Previlege to Super user in dashboard screens
 */
const TabContent = (props) => {
  const { userMasterData, showCheckbox, nationalList, stateList, predictionList, selectedTabs } = props;
  const dashboardContent = [
    {
      label: 'National Dashboard',
      value: 'National Dashboard',
      content: (
        <NationalTabPanel
          showCheckbox={showCheckbox}
          userMasterData={userMasterData}
          fieldItems={(data) => nationalList({ national: data })}
        />
      ),
    },
    {
      label: 'State Dashboard',
      value: 'State Dashboard',
      content: (
        <StateTabPanel
          showCheckbox={showCheckbox}
          userMasterData={userMasterData}
          fieldItems={(data) => stateList({ state: data })}
        />
      ),
    },
    {
      label: 'Prediction Dashboard',
      value: 'Prediction Dashboard',
      content: (
        <PredictionTabPanel
          showCheckbox={showCheckbox}
          userMasterData={userMasterData}
          fieldItems={(data) => predictionList({ prediction: data })}
        />
      ),
    },
  ];
  return <CustomTabBar TabDetails={dashboardContent} onTabClick={selectedTabs} />;
};
export default TabContent;
TabContent.propTypes = {
  userMasterData: PropTypes.objectOf,
  showCheckbox: PropTypes.bool,
  nationalList: PropTypes.func,
  stateList: PropTypes.func,
  predictionList: PropTypes.func,
  selectedTabs: PropTypes.func,
};
TabContent.defaultProps = {
  userMasterData: null,
  showCheckbox: false,
  nationalList: () => null,
  stateList: () => null,
  predictionList: () => null,
  selectedTabs: () => null,
};
