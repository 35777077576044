/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable array-callback-return */
import React, { useEffect } from 'react';
import * as XLSX from 'xlsx';
import { Grid, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import './Demo.css';
import Form from './DemoForm/index';
import DatePicker from '../../../components/atoms/DatePicker';
import FileUpload from '../../../components/molecules/FileUpload';
import Toaster from '../../../components/atoms/ToastMessage';
import Table from './Table/index';
import Typography from '../../../components/atoms/Typography/index';
import { FormEnteries, formDefaultValues } from './FormEnteries';
import { GET_MASTER_TEMPLATES, UPLOAD_DEMOGRAPHIC_DIMENSION } from '../../../constants/actionType';

/**
 * @returns {React.ReactElement} return the user role component
 */
const DemographicConfiguration = () => {
  const [date, setDate] = React.useState(new Date());
  const [file, setFile] = React.useState();
  const [isFormEdited, setIsFormEdited] = React.useState(false);
  const [toaster, setToaster] = React.useState(null);
  const [jsonData, setJsonData] = React.useState();
  const [jsonFinalData, setJsonFinalData] = React.useState([]);
  const [isView, setIsView] = React.useState(false);
  const [editableDefaultValue, setEditableDefaultValue] = React.useState({});
  const { status } = useSelector((state) => state.demographic);
  const dispatch = useDispatch();
  const [formControl, setFormControl] = React.useState();
  const ValidationGroup = localStorage.getItem('userGroupId');
  const ValidationType = localStorage.getItem('userTypeId');
  console.log(jsonFinalData, 'sdkfjskdir');
  const InputActionValidation = localStorage.getItem('ActionAccess');
  const Actionvalue = JSON.parse(InputActionValidation);
  const { BulkUpload, Download, Edit, ManualDataInput, View } = Actionvalue?.masters?.[0];
  console.log(BulkUpload, Download, Edit, ManualDataInput, View, 'aslkdjqqfasoihr');
  React.useEffect(() => {
    const arr = [];
    console.log(arr, 'slkdfjskdirt');
    jsonData?.map((value, index) => {
      arr.push({
        sno: index + 1,
        state: value?.state,
        District: value?.District,
        TotalDistrictPopulation: value?.TotalDistrictPopulation,
        TotalDistrictEndemic: value?.TotalDistrictEndemic,
        FormDate: value?.FormDate,
        ToDate: value?.ToDate,
        Status: value?.active_status === 'Active' ? 'Active' : 'Inactive',
      });
      // return array;
    });
    if (isView) setJsonFinalData(arr);
  }, [isView, jsonData]);

  /**
   *
   * @param {*} e -- Table Data
   * @param {number} ids All ids from the table
   * @param {object} stateDropDownValue state value
   * @param {object} districtDropDownValue district value
   * @returns {object} new defaultValues for edit option
   */
  const editableDefaultValues = (e, ids, stateDropDownValue, districtDropDownValue) => {
    setIsFormEdited(!isFormEdited);
    return setEditableDefaultValue({
      demoghrapic_dimension_id: ids[e.sno - 1],
      state_id: stateDropDownValue[e.sno - 1],
      district_id: districtDropDownValue[e.sno - 1],
      total_district_endamic_population: e.TotalDistrictEndemic,
      total_district_population: e.TotalDistrictPopulation,
      from_date: e.FormDate,
      to_date: e.ToDate,
      status: e.status,
    });
  };

  const { getMasterTemplate } = useSelector((state) => state.masterTemplate);
  console.log(getMasterTemplate, 'getMasterTemplate');
  useEffect(() => {
    dispatch({ type: GET_MASTER_TEMPLATES });
  }, [dispatch]);

  React.useEffect(() => {
    if (status && status?.message !== '') setToaster(true);
    else setToaster(false);
  }, [status]);

  React.useEffect(() => {
    if ((ValidationGroup === '0' && ValidationType === '4') || (ValidationGroup === '8' && ValidationType === '5')) {
      setFormControl(true);
    }
  }, [ValidationGroup, ValidationType]);
  /**
   * @param {*} files - sending files
   */
  const sendSelectedFiles = async (files) => {
    setFile(files);
    const data = await files.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const json = XLSX.utils.sheet_to_json(worksheet);
    setJsonData(json);
  };
  /**
   *
   */
  const onClickHandleView = () => {
    setIsView(true);
  };
  /**
   *
   */
  const onSubmitFiles = async () => {
    await dispatch({ type: UPLOAD_DEMOGRAPHIC_DIMENSION, payload: file });
  };
  return (
    <Grid container item md={12} sm={12} xs={12} spacing={3} sx={{ paddingTop: 2 }} className="dataLayout">
      {toaster && <Toaster open severity={status?.type} message={status?.message} close={() => setToaster(false)} />}
      <Grid item md={BulkUpload === 1 ? 9 : 12} sm={12} xs={12} className="noPadding">
        <Paper elevation={3} className="splitPages">
          {formControl && (
            <Grid>
              <Typography
                text="Create Demographic Dimension"
                colorType="primary"
                type="caption"
                customClass="userText"
              />
              <Grid sx={{ padding: 2 }}>
                <Form
                  entries={FormEnteries}
                  defaultValues={!isFormEdited ? formDefaultValues : editableDefaultValue}
                  isEdit={isFormEdited}
                  setIsFormEdited={() => setIsFormEdited(false)}
                />
              </Grid>
            </Grid>
          )}
          {View === 1 && (
            <Grid>
              <Table
                onEditClick={(e, ids, stateDropDownValue, districtDropDownValue) =>
                  editableDefaultValues(e, ids, stateDropDownValue, districtDropDownValue)
                }
                jsonFinalData={jsonFinalData}
                isView={isView}
              />
            </Grid>
          )}
        </Paper>
      </Grid>
      {BulkUpload === 1 && (
        <Grid item md={3} sm={12} xs={12} sx={{ height: '100%' }} className="paddingView">
          <Paper elevation={3} className="splitPage">
            <FileUpload
              downloadTemplate
              excelUploader
              sendSelectedFile={(files) => sendSelectedFiles(files)}
              templateUrl={getMasterTemplate.demographicDimension}
              masterSetup
              onSubmitFiles={onSubmitFiles}
              onClickHandleView={onClickHandleView}
            />
            <Grid sx={{ pt: 4 }}>
              <DatePicker calendarPicker date={date} onHandleChange={(newDate) => setDate(newDate)} />
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};
export default DemographicConfiguration;
