/* eslint-disable default-param-last */
import {
  GET_PREDICTION_ACTIVITY_CALENDAR_RESULT,
  GET_PREDICTION_ACTIVITY_CALENDAR_TABLE_RESULT,
  GET_EXPECTED_ACTIVITIES_RESULT,
  GET_PREDICTION_COUNTRY_PROFILE_RESULT,
  GET_PREDICTION_COUNTRY_PROFILE_MAP_RESULT,
  GET_PREDICTION_DASHBOARD_RESULT,
  GET_EXPECTED_ACTIVITIES_GRAPH_RESULT,
} from '../../constants/actionType/index';
import { predictionDashboard } from '../initialStates/index';

/**
 * @param {string} state defines the state
 * @param {object} action used for payload and type
 * @returns {Array} action response state
 */
const predictionDashboardReducer = (state = predictionDashboard, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PREDICTION_ACTIVITY_CALENDAR_RESULT:
      return {
        ...state,
        activityCalendarData: payload.data,
      };
    case GET_PREDICTION_ACTIVITY_CALENDAR_TABLE_RESULT:
      return {
        ...state,
        activityCalendarTableData: payload.data,
      };
    case GET_EXPECTED_ACTIVITIES_RESULT:
      return {
        ...state,
        getExpectedActivitesData: payload.data,
      };
    case GET_PREDICTION_COUNTRY_PROFILE_RESULT:
      return {
        ...state,
        getPredictionCountryProfileData: payload.data,
      };
    case GET_PREDICTION_COUNTRY_PROFILE_MAP_RESULT:
      return {
        ...state,
        gettPredictionCountryProfileMap: payload.data,
      };
    case GET_PREDICTION_DASHBOARD_RESULT:
      return {
        ...state,
        getPredictionDashboard: payload.data,
      };
    case GET_EXPECTED_ACTIVITIES_GRAPH_RESULT:
      return {
        ...state,
        getExpectedActivitesGraph: payload.data,
      };

    default:
      return state;
  }
};
export default predictionDashboardReducer;
