/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Paper } from '@mui/material';
import './Threshold.css';
import Toaster from '../../../components/atoms/ToastMessage';
import Table from './Table/index';

import Typography from '../../../components/atoms/Typography/index';
import FileUpload from '../../../components/molecules/FileUpload/index';
import DatePicker from '../../../components/atoms/DatePicker';
import {
  GET_THRESHOLD_CONFIG,
  INSERT_THRESHOLD_CONFIG,
  GET_MASTER_TEMPLATES,
  THRESHOLD_APPROVAL_ATTACHMENT,
} from '../../../constants/actionType';
/**
 * @returns {React.ReactElement} return the user role component
 */
const Threshold = () => {
  const [date, setDate] = React.useState(new Date());
  const [file, setFile] = useState();
  const [approveNote, setApproveNote] = useState({});
  const [toaster, setToaster] = React.useState(null);
  console.log(approveNote, 'approveNote');
  const dispatch = useDispatch();
  const InputActionValidation = localStorage.getItem('ActionAccess');
  const Actionvalue = JSON.parse(InputActionValidation);
  const { BulkUpload, Download, Edit, ManualDataInput, View } = Actionvalue?.masters?.[0];
  console.log(BulkUpload, Download, Edit, ManualDataInput, View, 'aslkdjqqfasoihr');

  const { getMasterTemplate } = useSelector((state) => state.masterTemplate);
  const { approvalStatus, bulkStatus } = useSelector((state) => state.thresholdConfig);

  useEffect(() => {
    dispatch({ type: GET_MASTER_TEMPLATES });
  }, [dispatch]);

  React.useEffect(() => {
    if (approvalStatus && approvalStatus?.message !== '') setToaster(true);
    else if (bulkStatus && bulkStatus?.message !== '') setToaster(true);
    else setToaster(false);
  }, [approvalStatus, bulkStatus]);
  /**
   * @param {*} files - sending files
   */
  const sendSelectedFiles = (files) => {
    setFile(files);
  };
  /**
   * @param {object} obj - sending object
   */
  const sendApprovalAttachment = (obj) => {
    setApproveNote(obj);
  };
  /**
   *
   */
  const onSubmitFiles = async () => {
    await dispatch({ type: INSERT_THRESHOLD_CONFIG, payload: file });
    await dispatch({ type: THRESHOLD_APPROVAL_ATTACHMENT, payload: approveNote });
    dispatch({ type: GET_THRESHOLD_CONFIG });
  };
  return (
    <Grid container item md={12} sm={12} xs={12} spacing={3} sx={{ paddingTop: 2 }} className="dataLayout">
      {toaster && (
        <Toaster
          open
          severity={approvalStatus ? approvalStatus?.type : bulkStatus?.type}
          message={approvalStatus ? approvalStatus?.message : bulkStatus?.message}
          close={() => setToaster(false)}
        />
      )}
      <Grid item md={BulkUpload === 1 ? 9 : 12} sm={12} xs={12} className="noPadding">
        <Paper elevation={3} className="splitPage">
          {View === 1 && <Table downloadUrl="http://122.165.52.124:8080/threshold_template.xlsx" screen="masters" />}
          <Grid sx={{ p: 3 }} item container direction="column">
            <Typography
              text="This screen shall be used only for the Backend Configuration reference only"
              colorType="senary"
              type="link"
              customClass="footText"
            />
            <Typography
              text="Business User has to convey threshold values to the technical team using the 'Threshold Configuration Attachment' "
              colorType="senary"
              type="link"
              customClass="footText"
            />
            <Typography
              text="At one Point of time,Only one Record shall be active."
              colorType="senary"
              type="link"
              customClass="footText"
            />
            <Typography
              text="On Bulk Upload Previous Active Record will be deactivated"
              colorType="senary"
              type="link"
              customClass="footText"
            />
          </Grid>
        </Paper>
      </Grid>

      {BulkUpload === 1 && (
        <Grid item md={3} sm={12} xs={12} sx={{ height: '100%' }} className="paddingView">
          <Paper elevation={3} className="splitPage">
            <Grid>
              <Grid>
                <FileUpload
                  bulkUploader
                  wordUploader
                  templateUrl={getMasterTemplate.thresholdConfiguration}
                  excelUploader
                  downloadTemplate
                  sendSelectedFile={(files) => sendSelectedFiles(files)}
                  sendAttachment={(obj) => sendApprovalAttachment(obj)}
                  onSubmitFiles={() => onSubmitFiles()}
                  masterSetup
                  isSubmit={true}
                />
              </Grid>

              <Grid sx={{ pt: 4 }}>
                <DatePicker calendarPicker date={date} onHandleChange={(newDate) => setDate(newDate)} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};
export default Threshold;
