/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { Grid } from '@mui/material';
import * as _ from 'loadsh';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomForm from '../../../../components/organisms/CustomForm';
import {
  formEntries,
  DistrictEntries,
  BlockEntries,
  formDefaultValues,
  districtDefaultEntries,
  blockDefaultEntries,
} from './formEntries';
import {
  GET_STATE_DROPDOWN,
  GET_DISTRICT_DROPDOWN,
  GET_STRATEGY_DROPDOWN,
  GET_TESTKITTYPE_DROPDOWN,
  INSERT_IDA,
  INSERT_BLOCK,
  GET_PROPOSED_DATE_IDA,
  IDA_STATUS,
  EU_NAME,
  BLOCK_NAME,
  UPDATE_IDA_BY_ID,
  INSERT_MDA_CHECK,
} from '../../../../constants/actionType';
import { INITIAL, INITIAL_LOAD, PROPOSED_DATE_POPULATED, SUCCESS } from '../../../../constants/common';
/**
 * @returns {React.ReactComponent} BuyForm
 */
const MDAFormInput = (props) => {
  const { OnView, Activity } = props;
  const dispatch = useDispatch();
  const { blockName } = useSelector((state) => state?.preTas);
  const dropdownList = useSelector((state) => state.dropDownReducer);
  const { proposedDateIDA } = useSelector((state) => state.IDADetails);
  const [strategy, setStrategy] = useState('District Strategy');
  const [statergyValue, setStatergyValue] = useState('');
  const districtCount = [
    { labelText: 'Sentinel Site', disabled: false, defaultCount: 2 },
    { labelText: 'Random Site', disabled: false, defaultCount: 2 },
  ];
  const shallowBlockEntries = JSON.parse(JSON.stringify([...formEntries, ...BlockEntries]));
  const shallowDistrictEntries = JSON.parse(JSON.stringify([...formEntries, ...DistrictEntries]));
  console.log("shallowDistrictEntries",shallowDistrictEntries);
  const [entries, setEntries] = useState(shallowDistrictEntries);
  const blockCount = [{ labelText: 'Block Count', disabled: false, defaultCount: 2 }];
  const [daItemCount, setDaItemscount] = React.useState(1);
  const [sentinalCount, setSentinalCount] = React.useState(2);
  const [randomCount, setRandomCount] = React.useState(2);
  const [mdaBlockCount, setMdaBlockCount] = React.useState(2);
  const [edit, setEdit] = React.useState(false);
  const [sentinal1, setSentinal] = React.useState(2);
  const [random1, setRandom] = React.useState(2);
  const [entryname, setEntryName] = React.useState('');
  const [districtValue, setDistrictValue] = React.useState();
  const [stateValeue, setStateValue] = React.useState();
  const [statergyId, setStatergyID] = React.useState();
  console.log(stateValeue, districtValue, 'sdkskjjjjdj');
  useEffect(() => {
    dispatch({ type: GET_STATE_DROPDOWN });
    dispatch({ type: GET_STRATEGY_DROPDOWN });
    dispatch({ type: GET_TESTKITTYPE_DROPDOWN });
  }, [dispatch]);
  useEffect(() => {
    const InsertPos = entries.findIndex((p) => p.entryName === 'block_name_R1');
    const val = sentinalCount * 5 + 8;
    shallowDistrictEntries[0].data = _.assign(dropdownList?.stateData);
    shallowDistrictEntries[1].data = _.assign(dropdownList?.districtData);
    shallowDistrictEntries[2].data = _.assign(dropdownList?.strategy);
    shallowDistrictEntries[10].data = _.assign(dropdownList?.testKitType);
    // shallowDistrictEntries[29].data = _.assign(dropdownList?.testKitType);
    setEntries(shallowDistrictEntries);
  }, [dropdownList, blockName]);
  /**
   *
   */
  const handleCancel = () => {
    setRandomCount(1);
    setSentinalCount(1);
    setEntries([...formEntries]);
  };
 
  /**
   *
   */
  const handleBlock = () => {
    for (let i = 1; i <= sentinalCount; i++) {
      const val = i * 5 + 8;
      entries[val].data = _.assign(blockName);
      setEntries(entries);
    }
  };
  /**
   *
   */
  const handleRandom = () => {
    for (let i = 1; i <= randomCount; i++) {
      const InsertPos = entries.findIndex((p) => p.entryName === 'block_name_R1');
      const val = i * 5 + InsertPos - 5;
      entries[val].data = _.assign(blockName);
      setEntries(entries);
    }
  };
  React.useMemo(() => {
    formDefaultValues.state = [];
    formDefaultValues.district = [];
    formDefaultValues.strategy = [];
    formDefaultValues.mda_date_completed = '';
    formEntries.find((item) => item.entryName === 'district').disabled = true;
    // formDefaultValues.date_planned_by_state = '';
    // formDefaultValues.date_proposed_by_nvbdcp = '';
    // formDefaultValues.covered_population = '';
    // formDefaultValues.total_population = '';
    setEntries(shallowDistrictEntries);
    // alert('test');
    // dispatch({
    //   type: GET_PROPOSED_DATE_IDA,
    //   payload: {
    //     district: '',
    //     state: '',
    //     strategy: '',
    //     superuser: '1',
    //   },
    // });
    // dispatch({
    //   type: IDA_STATUS,
    //   payload: { type: INITIAL, message: INITIAL_LOAD },
    // });
  }, [Activity]);

  /**
   *
   */
  const handleBlockStatergy = () => {
    for (let i = 1; i <= mdaBlockCount; i++) {
      const val = i * 16 - 7;
      entries[val].data = _.assign(blockName);
      setEntries(entries);
    }
  };

  useEffect(async () => {
    if (strategy === 'Block Strategy') {
      handleBlockStatergy();
    } else {
      handleBlock();
      handleRandom();
    }
  }, [blockName, sentinalCount, randomCount, mdaBlockCount, strategy]);
  /**
   *
   * @param {*} value - value of event on onchange
   * @param {*} entryName - entry name of form entries
   * @param {*} keyPair - keyPair value of selected dropdowns
   */
  const handleDropDown = (value, entryName, keyPair) => {
    setEntryName(entryName);
    if (entryName === 'state') {
      setStateValue(value);

      dispatch({ type: GET_DISTRICT_DROPDOWN, payload: { state: value } });
      formDefaultValues.state = value && value;
      formDefaultValues.district = '';
      formDefaultValues.strategy = '';
      formEntries.find((item) => item.entryName === 'district').disabled = false;
    }
    if (entryName === 'district') {
      setDistrictValue(value);
      formDefaultValues.district = value && value;
      dispatch({
        type: BLOCK_NAME,
        payload: { state_id: keyPair.state, district_id: keyPair.district, BlockName: '' },
      });
    }
    if (entryName === 'strategy') {
      setStatergyID(value);
      if (value === 'Block Strategy') {
        shallowBlockEntries[0].data = _.assign(dropdownList?.stateData);
        shallowBlockEntries[1].data = _.assign(dropdownList?.districtData);
        shallowBlockEntries[2].data = _.assign(dropdownList?.strategy);
        shallowBlockEntries[13].data = _.assign(dropdownList?.testKitType);
        shallowBlockEntries[29].data = _.assign(dropdownList?.testKitType);
        setEntries([...shallowBlockEntries]);
        entries[6].hide = true;
      } else {
        shallowDistrictEntries[0].data = _.assign(dropdownList?.stateData);
        shallowDistrictEntries[1].data = _.assign(dropdownList?.districtData);
        shallowDistrictEntries[2].data = _.assign(dropdownList?.strategy);
        shallowDistrictEntries[10].data = _.assign(dropdownList?.testKitType);
        setEntries([...shallowDistrictEntries]);
        entries[6].hide = false;
      }
      formDefaultValues.strategy = value && value;
      setStrategy(value);
      setStatergyValue(value);
      dispatch({ type: GET_PROPOSED_DATE_IDA, payload: { params: keyPair, type: Activity } });
    }
  };

  useEffect(() => {
    if (proposedDateIDA?.length > 0 && edit === false) {
      if (statergyValue !== '') {
        // formDefaultValues.date_proposed_by_nvbdcp = datePopulate;
        // setEntries(entries);
      } else {
        formDefaultValues.date_proposed_by_nvbdcp = '';
      }
    }
    if (
      proposedDateIDA &&
      proposedDateIDA[0]?.state_id !== undefined &&
      proposedDateIDA &&
      proposedDateIDA[0]?.block_strategy === strategy
    ) {
      formDefaultValues.date_planned_by_state = proposedDateIDA && proposedDateIDA[0]?.date_planned;
      formDefaultValues.mda_date_completed = proposedDateIDA && proposedDateIDA[0]?.date_completed;
    }
  }, [proposedDateIDA]);
  console.log(entries, 'testentries');
  /**
   *
   */
  const OnCancel = () => {
    setEntries(shallowDistrictEntries);
    dispatch({ type: INSERT_MDA_CHECK });
    setStrategy('District Strategy');
    shallowDistrictEntries[0].data = _.assign(dropdownList?.stateData);
  };

  /**
   *
   * @param {*} formData
   */
  const onSubmitData = (FormData) => {
    console.log(FormData, 'klksdkirt');
    const blockValue = [];
    const DistrictSentinal = [];
    const DistrictRandom = [];

    setEdit(true);
    if (proposedDateIDA && proposedDateIDA[0]?.state_id === undefined) {
      if (strategy === 'District Strategy') {
        // const DistrictSentinal = [];
        for (let i = 1; i <= sentinalCount; i++) {
          DistrictSentinal.push({
            block_name: FormData[`block_name_S${i}`],
            site_id: `S${i}`,
            additionData: true,
            site_name: FormData[`site_name_S${i}`],
            site_blood_count: FormData[`bse_S${i}`],
            site_possitive: FormData[`positive_S${i}`],
            mf_rate: FormData[`MF_Rate_S${i}`],
          });
        }
        // const DistrictRandom = [];

        for (let j = 1; j <= randomCount; j++) {
          DistrictRandom.push({
            block_name: FormData[`block_name_R${j}`],
            site_id: `R${j}`,
            site_name: FormData[`site_name_R${j}`],
            site_blood_count: FormData[`bse_R${j}`],
            site_possitive: FormData[`positive_R${j}`],
            mf_rate: FormData[`MF_Rate_R${j}`],
          });
        }
        dispatch({
          type: INSERT_IDA,
          payload: {
            ...FormData,
            coverage: [...DistrictSentinal, ...DistrictRandom],
            random: randomCount,
            sentinal: sentinalCount,
            activity: Activity,
          },
        });
      } else {
        for (let i = 1; i <= mdaBlockCount; i++) {
          blockValue.push({
            test_type_id: FormData[`test_type_B${i}`],
            nbs_survey_date: FormData[`date_of_mf_survey_B${i}`],
            block_date_completed: FormData[`date_completed_B${i}`],
            block_name: FormData[`block_name_B${i}`],
            block_covered_population: FormData[`covered_population_B${i}`],
            block_population: FormData[`total_population_B${i}`],
            site_id1: 'R1',
            site_name1: FormData[`site_name_R1_B${i}`],
            site_blood_count1: FormData[`bse_R1_B${i}`],
            site_possitive1: FormData[`positive_R1_B${i}`],
            mf_rate1: FormData[`mf_rate_R1_B${i}`],
            site_id2: 'S1',
            site_name2: FormData[`site_name_S1_B${i}`],
            site_blood_count2: FormData[`bse_S1_B${i}`],
            site_possitive2: FormData[`positive_S1_B${i}`],
            mf_rate2: FormData[`mf_rate_S1_B${i}`],

            // test_type_id: FormData[`test_type_B${i}`],
            // nbs_survey_date: FormData[`date_of_mf_survey_B${i}`],
            // block_date_completed: FormData[`date_completed_B${i}`],
            // block_name: FormData[`block_name_B${i}`],
            // block_covered_population: FormData[`covered_population_B${i}`],
            // block_population: FormData[`total_population_B${i}`],
            // site_id: 'S1',
            // site_name: 'Ariyalur',
            // site_blood_count: '222',
            // site_possitive: '11',
            // mf_rate: 1,
          });
        }
        dispatch({
          type: INSERT_BLOCK,
          payload: { ...FormData, coverage: blockValue, block_count: mdaBlockCount, activity: Activity },
        });
      }
    } else {
      dispatch({
        type: UPDATE_IDA_BY_ID,
        payload: {
          ...FormData,
          MDA_IDA_id: proposedDateIDA[0].MDA_IDA_id,
          coverage: strategy === 'District Strategy' ? [...DistrictSentinal, ...DistrictRandom] : blockValue,
          sentinal: sentinalCount,
          random: randomCount,
          activity: Activity,
        },
      });
    }
    // notify(true);
    handleCancel();
    setEdit(false);
  };

  /**
   * @param {number} count count value
   * @param {string} item selected count label
   */
  const handlePositiveCount = (count, item) => {
    if (item.labelText === 'Sentinel Site') {
      setSentinal(count);
    }
    if (item.labelText === 'Random Site') {
      setRandom(count);
    }
    setDaItemscount(count);
    const suffixName = item?.labelText.charAt(0);
    const InsertPos = entries.findIndex((p) => p.entryName === 'block_name_R1');
    const additionData = {onFocusSetDefault: true,
      random: suffixName === 'R',
      sentinal: suffixName === 'S',
      defaultValue: count,}
    let newItems = [
      // {
      //   value: '',
      //   isCombined: true,
      //   textField: true,
      //   entryName: `block_name_${suffixName}${count}`,
      //   placeholder: `Block Name ${suffixName}${count}`,
      //   textInputType: 'default',
      //   validation: { isRequired: false },
      // },

      {
        value: '',
        ComboBox: true,
        default: true,
        isCombined: true,
        entryName: `block_name_${suffixName}${count}`,
        placeholder: `Block Name ${suffixName}${count}`,
        data: [],
      },
      {
        value: '',
        isCombined: true,
        textField: true,
        entryName: `site_name_${suffixName}${count}`,
        placeholder: `Site Name ${suffixName}${count}`,
        textInputType: 'default',
        validation: { isRequired: false },
      },
      {
        value: '',
        isCombined: true,
        textField: true,
        entryName: `bse_${suffixName}${count}`,
        placeholder: `BSE ${suffixName}${count}`,
        textInputType: 'number',
        validation: { isRequired: false },
      },
      {
        value: '',
        isCombined: true,
        textField: true,
        entryName: `positive_${suffixName}${count}`,
        placeholder: `Positive ${suffixName}${count}`,
        textInputType: 'number',
        validation: { isRequired: false },
      },

      {
        value: '',
        default: true,
        textField: true,
        disabled: true,
        entryName: `MF_Rate_${suffixName}${count}`,
        placeholder: 'MF Rate(Auto Populate)',
        textInputType: 'default',
        validation: { isRequired: false },
      },
    ];
    newItems = newItems.map((item) => ({...item, ...additionData}));
    if (suffixName === 'S') {
      setSentinalCount(count);
      setEntries([...entries.slice(0, InsertPos), ...newItems, ...entries.slice(InsertPos)]);
    }
    if (suffixName === 'R') {
      setRandomCount(count);
      setEntries([...entries, ...newItems]);
    }
    if (item.labelText === 'Block Count') {
      setMdaBlockCount(count);
      setEntries([
        ...entries,
        {
          divider: true,
          fullView: true,
        },
        // {
        //   value: '',
        //   default: true,
        //   textField: true,
        //   entryName: `block_name_B${count}`,
        //   placeholder: `Block Name${count}`,
        //   textInputType: 'default',
        //   validation: { isRequired: false },
        // },
        {
          ComboBox: true,
          default: true,
          isCombined: true,
          entryName: `block_name_B${count}`,
          placeholder: `Block Name${count}`,
          data: [],
        },

        {
          // value: '',
          default: true,
          textField: true,
          disabled: true,
          entryName: `date_completed_B${count}`,
          placeholder: 'Date Completed(Auto Populate)',
          validation: { isRequired: false },
        },
        {
          value: '',
          isCombined: true,
          textField: true,
          textInputType: 'number',
          entryName: `covered_population_B${count}`,
          placeholder: 'Covered Population',
          validation: { isRequired: false },
        },
        {
          value: '',
          isCombined: true,
          textField: true,
          entryName: `total_population_B${count}`,
          placeholder: 'Total Population',
          textInputType: 'number',
          validation: { isRequired: false },
        },
        {
          value: '',
          default: true,
          entryName: `test_type_B${count}`,
          placeholder: 'Test Type',
          dropdown: true,
          returnValue: false,
          data: _.assign(dropdownList.testKitType) || [],
          validation: { isRequired: false },
        },
        {
          default: true,
          date: true,
          entryName: `date_of_mf_survey_B${count}`,
          placeholder: 'Date of MF Survey',
          validation: { isRequired: false },
        },
        {
          isEmptySpace: true,
          breakpoint: 4,
        },
        {
          value: '',
          default: true,
          textField: true,
          entryName: `site_name_S1_B${count}`,
          placeholder: 'Site Name S1',
          textInputType: 'default',
          validation: { isRequired: false },
        },
        {
          value: '',
          isCombined: true,
          textField: true,
          entryName: `bse_S1_B${count}`,
          placeholder: 'BSE S1',
          textInputType: 'number',
          validation: { isRequired: false },
        },
        {
          value: '',
          isCombined: true,
          textField: true,
          entryName: `positive_S1_B${count}`,
          placeholder: 'Positive S1',
          textInputType: 'number',
          validation: { isRequired: false },
        },

        {
          default: true,
          textField: true,
          disabled: true,
          entryName: `mf_rate_S1_B${count}`,
          placeholder: 'MF Rate(Auto Populate)',
          textInputType: 'default',
          validation: { isRequired: false },
        },
        {
          value: '',
          default: true,
          textField: true,
          entryName: `site_name_R1_B${count}`,
          placeholder: 'Site Name R1',
          textInputType: 'default',
          validation: { isRequired: false },
        },
        {
          value: '',
          isCombined: true,
          textField: true,
          entryName: `bse_R1_B${count}`,
          placeholder: 'BSE R1',
          textInputType: 'number',
          validation: { isRequired: false },
        },
        {
          value: '',
          isCombined: true,
          textField: true,
          entryName: `positive_R1_B${count}`,
          placeholder: 'Positive R1',
          textInputType: 'number',
          validation: { isRequired: false },
        },

        {
          default: true,
          textField: true,
          disabled: true,
          entryName: `mf_rate_R1_B${count}`,
          placeholder: 'MF Rate(Auto Populate)',
          textInputType: 'default',
          validation: { isRequired: false },
        },
      ]);
    }
  };

  /**
   * @param {number} __ current count value
   * @param {string} item selected count label
   */
  const handleNegativeCount = (__, item) => {
    const suffixName = item?.labelText.charAt(0);
    const InsertPos = entries.findIndex((p) => p.entryName === 'block_name_R1');
    if (suffixName === 'S') {
      setSentinalCount(sentinalCount - 1);
      entries.splice(InsertPos - 5, 5);
      setEntries([...entries]);
    }
    if (suffixName === 'R') {
      setRandomCount(randomCount - 1);
      entries.splice(entries.length - 5, 5);
      setEntries([...entries]);
    }
    if (item.labelText === 'Block Count') {
      entries.splice(entries.length - 16, 16);
      setEntries([...entries]);
    }
  };

  return (
    <Grid container item md={12}>
      <Grid item md={12} className="formContainer">
        <CustomForm
          daItemCount={daItemCount}
          entries={entries}
          onViewHandle={OnView}
          // defaultValues={
          //   strategy === 'District Strategy'
          //     ? { ...formDefaultValues, ...districtDefaultEntries }
          //     : { ...formDefaultValues, ...blockDefaultEntries }
          // }
          onReceiveData={(data) => onSubmitData(data)}
          dataInput
          separator
          countRange={strategy === 'District Strategy' ? districtCount : blockCount}
          getSelectedDropdown={handleDropDown}
          plus={handlePositiveCount}
          minus={handleNegativeCount}
          onCancelAction={() => OnCancel()}
          blackCount={mdaBlockCount}
          Activity={Activity}
          mdaSentinal={sentinalCount}
          BlockActivity="MDA"
          countS={sentinal1}
          countR={random1}
          strategy={strategy}
          districtValue={districtValue}
          stateValue={stateValeue}
          statergyId={statergyId}
          screen="input"
        />
      </Grid>
    </Grid>
  );
};
export default MDAFormInput;
