/* eslint-disable jsdoc/require-yields */
/* eslint-disable import/prefer-default-export */
import { all } from 'redux-saga/effects';
import nationalDashboardSaga from './dashboardSaga';
import programActivitySaga from './programActivitySaga';
import nationalBoardSaga from './nationalDashboardSaga';
import stateDashboardSaga from './stateDashboardSaga';
import insertDrugSaga from './insertDrugSaga';
import DrugTypeSaga from './drugTypeSaga';
import newUserSaga from './newUserSaga';
import loginSaga from './loginSaga';
import userRoleSaga from './userRole.saga';
import TASSaga from './TASSaga';
import IDASaga from './IDASaga';
import programStatusSaga from './programStatusSaga';
import geographicSaga from './geographicDimensionSaga';
import demographicSaga from './dashboardDimensionsSaga';
import DropDownsSaga from './dropdownSaga';
import dossierSaga from './dossierSaga';
import lymphedemaSaga from './lymphedemaSaga';
import preTasSaga from './preTasSaga';
import thresholdConfigSaga from './thresholdConfigSaga';
import formulaConfigSaga from './formulaConfigSaga';
import masterTemplateSaga from './masterTemplateSaga';
import predictionDashboardSaga from './predictionDashboardSaga';
import CustomReportSaga from './customReportSaga';
import DropDownSearchSaga from './dropdownSearchSaga';
/**
 *
 */
function* handler() {
  yield all([
    nationalDashboardSaga(),
    nationalBoardSaga(),
    stateDashboardSaga(),
    programActivitySaga(),
    insertDrugSaga(),
    DrugTypeSaga(),
    newUserSaga(),
    loginSaga(),
    userRoleSaga(),
    IDASaga(),
    programStatusSaga(),
    geographicSaga(),
    demographicSaga(),
    DropDownsSaga(),
    dossierSaga(),
    lymphedemaSaga(),
    preTasSaga(),
    TASSaga(),
    thresholdConfigSaga(),
    formulaConfigSaga(),
    masterTemplateSaga(),
    predictionDashboardSaga(),
    CustomReportSaga(),
    DropDownSearchSaga()
  ]);
}
export { handler };
