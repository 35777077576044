import { takeLatest } from 'redux-saga/effects';
import {
  GET_PREDICTION_ACTIVITY_CALENDAR,
  GET_PREDICTION_ACTIVITY_CALENDAR_TABLE,
  GET_PREDICTION_COUNTRY_PROFILE,
  GET_EXPECTED_ACTIVITIES,
  GET_PREDICTION_COUNTRY_PROFILE_MAP,
  GET_PREDICTION_DASHBOARD,
  GET_EXPECTED_ACTIVITIES_GRAPH,
} from '../../constants/actionType/index';
import {
  getPredictionActivityCalendarHandler,
  getPredictionActivityCalendarTableHandler,
  getPredictionCountryProfileHandler,
  getExpectedActivitesHandler,
  getPredictionCountryProfileMapHandler,
  getPredictionDashboardHandler,
  getExpectedActivitesGraphHandler,
} from '../handler/predictionDashboardHandler';

/**
 * @name predictionDashboardSaga used for dashboard api call
 * @yields getPredictionActivityCalendarHandler
 */
function* predictionDashboardSaga() {
  yield takeLatest(GET_PREDICTION_ACTIVITY_CALENDAR, getPredictionActivityCalendarHandler);
  yield takeLatest(GET_PREDICTION_ACTIVITY_CALENDAR_TABLE, getPredictionActivityCalendarTableHandler);
  yield takeLatest(GET_PREDICTION_COUNTRY_PROFILE, getPredictionCountryProfileHandler);
  yield takeLatest(GET_EXPECTED_ACTIVITIES, getExpectedActivitesHandler);
  yield takeLatest(GET_PREDICTION_COUNTRY_PROFILE_MAP, getPredictionCountryProfileMapHandler);
  yield takeLatest(GET_PREDICTION_DASHBOARD, getPredictionDashboardHandler);
  yield takeLatest(GET_EXPECTED_ACTIVITIES_GRAPH, getExpectedActivitesGraphHandler);
}
export default predictionDashboardSaga;
