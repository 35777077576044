/* eslint-disable import/prefer-default-export */
const tableHeader = [
  'S.No',
  'State',
  'District',
  'Strategy',
  'Date Proposed',
  'Date Planned',
  'Date Completed',
  'EU Count',
  'EU Name',
  'Block Date Proposed',
  'Block Date Completed',
  'Block Date Planned',
  'Population Pending Eu',
  'Sample Size',
  'Number Of Invalid Test',
  'Number Of Persons Positive',
  'Number Of Persons Examined',
  'Critical Cut Off',
  'EU Name',
  'Block Date Proposed',
  'Block Date Completed',
  'Block Date Planned',
  'Population Pending Eu',
  'Sample Size',
  'Number Of Invalid Test',
  'Number Of Persons Positive',
  'Number Of Persons Examined',
  'Critical Cut Off',
  'EU Name',
  'Block Date Proposed',
  'Block Date Completed',
  'Block Date Planned',
  'Population Pending Eu',
  'Sample Size',
  'Number Of Invalid Test',
  'Number Of Persons Positive',
  'Number Of Persons Examined',
  'Critical Cut Off',
  'EU Name',
  'Block Date Proposed',
  'Block Date Completed',
  'Block Date Planned',
  'Population Pending Eu',
  'Sample Size',
  'Number Of Invalid Test',
  'Number Of Persons Positive',
  'Number Of Persons Examined',
  'Critical Cut Off',
  'EU Name',
  'Block Date Proposed',
  'Block Date Completed',
  'Block Date Planned',
  'Population Pending Eu',
  'Sample Size',
  'Number Of Invalid Test',
  'Number Of Persons Positive',
  'Number Of Persons Examined',
  'Critical Cut Off',
  'EU Name',
  'Block Date Proposed',
  'Block Date Completed',
  'Block Date Planned',
  'Population Pending Eu',
  'Sample Size',
  'Number Of Invalid Test',
  'Number Of Persons Positive',
  'Number Of Persons Examined',
  'Critical Cut Off',
  'EU Name',
  'Block Date Proposed',
  'Block Date Completed',
  'Block Date Planned',
  'Population Pending Eu',
  'Sample Size',
  'Number Of Invalid Test',
  'Number Of Persons Positive',
  'Number Of Persons Examined',
  'Critical Cut Off',
  'EU Name',
  'Block Date Proposed',
  'Block Date Completed',
  'Block Date Planned',
  'Population Pending Eu',
  'Sample Size',
  'Number Of Invalid Test',
  'Number Of Persons Positive',
  'Number Of Persons Examined',
  'Critical Cut Off',
  'EU Name',
  'Block Date Proposed',
  'Block Date Completed',
  'Block Date Planned',
  'Population Pending Eu',
  'Sample Size',
  'Number Of Invalid Test',
  'Number Of Persons Positive',
  'Number Of Persons Examined',
  'Critical Cut Off',
  'EU Name',
  'Block Date Proposed',
  'Block Date Completed',
  'Block Date Planned',
  'Population Pending Eu',
  'Sample Size',
  'Number Of Invalid Test',
  'Number Of Persons Positive',
  'Number Of Persons Examined',
  'Critical Cut Off',
  'EU Name',
  'Block Date Proposed',
  'Block Date Completed',
  'Block Date Planned',
  'Population Pending Eu',
  'Sample Size',
  'Number Of Invalid Test',
  'Number Of Persons Positive',
  'Number Of Persons Examined',
  'Critical Cut Off',
  'EU Name',
  'Block Date Proposed',
  'Block Date Completed',
  'Block Date Planned',
  'Population Pending Eu',
  'Sample Size',
  'Number Of Invalid Test',
  'Number Of Persons Positive',
  'Number Of Persons Examined',
  'Critical Cut Off',
  'EU Name',
  'Block Date Proposed',
  'Block Date Completed',
  'Block Date Planned',
  'Population Pending Eu',
  'Sample Size',
  'Number Of Invalid Test',
  'Number Of Persons Positive',
  'Number Of Persons Examined',
  'Critical Cut Off',
  'EU Name',
  'Block Date Proposed',
  'Block Date Completed',
  'Block Date Planned',
  'Population Pending Eu',
  'Sample Size',
  'Number Of Invalid Test',
  'Number Of Persons Positive',
  'Number Of Persons Examined',
  'Critical Cut Off',
  'EU Name',
  'Block Date Proposed',
  'Block Date Completed',
  'Block Date Planned',
  'Population Pending Eu',
  'Sample Size',
  'Number Of Invalid Test',
  'Number Of Persons Positive',
  'Number Of Persons Examined',
  'Critical Cut Off',
];
const tableHeadeBlk = [
  'S.No',
  'State',
  'District',
  'Strategy',
  'Date Proposed',
  'Date Planned',
  'Date Completed',
  'EU Count',
  'EU Name',
  'Block Date Proposed',
  'Block Date Planned',
  'Block Date Completed',
  'Population Pending Eu',
  'Sample Size',
  'Number Of Persons Examined',
  'Number Of Persons Positive',
  'Number Of Invalid Test',
  'Critical Cut Off',
  // 'Block Name',
  // 'Population',
  // 'Block Name',
  // 'Population',
  // 'Block Name',
  // 'Population',
  'EU Name',
  'Block Date Proposed',
  'Block Date Planned',
  'Block Date Completed',
  'Population Pending Eu',
  'Sample Size',
  'Number Of Persons Examined',
  'Number Of Persons Positive',
  'Number Of Invalid Test',
  'Critical Cut Off',
  // 'Block Name',
  // 'Population',
  // 'Block Name',
  // 'Population',
  // 'Block Name',
  // 'Population',
  'EU Name',
  'Block Date Proposed',
  'Block Date Planned',
  'Block Date Completed',
  'Population Pending Eu',
  'Sample Size',
  'Number Of Persons Examined',
  'Number Of Persons Positive',
  'Number Of Invalid Test',
  'Critical Cut Off',
  // 'Block Name',
  // 'Population',
  // 'Block Name',
  // 'Population',
  // 'Block Name',
  // 'Population',
  'EU Name',
  'Block Date Proposed',
  'Block Date Planned',
  'Block Date Completed',
  'Population Pending Eu',
  'Sample Size',
  'Number Of Persons Examined',
  'Number Of Persons Positive',
  'Number Of Invalid Test',
  'Critical Cut Off',
  // 'Block Name',
  // 'Population',
  // 'Block Name',
  // 'Population',
  // 'Block Name',
  // 'Population',
  'EU Name',
  'Block Date Proposed',
  'Block Date Planned',
  'Block Date Completed',
  'Population Pending Eu',
  'Sample Size',
  'Number Of Persons Examined',
  'Number Of Persons Positive',
  'Number Of Invalid Test',

  'Critical Cut Off',
  // 'Block Name',
  // 'Population',
  // 'Block Name',
  // 'Population',
  // 'Block Name',
  // 'Population',
  'EU Name',
  'Block Date Proposed',
  'Block Date Planned',
  'Block Date Completed',
  'Population Pending Eu',
  'Sample Size',
  'Number Of Persons Examined',
  'Number Of Persons Positive',
  'Number Of Invalid Test',
  'Critical Cut Off',
  // 'Block Name',
  // 'Population',
  // 'Block Name',
  // 'Population',
  // 'Block Name',
  // 'Population',
  'EU Name',
  'Block Date Proposed',
  'Block Date Planned',
  'Block Date Completed',
  'Population Pending Eu',
  'Sample Size',
  'Number Of Persons Examined',
  'Number Of Persons Positive',
  'Number Of Invalid Test',
  'Critical Cut Off',
  // 'Block Name',
  // 'Population',
  // 'Block Name',
  // 'Population',
  // 'Block Name',
  // 'Population',
];
const BloodSurvey = [
  { name: 'Target_Sample_Size', placeholder: 'Target Sample Size', key: 'sample_size' },
  {
    name: 'Number_of_People_Examined',
    placeholder: 'Number of People Examined',
    key: 'number_of_persons_examined',
  },
  {
    name: 'Number_Of_People_Positive',
    placeholder: 'Number of People Positive',
    key: 'number_of_persons_positive',
  },
  { name: 'Number_Of_Invalid_Test', placeholder: 'Number of Invalid Test', key: 'number_of_invalid_test' },
  { name: 'Critical_Cut_Off', placeholder: 'Critical Cut Off', key: 'critical_cut_off' },
];
export { tableHeader, BloodSurvey, tableHeadeBlk };
