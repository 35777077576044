export const thData = [
  'S.No',
  'State',
  'District',
  'Drug Available Date-DEC',
  'Drug Available Date-ALB',
  'Drug Available Date-IVR',
];
export const tdData = [
  {
    S_no: 1,
    State: 'Utter Pradesh',
    district: 'Kanpur',
    strategy: 'District',
    Date_Proposed: '09 Mar 2020',
    Date_Planned: '09 Jan 2020',
    Date_completed: '09 Mar 2020',
    covered_population: ' 20,00,000',
    population: '20,00,000',
    Test_type: 'NBS',
  },
  {
    S_no: 2,
    State: 'Utter Pradesh',
    district: 'Kanpur',
    strategy: 'District',
    Date_Proposed: '09 Mar 2020',
    Date_Planned: '09 Jan 2020',
    Date_completed: '09 Mar 2020',
    covered_population: ' 20,00,000',
    population: '20,00,000',
    Test_type: 'NBS',
  },
  {
    S_no: 3,
    State: 'Utter Pradesh',
    district: 'Kanpur',
    strategy: 'District',
    Date_Proposed: '09 Mar 2020',
    Date_Planned: '09 Jan 2020',
    Date_completed: '09 Mar 2020',
    covered_population: ' 20,00,000',
    population: '20,00,000',
    Test_type: 'NBS',
  },
  {
    S_no: 4,
    State: 'Utter Pradesh',
    district: 'Kanpur',
    strategy: 'District',
    Date_Proposed: '09 Mar 2020',
    Date_Planned: '09 Jan 2020',
    Date_completed: '09 Mar 2020',
    covered_population: ' 20,00,000',
    population: '20,00,000',
    Test_type: 'NBS',
  },
  {
    S_no: 5,
    State: 'Utter Pradesh',
    district: 'Kanpur',
    strategy: 'District',
    Date_Proposed: '09 Mar 2020',
    Date_Planned: '09 Jan 2020',
    Date_completed: '09 Mar 2020',
    covered_population: ' 20,00,000',
    population: '20,00,000',
    Test_type: 'NBS',
  },
  {
    S_no: 6,
    State: 'Utter Pradesh',
    district: 'Kanpur',
    strategy: 'District',
    Date_Proposed: '09 june 2020',
    Date_Planned: '09 Jan 2020',
    Date_completed: '09 july 2020',
    covered_population: ' 20,00,000',
    population: '20,00,000',
    Test_type: 'NBS',
  },
  {
    S_no: 7,
    State: 'Utter Pradesh',
    district: 'Kanpur',
    strategy: 'District',
    Date_Proposed: '09 Mar 2020',
    Date_Planned: '09 Jan 2020',
    Date_completed: '09 Mar 2020',
    covered_population: ' 20,00,000',
    population: '20,00,000',
    Test_type: 'NBS',
  },
];
