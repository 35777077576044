/* eslint-disable react/jsx-no-useless-fragment */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Snackbar,Button } from '@material-ui/core';
import { Alert } from '@mui/lab';
import { CheckCircleOutline, ErrorOutline } from '@material-ui/icons';
import useStyle from './toastmessage-style';
import { INITIAL } from '../../../constants/common';
import "./ToastMessage.css";

/**
 * ToastMessage component displaying the toast
 *
 * @function ToastMessage
 * @param {object} props contains data related to Toast
 * @returns {React.ReactElement} ToastMessage component.
 */
const ToastMessage = memo((props) => {
  const { open, severity, message, anchorOrigin, close,buttons } = props;
  const { successAlert, errorAlert, marginTop, errorIcon } = useStyle();
  const [show, setShowHide] = React.useState(open);
  let customClass = '';
  let icon = null;
  if (severity === 'success') {
    customClass = successAlert;
    icon = <CheckCircleOutline />;
  } else if (severity === 'error') {
    customClass = errorAlert;
    icon = <ErrorOutline className={errorIcon} />;
  }
  return (
    <>
      {severity !== INITIAL && (
        <Snackbar
          classes={{
            root: marginTop,
          }}
          aria-label="toast-message"
          anchorOrigin={anchorOrigin}
          open={show}
          onClose={() => {
            setShowHide(false);
            close();
          }}
          autoHideDuration={40000}
          className='toaster_box'
        >
          <Alert className={customClass} severity={severity} icon={icon}>
            {message}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
          {buttons && buttons.map((button, index) => (
            <div key={index} style={{ marginLeft: '10px' }}>
              {button}
            </div>
          ))}
        </div>
          </Alert>
        </Snackbar>
      )}
    </>
  );
});

ToastMessage.propTypes = {
  open: PropTypes.bool.isRequired,
  severity: PropTypes.string,
  message: PropTypes.string.isRequired,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string.isRequired,
    horizontal: PropTypes.string.isRequired,
  }),
  close: PropTypes.func,
  buttons: PropTypes.arrayOf(PropTypes.node),
};

ToastMessage.defaultProps = {
  anchorOrigin: { vertical: 'top', horizontal: 'end' },
  severity: 'success',
  close: () => {},
};
export default ToastMessage;
