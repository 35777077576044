import { call, put } from 'redux-saga/effects';
import {
  STATE_DROPDOWN,
  DISTRICT_DROPDOWN,
  STATE_DROPDOWN_FAILED,
  PROGRAM_ACTIVITY_TYPE,
  PROGRAM_ACTIVITY_TYPE_FAILED,
  STRATEGY_DROPDOWN,
  STRATEGY_DROPDOWN_FAILED,
  TESTKITTYPE_DROPDOWN,
  TESTKITTYPE_DROPDOWN_FAILED,
  GET_STATE_BY_SEARCH,
  // STATE_BY_SEARCH_FAILED,
  GET_DISTRICT_BY_SEARCH,
  GET_STATE_BY_SEARCH_RESULT,
  // DISTRICT_BY_SEARCH_FAILED,
} from '../../constants/actionType';
import {
  getStateDropdownList,
  getDistrictDropdownList,
  getProgramActivityTypes,
  getStrategy,
  getTestKitTypeRequest,
  getStateSearchRequest,
  getDistrictSearchRequest,
} from '../request/dropdownRequest';

/**
 * @function getDemographicDimensionsHandler handler function to handle program activity get request and dispatch response data to reducer state
 * @yields getDemographicDimensionsHandler
 */
export function* getStateDropdownListHandler() {
  try {
    const response = yield call(getStateDropdownList);
    yield put({
      type: STATE_DROPDOWN,
      payload: response?.data,
    });
  } catch (err) {
    yield put({
      type: STATE_DROPDOWN_FAILED,
      payload: { Message: 'Something Went Wrong' },
    });
  }
}
/**
 * @param {object} action payload data
 * @function insertDemographicDimensionsHandler handler function to handle program activity post request
 * @yields insertDemographicDimensionsHandler
 */
export function* getDistrictDropdownListHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getDistrictDropdownList, payload);
    yield put({
      type: DISTRICT_DROPDOWN,
      payload: response?.data,
    });
  } catch (err) {
    yield put({
      type: STATE_DROPDOWN_FAILED,
      payload: { Message: 'Something Went Wrong' },
    });
  }
}

/**
 * @function getStateSearchHandler handler function to handle program activity get request and dispatch response data to reducer state
 * @yields getStateSearchHandler
 */

export function* getStateSearchHandler() {
  try {
    const response = yield call(getStateSearchRequest);
    console.log(response, 'response');
    yield put({
      type: GET_STATE_BY_SEARCH_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}
/**
 * @param {object} action payload data
 * @function insertDemographicDimensionsHandler handler function to handle program activity post request
 * @yields insertDemographicDimensionsHandler
 */

export function* getDistrictSearchHandler() {
  try {
    const response = yield call(getDistrictSearchRequest);
    console.log(response, 'response');
    yield put({
      type: GET_DISTRICT_BY_SEARCH,
      payload: response?.data,
    });
  } catch (err) {}
}

/**
 * @function insertDemographicDimensionsHandler handler function to handle program activity post request
 * @yields insertDemographicDimensionsHandler
 */
export function* getProgramActivityTypeDropdownListHandler() {
  try {
    const response = yield call(getProgramActivityTypes);
    yield put({
      type: PROGRAM_ACTIVITY_TYPE,
      payload: response?.data,
    });
  } catch (err) {
    yield put({
      type: PROGRAM_ACTIVITY_TYPE_FAILED,
      payload: { Message: 'Something Went Wrong' },
    });
  }
}
/**
 * @function getStrategyHandler handler function to handle strategy get request and dispatch response data to reducer state
 * @yields getStrategyHandler
 */
export function* getStrategyHandler() {
  try {
    const response = yield call(getStrategy);
    yield put({
      type: STRATEGY_DROPDOWN,
      payload: response?.data,
    });
  } catch (err) {
    yield put({
      type: STRATEGY_DROPDOWN_FAILED,
      payload: { Message: 'Something Went Wrong' },
    });
  }
}
/**
 * @function getTestKitTypeHandler handler function to handle strategy get request and dispatch response data to reducer state
 * @yields getTestKitTypeHandler
 */
export function* getTestKitTypeHandler() {
  try {
    const response = yield call(getTestKitTypeRequest);
    yield put({
      type: TESTKITTYPE_DROPDOWN,
      payload: response?.data,
    });
  } catch (err) {
    yield put({
      type: TESTKITTYPE_DROPDOWN_FAILED,
      payload: { Message: 'Something Went Wrong' },
    });
  }
}