/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Paper } from '@mui/material';
import * as _ from 'loadsh';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '../../../components/atoms/Typography';
import CustomForm from '../../../components/organisms/CustomForm';
import DatePicker from '../../../components/atoms/DatePicker';
import Loader from '../../../components/atoms/Loader';
import Toaster from '../../../components/atoms/ToastMessage';
import ViewPage from './ViewPage';
import {
  GET_NEWUSER_LIST,
  INSERT_NEWUSER_LIST,
  UPDATE_NEWUSER_LIST,
  DELETE_NEWUSER_LIST,
  USER_TYPE,
  USER_GROUP,
  GET_STATE_DROPDOWN,
} from '../../../constants/actionType';
import Table from '../../../components/organisms/Table';
import { CreateFormEntries, CreateFormDefaultValues, tableHeader } from './entries';
import { INITIAL_LOAD } from '../../../constants/common';

/**
 * @name MasterSetup
 * @returns {React.ReactElement} --
 */
const NewUserCreation = () => {
  const dispatch = useDispatch();
  const [viewPage, setViewPage] = useState(false);
  const [mainPage, setMainPage] = useState(true);
  const [rowData, setRowData] = useState([]);
  const [date, setDate] = React.useState(new Date());
  const { getNewUser, userGroups, userTypes, loading, status } = useSelector((state) => state.newUserDetails);
  console.log(getNewUser, 'kkddjstatus');
  const InputActionValidation = localStorage.getItem('ActionAccess');
  const Actionvalue = JSON.parse(InputActionValidation);
  const dropdownList = useSelector((state) => state.dropDownReducer);
  console.log(dropdownList, 'sdfsrrredropdownList');
  React.useEffect(() => {
    CreateFormEntries[0].data = _.assign(dropdownList?.stateData);
    CreateFormEntries.find((item) => item.entryName === 'state').data = dropdownList?.stateData;
    // setEntries(shallowDistrictEntries);
  }, [dropdownList]);
  const { BulkUpload, Download, Edit, ManualDataInput, View } = Actionvalue?.masters?.[0];
  console.log(BulkUpload, Download, Edit, ManualDataInput, View, 'aslkdjqqfasoihr');
  const [defaultFormValues, setDefaultForm] = React.useState(CreateFormDefaultValues);
  const [formMode, setFormMode] = React.useState(true);
  const [userId, setUserId] = React.useState(null);
  const [userInfo, setUserInfo] = React.useState({});
  const [toaster, setToaster] = React.useState(false);
  // const [view, setView] = React.useState(false);
  // const [formControl, setFormControl] = React.useState();
  const [userDetails, setUserDEtails] = React.useState({
    User_Group: '',
    User_Types: '',
  });
  useEffect(() => {
    dispatch({ type: GET_STATE_DROPDOWN });
    // dispatch({ type: GET_STRATEGY_DROPDOWN });
    // dispatch({ type: GET_TESTKITTYPE_DROPDOWN });
  }, [dispatch]);

  // const ValidationGroupId = localStorage.getItem('userGroupId');
  // const ValidationTypeId = localStorage.getItem('userTypeId');

  useEffect(() => {
    dispatch({ type: GET_NEWUSER_LIST });
    dispatch({ type: USER_GROUP });
    dispatch({ type: USER_TYPE });
    dispatch({ type: INITIAL_LOAD });
  }, []);

  React.useEffect(() => {
    if (status && status?.message !== '') setToaster(true);
    else setToaster(false);
  }, [status]);

  React.useMemo(() => {
    CreateFormEntries[0].data = _.assign(userGroups);
    CreateFormEntries[1].data = _.assign(userTypes);
  }, [userGroups, userTypes]);

  useEffect(() => {
    if (getNewUser) {
      const row = [];
      getNewUser?.map((data, index) => {
        row.push({
          S_no: index + 1,
          User_Group: data.user_group,
          User_Types: data.user_type,
          Name: data.user_name,
          Email: data.email,
          Address: data.address,
          Status: data.active_status,
          UserId: data.user_id,
          UserTypeId: data.user_type_id,
          UserGroupId: data.user_group_id,
          State: data?.state,
          state_id: data?.state_id,
        });
        return null;
      });
      setRowData(row);
    }
  }, [getNewUser]);

  // useEffect(() => {
  //   if (ValidationGroupId === '5' && ValidationTypeId === '2') {
  //     setView(true);
  //   }
  //   if (ValidationGroupId === '4' && ValidationTypeId === '1') {
  //     setView(false);
  //   }
  //   if (ValidationGroupId === '7' && ValidationTypeId === '1') {
  //     setView(false);
  //   }
  //   if (ValidationGroupId === '8' && ValidationTypeId === '5') {
  //     setView(true);
  //   }
  //   if (ValidationGroupId === '4' && ValidationTypeId === '3') {
  //     setView(false);
  //   }
  //   if (ValidationGroupId && ValidationTypeId === '4') {
  //     setView(true);
  //   }
  // }, [ValidationTypeId, ValidationGroupId]);
  // React.useEffect(() => {
  //   if (
  //     (ValidationGroupId === '0' && ValidationTypeId === '4') ||
  //     (ValidationGroupId === '8' && ValidationTypeId === '5')
  //   ) {
  //     // setFormControl(true);
  //   }
  // }, [ValidationGroupId, ValidationTypeId]);
  /**
   
  /**
   *
   * @param {*} data - data
   */
  const dataHandle = (data) => {
    setUserInfo(data);
    setViewPage(true);
    setMainPage(false);
  };
  /**
   *
   * @param {*} data returns form data
   * @function handleFormSubmit
   */
  const handleFormSubmit = async (data) => {
    console.log(data, 'state_id');
    const dynObj = {
      User_Group: data.User_Group !== '' ? data.User_Group : userDetails.User_Group,
      UserType: data.User_Types !== '' ? data.User_Types : userDetails.User_Types,
      Name: data.Name,
      Email: data.Email,
      Address: data.Address,
      Status: data.Status,
      UserId: userId,
      state_id: data?.state,
    };

    // active_status: 1;
    // address: 'asdfasdfasdff';
    // created_by: '106';
    // email: 'kamal@gmail.com';
    // user_group_id: 3;
    // user_name: 'kamal';
    // user_type_id: 2;

    if (formMode) {
      await dispatch({ type: INSERT_NEWUSER_LIST, payload: dynObj });
      dispatch({ type: GET_NEWUSER_LIST });
      setDefaultForm(CreateFormDefaultValues);
    } else {
      await dispatch({ type: UPDATE_NEWUSER_LIST, payload: dynObj });
      dispatch({ type: GET_NEWUSER_LIST });
      setFormMode(true);
      setDefaultForm(CreateFormDefaultValues);
    }
    // setTimeout(() => {

    // }, timeout);
  };

  /**
   *
   * @param {*} data returns form data
   * @function handleEdit
   */
  const handleEdit = (data) => {
    setUserId(data.UserId);
    console.log(data, 'lskdjfsjirt');
    const Group = CreateFormEntries[0].data.find((vals) => {
      return vals.value === data.User_Group ? vals.id : '';
    });

    const Type = CreateFormEntries[1].data.find((vals) => {
      return vals.value === data.User_Types ? vals.id : '';
    });

    setDefaultForm((prevState) => ({
      ...prevState,
      Address: data.Address,
      Email: data.Email,
      Name: data.Name,
      User_Types: Type?.id,
      User_Group: Group?.id,
      Status: data.Status,
      state: data?.state_id,
    }));
    setFormMode(false);
  };

  /**
   * @param {*} data returns form data
   * @function handleDelete
   */
  const handleDelete = async (data) => {
    await dispatch({ type: DELETE_NEWUSER_LIST, payload: data });
    dispatch({ type: GET_NEWUSER_LIST });
    setDefaultForm(CreateFormDefaultValues);
  };

  /**
   * @function handleCancel
   */
  const handleCancel = () => {
    setDefaultForm(CreateFormDefaultValues);
    setFormMode(true);
  };
  /**
   * @param {number} data - data
   * @param {string} name - name
   * @function getSelectedDropdown
   */
  const getSelectedDropdown = (data, name) => {
    setUserDEtails((prevState) => ({
      ...prevState,
      [name]: data,
    }));
  };
  if (loading) {
    return <Loader />;
  }

  return (
    <Grid item container md={12} sm={12} xs={12} spacing={3} sx={{ paddingTop: 2 }} className="dataLayout">
      {toaster && <Toaster open severity={status?.type} message={status?.message} close={() => setToaster(false)} />}
      <Grid item md={9} sm={12} xs={12} className="noPadding">
        <Paper elevation={3} className="splitPage">
          {mainPage && (
            <Grid>
              <Grid>
                <Typography
                  text="New User Creation"
                  type="caption"
                  customStyle={{ fontWeight: 'bold' }}
                  customClass="userText"
                />
              </Grid>

              <Grid item md={12} className="formContainer">
                <CustomForm
                  entries={CreateFormEntries}
                  defaultValues={defaultFormValues}
                  masterSetup
                  onReceiveData={(data) => handleFormSubmit(data)}
                  onCancelAction={handleCancel}
                  SubmitBtnTitle={formMode ? 'Submit' : 'Update'}
                  getSelectedDropdown={getSelectedDropdown}
                  screen="masters"
                />
              </Grid>

              {View === 1 && (
                <Grid>
                  <Table
                    // masterSetup={['Print', 'Downloader', 'Search']}
                    masterSetup={['Downloader', 'Search']}
                    userActivity="New User Creation"
                    header={tableHeader}
                    rows={rowData}
                    optional
                    edit
                    deleteData
                    view
                    onViewData={(data) => dataHandle(data)}
                    onDeleteData={(data) => handleDelete(data)}
                    onEditData={(data) => handleEdit(data)}
                    hideKeyFields={['UserId', 'UserGroupId', 'UserTypeId', 'State', 'state_id']}
                    Activity="NewUser"
                    screen="masters"
                  />
                </Grid>
              )}
            </Grid>
          )}
          {viewPage && (
            <ViewPage
              userInfo={userInfo}
              onClose={() => {
                setMainPage(true);
                setViewPage(false);
              }}
            />
          )}
        </Paper>
      </Grid>
      <Grid item md={3} sm={12} xs={12} sx={{ height: '100%' }} className="paddingView">
        <Paper elevation={3} className="splitPage">
          <Grid sx={{ pt: 2 }}>
            <DatePicker calendarPicker date={date} onHandleChange={(newDate) => setDate(newDate)} />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default NewUserCreation;
