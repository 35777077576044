import React from 'react';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import theme from './assets/theme';
import RouterPage from './route';
// import { AuthContext } from "./context/auth";

/**
 *@returns {React.ReactElement} - return
 */
const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <RouterPage />
      </div>
    </ThemeProvider>
  );
};

export default App;
