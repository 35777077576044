/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable array-callback-return */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import Table from '../../../../components/organisms/Table';
import { thData } from './TableEntries';
import './DrugTable.css';
import {
  BULK_UPLOAD_DRUG,
  GET_DRUG_AVILABILITY,
  UPDATE_DRUG_AVILABILITY,
  UPLOAD_DRUG_AVILABILITY,
} from '../../../../constants/actionType';

/**
 *
 * @returns {React.ReactElement} - returns the IDA input screen
 */
const DrugTable = (props) => {
  const { onCancel, Activity, jsonData, isJson, setIsJson } = props;
  const dispatch = useDispatch();

  const [rowIds, setRowIds] = useState([]);

  const { getDrug } = useSelector((state) => state?.insertDrugDetails);
  console.log(jsonData, 'kkdkfdkjrrr');
  useEffect(() => {
    dispatch({ type: GET_DRUG_AVILABILITY });
  }, [dispatch]);

  const onSendUpdatedValue = React.useCallback(
    async (key, rowData) => {
      if (jsonData?.length > 0) {
        const arr = [];

        jsonData?.map((item) => {
          console.log(item, 'sdkjdskjir');
          arr.push({
            state: item?.state,
            district: item?.district,
            drug_available_date_DEC: moment(new Date((item?.DEC - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD'),
            drug_available_date_ALB: moment(new Date((item?.ALB - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD'),
            drug_available_date_IVR: moment(new Date((item?.IVR - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD'),
          });
        });

        dispatch({ type: UPLOAD_DRUG_AVILABILITY, payload: arr });
      } else {
        await dispatch({ type: UPDATE_DRUG_AVILABILITY, payload: rowData });
        setTimeout(() => {
          dispatch({ type: GET_DRUG_AVILABILITY });
        }, 2000);
      }
      setIsJson(false);
    },
    [dispatch, jsonData, setIsJson],
  );

  React.useEffect(() => {
    if (getDrug?.length) {
      const tmpArr = [];
      const tmpIdsArr = [];
      getDrug?.map((value, index) => {
        tmpIdsArr.push(value.drug_availability_id);
        return tmpArr.push({
          sno: index + 1,
          state: value.state,
          district: value.district,
          drug_available_date_ALB: value?.drug_available_date_ALB,
          drug_available_date_DEC: value.drug_available_date_DEC,
          drug_available_date_IVR: value?.drug_available_date_IVR,
          // Status: value.status === 'Active' ? 'Active' : 'Inactive',
        });
      });
      setRowIds(tmpIdsArr);
    }
  }, [dispatch, getDrug]);
  console.log(isJson, 'asdlfkjr');
  return (
    <Table
      header={thData}
      rows={isJson ? jsonData : getDrug}
      slideData
      inputTable
      onSendUpdatedData={(key, rowData) => onSendUpdatedValue(key, rowData)}
      hideKeyFields={['block', 'certification_completed', 'drug_availability_id', 'district_id', 'state_id']}
      showSno
      getById="drug_availability_id"
      onCancel={onCancel}
      Activity={Activity}
      uniqueKey="drug_availability_id"
      IsExcelData={isJson}
      screen="input"
    />
  );
};

export default DrugTable;
DrugTable.propTypes = {
  onCancel: PropTypes.func,
  Activity: PropTypes.string,
};
DrugTable.defaultProps = {
  onCancel: () => null,
  Activity: '',
};
