/* eslint-disable import/prefer-default-export */
/* eslint-disable no-empty */
import { call, put } from 'redux-saga/effects';
import { FILTER_DRIVEN_EXTRACT_REPORT_RESULT, CUSTOMREPORT_BY_TYPE_RESULT } from '../../constants/actionType/index';
import { filterDrivenExtractReportsRequest, customReportByTypeRequest } from '../request/customReportRequest';

/**
 * @name filterDrivenExtractReportsHandler used for filter reports
 * @param {object} action payload
 * @yields filterDrivenExtractReportsHandler
 */
export function* filterDrivenExtractReportsHandler(action) {
  const { payload, filterType } = action;
  try {
    const response = yield call(filterDrivenExtractReportsRequest, payload, filterType);
    yield put({
      type: FILTER_DRIVEN_EXTRACT_REPORT_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}

/**
 * @name customReportByTypeHandler used for filter reports
 * @param {object} action payload
 * @yields customReportByTypeHandler
 */
export function* customReportByTypeHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(customReportByTypeRequest, payload);
    yield put({
      type: CUSTOMREPORT_BY_TYPE_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}
