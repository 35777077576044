import React from 'react';
import { CircularProgress, Box } from '@mui/material';
import './loader.css';
/**
 *
 * @returns {React.ReactElement} - returns loader
 */
const Loader = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <CircularProgress />
    </Box>
  );
};

export default Loader;
