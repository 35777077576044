/* eslint-disable import/prefer-default-export */
/* eslint-disable no-empty */
import { call, put } from 'redux-saga/effects';
import {
  BLOCK_NAME_RESULT,
  EU_NAME_RESULT,
  GET_PRE_TAS_RESULT,
  // INSERT_PRE_TAS_RESULT,
  // UPDATE_PRE_TAS_ID_RESULT,
  // UPDATE_PRE_TAS_RESULT,
  GET_PROPOSED_PRE_TAS_RESULT,
  LOADER_PRE_TAS,
  PRE_TAS_STATUS,
} from '../../constants/actionType/index';
import {
  getPreTasRequest,
  insertPreTasRequest,
  updatepreTasIdRequest,
  updatePreTasRequest,
  getProposedDatePreTasRequest,
  bulkUploadPreTasRequest,
  insertPreTasBlockStrategyRequest,
  getBlockNameRequest,
  getEUNameRequest,
} from '../request/PreTasRequest';
import { CATCH, ERROR, SUCCESS, FAILED, UPDATED, SUBMITTED, BULK_UPLOAD_SUCCESS } from '../../constants/common';

/**
 * @name getPreTasHandler used for dashboard api call
 * @param {object} action payload
 * @yields nationalMdaCoverageHandler
 */
export function* getPreTasHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getPreTasRequest, payload);

    yield put({
      type: GET_PRE_TAS_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}

/**
 * @name insertPreTasHandler used for dashboard api call
 * @param {object} action payload
 * @yields nationalMdaCoverageHandler
 */
export function* insertPreTasHandler(action) {
  const { payload } = action;
  try {
    yield put({ type: LOADER_PRE_TAS, payload: true });
    const response = yield call(insertPreTasRequest, payload);
    if (response.status === 200) {
      yield put({
        type: PRE_TAS_STATUS,
        payload: { type: SUCCESS, message: SUBMITTED },
      });
    } else {
      yield put({
        type: PRE_TAS_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    }
  } catch (err) {
    yield put({
      type: PRE_TAS_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}

/**
 * @name insertPreTasHandler used for dashboard api call
 * @param {object} action payload
 * @yields nationalMdaCoverageHandler
 */
export function* insertPreTasBlockHandler(action) {
  const { payload } = action;
  try {
    yield put({ type: LOADER_PRE_TAS, payload: true });
    const response = yield call(insertPreTasBlockStrategyRequest, payload);
    if (response.status === 200) {
      yield put({
        type: PRE_TAS_STATUS,
        payload: { type: SUCCESS, message: SUBMITTED },
      });
    } else {
      yield put({
        type: PRE_TAS_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    }
  } catch (err) {
    yield put({
      type: PRE_TAS_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
/**
 * @name updatePreTasIdHandler used for dashboard api call
 * @param {object} action payload
 * @yields nationalMdaCoverageHandler
 */
export function* updatePreTasIdHandler(action) {
  const { payload } = action;
  try {
    yield put({ type: LOADER_PRE_TAS, payload: true });
    const response = yield call(updatepreTasIdRequest, payload);
    if (response.status === 200) {
      yield put({
        type: PRE_TAS_STATUS,
        payload: { type: SUCCESS, message: UPDATED },
      });
    } else {
      yield put({
        type: PRE_TAS_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    }
  } catch (err) {
    yield put({
      type: PRE_TAS_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
/**
 * @name updatePreTasHandler used for dashboard api call
 * @param {object} action payload
 * @yields nationalMdaCoverageHandler
 */
export function* updatePreTasHandler(action) {
  const { payload } = action;
  try {
    yield put({ type: LOADER_PRE_TAS, payload: true });
    const response = yield call(updatePreTasRequest, payload);
    if (response.status === 200) {
      yield put({
        type: PRE_TAS_STATUS,
        payload: { type: SUCCESS, message: UPDATED },
      });
    } else {
      yield put({
        type: PRE_TAS_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    }
  } catch (err) {
    yield put({
      type: PRE_TAS_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}

// export function* updateIDAHandler(action) {
//   const { payload } = action;
//   try {
//     yield put({ type: LOADER, payload: true });
//     const response = yield call(updateIDARequest, payload);
//     if (response.status === 200) {
//       yield put({
//         type: IDA_STATUS,
//         payload: { type: SUCCESS, message: UPDATED },
//       });
//     } else {
//       yield put({
//         type: IDA_STATUS,
//         payload: { type: ERROR, message: FAILED },
//       });
//     }
//   } catch (err) {
//     yield put({
//       type: IDA_STATUS,
//       payload: { type: ERROR, message: CATCH },
//     });
//   }
// }

/**
 * @name getPreTasHandler used for dashboard api call
 * @param {object} action payload
 * @yields nationalMdaCoverageHandler
 */
export function* getProposedPreTasHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getProposedDatePreTasRequest, payload);
    if (response.status !== 200) {
      yield put({
        type: PRE_TAS_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    } else {
      yield put({
        type: GET_PROPOSED_PRE_TAS_RESULT,
        payload: response?.data,
      });
      if (response.data.status === 0) {
        yield put({
          type: PRE_TAS_STATUS,
          payload: { type: ERROR, message: response.data.msg },
        });
      }
    }
  } catch (err) {
    yield put({
      type: PRE_TAS_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
/**
 * @param {object} action payload data
 * @function updatePreTasHandler handler function to handle program activity update request
 * @yields updateDossierHandler
 */
export function* uploadPreTasHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(bulkUploadPreTasRequest, payload);
    if (response.status === 200) {
      yield put({
        type: PRE_TAS_STATUS,
        payload: { type: SUCCESS, message: BULK_UPLOAD_SUCCESS },
      });
    } else {
      yield put({
        type: PRE_TAS_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    }
  } catch (err) {
    yield put({
      type: PRE_TAS_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}

/**
 * @param {object} action payload data
 * @function blockNameHandler handler function to handle program activity update request
 * @yields updateDossierHandler
 */
export function* blockNameHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getBlockNameRequest, payload);
    if (response.status === 200) {
      yield put({
        type: BLOCK_NAME_RESULT,
        payload: response?.data,
      });
    } else {
      yield put({
        type: PRE_TAS_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    }
  } catch (err) {
    yield put({
      type: PRE_TAS_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}

/**
 * @param {object} action payload data
 * @function updatePreTasHandler handler function to handle program activity update request
 * @yields updateDossierHandler
 */
export function* euNameHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getEUNameRequest, payload);

    if (response.status === 200) {
      yield put({
        type: EU_NAME_RESULT,
        payload: response?.data,
      });
    } else {
      yield put({
        type: PRE_TAS_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    }
  } catch (err) {
    yield put({
      type: PRE_TAS_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}

// /**
//  * @param {object} action payload data
//  * @function blockNameHandler handler function to handle program activity update request
//  * @yields updateDossierHandler
//  */
// export function* euNameHandler(action) {
//   alert('handler');
//   const { payload } = action;
//   try {
//     const response = yield call(getEUNameRequest, payload);
//     if (response.status === 200) {
//       yield put({
//         type: PRE_TAS_STATUS,
//         payload: { type: SUCCESS, message: BULK_UPLOAD_SUCCESS },
//       });
//     } else {
//       yield put({
//         type: PRE_TAS_STATUS,
//         payload: { type: ERROR, message: FAILED },
//       });
//     }
//   } catch (err) {
//     yield put({
//       type: PRE_TAS_STATUS,
//       payload: { type: ERROR, message: CATCH },
//     });
//   }
// }
