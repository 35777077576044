/* eslint-disable default-param-last */
import {
  GET_THRESHOLD_CONFIG_RESULT,
  INSERT_THRESHOLD_CONFIG_RESULT,
  THRESHOLD_APPROVAL_ATTACHMENT_RESULT,
  THRESHOLD_STATUS,
  APPROVAL_STATUS,
} from '../../constants/actionType';
import { thresholdInitialState } from '../initialStates';

/**
 * @param {string} state defines the state
 * @param {object} action used for payload and type
 * @returns {Array} action response state
 */
const thresholdReducer = (state = thresholdInitialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_THRESHOLD_CONFIG_RESULT:
      return {
        ...state,
        getThreshold: payload?.data,
      };
    case INSERT_THRESHOLD_CONFIG_RESULT:
      return {
        ...state,
        bulkStatus: payload.data,
      };
    case THRESHOLD_APPROVAL_ATTACHMENT_RESULT:
      return {
        ...state,
        approvalStatus: payload.data,
      };
    case THRESHOLD_STATUS:
      return {
        ...state,
        bulkStatus: payload,
        loading: false,
      };
    case APPROVAL_STATUS:
      return {
        ...state,
        approvalStatus: payload,
        loading: false,
      };

    default:
      return state;
  }
};
export default thresholdReducer;
