/* eslint-disable default-param-last */
import { GET_MASTER_TEMPLATES_RESULT } from '../../constants/actionType';
import { masterTemplate } from '../initialStates';

/**
 * @param {string} state defines the state
 * @param {object} action used for payload and type
 * @returns {Array} action response state
 */
const masterTemplateReducer = (state = masterTemplate, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_MASTER_TEMPLATES_RESULT:
      return {
        ...state,
        getMasterTemplate: payload?.data,
      };

    default:
      return state;
  }
};
export default masterTemplateReducer;
