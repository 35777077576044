/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import './Table.css';
import PropTypes from 'prop-types';
import { thDatas } from './TableEntries';
import Table from '../../../../components/organisms/Table/index';
import Typography from '../../../../components/atoms/Typography/index';
import { BULK_UPLOAD_DRUG, GET_DRUG } from '../../../../constants/actionType';
/**
 * @param {object} props -  required props in input table
 * @name InputTable
 * @returns {React.ReactElement} return the user role component
 */
const InputTable = (props) => {
  const { onEditClick, isJson, jsonData, setIsJson } = props;
  const dispatch = useDispatch();
  const { getDrugType } = useSelector((state) => state?.drugTypeDetails);
  const [mappedRowData, setMappedRowData] = useState([]);
  const [rowIds, setRowIds] = useState([]);
  console.log(mappedRowData, 'sdfsrrr');
  useEffect(() => {
    dispatch({ type: GET_DRUG });
  }, [dispatch]);
  /**
   *
   */
  const onSendUpdatedData = () => {
    const arr = [];
    jsonData?.map((item) => {
      console.log(item, 'sdkfjsidirrttw');
      arr.push({
        state: item?.State,
        district: item?.District,
        drug_regime: item?.Drug_Reigme,
        drug_type: item?.Drug_Type,
        from_date: item?.From_Date,
        to_date: item?.To_Date,
        active_status: item?.Status,
      });
    });
    dispatch({ type: BULK_UPLOAD_DRUG, payload: arr });
    setIsJson(false);
  };

  React.useEffect(() => {
    if (getDrugType?.length) {
      const tmpArr = [];
      const tmpIdsArr = [];
      getDrugType?.map((data, index) => {
        tmpIdsArr.push(data.drug_id);
        return tmpArr.push({
          S_no: index + 1,
          State: data?.state,
          State_Id: data?.state_id,
          District: data?.district,
          District_Id: data?.district_id,
          Drug_Reigme: data?.drug_regime,
          Drug_Reigme_Id: data?.drug_regime_id,
          Drug_Type: data?.drug_type,
          Drug_Type_Id: data?.drug_type_id,
          From_Date: data?.from_date,
          To_Date: data?.to_date,
          Status: data?.status,
        });
      });
      setRowIds(tmpIdsArr);
      setMappedRowData(tmpArr);
    }
  }, [getDrugType]);

  return (
    <Grid>
      <Typography
        className="class"
        text="Create Program Activity"
        colorType="quinary"
        customClass="text"
        type="subHeading"
      />

      <Table
        onEditData={(e) => onEditClick(e, rowIds)}
        header={thDatas}
        rows={isJson ? jsonData : mappedRowData}
        masterSetup={!isJson && ['Downloader', 'Search']}
        userActivity="View Drug Type Master"
        optional
        edit
        hideKeyFields={['State_Id', 'District_Id', 'Drug_Reigme_Id', 'Drug_Type_Id']}
        Activity="DrugType"
        inputTable={isJson}
        IsExcelData={isJson}
        onSendUpdatedData={onSendUpdatedData}
        screen="masters"
      />
    </Grid>
  );
};
export default InputTable;
InputTable.propTypes = {
  onEditClick: PropTypes.func.isRequired,
};

// {
//     "user_group_id": 4,
//     "user_type_id": 1,
//     "national_dashboard": [
//         {
//             "screen_name": "national",
//             "country_profile_fields": "",
//             "country_profile": "0",
//             "progress_status_fields": "",
//             "progress_status": "0",
//             "activity_calender_fields": "",
//             "activity_calender": "0",
//             "pre_tas_overview_fields": "",
//             "pre_tas_overview": "0",
//             "tas_decision_matrix_fields": "",
//             "tas_decision_matrix": "0",
//             "tas_summary_fields": "",
//             "tas_summary": "0",
//             "mda_coverage_fields": "",
//             "mda_coverage": "0",
//             "data_quality_fields": "",
//             "data_quality": "0",
//             "role_privilege_access_dashboard_id": 108
//         }
//     ],
//     "state_dashboard": [
//         {
//             "screen_name": "state",
//             "state_profile_fields": "",
//             "state_profile": "0",
//             "state_progress_status_fields": "",
//             "state_progress_status": "0",
//             "state_activity_calender_fields": "",
//             "state_activity_calender": "0",
//             "state_tasDecisionMatrix_fields": "",
//             "state_tasDecisionMatrix": "0",
//             "state_districtProfileWidget_fields": "",
//             "state_districtProfileWidget": "0",
//             "state_districtTasProfile_fields": "",
//             "state_districtTasProfile": "0",
//             "state_districtDecisionMatrix_fields": "",
//             "state_districtDecisionMatrix": "0",
//             "state_dataQuality_fields": "",
//             "state_dataQuality": "0",
//             "role_privilege_access_dashboard_id": 108
//         }
//     ],
//     "prediction_dashboard": [
//         {
//             "screen_name": "prediction",
//             "prediction_countryProfile_fields": "",
//             "prediction_countryProfile": "0",
//             "prediction_expectedActivites_fields": "",
//             "prediction_expectedActivites": "0",
//             "prediction_activityCalender_fields": "",
//             "prediction_activityCalender": "0"
//         }
//     ],
//     "created_by": "1",
//     "user_id": "1",
//     "privilege_dashboard": [
//         {
//             "screen_name": "data_input",
//             "view": "1",
//             "download": "1",
//             "edit": "1",
//             "manual_data_input": "0",
//             "bulk_upload": "0",
//             "role_privilege_access_id": 260
//         },
//         {
//             "screen_name": "output_dashboard",
//             "view": "1",
//             "download": "1",
//             "edit": "1",
//             "manual_data_input": "0",
//             "bulk_upload": "0",
//             "role_privilege_access_id": 261
//         },
//         {
//             "screen_name": "master_setup",
//             "view": "0",
//             "download": "0",
//             "edit": "0",
//             "manual_data_input": "0",
//             "bulk_upload": "0",
//             "role_privilege_access_id": 262
//         },
//         {
//             "screen_name": "send_mail",
//             "view": "0",
//             "download": "0",
//             "edit": "0",
//             "manual_data_input": "0",
//             "bulk_upload": "0",
//             "role_privilege_access_id": 263
//         }
//     ]
// }

// {
//     "role_privilege_id":"3",
//     "user_group_id": "2",
//     "user_type_id": "2",
//     "national_dashboard": [
//         {
//             "screen_name": "national",
//             "country_profile_fields": "wdad",
//             "country_profile": "0",
//             "progress_status_fields": "",
//             "progress_status": "0",
//             "activity_calender_fields": "",
//             "activity_calender": "0",
//             "pre_tas_overview_fields": "",
//             "pre_tas_overview": "0",
//             "tas_decision_matrix_fields": "",
//             "tas_decision_matrix": "0",
//             "tas_summary_fields": "",
//             "tas_summary": "0",
//             "mda_coverage_fields": "",
//             "mda_coverage": "0",
//             "data_quality_fields": "",
//             "data_quality": "0",
//             "role_privilege_access_dashboard_id": 1
//         }
//     ],
//     "state_dashboard": [
//         {
//             "screen_name": "state",
//             "state_profile_fields": "",
//             "state_profile": "0",
//             "state_progress_status_fields": "",
//             "state_progress_status": "0",
//             "state_activity_calender_fields": "",
//             "state_activity_calender": "0",
//             "state_tasDecisionMatrix_fields": "",
//             "state_tasDecisionMatrix": "0",
//             "state_districtProfileWidget_fields": "",
//             "state_districtProfileWidget": "0",
//             "state_districtTasProfile_fields": "",
//             "state_districtTasProfile": "0",
//             "state_districtDecisionMatrix_fields": "",
//             "state_districtDecisionMatrix": "0",
//             "state_dataQuality_fields": "",
//             "state_dataQuality": "0",
//             "role_privilege_access_dashboard_id": 2
//         }
//     ],
//     "prediction_dashboard": [
//         {
//             "screen_name": "prediction",
//             "prediction_countryProfile_fields": "",
//             "prediction_countryProfile": "0",
//             "prediction_expectedActivites_fields": "",
//             "prediction_expectedActivites": "0",
//             "prediction_activityCalender_fields": "",
//             "prediction_activityCalender": "0",
//             "role_privilege_access_dashboard_id":3
//         }
//     ],
//     "updated_by": "1",
//     "user_id": "1",
//     "privilege_dashboard": [
//         {
//             "screen_name": "data_input",
//             "view": "1",
//             "download": "1",
//             "edit": "1",
//             "manual_data_input": "0",
//             "bulk_upload": "0",
//             "role_privilege_access_id": 1
//         },
//         {
//             "screen_name": "output_dashboard",
//             "view": "0",
//             "download": "0",
//             "edit": "0",
//             "manual_data_input": "0",
//             "bulk_upload": "0",
//             "role_privilege_access_id": 2
//         },
//         {
//             "screen_name": "master_setup",
//             "view": "0",
//             "download": "0",
//             "edit": "0",
//             "manual_data_input": "1",
//             "bulk_upload": "0",
//             "role_privilege_access_id": 3
//         },
//         {
//             "screen_name": "send_mail",
//             "view": "0",
//             "download": "0",
//             "edit": "0",
//             "manual_data_input": "1",
//             "bulk_upload": "0",
//             "role_privilege_access_id": 4
//         }
//     ]
// }
