const colors = {
  blue: {
    primary: '#1BA1E2',
    secondary: '#25214e',
    tertiary: '#595778a8',
    quaternary: '#4791FF',
    quinary: '#9475FF',
    senary: '#2EC7DC',
    septenary: '#2C77FF',
    octonary: '#003696',
    nonary: '#0093ff',
  },
  gray: {
    primary: '#9b9c9e',
    secondary: '#f2f4f6',
    tertiary: '#484850',
    quaternary: '#707070',
    quinary: '#DDDEDE',
    senary: '#a8a8a9',
    septenary: '#aeafaf ',
    octonary: '#6d6e6f',
  },
  orange: {
    primary: '#F09609',
    secondary: '#FF8800',
  },
  green: {
    primary: '#339933',
    secondary: '#12E26C',
    tertiary: '#00ABA9',
    quaternary: '#8CBF26',
    quinary: '#02BC77',
    senary: '#4BB04E',
    septenary: '#15DEA7',
    octonary: '#00C943',
  },
  yellow: {
    primary: '#fed700',
    secondary: '#fd9729',
    tertiary: '#dcb30c',
    quaternary: '#e2c036',
    quinary: '#FFD950',
  },
  violet: {
    primary: '#B677FF',
  },
  pink: {
    primary: '#ff6e89',
    secondary: '#E671B8',
    tertiary: '#FF2366',
    quaternary: '#FD4E71',
  },
  white: {
    primary: '#fff',
    secondary: '#DFE2E6',
    tertiary: '#ebeff5',
    quaternary: '#147AD6',
  },
  black: {
    primary: '#000',
  },
  navyBlue: '#130F3F',
  lightorange: '#1EAAF1',
  lightyellow: '#8CC152',
  skyblue: '#159588',
  error: {
    primary: '#EC6666',
  },
  hoverMode: {
    pink: '#e43a5c',
    violet: '#7557e0',
    orange: '#d87f1c',
    lightGreen: '#82b24d',
    skyblue: '#0c8ea0',
    darkBlue: '#1156d2',
    darkred: '#e36243',
    lightblue: '#7954f5',
  },
  activityMode: {
    orange: '#fd9727',
    violet: '#9475ff',
    lightGreen: '#8cc152',
    skyblue: '#2ec7dc',
    darkBlue: '#2c77ff',
  },
  typeCode: {
    halfRed: '#FC5830',
    orange: '#FD9727',
    seablue: '#1EAAF1',
    skyblue: '#587BF8',
    peachGreen: '#159588',
    yellow: '#FED700',
    default: '#DBDBDB',
    transparent: 'transparent',
  },
  graph: '#130F3F',
};
export default colors;
