export const CreateFormEntries = [
  {
    count: true,
    entryName: 'Program_Activity_Type',
    placeholder: 'Program Activity Type',
    dropdown: true,
    data: [
      { id: 101, value: 'DA' },
      { id: 102, value: 'IDA' },
      { id: 87, value: 'Pre-TAS' },
      { id: 5, value: 'TAS1' },
      { id: 6, value: 'TAS2' },
      { id: 8, value: 'TAS3' },
    ],
    validation: [{ isRequired: true }],
  },
  {
    count: true,
    textField: true,
    entryName: 'Program_Status',
    placeholder: 'Program Status',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },
  {
    criteria: true,
    textField: true,
    multiline: true,
    entryName: 'Criteria',
    placeholder: 'Criteria',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },
  {
    count: true,
    textField: true,
    entryName: 'Data_Status',
    placeholder: 'Data Status',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },
  {
    count: true,
    textField: true,
    entryName: 'Result_For_Next_Year',
    placeholder: 'Result for next year',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },

  {
    count: 'true',
    date: true,
    entryName: 'from_date',
    placeholder: 'From Date',
    validation: [{ isRequired: true }, { min: false }, { dateValidation: true }],
  },
  {
    count: 'true',
    date: true,
    entryName: 'to_date',
    placeholder: ' To Date ',
    validation: [{ isRequired: true }],
  },

  {
    count: true,
    radioButton: true,
    entryName: 'Certificate',
    placeholder: 'Certificate',
    labelText: 'Status',
    radioData: [{ name: 'Active' }, { name: 'Inactive' }],
    validation: [{ isRequired: true }],
  },
];
export const CreateFormDefaultValues = {
  Program_Activity_Type: '',
  Program_Status: '',
  Criteria: '',
  Data_Status: '',
  Result_For_Next_Year: '',
  To_Date: '',
  From_Date: '',
  Certificate: 'Active',
};

export const thDatas = [
  'S.No',
  'Program Activity Type',
  'Program Status',
  'Old Program Status',
  'Criteria',
  'Data Status',
  'Old Data Status',
  'From Date',
  'To Date',
  'Status',
  'Action',
  'StatusId',
  'ResultYear',
];

export const statusTdData = [
  {
    sno: '1',
    ProgramActivityType: 'Pre-TAS',
    ProgramStatus: 'Postponed',
    OldProgramStatus: '',
    Criteria: 'Mf Rate',
    DataStatus: 'Postponed',
    OldDataStatus: 'Postponed',
    ToDate: '',
    FromDate: '09 Mar 2022',
    Status: 'Active',
  },
  {
    sno: '2',
    ProgramActivityType: 'TAS1',
    ProgramStatus: 'Postponed',
    OldProgramStatus: '',
    Criteria: 'Mf Rate',
    DataStatus: 'Postponed',
    OldDataStatus: 'Postponed',
    ToDate: '',
    FromDate: '09 Mar 2022',
    Status: 'Active',
  },
  {
    sno: '3',
    ProgramActivityType: 'TAS2',
    ProgramStatus: 'Postponed',
    OldProgramStatus: '',
    Criteria: 'Mf Rate',
    DataStatus: 'Delayed',
    OldDataStatus: 'Delayed',
    ToDate: '',
    FromDate: '09 Mar 2022',
    Status: 'Active',
  },
  {
    sno: '4',
    ProgramActivityType: 'TAS3',
    ProgramStatus: 'Postponed',
    OldProgramStatus: '',
    Criteria: 'Mf Rate',
    DataStatus: 'Completed',
    OldDataStatus: 'Completed',
    ToDate: '',
    FromDate: '09 Mar 2022',
    Status: 'Active',
  },
  {
    sno: '5',
    ProgramActivityType: 'TAS1 Cleared',
    ProgramStatus: 'Postponed',
    OldProgramStatus: '',
    Criteria: 'Mf Rate',
    DataStatus: 'Completed',
    OldDataStatus: 'Completed',
    ToDate: '',
    FromDate: '09 Mar 2022',
    Status: 'Active',
  },
];
