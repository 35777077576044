import { Grid } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../../../components/organisms/Table';

/**
 *
 * @param {object} props - required props in table
 * @returns {React.ReactElement} returns the Geographic Table
 */
const GeoGraphicDimensionTable = (props) => {
  const { handleEdit, tableHeader, tableData, handleUploaderView, handleUploadValues } = props;
  return (
    <Grid>
      <Table
        header={tableHeader}
        rows={tableData}
        masterSetup={!handleUploaderView && ['Downloader', 'Search']}
        userActivity="View Geography Dimension"
        optional
        edit
        onEditData={(data) => handleEdit(data)}
        hideKeyFields={['geographic_dimension_id', 'state_id', 'district_id']}
        Activity="GeographicDimension"
        inputTable={handleUploaderView}
        IsExcelData={handleUploaderView}
        onSendUpdatedData={() => handleUploadValues()}
        screen="masters"
      />
    </Grid>
  );
};
export default GeoGraphicDimensionTable;
GeoGraphicDimensionTable.propTypes = {
  handleEdit: PropTypes.func,
  tableHeader: PropTypes.arrayOf(),
  tableData: PropTypes.arrayOf(PropTypes.objectOf),
  handleUploaderView: PropTypes.bool,
  handleUploadValues: PropTypes.func,
};
GeoGraphicDimensionTable.defaultProps = {
  handleEdit: () => null,
  tableHeader: [],
  tableData: {},
  handleUploaderView: false,
  handleUploadValues: () => null,
};
