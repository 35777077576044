/* eslint-disable default-param-last */
import {
  COUNTRY_PROFILE_TABLE_RESULT,
  NATIONAL_PROGRESS_STATUS_RESULT,
  COUNTRY_PROFILE_MAP_RESULT,
  NATIONAL_STATES_DASHBOARD_CARD_RESULT,
  NATIONAL_PROGRESS_STATUSBAR_GRAPH_RESULT,
  ACTIVITY_CALENDAR_RESULT,
  ACTIVITY_CALENDAR_TABLE_RESULT,
  GET_DATA_QUALITY_RESULT,
  GET_TAS_DECISION_MATRIX_RESULT,
  GET_DATA_QUALITY_GRAPH_RESULT,
  GET_STATE_CODE_RESULT,
  GET_ACTIVITY_LEGENDS_RESULT,
  SET_GET_DASHBOARD_FILTER_DATA_RESULT,
  GET_COUNTRY_PROFILE_CHART_RESULT,
} from '../../constants/actionType/index';
import { dashboardInitialstate } from '../initialStates/index';

/**
 * @param {string} state defines the state
 * @param {object} action used for payload and type
 * @returns {Array} action response state
 */
const dashboardReducer = (state = dashboardInitialstate, action) => {
  const { type, payload } = action;
  switch (type) {
    case NATIONAL_STATES_DASHBOARD_CARD_RESULT:
      return {
        ...state,
        nationalStateDashboardCard: { ...state.nationalStateDashboardCard, [payload.dashboardType]: payload.data },
      };
    case COUNTRY_PROFILE_TABLE_RESULT:
      return {
        ...state,
        countryProfileTable: payload.data,
      };
    case NATIONAL_PROGRESS_STATUS_RESULT:
      return {
        ...state,
        progressStatus: payload.data,
      };
    case COUNTRY_PROFILE_MAP_RESULT:
      return {
        ...state,
        countryProfileMap: { ...state.countryProfileMap, [payload.dashboardType]: payload.data },
      };
    case GET_COUNTRY_PROFILE_CHART_RESULT:
      return {
        ...state,
        getCountryProfileChart: { ...state.getCountryProfileChart, [payload.dashboardType]: payload.data },
      };
    case NATIONAL_PROGRESS_STATUSBAR_GRAPH_RESULT:
      return {
        ...state,
        nationalStateProgressStatusbar: {
          ...state.nationalStateProgressStatusbar,
          [payload.dashboardType]: payload.data,
        },
      };
    case ACTIVITY_CALENDAR_RESULT:
      return {
        ...state,
        activityCalendar: { ...state.activityCalendar, [payload.dashboardType]: payload.data },
      };
    case ACTIVITY_CALENDAR_TABLE_RESULT:
      return {
        ...state,
        activityCalendarTable: { ...state.activityCalendarTable, [payload.dashboardType]: payload.data },
      };
    case GET_DATA_QUALITY_RESULT:
      return {
        ...state,
        dataQuality: { ...state.dataQuality, [payload.dashboardType]: payload.data },
      };
    case GET_TAS_DECISION_MATRIX_RESULT:
      return {
        ...state,
        tasDecisionMatrix: { ...state.tasDecisionMatrix, [payload.dashboardType]: payload.data },
      };
    case GET_DATA_QUALITY_GRAPH_RESULT:
      return {
        ...state,
        dataQualityGraph: { ...state.dataQualityGraph, [payload.dashboardType]: payload.data },
      };
    case GET_STATE_CODE_RESULT:
      return {
        ...state,
        stateCode: payload.data,
      };
    case GET_ACTIVITY_LEGENDS_RESULT:
      return {
        ...state,
        activityLegends: payload.data,
      };
    case SET_GET_DASHBOARD_FILTER_DATA_RESULT:
      return {
        ...state,
        filterData: payload,
      };
    default:
      return state;
  }
};
export default dashboardReducer;
