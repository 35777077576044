export const blockFormEntries = [
  {
    divider: true,
    fullView: true,
  },

  { formTitle: true, labelText: 'TAS Blood Survey' },
  
  {
    ComboBox: true,
    default: true,
    isCombined: true,
    entryName: 'eu_name',
    placeholder: 'EU Name',
    data: [],
  },
  {
    value: '',
    default: true,
    populationField: true,
    disabled: true,
    entryName: 'population_under_eu',
    placeholder: 'Population Under EU (Auto Populate)',
    textInputType: 'number',
    // validation: { isRequired: true, min: 0 },
  },
  {
    blockCount: true,
  },
  {
    value: '',
    default: true,
    date: true,
    disabled: true,
    entryName: 'date_completed',
    placeholder: 'TAS Survey Date Completed',
    validation: { isRequired: true },
  },
  {
    value: '',
    default: true,
    date: true,
    disabled: true,
    entryName: 'date_planned',
    placeholder: 'Date Planned by State',
    validation: { isRequired: true },
  },
  {
    value: '',
    default: true,
    disabled: true,
    textField: true,
    entryName: 'date_proposed',
    placeholder: 'Date Proposed NVBDCP',
    textInputType: 'default',
    validation: { isRequired: true },
  },

  {
    value: '',
    default: true,
    textField: true,
    entryName: 'sample_size',
    placeholder: 'Target Sample Size',
    textInputType: 'number',
    validation: { isRequired: true, min: 0 },
  },
  {
    value: '',
    default: true,
    textField: true,
    entryName: 'number_of_persons_examined',
    placeholder: 'Number of People Examined',
    textInputType: 'number',
    validation: { isRequired: true, min: 0 },
  },
  {
    value: '',
    default: true,
    textField: true,
    entryName: 'number_of_persons_positive',
    placeholder: 'Number of People Positive',
    textInputType: 'number',
    validation: { isRequired: true, min: 0 },
  },
  {
    value: '',
    default: true,
    textField: true,
    entryName: 'number_of_invalid_test',
    placeholder: 'Number of Invalid Test',
    textInputType: 'number',
    validation: { isRequired: true, min: 0 },
  },
  {
    value: '',
    default: true,
    textField: true,
    entryName: 'critical_cut_off',
    placeholder: 'Critical Cut Off',
    textInputType: 'number',
    validation: { isRequired: true, min: 0 },
  },
];
export const blocktFormDefaultValues = {
  state: '',
  district: '',
  strategy: '',
  date_proposed: '',
  date_planned: '',
  date_completed: '',
  eu_name: '',
  population_under_eu: '',
  sample_size: '',
  number_of_persons_examined: '',
  number_of_persons_positive: '',
  number_of_invalid_test: '',
  critical_cut_off: '',
};
