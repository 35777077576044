export const PreTASFormEntries = [
  // { formTitle: true, labelText: 'Activity Update', icons: true, validation: [{ isRequired: true }] },
  {
    default: true,
    entryName: 'State',
    placeholder: 'State',
    dropdown: true,
    returnValue: false,
    data: [],
    validation: [{ isRequired: true }],
    regex: [{ isrequired: true }],
  },
  {
    default: true,
    entryName: 'District',
    placeholder: 'District',
    dropdown: true,
    returnValue: false,
    disabled: true,
    data: [],
    validation: { isRequired: true },
  },
  {
    default: true,
    entryName: 'Strategy',
    placeholder: 'Strategy',
    dropdown: true,
    returnValue: false,
    disabled: false,
    data: [],
    validation: [{ isRequired: true }],
  },
  {
    default: true,
    date: true,
    entryName: 'Pre_Tas_Date_Completed',
    placeholder: 'Pre-Tas Date Completed',
    validation: [{ isRequired: false }],
  },
  {
    default: true,
    date: true,
    entryName: 'Date_Planned_by_state',
    placeholder: 'Date Planned by State',
    validation: [{ isRequired: false }],
  },
  {
    default: true,
    disabled: true,
    date: true,
    entryName: 'Date_Proposed_by_NVBDCP',
    placeholder: 'Date Proposed by NVBDCP',
    validation: [{ isRequired: true }],
  },
  {
    default: true,
    textField: true,
    entryName: 'Total_Population',
    placeholder: 'Total Population',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  { formTitle: true, labelText: 'PRE-TAS', validation: [{ isRequired: false }] },
  {
    default: true,
    entryName: 'Test_Type',
    placeholder: 'Test Type',
    dropdown: true,
    data: ['Block Strategy', 'District Strategy'],
    validation: [{ isRequired: true }],
  },
  {
    default: true,
    date: true,
    entryName: 'survey_Date_AutoPopulate',
    placeholder: 'Survey Date',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },
  {
    isEmptySpace: true,
    breakpoint: 4,
  },
  // {
  //   isCombined: true,
  //   textField: true,
  //   disabled: false,
  //   entryName: 'Block_R1',
  //   placeholder: 'Block R1',
  //   textInputType: 'default',
  //   validation: [{ isRequired: true }],
  // },
  {
    ComboBox: true,
    default: true,
    isCombined: true,
    entryName: 'Block_R1',
    placeholder: 'Block R1',
    data: [{ block_name: 're' }],
  },
  {
    isCombined: true,
    textField: true,
    disabled: false,
    entryName: 'Site_R1',
    placeholder: 'Site R1',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    disabled: false,
    entryName: 'BSE_R1',
    placeholder: 'BSE R1',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    disabled: false,
    entryName: 'Positive_R1',
    placeholder: 'Positive R1',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    default: true,
    textField: true,
    disabled: true,
    entryName: 'MF_Rate_R1',
    placeholder: 'MF Rate R1 (Auto Populate)',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },

  // {
  //   isCombined: true,
  //   textField: true,
  //   disabled: false,
  //   entryName: 'Block_R2',
  //   placeholder: 'Block R2',
  //   textInputType: 'default',
  //   validation: [{ isRequired: true }],
  // },
  {
    ComboBox: true,
    default: true,
    isCombined: true,
    entryName: 'Block_R2',
    placeholder: 'Block R2',
    data: [],
  },
  {
    isCombined: true,
    textField: true,
    disabled: false,
    entryName: 'Site_R2',
    placeholder: 'Site R2',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    disabled: false,
    entryName: 'BSE_R2',
    placeholder: 'BSE R2',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    disabled: false,
    entryName: 'Positive_R2',
    placeholder: 'Positive R2',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    default: true,
    textField: true,
    disabled: true,
    entryName: 'MF_Rate_R2',
    placeholder: 'MF Rate R2 (Auto Populate)',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },

  // {
  //   isCombined: true,
  //   textField: true,
  //   disabled: false,
  //   entryName: 'Block_R3',
  //   placeholder: 'Block R3',
  //   textInputType: 'default',
  //   validation: [{ isRequired: true }],
  // },
  {
    ComboBox: true,
    default: true,
    isCombined: true,
    entryName: 'Block_R3',
    placeholder: 'Block R3',
    data: [],
  },
  {
    isCombined: true,
    textField: true,
    disabled: false,
    entryName: 'Site_R3',
    placeholder: 'Site R3',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    disabled: false,
    entryName: 'BSE_R3',
    placeholder: 'BSE R3',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    disabled: false,
    entryName: 'Positive_R3',
    placeholder: 'Positive R3',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    default: true,
    textField: true,
    disabled: true,
    entryName: 'MF_Rate_R3',
    placeholder: 'MF Rate R3 (Auto Populate)',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },

  // {
  //   isCombined: true,
  //   textField: true,
  //   disabled: false,
  //   entryName: 'Block_R4',
  //   placeholder: 'Block R4',
  //   textInputType: 'default',
  //   validation: [{ isRequired: true }],
  // },
  {
    ComboBox: true,
    default: true,
    isCombined: true,
    entryName: 'Block_R4',
    placeholder: 'Block R4',
    data: [],
  },
  {
    isCombined: true,
    textField: true,
    disabled: false,
    entryName: 'Site_R4',
    placeholder: 'Site R4',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    disabled: false,
    entryName: 'BSE_R4',
    placeholder: 'BSE R4',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    disabled: false,
    entryName: 'Positive_R4',
    placeholder: 'Positive R4',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    default: true,
    textField: true,
    disabled: true,
    entryName: 'MF_Rate_R4',
    placeholder: 'MF Rate R4 (Auto Populate)',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },

  // {
  //   isCombined: true,
  //   textField: true,
  //   disabled: false,
  //   entryName: 'Block_R5',
  //   placeholder: 'Block R5',
  //   textInputType: 'default',
  //   validation: [{ isRequired: true }],
  // },
  {
    ComboBox: true,
    default: true,
    isCombined: true,
    entryName: 'Block_R5',
    placeholder: 'Block R5',
    data: [],
  },
  {
    isCombined: true,
    textField: true,
    disabled: false,
    entryName: 'Site_R5',
    placeholder: 'Site R5',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    disabled: false,
    entryName: 'BSE_R5',
    placeholder: 'BSE R5',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    disabled: false,
    entryName: 'Positive_R5',
    placeholder: 'Positive R5',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    default: true,
    textField: true,
    disabled: true,
    entryName: 'MF_Rate_R5',
    placeholder: 'MF Rate R5 (Auto Populate)',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },

  // {
  //   isCombined: true,
  //   textField: true,
  //   disabled: false,
  //   entryName: 'Block_R6',
  //   placeholder: 'Block R6',
  //   textInputType: 'default',
  //   validation: [{ isRequired: true }],
  // },
  {
    ComboBox: true,
    default: true,
    isCombined: true,
    entryName: 'Block_R6',
    placeholder: 'Block R6',
    data: [],
  },
  {
    isCombined: true,
    textField: true,
    disabled: false,
    entryName: 'Site_R6',
    placeholder: 'Site R6',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    disabled: false,
    entryName: 'BSE_R6',
    placeholder: 'BSE R6',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    disabled: false,
    entryName: 'Positive_R6',
    placeholder: 'Positive R6',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    default: true,
    textField: true,
    disabled: true,
    entryName: 'MF_Rate_R6',
    placeholder: 'MF Rate R6 (Auto Populate)',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },

  // {
  //   isCombined: true,
  //   textField: true,
  //   entryName: 'Block_R7',
  //   placeholder: 'Block R7',
  //   textInputType: 'default',
  //   validation: [{ isRequired: true }],
  // },
  {
    ComboBox: true,
    default: true,
    isCombined: true,
    entryName: 'Block_R7',
    placeholder: 'Block R7',
    data: [],
  },
  {
    isCombined: true,
    textField: true,
    entryName: 'Site_R7',
    placeholder: 'Site R7',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    entryName: 'BSE_R7',
    placeholder: 'BSE R7',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    // disabled: true,
    entryName: 'Positive_R7',
    placeholder: 'Positive R7',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    default: true,
    textField: true,
    disabled: true,
    entryName: 'MF_Rate_R7',
    placeholder: 'MF Rate R7 (Auto Populate)',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },
  // {
  //   isCombined: true,
  //   textField: true,
  //   // disabled: true,
  //   entryName: 'Block_R8',
  //   placeholder: 'Block R8',
  //   textInputType: 'default',
  //   validation: [{ isRequired: true }],
  // },
  {
    ComboBox: true,
    default: true,
    isCombined: true,
    entryName: 'Block_R8',
    placeholder: 'Block R8',
    data: [],
  },
  {
    isCombined: true,
    textField: true,
    // disabled: true,
    entryName: 'Site_R8',
    placeholder: 'Site R8',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    // disabled: true,
    entryName: 'BSE_R8',
    placeholder: 'BSE R8',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    // disabled: true,
    entryName: 'Positive_R8',
    placeholder: 'Positive R8',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    default: true,
    textField: true,
    disabled: true,
    entryName: 'MF_Rate_R8',
    placeholder: 'MF Rate R8 (Auto Populate)',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },
  // {
  //   isCombined: true,
  //   textField: true,
  //   // disabled: true,
  //   entryName: 'Block_R9',
  //   placeholder: 'Block R9',
  //   textInputType: 'default',
  //   validation: [{ isRequired: true }],
  // },
  {
    ComboBox: true,
    default: true,
    isCombined: true,
    entryName: 'Block_R9',
    placeholder: 'Block R9',
    data: [],
  },
  {
    isCombined: true,
    textField: true,
    // disabled: true,
    entryName: 'Site_R9',
    placeholder: 'Site R9',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    // disabled: true,
    entryName: 'BSE_R9',
    placeholder: 'BSE R9',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    // disabled: true,
    entryName: 'Positive_R9',
    placeholder: 'Positive R9',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    default: true,
    textField: true,
    disabled: true,
    entryName: 'MF_Rate_R9',
    placeholder: 'MF Rate R9 (Auto Populate)',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },

  // {
  //   isCombined: true,
  //   textField: true,
  //   // disabled: true,
  //   entryName: 'Block_R10',
  //   placeholder: 'Block R10',
  //   textInputType: 'default',
  //   validation: [{ isRequired: true }],
  // },
  {
    ComboBox: true,
    default: true,
    isCombined: true,
    entryName: 'Block_R10',
    placeholder: 'Block R10',
    data: [],
  },
  {
    isCombined: true,
    textField: true,
    // disabled: true,
    entryName: 'Site_R10',
    placeholder: 'Site R10',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    // disabled: true,
    entryName: 'BSE_R10',
    placeholder: 'BSE R10',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    // disabled: true,
    entryName: 'Positive_R10',
    placeholder: 'Positive R10',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    default: true,
    textField: true,
    disabled: true,
    entryName: 'MF_Rate_R10',
    placeholder: 'MF Rate R10 (Auto Populate)',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },
];
export const PreTASFormEntries1 = [
  // { formTitle: true, labelText: 'Activity Update', icons: true, validation: [{ isRequired: true }] },
  {
    default: true,
    entryName: 'State',
    placeholder: 'State',
    dropdown: true,
    returnValue: false,
    data: [],
    validation: [{ isRequired: true }],
    regex: [{ isrequired: true }],
  },
  {
    default: true,
    entryName: 'District',
    placeholder: 'District',
    dropdown: true,
    returnValue: false,
    disabled: true,
    data: [],
    validation: { isRequired: true },
  },
  {
    default: true,
    entryName: 'Strategy',
    placeholder: 'Strategy',
    dropdown: true,
    returnValue: false,
    disabled: false,
    data: [],
    validation: [{ isRequired: true }],
  },
  {
    default: true,
    date: true,
    disabled: true,
    entryName: 'Survey_Date_Completed',
    placeholder: 'Survey Date Completed (Auto Populate)',
    validation: [{ isRequired: false }],
  },
  {
    default: true,
    date: true,
    entryName: 'Date_Planned_by_state',
    placeholder: 'Date Planned by State',
    validation: [{ isRequired: false }],
  },
  {
    disabled: true,
    default: true,
    date: true,
    entryName: 'Date_Proposed_by_NVBDCP',
    placeholder: 'Date Proposed by NVBDCP',
    validation: [{ isRequired: true }],
  },
  {
    default: true,
    textField: true,
    disabled: true,
    entryName: 'Population_Under_Pre_TAS',
    placeholder: 'Population Under Pre TAS (Auto Populate)',
    // textInputType: 'default',
    validation: [{ isRequired: true }],
  },
  { formTitle: true, labelText: 'PRE-TAS', validation: [{ isRequired: false }] },
  // {
  //   default: true,
  //   textField: true,
  //   entryName: 'Block_Name_B1',
  //   placeholder: 'Block Name 1',
  //   textInputType: 'default',
  //   validation: [{ isRequired: true }],
  // },
  {
    ComboBox: true,
    default: true,
    isCombined: true,
    entryName: 'Block_Name_B1',
    placeholder: 'Block Name 1',
    data: [],
  },
  {
    default: true,
    textField: true,
    entryName: 'Total_population_of_block_B1',
    placeholder: 'Total Population Of Block 1',
    textInputType: 'number',
    // textInputType: 'default',
    validation: [{ isRequired: true }],
  },
  {
    CountRangePreTas: true,
    default: true,
    labelText: 'Additional Random Site',
  },
  {
    default: true,
    entryName: 'Test_Type',
    placeholder: 'Test Type',
    dropdown: true,
    data: ['Block Strategy', 'District Strategy'],
    validation: [{ isRequired: true }],
  },
  {
    default: true,
    date: true,
    entryName: 'survey_Date_B1',
    placeholder: 'Pre-Tas Survey Date',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },
  {
    isEmptySpace: true,
    breakpoint: 4,
  },
  // {
  //   default: true,
  //   isCombined: true,
  //   textField: true,
  //   entryName: 'Block_R1_B1',
  //   placeholder: 'Block R1',
  //   textInputType: 'default',
  //   validation: [{ isRequired: true }],
  // },
  {
    default: true,
    textField: true,
    entryName: 'Site_R1_B1',
    placeholder: 'Site Name R1',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    entryName: 'BSE_R1_B1',
    placeholder: 'BSE R1',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    entryName: 'Positive_R1_B1',
    placeholder: 'Positive R1',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    default: true,
    textField: true,
    disabled: true,
    entryName: 'MF_Rate_R1_B1',
    placeholder: 'MF Rate R1 (Auto Populate)',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },

  // {
  //   isCombined: true,
  //   textField: true,
  //   entryName: 'Block_R2_B1',
  //   placeholder: 'Block R2',
  //   textInputType: 'default',
  //   validation: [{ isRequired: true }],
  // },
  {
    default: true,
    textField: true,
    entryName: 'Site_R2_B1',
    placeholder: 'Site Name R2',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    entryName: 'BSE_R2_B1',
    placeholder: 'BSE R2',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    entryName: 'Positive_R2_B1',
    placeholder: 'Positive R2',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    default: true,
    textField: true,
    disabled: true,
    entryName: 'MF_Rate_R2_B1',
    placeholder: 'MF Rate R2 (Auto Populate)',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  // {
  //   isCombined: true,
  //   textField: true,
  //   entryName: 'Block_R3_B1',
  //   placeholder: 'Block R3',
  //   textInputType: 'default',
  //   validation: [{ isRequired: true }],
  // },
  {
    default: true,
    textField: true,
    entryName: 'Site_R3_B1',
    placeholder: 'Site Name R3',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    entryName: 'BSE_R3_B1',
    placeholder: 'BSE R3',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    entryName: 'Positive_R3_B1',
    placeholder: 'Positive R3',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    default: true,
    textField: true,
    disabled: true,
    entryName: 'MF_Rate_R3_B1',
    placeholder: 'MF Rate R3 (Auto Populate)',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    divider: true,
    validation: { isRequired: false },
    fullView: true,
  },
  // {
  //   default: true,
  //   textField: true,
  //   entryName: 'Block_Name_B2',
  //   placeholder: 'Block Name 2',
  //   textInputType: 'default',
  //   validation: [{ isRequired: true }],
  // },
  {
    ComboBox: true,
    default: true,
    isCombined: true,
    entryName: 'Block_Name_B2',
    placeholder: 'Block Name 2',
    data: [],
  },
  {
    default: true,
    textField: true,
    entryName: 'Total_population_of_block_B2',
    placeholder: 'Total Population Of Block 2',
    // textInputType: 'default',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    CountRangePreTas: true,
    default: true,
    labelText: 'Additional Random Site',
  },
  {
    default: true,
    entryName: 'Test_Type',
    placeholder: 'Test Type',
    dropdown: true,
    data: ['Block Strategy', 'District Strategy'],
    validation: [{ isRequired: true }],
  },
  {
    default: true,
    date: true,
    entryName: 'survey_Date_B2',
    placeholder: 'Pre-Tas Survey Date',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },
  {
    isEmptySpace: true,
    breakpoint: 4,
  },
  // {
  //   isCombined: true,
  //   textField: true,
  //   entryName: 'Block_R1_B2',
  //   placeholder: 'Block R1',
  //   textInputType: 'default',
  //   validation: [{ isRequired: true }],
  // },
  {
    default: true,
    textField: true,
    entryName: 'Site_R1_B2',
    placeholder: 'Site Name R1',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    entryName: 'BSE_R1_B2',
    placeholder: 'BSE R1',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    entryName: 'Positive_R1_B2',
    placeholder: 'Positive R1',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    default: true,
    textField: true,
    disabled: true,
    entryName: 'MF_Rate_R1_B2',
    placeholder: 'MF Rate R1 (Auto Populate)',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },

  // {
  //   isCombined: true,
  //   textField: true,
  //   entryName: 'Block_R2_B2',
  //   placeholder: 'Block R2',
  //   textInputType: 'default',
  //   validation: [{ isRequired: true }],
  // },
  {
    default: true,
    textField: true,
    entryName: 'Site_R2_B2',
    placeholder: 'Site Name R2',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    entryName: 'BSE_R2_B2',
    placeholder: 'BSE R2',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    entryName: 'Positive_R2_B2',
    placeholder: 'Positive R2',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    default: true,
    textField: true,
    disabled: true,
    entryName: 'MF_Rate_R2_B2',
    placeholder: 'MF Rate R2 (Auto Populate)',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  // {
  //   isCombined: true,
  //   textField: true,
  //   entryName: 'Block_R3_B2',
  //   placeholder: 'Block R3',
  //   textInputType: 'default',
  //   validation: [{ isRequired: true }],
  // },
  {
    default: true,
    textField: true,
    entryName: 'Site_R3_B2',
    placeholder: 'Site Name R3',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    entryName: 'BSE_R3_B2',
    placeholder: 'BSE R3',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    entryName: 'Positive_R3_B2',
    placeholder: 'Positive R3',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
  {
    default: true,
    textField: true,
    disabled: true,
    entryName: 'MF_Rate_R3_B2',
    placeholder: 'MF Rate R3 (Auto Populate)',
    textInputType: 'number',
    validation: [{ isRequired: true }],
  },
];
export const EditEntries = [
  {
    default: true,
    entryName: 'State',
    placeholder: 'State',
    dropdown: true,
    returnValue: false,
    data: [],
    validation: { isRequired: true },
  },
  {
    default: true,
    entryName: 'District',
    placeholder: 'District',
    dropdown: true,
    returnValue: false,
    disabled: true,
    data: [],
    validation: { isRequired: true },
  },
  {
    default: true,
    entryName: 'Strategy',
    placeholder: 'Strategy',
    dropdown: true,
    returnValue: true,
    disabled: true,
    data: [],
    validation: [{ isRequired: true }],
  },
  {
    default: true,
    entryName: 'block',
    placeholder: 'Block',
    dropdown: true,
    returnValue: true,
    disabled: true,
    data: [],
    validation: [{ isRequired: true }],
  },
  {
    default: true,
    date: true,
    entryName: 'Date_Completed',
    placeholder: 'Date Completed',
    validation: [{ isRequired: false }],
  },
  {
    default: true,
    date: true,
    entryName: 'Date_Planned',
    placeholder: 'Date Planned',
    validation: [{ isRequired: false }],
  },
  {
    value: '',
    disabled: true,
    default: true,
    date: true,
    entryName: 'Date_Proposed_by_NVBDCP',
    placeholder: 'Date Proposed',
    validation: [{ isRequired: false }],
  },
  { formTitle: true, labelText: 'Pre-TAS Survey', validation: [{ isRequired: false }] },
  {
    default: true,
    entryName: 'Test_Type',
    placeholder: 'Test Type',
    dropdown: true,
    data: ['Block Strategy', 'District Strategy'],
    validation: [{ isRequired: true }],
  },
  {
    default: true,
    textField: true,
    entryName: 'Pre_TAS_surveyDate',
    placeholder: 'Pre-TAS Survey Date',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },
  // {
  //   default: true,
  //   textField: true,
  //   entryName: 'BSE_R1_Name',
  //   placeholder: 'BSE R1 Name',
  //   textInputType: 'default',
  //   validation: [{ isRequired: true }],
  // },
  {
    default: true,
    textField: true,
    entryName: 'BSE_R1',
    placeholder: 'BSE R1',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },
  {
    isCombined: true,
    textField: true,
    entryName: 'Positive_R1',
    placeholder: 'Positive R1',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },
];
export const PreTASFormDefaultValues = {
  state: '',
  district: '',
  block_strategy: '',
  Date_Proposed_by_NVBDCP: '',
  date_planned: '',
  date_completed: '',
  block: '',
  test_type: '',
  pre_tas_survey_date: '',
  BSE_R1_Name: '',
  BSE_R1: '',
  Positive_R1: '',
};
