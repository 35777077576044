/* eslint-disable default-param-last */
import {
  GET_FORMULA_CONFIG_RESULT,
  INSERT_FORMULA_CONFIG_RESULT,
  FORMULA_APPROVAL_ATTACHMENT_RESULT,
  FORMULA_STATUS,
  APPROVAL_STATUS,
} from '../../constants/actionType';
import { formulaInitialState } from '../initialStates';

/**
 * @param {string} state defines the state
 * @param {object} action used for payload and type
 * @returns {Array} action response state
 */
const formulaReducer = (state = formulaInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_FORMULA_CONFIG_RESULT:
      return {
        ...state,
        getFormula: payload?.data,
      };
    case INSERT_FORMULA_CONFIG_RESULT:
      return {
        ...state,
        status: payload.data,
      };
    case FORMULA_APPROVAL_ATTACHMENT_RESULT:
      return {
        ...state,
        formulaApproval: payload.data,
      };
    case FORMULA_STATUS:
      return {
        ...state,
        status: payload,
        loading: false,
      };
    case APPROVAL_STATUS:
      return {
        ...state,
        status: payload,
        loading: false,
      };

    default:
      return state;
  }
};
export default formulaReducer;
