/* eslint-disable react/prop-types */
import React from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
/**
 * @name CustomTextField
 * @param {object} props - required props in text field component
 * @returns {React.ReactElement} return the TextField Component
 */
const CustomTextfield = (props) => {
  const {
    id,
    label,
    error,
    helperText,
    maxRows,
    onChange,
    placeholder,
    required,
    pattern,
    onFocus,
    type,
    value,
    disabled,
    multiline,
    InputProps,
  } = props;
  /**
   *
   * @returns {string} - returns the pattern regex
   */
  const patternValidation = () => {
    switch (pattern) {
      case 'email':
        return /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
      case 'number':
        return /^([0-9][0-9]{9,14})$/;
      case 'text':
        return /^[a-zA-Z ]*$/;
      default:
        return null;
    }
  };
  return (
    <div>
      <TextField
        id={id}
        label={label}
        error={error}
        helperText={helperText}
        maxRows={maxRows}
        onChange={(e) => onChange(e, patternValidation())}
        placeholder={placeholder}
        required={required}
        onFocus={(e) => onFocus(e)}
        type={type}
        // value={value}
        value={value || ''}
        disabled={disabled}
        multiline={multiline}
        InputProps={InputProps}
        color="secondary"
        fullWidth
      />
    </div>
  );
};
export default CustomTextfield;
CustomTextfield.propTypes = {
  id: PropTypes.number,
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  maxRows: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  pattern: PropTypes.string,
  InputProps: PropTypes.objectOf(PropTypes.oneOf),
};
CustomTextfield.defaultProps = {
  id: '',
  error: false,
  helperText: '',
  maxRows: '',
  onChange: () => null,
  onFocus: () => null,
  placeholder: '',
  required: false,
  type: '',
  value: '',
  pattern: '',
  disabled: false,
  multiline: false,
  InputProps: {},
};
