import { takeLatest } from 'redux-saga/effects';
import {
  COUNTRY_PROFILE_TABLE,
  NATIONAL_PROGRESS_STATUS,
  COUNTRY_PROFILE_MAP,
  NATIONAL_STATES_DASHBOARD_CARD,
  NATIONAL_PROGRESS_STATUSBAR_GRAPH,
  ACTIVITY_CALENDAR,
  GET_DATA_QUALITY,
  GET_TAS_DECISION_MATRIX,
  GET_DATA_QUALITY_GRAPH,
  GET_STATE_CODE,
  GET_ACTIVITY_LEGENDS,
  ACTIVITY_CALENDAR_TABLE,
  SET_GET_DASHBOARD_FILTER_DATA,
  GET_COUNTRY_PROFILE_CHART,
} from '../../constants/actionType/index';
import {
  countryProfileHandler,
  nationalProgressStatusHandler,
  countryProfileMapHandler,
  nationalStatesDashboardCardHandler,
  nationalProgressStatusbarHandler,
  getActivityCalenderHandler,
  getDataQualityHandler,
  getTasDecisionMatrixHandler,
  getDataQualityGraphHandler,
  getStateCodeHandler,
  getActivitesLegendsRequestHandler,
  getActivityCalenderTableHandler,
  getDashboardFilterHandler,
  getCountryProfileChartHandler,
} from '../handler/dashboardHandler';

/**
 * @name nationalDashboardSaga used for dashboard api call
 * @yields countryProfileHandler
 */
function* nationalDashboardSaga() {
  yield takeLatest(COUNTRY_PROFILE_TABLE, countryProfileHandler);
  yield takeLatest(NATIONAL_PROGRESS_STATUS, nationalProgressStatusHandler);
  yield takeLatest(COUNTRY_PROFILE_MAP, countryProfileMapHandler);
  yield takeLatest(GET_COUNTRY_PROFILE_CHART, getCountryProfileChartHandler);
  yield takeLatest(NATIONAL_STATES_DASHBOARD_CARD, nationalStatesDashboardCardHandler);
  yield takeLatest(NATIONAL_PROGRESS_STATUSBAR_GRAPH, nationalProgressStatusbarHandler);
  yield takeLatest(ACTIVITY_CALENDAR, getActivityCalenderHandler);
  yield takeLatest(ACTIVITY_CALENDAR_TABLE, getActivityCalenderTableHandler);
  yield takeLatest(GET_DATA_QUALITY, getDataQualityHandler);
  yield takeLatest(GET_TAS_DECISION_MATRIX, getTasDecisionMatrixHandler);
  yield takeLatest(GET_DATA_QUALITY_GRAPH, getDataQualityGraphHandler);
  yield takeLatest(GET_STATE_CODE, getStateCodeHandler);
  yield takeLatest(GET_ACTIVITY_LEGENDS, getActivitesLegendsRequestHandler);
  yield takeLatest(SET_GET_DASHBOARD_FILTER_DATA, getDashboardFilterHandler);
}
export default nationalDashboardSaga;
