import axios from 'axios';
import BaseUrl from '../../config/config';
import { getFormulaConfiguration, insertFormulaConfiguration, formulaApprovalAttachment } from '../../utils/api';

const userAurhId = localStorage.getItem('UserLoginId');

/**
 * @name getFormulaConfigRequest function which make api request to get  formula configuration.
 * @returns {*} returns axios response data
 */
export const getFormulaConfigRequest = () => {
  return axios.get(`${BaseUrl}/${getFormulaConfiguration}`);
};

/**
 * @param {object} data payload data
 * @name insertFormulaConfigRequest function which make api request to add  formula configuration.
 * @returns {*} returns axios response data
 */
export const insertFormulaConfigRequest = (data) => {
  const formData = new FormData();
  formData.append('file_name_upload', data);
  formData.set('created_by', userAurhId && userAurhId);
  return axios({
    method: 'post',
    url: `${BaseUrl}/${insertFormulaConfiguration}`,
    data: formData,
  });
};
/**
 * @param {object} data payload data
 * @param {object} note payload note
 * @name approvalAttachmentFormula function which make api request to add  Demographics.
 * @returns {*} returns axios response data
 */
export const approvalAttachmentFormula = (data) => {
  const formData = new FormData();
  formData.append('approval_proof_attachment', data.file);
  formData.set('created_by', userAurhId && userAurhId);
  formData.set('approval_note', data.name);
  return axios({
    method: 'post',
    url: `${BaseUrl}/${formulaApprovalAttachment}`,
    data: formData,
  });
};
