import axios from 'axios';
import BaseUrl from '../../config/config';
import { getThresholdConfiguration, insertThresholdConfiguration, thresholdApprovalAttachment } from '../../utils/api';

const userAurhId = localStorage.getItem('UserLoginId');
/**
 * @name getDossierRequest function which make api request to get Demographics.
 * @returns {*} returns axios response data
 */
export const getThresholdConfigRequest = () => {
  return axios.get(`${BaseUrl}/${getThresholdConfiguration}`);
};

/**
 * @param {object} data payload data
 * @name insertDossierRequest function which make api request to add  Demographics.
 * @returns {*} returns axios response data
 */
export const insertThresholdConfigRequest = (data) => {
  const formData = new FormData();
  formData.append('file_upload', data);
  formData.set('created_by', userAurhId && userAurhId);
  return axios({
    method: 'post',
    url: `${BaseUrl}/${insertThresholdConfiguration}`,
    data: formData,
  });
};

/**
 * @param {object} data payload data
 * @name approvalAttachment function which make api request to add  Demographics.
 * @returns {*} returns axios response data
 */
export const approvalAttachment = (data) => {
  const formData = new FormData();
  formData.append('approval_proof_attachment', data.file);
  formData.set('created_by', userAurhId && userAurhId);
  formData.set('approval_note', data.name);
  return axios({
    method: 'post',
    url: `${BaseUrl}/${thresholdApprovalAttachment}`,
    data: formData,
  });
};
