/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import * as XLSX from 'xlsx';
import { Grid, Paper } from '@mui/material';
import Tooltip from '@material-ui/core/Tooltip';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DossierFormInput from './DossierForm/index';
import DossierTable from './TableDossier';
import Toaster from '../../../components/atoms/ToastMessage';
import FileUpload from '../../../components/molecules/FileUpload';
import { dossierFormEntries } from './DossierForm/formEntries-Dossier';
import { GET_STATE_DROPDOWN, GET_DOSSIER, UPLOAD_DOSSIER, GET_MASTER_TEMPLATES } from '../../../constants/actionType';

/**
 *
 * @param  {object} props - required props in form input
 * @returns {React.ReactElement} - returns the input form
 */
const Form = (props) => {
  const { onViewTable, onClickHandleView, setJsonData } = props;
  const dispatch = useDispatch();
  const [file, setFile] = React.useState();
  const [upload, setUpload] = React.useState(false);
  const { getMasterTemplate } = useSelector((state) => state.masterTemplate);
  const InputActionValidation = localStorage.getItem('ActionAccess');
  const Actionvalue = JSON.parse(InputActionValidation);
  const { BulkUpload } = Actionvalue?.input?.[0];

  React.useEffect(() => {
    dispatch({ type: GET_STATE_DROPDOWN, GET_DOSSIER });
    dispatch({ type: GET_MASTER_TEMPLATES });
  }, [dispatch]);
  /**
   * @param {*} files - sending files
   */
  const sendSelectedFiles = async (files) => {
    setFile(files);
    const data = await files.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const json = XLSX.utils.sheet_to_json(worksheet);
    setJsonData(json);
  };
  /**
   *
   */
  const onSubmitFiles = async () => {
    await dispatch({ type: UPLOAD_DOSSIER, payload: file });
    dispatch({ type: GET_MASTER_TEMPLATES });
  };

  return (
    <Grid container item md={12} spacing={3} sx={{ paddingTop: 2, height: '100%' }}>
      <Grid item md={upload ? 9 : 11.4} className="layoutFormat">
        <Paper elevation={3} className="splitPage">
          <DossierFormInput onViewHandle={onViewTable} formEntries={dossierFormEntries} />
        </Paper>
      </Grid>
      {upload === false && (
        <Tooltip title="File Upload " placement="top">
          <Grid
            onClick={() => BulkUpload === 1 && setUpload(true)}
            padding="10px"
            marginTop="40px"
            className="uploadContainer"
          >
            {/* <img src={CustomIcons.LeftArrow} alt="upload" className="uploadImgFile" /> */}
            {/* <p className="uploadFile">File Upload</p> */}
            <KeyboardDoubleArrowLeftIcon fontSize="large" color="primary" className="uploadImgFile" />
          </Grid>
        </Tooltip>
      )}
      {upload === true && (
        <Grid item md={3} sm={12} xs={12} className="layoutFormat">
          <Paper elevation={3} className="splitPage">
            <Grid>
              <FileUpload
                downloadTemplate
                excelUploader
                sendSelectedFile={(files) => sendSelectedFiles(files)}
                onSubmitFiles={() => onSubmitFiles()}
                handleCancel={() => setUpload(false)}
                onClickHandleView={onClickHandleView}
                templateUrl={getMasterTemplate.dossier}
              />
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};
Form.propTypes = {
  onViewTable: PropTypes.func.isRequired,
};
/**
 *
 * @returns {React.ReactElement} - returns the input table
 */
const Table = ({ onClickHandleView, setJsonData, jsonData, onCancel, isJson, setIsJson }) => {
  const dispatch = useDispatch();
  const [file, setFile] = React.useState();
  const ValidationGroup = localStorage.getItem('userGroupId');
  const ValidationType = localStorage.getItem('userTypeId');
  const [upload, setUpload] = React.useState(false);
  const { getMasterTemplate } = useSelector((state) => state.masterTemplate);
  const InputActionValidation = localStorage.getItem('ActionAccess');
  const Actionvalue = JSON.parse(InputActionValidation);
  const { BulkUpload } = Actionvalue?.input?.[0];

  /**
   * @param {*} files - sending files
   */
  const sendSelectedFiles = async (files) => {
    setFile(files);
    const data = await files.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const json = XLSX.utils.sheet_to_json(worksheet);
    setJsonData(json);
  };
  /**
   *
   */
  const onSubmitFiles = async () => {
    await dispatch({ type: UPLOAD_DOSSIER, payload: file });
    dispatch({ type: GET_MASTER_TEMPLATES });
  };

  // React.useEffect(() => {
  //   if (
  //     (ValidationGroup === '4' && ValidationType === '1') ||
  //     (ValidationGroup === '7' && ValidationType === '1') ||
  //     (ValidationGroup === '8' && ValidationType === '5')
  //   ) {
  //     setUpload(true);
  //   }
  //   if (
  //     (ValidationGroup === '5' && ValidationType === '2') ||
  //     (ValidationGroup === '4' && ValidationType === '3') ||
  //     (ValidationGroup === '0' && ValidationType === '4')
  //   ) {
  //     setUpload(false);
  //   }
  // }, [setUpload, ValidationGroup, ValidationType]);
  return (
    <Grid container item md={12} spacing={3} sx={{ paddingTop: 2, height: '100%' }}>
      <Grid item md={upload ? 9 : 11.4} className="layoutFormat">
        <Paper elevation={3} className="splitPage">
          <DossierTable
            Activity="Dossier"
            jsonData={jsonData}
            onCancel={onCancel}
            isJson={isJson}
            setIsJson={setIsJson}
          />
        </Paper>
      </Grid>
      {upload === false && (
        <Tooltip title="File Upload " placement="top">
          <Grid
            onClick={() => BulkUpload === 1 && setUpload(true)}
            padding="10px"
            marginTop="40px"
            className="uploadContainer"
          >
            {/* <img src={CustomIcons.LeftArrow} alt="upload" className="uploadImgFile" /> */}
            {/* <p className="uploadFile">File Upload</p> */}
            <KeyboardDoubleArrowLeftIcon fontSize="large" color="primary" className="uploadImgFile" />
          </Grid>
        </Tooltip>
      )}
      {upload === true && (
        <Grid item md={3} sm={12} xs={12} className="layoutFormat">
          <Paper elevation={3} className="splitPage">
            <Grid>
              <FileUpload
                downloadTemplate
                excelUploader
                sendSelectedFile={(files) => sendSelectedFiles(files)}
                onSubmitFiles={onSubmitFiles}
                handleCancel={() => setUpload(false)}
                onClickHandleView={onClickHandleView}
                templateUrl={getMasterTemplate.dossier}
              />
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

/**
 *
 * @returns {React.ReactElement} - returns the dossier data input
 */
const DossierInput = () => {
  const [showTable, setShowTable] = React.useState('Form');
  const [toaster, setToaster] = React.useState(false);
  const { status } = useSelector((state) => state.dossier);
  /**
   *
   */
  const onViewTableData = () => {
    setShowTable('Table');
  };
  React.useEffect(() => {
    if (status && status?.message !== '') setToaster(true);
    else setToaster(false);
  }, [status]);
  const ValidationGroup = localStorage.getItem('userGroupId');
  const ValidationType = localStorage.getItem('userTypeId');
  const [jsonData, setJsonData] = React.useState();
  const [isJson, setIsJson] = React.useState(false);

  /**
   *
   */
  const onClickHandleView = () => {
    setShowTable('Table');
    setIsJson(true);
  };
  // React.useEffect(() => {
  //   if (
  //     (ValidationGroup === '4' && ValidationType === '1') ||
  //     (ValidationGroup === '7' && ValidationType === '1') ||
  //     (ValidationGroup === '8' && ValidationType === '5')
  //   ) {
  //     setShowTable('Form');
  //   }
  //   if (
  //     (ValidationGroup === '5' && ValidationType === '2') ||
  //     (ValidationGroup === '4' && ValidationType === '3') ||
  //     (ValidationGroup === '0' && ValidationType === '4')
  //   ) {
  //     setShowTable('Form');
  //   }
  // }, [setShowTable, ValidationGroup, ValidationType]);
  /**
   *
   * @param {string} type - type of IDA input format
   * @returns {Function} - returns the required type
   */
  const getDossierModule = (type) => {
    switch (type) {
      case 'Form':
        return <Form onViewTable={onViewTableData} onClickHandleView={onClickHandleView} setJsonData={setJsonData} />;
      case 'Table':
        return (
          <Table
            onClickHandleView={onClickHandleView}
            setJsonData={setJsonData}
            jsonData={jsonData}
            onCancel={() => setShowTable('Form')}
            isJson={isJson}
            setIsJson={setIsJson}
          />
        );
      default:
        return <Form onClickHandleView={onClickHandleView} setJsonData={setJsonData} />;
    }
  };
  return (
    <Grid className="dataLayout">
      {toaster && <Toaster open severity={status?.type} message={status?.message} close={() => setToaster(false)} />}
      {getDossierModule(showTable)}
    </Grid>
  );
};
export default DossierInput;
