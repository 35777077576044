import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import 'leaflet.wms/dist/leaflet.wms';
import { object } from 'prop-types';
import { district } from './lat_long';
import { overlay } from 'leaflet.wms';

const MapComponent = ({
  selectedState,
  stateCoordinates,
  token,
  selectedDistrict,
  countryProfileMap,
  selectedStrategy,
  selectedStateYear,
}) => {
  const endemicPopulations = countryProfileMap?.state?.['TAS 2 Completed']?.map((item) => item['Endemic Population']);
  const EndemicDistricts = countryProfileMap?.state?.['TAS 2 Completed']?.map((item) => item['Endemic Districts']);
  const district = countryProfileMap?.state?.['TAS 2 Completed']?.map((item) => item.district);
  const Strategy = countryProfileMap?.state?.['TAS 2 Completed']?.map((item) => item.Strategy);
  const MDADistricts = countryProfileMap?.state?.['TAS 2 Completed']?.map((item) => item['MDA Districts']);
  const PreTASDistricts = countryProfileMap?.state?.['TAS 2 Completed']?.map((item) => item['Pre TAS Districts']);
  const TASclearedDistricts = countryProfileMap?.state?.['TAS 2 Completed']?.map(
    (item) => item['TAS cleared Districts'],
  );
  console.log(token, 'token');
  console.log(countryProfileMap, 'countryProfileMap');

  //console.log(selectedState, 'selectedState1');
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const countryProfile = countryProfileMap;
  console.log(countryProfile, 'countryProfile');

  const addStateLayer = (state) => {
    if (!stateCoordinates[state]) {
      console.error(`Coordinates not available for state: ${state}`, 'stateerror');
      return;
    }

    const [lat, lng] = stateCoordinates[state];

    if (lat === undefined || lng === undefined) {
      console.error(`Invalid coordinates for state: ${state}`);
      map.removeLayer(layer);
      return;
    }
    const dataSetName = selectedStrategy === 'Block Strategy' ? 'ai_block_town' : 'ai_district';
    const styleName =
      selectedStrategy === 'Block Strategy' ? 'ai_block_town' : 'ai_district_label_1692718543843_workview';

    const wmsParams = {
      access_token: `Bearer ${token}`,
      datasetNme: dataSetName,
      cql_filter: `state='${state}'`,
      srs: 'EPSG:3857',
      styles: styleName,
      service: 'WMS',
      request: 'GetMap',
      version: '1.1.1',
      env: 'polygonfillcolor:ffffff',
      transparent: true,
      format: 'image/png',
      autoZIndex: true,
    };
    var fillColor = 'polygonfillcolor:efefff';

    const wmsParams_block = {
      access_token: `Bearer ${token}`,
      datasetNme: dataSetName,
      height: map.innerHeight,
      width: map.innerWidth,
      cql_filter: `district in ('${selectedDistrict}')`,
      bbox: map.getBounds().toBBoxString(),
      srs: 'EPSG:3857',
      styles: styleName,
      service: 'WMS',
      request: 'GetMap',
      version: '1.1.1',
      env: fillColor,
      transparent: true,
      format: 'image/png',
      autoZIndex: true,
    };
    if (map && state) {
      map.setView([lat, lng], 7);

      map.eachLayer((layer) => {
        if (!(layer instanceof L.TileLayer)) {
          map.removeLayer(layer);
        }
      });

      const aiStateLayer = new L.TileLayer.WMS('https://mgis-apiv2.mappls.com/map/getMap', wmsParams);
      aiStateLayer.addTo(map);

      //IDA_COMPLETED:

      const IDA_COMPLETED = countryProfile?.state['IDA Completed']?.map((item) => `'${item?.district}'`).join("'',''");

      console.log(IDA_COMPLETED, 'IDA_COMPLETED');
      if (IDA_COMPLETED) {
        console.log(IDA_COMPLETED, 'IDA_COMPLETED...');
        const formattedDistricts1 = IDA_COMPLETED.replace(/'/g, ''); // Removing single quotes
        const spacedDistricts1 = formattedDistricts1.replace(/,/g, "', '"); // Adding spaces after commas

        const cqlFilter1 = `district in ('${spacedDistricts1}')`;

        const wmsParams1 = {
          access_token: `Bearer ${token}`,
          datasetNme: dataSetName,
          // cql_filter: "district in ('Saran','Patna','Gaya')",
          cql_filter: cqlFilter1,
          srs: 'EPSG:3857',
          styles: styleName,
          service: 'WMS',
          request: 'GetMap',
          version: '1.1.1',
          env: `polygonfillcolor:fd9727`,
          transparent: true,
          format: 'image/png',
          autoZIndex: true,
        };

        const createAiStateLayer1 = (params) => {
          return new L.TileLayer.WMS('https://mgis-apiv2.mappls.com/map/getMap', params);
        };

        const aiStateLayer1 = createAiStateLayer1(wmsParams1);
        aiStateLayer1.addTo(map);
        console.log(wmsParams1, 'wsm1');
      }
      //DA_COMPLETED

      const DA_COMPLETED = countryProfile?.state['DA Completed']?.map((item) => `'${item?.district}'`).join("'',''");

      console.log(DA_COMPLETED, 'DA_COMPLETED');

      const formattedDistricts2 = DA_COMPLETED.replace(/'/g, '');
      const spacedDistricts2 = formattedDistricts2.replace(/,/g, "', '");

      const cqlFilter2 = `district in ('${spacedDistricts2}')`;
      const wmsParams2 = {
        access_token: `Bearer ${token}`,
        datasetNme: dataSetName,
        // cql_filter: "district in ('Saran','Patna','Gaya')",
        cql_filter: cqlFilter2,
        srs: 'EPSG:3857',
        styles: styleName,
        service: 'WMS',
        request: 'GetMap',
        version: '1.1.1',
        env: `polygonfillcolor:fc5830`,

        transparent: true,
        format: 'image/png',
        autoZIndex: true,
      };

      const createAiStateLayer2 = (params) => {
        console.log(wmsParams2, 'fbgbgfbgfb');

        return new L.TileLayer.WMS('https://mgis-apiv2.mappls.com/map/getMap', params);
      };

      const aiStateLayer2 = createAiStateLayer2(wmsParams2);
      aiStateLayer2.addTo(map);

      //Under DA
      const Under_DA = countryProfile?.state['Pending DA']?.map((item) => `'${item?.district}'`).join("'',''");

      console.log(Under_DA, 'Under_DA');
      if (Under_DA) {
        const formattedDistricts3 = Under_DA.replace(/'/g, ''); // Removing single quotes
        const spacedDistricts3 = formattedDistricts3.replace(/,/g, "', '"); // Adding spaces after commas

        const cqlFilter3 = `district in ('${spacedDistricts3}')`;

        const wmsParams3 = {
          access_token: `Bearer ${token}`,
          datasetNme: dataSetName,
          // cql_filter: "district in ('Saran','Patna','Gaya')",
          cql_filter: cqlFilter3,
          srs: 'EPSG:3857',
          styles: styleName,
          service: 'WMS',
          request: 'GetMap',
          version: '1.1.1',
          env: `polygonfillcolor:a2ba67`,
          transparent: true,
          format: 'image/png',
          autoZIndex: true,
        };

        const createAiStateLayer3 = (params) => {
          return new L.TileLayer.WMS('https://mgis-apiv2.mappls.com/map/getMap', params);
        };

        const aiStateLayer3 = createAiStateLayer3(wmsParams3);
        aiStateLayer3.addTo(map);
        console.log(wmsParams3, 'wsm3');
      }

      //Under_IDA;
      const Under_IDA = countryProfile?.state['Pending IDA'].map((item) => `'${item.district}'`).join("'',''");

      console.log(Under_IDA, 'Under_IDA');
      if (Under_IDA) {
        const formattedDistricts4 = Under_IDA.replace(/'/g, ''); // Removing single quotes
        const spacedDistricts4 = formattedDistricts4.replace(/,/g, "', '"); // Adding spaces after commas

        const cqlFilter4 = `district in ('${spacedDistricts4}')`;

        const wmsParams4 = {
          access_token: `Bearer ${token}`,
          datasetNme: dataSetName,
          // cql_filter: "district in ('Saran','Patna','Gaya')",
          cql_filter: cqlFilter4,
          srs: 'EPSG:3857',
          styles: styleName,
          service: 'WMS',
          request: 'GetMap',
          version: '1.1.1',
          env: `polygonfillcolor:79a30b`,
          transparent: true,
          format: 'image/png',
          autoZIndex: true,
        };

        const createAiStateLayer4 = (params) => {
          return new L.TileLayer.WMS('https://mgis-apiv2.mappls.com/map/getMap', params);
        };

        const aiStateLayer4 = createAiStateLayer4(wmsParams4);
        aiStateLayer4.addTo(map);
        console.log(wmsParams4, 'wsm4');
      }
      //PMS1
      const PMS1 = countryProfile?.state['PMS1'].map((item) => `'${item.district}'`).join("'',''");

      console.log(PMS1, 'PMS1');
      if (PMS1) {
        const formattedDistricts5 = PMS1.replace(/'/g, ''); // Removing single quotes
        const spacedDistricts5 = formattedDistricts5.replace(/,/g, "', '"); // Adding spaces after commas

        const cqlFilter5 = `district in ('${spacedDistricts5}')`;

        const wmsParams5 = {
          access_token: `Bearer ${token}`,
          datasetNme: dataSetName,
          // cql_filter: "district in ('Saran','Patna','Gaya')",
          cql_filter: cqlFilter5,
          srs: 'EPSG:3857',
          styles: styleName,
          service: 'WMS',
          request: 'GetMap',
          version: '1.1.1',
          env: `polygonfillcolor:a96192`,
          transparent: true,
          format: 'image/png',
          autoZIndex: true,
        };

        const createAiStateLayer5 = (params) => {
          return new L.TileLayer.WMS('https://mgis-apiv2.mappls.com/map/getMap', params);
        };
        const aiStateLayer5 = createAiStateLayer5(wmsParams5);
        aiStateLayer5.addTo(map);
        console.log(wmsParams5, 'wsm5');
      }
      //PMS2;
      const PMS2 = countryProfile?.state['PMS2'].map((item) => `'${item.district}'`).join("'',''");

      console.log(PMS2, 'PMS2');
      if (PMS2) {
        const formattedDistricts6 = PMS2.replace(/'/g, ''); // Removing single quotes
        const spacedDistricts6 = formattedDistricts6.replace(/,/g, "', '"); // Adding spaces after commas

        const cqlFilter6 = `district in ('${spacedDistricts6}')`;

        const wmsParams6 = {
          access_token: `Bearer ${token}`,
          datasetNme: dataSetName,
          // cql_filter: "district in ('Saran','Patna','Gaya')",
          cql_filter: cqlFilter6,
          srs: 'EPSG:3857',
          styles: styleName,
          service: 'WMS',
          request: 'GetMap',
          version: '1.1.1',
          env: `polygonfillcolor:9e1ecf`,
          transparent: true,
          format: 'image/png',
          autoZIndex: true,
        };

        const createAiStateLayer6 = (params) => {
          return new L.TileLayer.WMS('https://mgis-apiv2.mappls.com/map/getMap', params);
        };

        const aiStateLayer6 = createAiStateLayer6(wmsParams6);
        aiStateLayer6.addTo(map);
        console.log(wmsParams6, 'wsm6');
      }
      //PMS3
      const PMS3 = countryProfile?.state['PMS3'].map((item) => `'${item.district}'`).join("'',''");

      console.log(PMS3, 'PMS3');
      if (PMS3) {
        const formattedDistricts7 = PMS3.replace(/'/g, '');
        const spacedDistricts7 = formattedDistricts7.replace(/,/g, "', '"); // Adding spaces after commas

        const cqlFilter7 = `district in ('${spacedDistricts7}')`;

        const wmsParams7 = {
          access_token: `Bearer ${token}`,
          datasetNme: dataSetName,
          // cql_filter: "district in ('Saran','Patna','Gaya')",
          cql_filter: cqlFilter7,
          srs: 'EPSG:3857',
          styles: styleName,
          service: 'WMS',
          request: 'GetMap',
          version: '1.1.1',
          env: `polygonfillcolor:1eaacf`,
          transparent: true,
          format: 'image/png',
          autoZIndex: true,
        };

        const createAiStateLayer7 = (params) => {
          console.log(params, 'wsm7');

          return new L.TileLayer.WMS('https://mgis-apiv2.mappls.com/map/getMap', params);
        };

        const aiStateLayer7 = createAiStateLayer7(wmsParams7);
        aiStateLayer7.addTo(map);
      }

      //TAS 1
      const TAS1_Array = countryProfile?.state['TAS 1 Completed'];

      if (TAS1_Array !== undefined && TAS1_Array !== null && TAS1_Array.length > 0) {
        const TAS1 = TAS1_Array.map((item) => `'${item.district}'`).join("'',''");

        const formattedDistricts8 = TAS1.replace(/'/g, ''); // Removing single quotes
        const spacedDistricts8 = formattedDistricts8.replace(/,/g, "', '"); // Adding spaces after commas

        const cqlFilter8 = `district in ('${spacedDistricts8}')`;

        const wmsParams8 = {
          access_token: `Bearer ${token}`,
          datasetNme: dataSetName,
          //    height: window.innerHeight,
          //     width: window.innerWidth,
          bbox: map.getBounds().toBBoxString(),
          cql_filter: cqlFilter8,
          srs: 'EPSG:3857',
          // styles: 'ai_district_label_1692718543843_workview',
          styles: styleName,
          service: 'WMS',
          request: 'GetMap',
          version: '1.1.1',
          env: `polygonfillcolor:fc5830`,
          // env: `polygonfillcolor:${color}`,
          transparent: true,
          format: 'image/png',
          autoZIndex: true,
        };

        const createAiStateLayer8 = (params) => {
          return overlay('https://mgis-apiv2.mappls.com/map/getMap', params);
        };

        const aiStateLayer8 = createAiStateLayer8(wmsParams8);
        aiStateLayer8.addTo(map);
        console.log(wmsParams8, 'wsm8');
      }

      //TAS 2
      const TAS2_Array = countryProfile?.state['TAS 2 Completed'];

      if (TAS2_Array !== undefined && TAS2_Array !== null && TAS2_Array.length > 0) {
        const TAS2 = TAS2_Array.map((item) => `'${item.district}'`).join("'',''");

        const formattedDistricts9 = TAS2.replace(/'/g, ''); // Removing single quotes
        const spacedDistricts9 = formattedDistricts9.replace(/,/g, "', '"); // Adding spaces after commas

        const cqlFilter9 = `district in ('${spacedDistricts9}')`;

        const wmsParams9 = {
          access_token: `Bearer ${token}`,
          datasetNme: dataSetName,
          //    height: window.innerHeight,
          //     width: window.innerWidth,
          bbox: map.getBounds().toBBoxString(),
          cql_filter: cqlFilter9,
          srs: 'EPSG:3857',
          // styles: 'ai_district_label_1692718543843_workview',
          styles: styleName,
          service: 'WMS',
          request: 'GetMap',
          version: '1.1.1',
          env: `polygonfillcolor:2d992c`,
          // env: `polygonfillcolor:${color}`,
          transparent: true,
          format: 'image/png',
          autoZIndex: true,
        };

        const createAiStateLayer9 = (params) => {
          return overlay('https://mgis-apiv2.mappls.com/map/getMap', params);
        };

        const aiStateLayer9 = createAiStateLayer9(wmsParams9);
        aiStateLayer9.addTo(map);
        console.log(wmsParams9, 'wsm9');
      }
      //TAS 3
      const TAS3_Array = countryProfile?.state['TAS 3 Completed'];

      if (TAS3_Array !== undefined && TAS3_Array !== null && TAS3_Array.length > 0) {
        const TAS3 = TAS3_Array.map((item) => `'${item.district}'`).join("'',''");

        const formattedDistricts10 = TAS3.replace(/'/g, ''); // Removing single quotes
        const spacedDistricts10 = formattedDistricts10.replace(/,/g, "', '"); // Adding spaces after commas

        const cqlFilter10 = `district in ('${spacedDistricts10}')`;

        const wmsParams10 = {
          access_token: `Bearer ${token}`,
          datasetNme: dataSetName,
          //    height: window.innerHeight,
          //     width: window.innerWidth,
          bbox: map.getBounds().toBBoxString(),
          cql_filter: cqlFilter10,
          srs: 'EPSG:3857',
          // styles: 'ai_district_label_1692718543843_workview',
          styles: styleName,
          service: 'WMS',
          request: 'GetMap',
          version: '1.1.1',
          env: `polygonfillcolor:fc5830`,
          // env: `polygonfillcolor:${color}`,
          transparent: true,
          format: 'image/png',
          autoZIndex: true,
        };

        const createAiStateLayer10 = (params) => {
          return overlay('https://mgis-apiv2.mappls.com/map/getMap', params);
        };

        const aiStateLayer10 = createAiStateLayer10(wmsParams10);
        aiStateLayer10.addTo(map);
        console.log(wmsParams10, 'wsm10');
      }

      //DA COMPLETD BLOCK
      const DA_COMPLETED_BLOCK = countryProfile?.state['DA Completed']
        ?.map((item) => `'${item?.['Blocks Name']}'`)
        .join("'',''");
      const formattedDistrictsBlock2 = DA_COMPLETED_BLOCK.replace(/'/g, '');

      const spacedDistrictsBlock2 = formattedDistrictsBlock2.replace(/,/g, "', '");

      // const cqlFilterBlock2 = `block_town in('${spacedDistrictsBlock2}')`;
      const cqlFilterBlock2 = `block_town in('Devadurga','Lingsugur')`;

      console.log(spacedDistrictsBlock2, 'spacedDistrictsBlock2');

      const wmsParamsBlock2 = {
        access_token: `Bearer ${token}`,
        datasetNme: dataSetName,
        // cql_filter: "district in ('Saran','Patna','Gaya')",
        cql_filter: cqlFilterBlock2,
        srs: 'EPSG:3857',
        styles: 'ai_block_town',
        service: 'WMS',
        request: 'GetMap',
        version: '1.1.1',
        env: `polygonfillcolor:0000ff`,
        test: 0.2913425044164293,
        transparent: true,
        format: 'image/png',
        // format: 'image%2Fpng',

        // format: 'image%2Fpng',

        autoZIndex: false,
      };

      const createAiStateLayerBlock2 = (params) => {
        console.log(wmsParamsBlock2, 'wmsParamsBlock2');
        return new L.TileLayer.WMS('https://mgis-apiv2.mappls.com/map/getMap', params);
      };

      const aiStateLayerBlock2 = createAiStateLayerBlock2(wmsParamsBlock2);
      aiStateLayerBlock2.addTo(map);
    }
  };

  useEffect(() => {
    const mapInstance = L.map(mapRef.current, {
      center: [25.0961, 85.3131],
      attributionControl: false,
      zoom: 4,
      zoomControl: true,
      scrollWheelZoom: false,
      dragging: true,
    });

    setMap(mapInstance);

    return () => {
      if (mapInstance) {
        mapInstance.remove();
      }
    };
  }, []);
  console.log(map, 'map');
  console.log(selectedState, 'selectedState');

  useEffect(() => {
    if (map && map !== null && selectedState) {
      addStateLayer(selectedState, selectedStateYear.Year);

      // eslint-disable-next-line func-names
      map.on('click', function (event) {
        const point = map.latLngToContainerPoint(event.latlng, map.getZoom());
        const size = map.getSize();
        const params = {
          request: 'GetFeatureInfo',
          datasetNme: 'ai_district',
          styles: 'ai_district',
          service: 'WMS',
          // srs: 'EPSG:4326',
          transparent: true,
          format: 'image/png',
          bbox: map.getBounds().toBBoxString(),
          height: size.y,
          width: size.x,
          buffer: 15,
          crossDomain: true,
          version: '1.1.1',
          x: parseInt(point.x, 10),
          y: parseInt(point.y, 10),
        };

        if (selectedState) {
          params['cql_filter'] = `state = '${selectedState}'`;
        }
        var geoserver = 'https:///mgis-apiv2.mappls.com/map/getFeatureInfo';
        var urls = geoserver + L.Util.getParamString(params, geoserver, false);
        console.log(params);
        axios
          .get(urls, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            const response = res.data.featureInfo.featureInfo[0];
            console.log('log API', response);

            //

            const popupList =
              `<div style="width:100%; height:150px; background-color:#FFFFFF;"><div class="toolTipTextSec">` +
              `        <div><label>STRATEGY</label><span> : ${Strategy}</span></div>` +
              `        <div><label>STATE NAME</label><span> : ${response?.properties?.state}</span></div>` +
              `        <div><label>DISTRICT</label><span> : ${response?.properties?.district}</span></div>` +
              `        <div><label>ENDEMIC DISTRICTS</label><span> : ${EndemicDistricts}</span></div>` +
              `        <div><label>ENDEMIC POPULATIONS</label><span> : ${endemicPopulations}</span></div>` +
              `        <div><label>MDA DISTRICTS</label><span> : ${MDADistricts}</span></div>` +
              `        <div><label>PRE TAS DISTRICTS</label><span> : ${PreTASDistricts}</span></div>` +
              `        <div><label>TAS CLEARED DISTRICTS</label><span> : ${TASclearedDistricts}</span></div>` +
              `</div>` +
              `</div>`;

            const popup = L.popup();
            popup.setLatLng(event.latlng).setContent(popupList).openOn(map);

            console.log('you clicked latlng at :', event.latlng);
          })
          .catch((error) => console.log(error));
      });
    }
  }, [map, selectedState, selectedStateYear, addStateLayer, countryProfile.state.type, countryProfileMap]);

  return (
    <div style={{ margin: '0 auto', textAlign: 'center' }}>
      <div id="map" ref={mapRef} style={{ minHeight: '80vh', background: 'none', height: 'auto', width: '100%' }} />
    </div>
  );
};

MapComponent.propTypes = {
  selectedState: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  selectedStateYear: PropTypes.arrayOf(object).isRequired,
  countryProfileMap: PropTypes.arrayOf(PropTypes.object).isRequired,
  stateCoordinates: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
};

export default MapComponent;