/* eslint-disable react/prop-types */
/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import './Table.css';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { thDatas } from './TableEnteries';
import Table from '../../../../components/organisms/Table/index';
import { DEMOGRAPHIC_LIST, UPLOAD_DEMOGRAPHIC_DIMENSION } from '../../../../constants/actionType';

/**
 * @param {object} props --
 * @name InputTable
 * @returns {React.ReactElement} return the user role component
 */
const InputTable = (props) => {
  const { onEditClick, jsonFinalData, isView } = props;
  const dispatch = useDispatch();
  const [mappedRowData, setMappedRowData] = useState([]);
  const dropdownList = useSelector((state) => state.demographic);
  const [rowIds, setRowIds] = useState([]);
  const [stateDropDownValue, setStateDropDownValue] = useState([]);
  const [districtDropDownValue, setDistrictDropDownValue] = useState([]);
  console.log(mappedRowData, 'skdnfskrttyt');
  React.useEffect(() => {
    dispatch({ type: DEMOGRAPHIC_LIST });
  }, [dispatch]);
  React.useEffect(() => {
    if (dropdownList?.getDemographicDimensions?.length) {
      const tmpArr = [];
      const tmpIdsArr = [];
      const tmpStateDropDownValue = [];
      const tmpDistrictDropDownValue = [];
      dropdownList?.getDemographicDimensions.map((value, index) => {
        tmpIdsArr.push(value.demoghrapic_dimension_id);
        tmpStateDropDownValue.push(value.state_id);
        tmpDistrictDropDownValue.push(value.district_id);
        tmpArr.push({
          sno: index + 1,
          state: value.state,
          District: value.district,
          TotalDistrictPopulation: value.total_district_population,
          TotalDistrictEndemic: value.total_district_endamic_population,
          FormDate: value.from_date,
          ToDate: value.to_date,
          Status: value.active_status === 'Active' ? 'Active' : 'Inactive',
        });
      });
      setRowIds(tmpIdsArr);
      setDistrictDropDownValue(tmpDistrictDropDownValue);
      setStateDropDownValue(tmpStateDropDownValue);
      setMappedRowData(tmpArr);
    }
  }, [dispatch, dropdownList?.getDemographicDimensions, dropdownList, isView]);
  React.useEffect(() => {
    if (isView) {
      setMappedRowData(jsonFinalData);
    }
  }, [isView, jsonFinalData]);
  /**
   *
   */
  const onSendUpdatedData = () => {
    const arr = [];
    jsonFinalData?.map((item) => {
      arr.push({
        state: item?.state,
        district: item?.District,
        total_district_population: item?.TotalDistrictPopulation,
        total_district_endamic_population: item?.TotalDistrictEndemic,
        from_date: item?.FormDate,
        to_date: item?.ToDate,
        active_status: item?.Status,
      });
    });

    dispatch({
      type: UPLOAD_DEMOGRAPHIC_DIMENSION,
      payload: {
        bulkInsertDemoghrapicDimension: arr,
        created_by: '1',
      },
    });
  };

  return (
    <Table
      header={thDatas}
      rows={mappedRowData}
      slideData
      masterSetup={['Downloader', 'Search']}
      userActivity="View Demographic Dimension"
      optional
      edit
      onEditData={(e) => onEditClick(e, rowIds, stateDropDownValue, districtDropDownValue)}
      Activity="DemoGraphic"
      inputTable={isView}
      IsExcelData={isView}
      onSendUpdatedData={onSendUpdatedData}
      screen="masters"
    />
  );
};
export default InputTable;
InputTable.propTypes = {
  onEditClick: PropTypes.func.isRequired,
};
