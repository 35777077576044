/* eslint-disable default-param-last */
import {
  GET_STATE_PROFILE_RESULT,
  GET_STATE_PROGRESS_STATUS_RESULT,
  DISTRICT_MDA_PROFILE_RESULT,
  GET_DISTRICT_PROFILE_WIDGET_RESULT,
  GET_DISTRICT_PROFILE_WIDGET_GRAPH_RESULT,
  LOADER,
  GET_DISTRICT_TAS_PROFILE_RESULT,
  GET_DISTRICT_DECISION_MATRIX_RESULT,
  STATE_PROFILE_CHART_RESULT,
  STATE_DATA_QUALITY_TABLE_RESULT,
  GET_DISTRICT_MDA_BLOCKS_RESULT,
} from '../../constants/actionType/index';
import { stateDashboard } from '../initialStates/index';

/**
 * @param {string} state defines the state
 * @param {object} action used for payload and type
 * @returns {Array} action response state
 */
const stateDashboardReducer = (state = stateDashboard, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_STATE_PROGRESS_STATUS_RESULT:
      return {
        ...state,
        ProgressStatus: payload.data,
      };
    case GET_STATE_PROFILE_RESULT:
      return {
        ...state,
        StateProfile: payload.data,
      };
    case DISTRICT_MDA_PROFILE_RESULT:
      return {
        ...state,
        districtMdaProfile: payload.data,
      };
    case GET_DISTRICT_PROFILE_WIDGET_RESULT:
      return {
        ...state,
        districtProfileWidget: payload.data,
      };
    case GET_DISTRICT_PROFILE_WIDGET_GRAPH_RESULT:
      return {
        ...state,
        districtProfileWidgetGraph: payload.data,
        loading: false,
      };
    case LOADER:
      return {
        ...state,
        loading: payload,
      };
    case GET_DISTRICT_TAS_PROFILE_RESULT:
      return {
        ...state,
        districtTasProfile: payload.data,
      };
    case GET_DISTRICT_DECISION_MATRIX_RESULT:
      return {
        ...state,
        districtDecisionMatrix: payload.data,
      };
    case STATE_PROFILE_CHART_RESULT:
      return {
        ...state,
        stateProfileChart: payload.data,
      };
    case STATE_DATA_QUALITY_TABLE_RESULT:
      return {
        ...state,
        stateDataQuality: payload.data,
      };
    case GET_DISTRICT_MDA_BLOCKS_RESULT:
      return {
        ...state,
        districtMDABlocks: payload.data,
      };
    default:
      return state;
  }
};
export default stateDashboardReducer;
