/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import BaseUrl from '../../config/config';
import {
  insertDrugAvailability,
  getDrugAvailability,
  updateDrugAvailability,
  bulkUploadDrugAvailability,
} from '../../utils/api';

const userAurhId = localStorage.getItem('UserLoginId');

/**
 * @param {object} data payload
 * @name insertDrug used for dashboard api call
 * @returns {Array} returns the axios output
 */
export const insertDrugRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${insertDrugAvailability}`,
    data: {
      state_id: data?.state,
      district_id: data?.district,
      drug_available_date_DEC: data?.drug_available_date_DEC,
      drug_available_date_ALB: data?.drug_available_date_ALB,
      drug_available_date_IVR: data?.drug_available_date_IVR,
      created_by: userAurhId && userAurhId,
    },
  });
};
/**
 * @name getDrugAvailabilityRequest used for dashboard api call
 * @returns {Array} returns the axios output
 */
export const getDrugAvailabilityRequest = () => {
  return axios.get(`${BaseUrl}/${getDrugAvailability}`);
};

/**
 * @param {string} value payload data
 * @name updateDrugAvilabilityRequest function which make api request to update Demographics.
 * @returns {*} returns axios response data
 */
export const updateDrugAvilabilityRequest = (value) => {
  return axios({
    method: 'put',
    url: `${BaseUrl}/${updateDrugAvailability}`,
    data: {
      updated_by: userAurhId && userAurhId,
      inputScreen: value,
    },
  });
};
/**
 *
 * @param  {object} data payload data
 * @returns {*} returns axios response data
 */
export const bulkUploadDrugAvilabilityRequest = (data) => {
  // const formData = new FormData();
  // formData.append('file_upload', data);
  // formData.set('created_by', 1);
  return axios({
    method: 'post',
    url: `${BaseUrl}/${bulkUploadDrugAvailability}`,
    data: {
      bulkInsertDrugAvailability: data,
      created_by: userAurhId && userAurhId,
    },
  });
};
