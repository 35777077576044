import { takeLatest } from 'redux-saga/effects';
import { GET_DOSSIER, INSERT_DOSSIER, UPDATE_DOSSIER, UPLOAD_DOSSIER } from '../../constants/actionType';
import {
  getDossierHandler,
  insertDossierHandler,
  updateDossierHandler,
  uploadDossierHandler,
} from '../handler/dossierHandler';

/**
 * @name dossierSaga used for program activities api call
 * @yields dossierSaga
 */
function* dossierSaga() {
  yield takeLatest(GET_DOSSIER, getDossierHandler);
  yield takeLatest(INSERT_DOSSIER, insertDossierHandler);
  yield takeLatest(UPDATE_DOSSIER, updateDossierHandler);
  yield takeLatest(UPLOAD_DOSSIER, uploadDossierHandler);
}
export default dossierSaga;
