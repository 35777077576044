/* eslint-disable default-param-last */
import {
  USER_LOGIN_RESULT,
  FORGET_PASSWORD_RESULT,
  USER_LOGOUT_RESULT,
  CHANGE_PASSWORD_RESULT,
} from '../../constants/actionType/index';
import { loginInfo } from '../initialStates/index';

/**
 * @param {string} state defines the state
 * @param {object} action used for payload and type
 * @returns {Array} action response state
 */
const loginReducer = (state = loginInfo, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_LOGIN_RESULT:
      return {
        ...state,
        status: payload,
        loginDetails: payload,
      };
    case FORGET_PASSWORD_RESULT:
      return {
        ...state,
        forgetPasseordDetails: payload,
      };
    case USER_LOGOUT_RESULT:
      return {
        ...state,
        userlogout: payload,
      };
    case CHANGE_PASSWORD_RESULT:
      return {
        ...state,
        changePassword: payload,
      };
    default:
      return state;
  }
};
export default loginReducer;
