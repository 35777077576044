/* eslint-disable no-unused-vars */
import React from 'react';
import { Grid, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DossierFormInput from './DossierForm/index';
import DossierTable from './TableDossier';
import Toaster from '../../../components/atoms/ToastMessage';
import FileUpload from '../../../components/molecules/FileUpload';
import { dossierFormEntries } from './DossierForm/formEntries-Dossier';
import { GET_STATE_DROPDOWN, GET_DOSSIER, UPLOAD_DOSSIER, GET_MASTER_TEMPLATES } from '../../../constants/actionType';

/**
 *
 * @param  {object} props - required props in form input
 * @returns {React.ReactElement} - returns the input form
 */
const Form = (props) => {
  const { onViewTable } = props;
  const dispatch = useDispatch();
  const [file, setFile] = React.useState();
  React.useEffect(() => {
    dispatch({ type: GET_STATE_DROPDOWN, GET_DOSSIER });
  }, [dispatch]);
  /**
   * @param {*} files - sending files
   */
  const sendSelectedFiles = (files) => {
    setFile(files);
  };
  /**
   *
   */
  const onSubmitFiles = async () => {
    await dispatch({ type: UPLOAD_DOSSIER, payload: file });
    dispatch({ type: GET_MASTER_TEMPLATES });
  };

  return (
    <Grid container item md={12} spacing={3} sx={{ paddingTop: 2, height: '100%' }}>
      <Grid item md={9} className="layoutFormat">
        <Paper elevation={3} className="splitPage">
          <DossierFormInput onViewHandle={onViewTable} formEntries={dossierFormEntries} />
        </Paper>
      </Grid>

      <Grid item md={3} sm={12} xs={12} className="layoutFormat">
        <Paper elevation={3} className="splitPage">
          <Grid>
            <FileUpload
              downloadTemplate
              excelUploader
              sendSelectedFile={(files) => sendSelectedFiles(files)}
              onSubmitFiles={() => onSubmitFiles()}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
Form.propTypes = {
  onViewTable: PropTypes.func.isRequired,
};
/**
 *
 * @returns {React.ReactElement} - returns the input table
 */
const Table = () => {
  const dispatch = useDispatch();
  const [file, setFile] = React.useState();
  const ValidationGroup = localStorage.getItem('userGroupId');
  const ValidationType = localStorage.getItem('userTypeId');
  const [upload, setUpload] = React.useState(false);
  /**
   * @param {*} files - sending files
   */
  const sendSelectedFiles = (files) => {
    setFile(files);
  };
  /**
   *
   */
  const onSubmitFiles = async () => {
    await dispatch({ type: UPLOAD_DOSSIER, payload: file });
    dispatch({ type: GET_MASTER_TEMPLATES });
  };

  // React.useEffect(() => {
  //   if (
  //     (ValidationGroup === '4' && ValidationType === '1') ||
  //     (ValidationGroup === '7' && ValidationType === '1') ||
  //     (ValidationGroup === '8' && ValidationType === '5')
  //   ) {
  //     setUpload(true);
  //   }
  //   if (
  //     (ValidationGroup === '5' && ValidationType === '2') ||
  //     (ValidationGroup === '4' && ValidationType === '3') ||
  //     (ValidationGroup === '0' && ValidationType === '4')
  //   ) {
  //     setUpload(false);
  //   }
  // }, [setUpload, ValidationGroup, ValidationType]);
  return (
    <Grid container item md={12} spacing={3} sx={{ paddingTop: 2, height: '100%' }}>
      <Grid item md={9} className="layoutFormat">
        <Paper elevation={3} className="splitPage">
          <DossierTable />
        </Paper>
      </Grid>
      <Grid item md={3} sm={12} xs={12} className="layoutFormat">
        <Paper elevation={3} className="splitPage">
          <Grid>
            <FileUpload
              downloadTemplate
              excelUploader
              sendSelectedFile={(files) => sendSelectedFiles(files)}
              onSubmitFiles={onSubmitFiles}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

/**
 *
 * @returns {React.ReactElement} - returns the dossier data input
 */
const NewInput = () => {
  const [showTable, setShowTable] = React.useState('Form');
  const [toaster, setToaster] = React.useState(false);
  const { status } = useSelector((state) => state.dossier);
  /**
   *
   */
  const onViewTableData = () => {
    setShowTable('Table');
  };
  React.useEffect(() => {
    if (status && status?.message !== '') setToaster(true);
    else setToaster(false);
  }, [status]);
  const ValidationGroup = localStorage.getItem('userGroupId');
  const ValidationType = localStorage.getItem('userTypeId');

  // React.useEffect(() => {
  //   if (
  //     (ValidationGroup === '4' && ValidationType === '1') ||
  //     (ValidationGroup === '7' && ValidationType === '1') ||
  //     (ValidationGroup === '8' && ValidationType === '5')
  //   ) {
  //     setShowTable('Form');
  //   }
  //   if (
  //     (ValidationGroup === '5' && ValidationType === '2') ||
  //     (ValidationGroup === '4' && ValidationType === '3') ||
  //     (ValidationGroup === '0' && ValidationType === '4')
  //   ) {
  //     setShowTable('Form');
  //   }
  // }, [setShowTable, ValidationGroup, ValidationType]);
  /**
   *
   * @param {string} type - type of IDA input format
   * @returns {Function} - returns the required type
   */
  const getDossierModule = (type) => {
    switch (type) {
      case 'Form':
        return <Form onViewTable={onViewTableData} />;
      case 'Table':
        return <Table />;
      default:
        return <Form />;
    }
  };
  return (
    <Grid className="dataLayout">
      {toaster && <Toaster open severity={status?.type} message={status?.message} close={() => setToaster(false)} />}
      {getDossierModule(showTable)}
    </Grid>
  );
};
export default NewInput;
