/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import * as XLSX from 'xlsx';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { Grid, Paper } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import Tooltip from '@material-ui/core/Tooltip';
import Toaster from '../../../components/atoms/ToastMessage';
import PreTASFormInput from './Pre-TAS-Form';
import PreTASTableInput from './Pre-TAS-Table';
import FileUpload from '../../../components/molecules/FileUpload';
import Loader from '../../../components/atoms/Loader';
import './Pre-TAS.css';
import { GET_MASTER_TEMPLATES, PRE_TAS_UPLOAD } from '../../../constants/actionType';
import CustomIcons from '../../../utils/icons';

/**
 *
 * @param {object} props - required props in IDA form
 * @returns {React.ReactElement} - return the I DA form input
 */
const PreTASForm = (props) => {
  const { onViewTable, onClickHandleView, setJsonData } = props;
  const [file, setFile] = React.useState();
  const [upload, setUpload] = React.useState(false);
  const { getMasterTemplate } = useSelector((state) => state.masterTemplate);
  const InputActionValidation = localStorage.getItem('ActionAccess');
  const Actionvalue = JSON.parse(InputActionValidation);
  const { BulkUpload } = Actionvalue?.input?.[0];

  const dispatch = useDispatch();
  /**
   * @param {*} files - sending files
   */
  const sendSelectedFiles = async (files) => {
    setFile(files);
    const data = await files.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const json = XLSX.utils.sheet_to_json(worksheet);
    setJsonData(json);
  };
  /**
   *
   */
  // const onSubmitFiles = async () => {
  //   await dispatch({ type: PRE_TAS_UPLOAD, payload: file });
  //   dispatch({ type: GET_MASTER_TEMPLATES });
  // };
  React.useEffect(() => {
    dispatch({ type: GET_MASTER_TEMPLATES });
  }, [dispatch]);
  const { getPreTas } = useSelector((state) => state?.preTas);
  return (
    <Grid container item md={12} spacing={3} sx={{ paddingTop: 2, height: '100%' }}>
      <Grid item md={upload === true ? 9 : 11.4} className="layoutFormat">
        <Paper elevation={3} className="splitPage">
          <PreTASFormInput OnView={onViewTable} />
        </Paper>
      </Grid>
      {upload === false && (
        <Tooltip title="File Upload " placement="top">
          <Grid
            onClick={() => BulkUpload === 1 && setUpload(true)}
            padding="10px"
            marginTop="40px"
            className="uploadContainer"
          >
            {/* <img src={CustomIcons.LeftArrow} alt="upload" className="uploadImgFile" /> */}
            {/* <p className="uploadFile">File Upload</p> */}
            <KeyboardDoubleArrowLeftIcon fontSize="large" color="primary" className="uploadImgFile" />
          </Grid>
        </Tooltip>
      )}
      {upload === true && (
        <Grid item md={3} sm={12} xs={12} className="layoutFormat">
          <Paper elevation={3} className="splitPage">
            <Grid>
              <FileUpload
                templateUrl={getMasterTemplate.PreTasDistrict}
                downloadTemplate
                excelUploader
                sendSelectedFile={(files) => sendSelectedFiles(files)}
                // onSubmitFiles={() => onSubmitFiles()}
                handleCancel={() => setUpload(false)}
                onClickHandleView={onClickHandleView}
              />
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};
PreTASForm.propTypes = {
  onViewTable: PropTypes.func.isRequired,
};
/**
 *
 * @returns {React.ReactElement} - returns the IDA table input
 */
const PreTASTable = (props) => {
  const { TableCancel, onClickHandleView, jsonData, fieldValue, isJson, setIsJson } = props;
  const { getMasterTemplate } = useSelector((state) => state.masterTemplate);
  const InputActionValidation = localStorage.getItem('ActionAccess');
  const Actionvalue = JSON.parse(InputActionValidation);
  const { BulkUpload } = Actionvalue?.input?.[0];
  const [upload, setUpload] = React.useState(false);
  const ValidationGroup = localStorage.getItem('userGroupId');
  const ValidationType = localStorage.getItem('userTypeId');
  const [file, setFile] = React.useState();

  const dispatch = useDispatch();
  /**
   * @param {*} files - sending files
   */
  const sendSelectedFiles = (files) => {
    setFile(files);
  };
  /**
   *
   */
  const onSubmitFiles = async () => {
    await dispatch({ type: PRE_TAS_UPLOAD, payload: file });
    dispatch({ type: GET_MASTER_TEMPLATES });
  };
  React.useEffect(() => {
    dispatch({ type: GET_MASTER_TEMPLATES });
  }, [dispatch]);

  return (
    <Grid container item md={12} spacing={3} sx={{ paddingTop: 2, height: '100%' }}>
      <Grid item md={upload === true ? 9 : 11.4} className="layoutFormat">
        <Paper elevation={3} className="splitPage">
          <PreTASTableInput
            testCancel={() => TableCancel()}
            jsonData={jsonData}
            fieldValue={fieldValue}
            isJson={isJson}
            setIsJson={setIsJson}
          />
        </Paper>
      </Grid>
      {upload === false && (
        <Tooltip title="File Upload " placement="top">
          <Grid
            onClick={() => BulkUpload === 1 && setUpload(true)}
            padding="10px"
            marginTop="40px"
            className="uploadContainer"
          >
            {/* <img src={CustomIcons.LeftArrow} alt="upload" className="uploadImgFile" /> */}
            {/* <p className="uploadFile">File Upload</p> */}
            <KeyboardDoubleArrowLeftIcon fontSize="large" color="primary" className="uploadImgFile" />
          </Grid>
        </Tooltip>
      )}
      {upload === true && (
        <Grid item md={3} sm={12} xs={12} className="layoutFormat">
          <Paper elevation={3} className="splitPage">
            <Grid>
              <FileUpload
                templateUrl={
                  fieldValue === 'District Strategy' ? getMasterTemplate.PreTasDistrict : getMasterTemplate.PreTasBlock
                }
                downloadTemplate
                excelUploader
                sendSelectedFile={(files) => sendSelectedFiles(files)}
                onSubmitFiles={() => onSubmitFiles()}
                handleCancel={() => setUpload(false)}
                onClickHandleView={onClickHandleView}
              />
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};
/**
 *
 * @returns {React.ReactElement} - returns the IDA input
 */
const PreTASInput = () => {
  const [showTable, setShowTable] = React.useState('PreTASForm');
  const ValidationGroup = localStorage.getItem('userGroupId');
  const [toaster, setToaster] = React.useState(false);
  const ValidationType = localStorage.getItem('userTypeId');
  const [isJson, setIsJson] = React.useState(false);
  const { loading, status } = useSelector((state) => state?.preTas);
  const [jsonData, setJsonData] = React.useState();
  const [prompt, setPrompt] = React.useState(false);
  const [fieldValue, setFieldValue] = React.useState('District Strategy');

  console.log(jsonData, 'kkkdkjijt');
  useEffect(() => {
    if (status && status?.message !== '') setToaster(true);
    else setToaster(false);
  }, [status]);
  if (loading) {
    return <Loader />;
  }

  /**
   *
   */
  const onViewTableData = () => {
    setPrompt(true);
    // setShowTable('PreTASTable');
  };
  /**
   *
   */
  const onClickHandleView = () => {
    setShowTable('PreTASTable');
    setIsJson(true);
  };
  /**
   *
   * @param {string} type - type of IDA input format
   * @returns {Function} - returns the required type
   */
  const getPreTASModule = (type) => {
    switch (type) {
      case 'PreTASForm':
        return (
          <PreTASForm onViewTable={onViewTableData} onClickHandleView={onClickHandleView} setJsonData={setJsonData} />
        );
      case 'PreTASTable':
        return (
          <PreTASTable
            onClickHandleView={onClickHandleView}
            TableCancel={() => {
              setShowTable('PreTASForm');
            }}
            jsonData={jsonData}
            fieldValue={fieldValue}
            isJson={isJson}
            setIsJson={setIsJson}
          />
        );
      default:
        return <PreTASForm />;
    }
  };
  /**
   *
   */
  const handleTableClick = (value) => {
    setFieldValue(value);
    // dispatch({ type: GET_IDA, payload: { proposed_program_activities: 'IDA', strategy: value } });
    // setShowTable(true);
    setShowTable('PreTASTable');

    setTimeout(() => {
      setPrompt(false);
    }, 1000);
  };
  return (
    <Grid className="dataLayout">
      {toaster && <Toaster open severity={status?.type} message={status?.message} close={() => setToaster(false)} />}
      {prompt && (
        <Dialog
          open={true}
          // onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogActions>
            <Button onClick={() => handleTableClick('District Strategy')}>District Statergy</Button>
            <Button onClick={() => handleTableClick('Block Strategy')}>Block Statergy</Button>
          </DialogActions>
        </Dialog>
      )}
      {getPreTASModule(showTable)}
    </Grid>
  );
};

export default PreTASInput;
