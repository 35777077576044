import React from 'react';
import CustomIcons from '../../../../utils/icons';

export const thDatas = [
  'S.No',
  'Bulk Upload Filename',
  'Bulk Upload By',
  'Formula Configuration',
  'From Date',
  'To Date',
  'Status',
  'Action',
];

export const tdDatas = [
  {
    sno: '1',
    BulkUploadFilename: 'Formula Configuration-1997',
    BulkUploadby: 'John',
    FormulaConfiguration: <img src={CustomIcons.Threshold} alt="configuration" />,
    ToDate: '09 Mar 2022',
    FromDate: '09 Mar 2025',
    Status: 'InActive',
  },
  {
    sno: '2',
    BulkUploadFilename: 'Formula Configuration-1998',
    BulkUploadby: 'John',
    FormulaConfiguration: <img src={CustomIcons.Threshold} alt="configuration" />,
    ToDate: '09 Mar 2022',
    FromDate: '09 Mar 2022',
    Status: 'InActive',
  },
];
