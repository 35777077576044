/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import BaseUrl from '../../config/config';
import {
  getDrug,
  insertDrug,
  getDrugRegime,
  getDrugType,
  updateDrug,
  // bulkUploadDrug,
  BulkInsertDrug,
} from '../../utils/api';

const userAurhId = localStorage.getItem('UserLoginId');

/**
 * @name getDrugRequest used for dashboard api call
 * @returns {Array} returns the axios output
 */
export const getDrugRequest = () => {
  return axios.get(`${BaseUrl}/${getDrug}`);
};

/**
 * @param {object} data payload
 * @name insertDrugMasterRequest used for dashboard api call
 * @returns {Array} returns the axios output
 */
export const insertDrugMasterRequest = (data) => {
  data.updated_by = userAurhId && userAurhId;
  if (data.status === 'Active') {
    data.status = 1;
  } else {
    data.status = 0;
  }
  return axios({
    method: 'post',
    url: `${BaseUrl}/${insertDrug}`,
    data: {
      state_id: data.state,
      district_id: data.district,
      drug_regime_id: data.drug_regime,
      drug_type_id: data.drug_type,
      from_date: data.from_date,
      to_date: data.to_date,
      status: data.status,
      created_by: data.updated_by,
    },
  });
};
/**
 * @name getDrugRegimeRequest used for dashboard api call
 * @returns {Array} returns the axios output
 */
export const getDrugRegimeRequest = () => {
  return axios.get(`${BaseUrl}/${getDrugRegime}`);
};
/**
 * @name getDrugTypeRequest used for dashboard api call
 * @returns {Array} returns the axios output
 */
export const getDrugTypeRequest = () => {
  return axios.get(`${BaseUrl}/${getDrugType}`);
};
/**
 * @param {object} data payload data
 * @name updateDrugRequest function which make api request to update Demographics.
 * @returns {*} returns axios response data
 */
export const updateDrugRequest = (data) => {
  data.updated_by = userAurhId && userAurhId;
  if (data.status === 'Active') {
    data.status = 1;
  } else {
    data.status = 0;
  }
  return axios({
    method: 'put',
    url: `${BaseUrl}/${updateDrug}`,
    data: {
      state_id: data?.state,
      district_id: data?.district,
      drug_regime_id: data?.drug_regime,
      drug_type_id: data?.drug_type,
      from_date: data?.from_date,
      to_date: data?.to_date,
      status: data?.status,
      updated_by: data?.updated_by,
      drug_id: data?.drug_id,
    },
  });
};
/**
 *
 * @param  {object} data payload data
 * @returns {*} returns axios response data
 */
export const bulkUploadDrugRequest = (data) => {
  // const formData = new FormData();
  // formData.append('file_upload', data);
  // formData.set('created_by', 1);
  console.log(data, 'sdkfjskr');
  return axios({
    method: 'post',
    url: `${BaseUrl}/${BulkInsertDrug}`,
    data: {
      bulkInsertDrugType: data,
      created_by: userAurhId && userAurhId,
    },
  });
};
