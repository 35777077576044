import Bell from '../../assets/icon/bell.png';
import Calender from '../../assets/icon/calender.png';
import Dashboard from '../../assets/icon/dashboard.png';
import DataCollection from '../../assets/icon/data-collection.png';
import Delayed from '../../assets/icon/delayed.png';
import Failed from '../../assets/icon/failed.png';
import Geographic from '../../assets/icon/geographic-area.png';
import Planned from '../../assets/icon/mda-planned.png';
import PlusIcon from '../../assets/icon/plusIcon.png';
import Postponed from '../../assets/icon/postponed.png';
import Schedule from '../../assets/icon/schedule.png';
import Settings from '../../assets/icon/settings.png';
import SingleTick from '../../assets/icon/single-tick.png';
import Cleared from '../../assets/icon/cleared.png';
import Download from '../../assets/icon/download.png';
import RedCross from '../../assets/icon/redCross.png';
import DownArrow from '../../assets/icon/downArrow.png';
import LeftArrow from '../../assets/icon/leftArrow.png';
import RightArrow from '../../assets/icon/rightArrow.png';
import Switch from '../../assets/icon/switch.svg';
import Help from '../../assets/icon/help.svg';
import ChangePassword from '../../assets/icon/key.svg';
import Logout from '../../assets/icon/logout.svg';
import DashboardColored from '../../assets/icon/DashboardColor.png';
import Mail from '../../assets/icon/mail.png';
import WordIcon from '../../assets/icon/thumbnail_doc.svg';
import ExcelIcon from '../../assets/icon/thumbnail_xlsx.svg';
import PdfIcon from '../../assets/icon/thumbnail_pdf.svg';
import DownArrowSvg from '../../assets/icon/downArrowSvg.svg';
import Upload from '../../assets/icon/upload.png';
import Edit from '../../assets/icon/edit.svg';
import Search from '../../assets/icon/search.svg';
import Print from '../../assets/icon/print.svg';
import Filter from '../../assets/icon/filter.svg';
import Pencil from '../../assets/icon/pencil.svg';
import View from '../../assets/icon/eye.svg';
import Delete from '../../assets/icon/trash.svg';
import Threshold from '../../assets/icon/threshold.png';
import DownloadData from '../../assets/icon/downloadData.png';
import DoubleTick from '../../assets/icon/doubleTick.png';
import LoginHeader from '../../assets/icon/loginHeader.svg';
import WordUpload from '../../assets/icon/wordUpload.png';
import Downloader from '../../assets/icon/downloadSvg.svg';
import LoginMail from '../../assets/icon/loginMail.svg';
import CrossIcon from '../../assets/icon/CrossIcon.svg';
import Check from '../../assets/icon/check.svg';
import Banner from '../../assets/icon/Banner.svg';
import NoDataFound from '../../assets/images/emptyData.png';
import Update from '../../assets/icon/upload.svg';
import Disclaimer from '../../assets/icon/danger.png';

const CustomIcons = {
  Bell,
  Calender,
  Dashboard,
  DataCollection,
  Delayed,
  Download,
  DoubleTick,
  Failed,
  Geographic,
  Planned,
  PlusIcon,
  Postponed,
  Schedule,
  Settings,
  SingleTick,
  Cleared,
  Upload,
  RedCross,
  DownArrow,
  LeftArrow,
  RightArrow,
  Switch,
  ChangePassword,
  Help,
  Logout,
  DashboardColored,
  Mail,
  WordIcon,
  ExcelIcon,
  PdfIcon,
  DownArrowSvg,
  Edit,
  Search,
  Print,
  Filter,
  Pencil,
  View,
  Delete,
  DownloadData,
  Threshold,
  LoginHeader,
  WordUpload,
  Downloader,
  LoginMail,
  CrossIcon,
  Check,
  Banner,
  NoDataFound,
  Update,
  Disclaimer,
};

export default CustomIcons;
