/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-plus */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import * as _ from 'loadsh';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import FormTitle from '../../../../../components/atoms/FormTitle';
import AddRemoveCount from '../../../../../components/atoms/AddRemoveCount';
import CustomTextField from '../../../../../components/atoms/TextField';
import Dropdown from '../../../../../components/atoms/CustomDropdown';
import DatePicker from '../../../../../components/atoms/DatePicker';
import Button from '../../../../../components/atoms/CustomButton';
import Typography from '../../../../../components/atoms/Typography';
import Block from './block';
import { districtEntries } from './districtEntries';
import { blockFormEntries } from './blockFormEntries';
import Toaster from '../../../../../components/atoms/ToastMessage';
import {
  INSERT_TAS_DISRICT_STRATEGY,
  INSERT_TAS_BLOCK_STRATEGY,
  GET_STATE_DROPDOWN,
  GET_DISTRICT_DROPDOWN,
  GET_STRATEGY_DROPDOWN,
  GET_TAS_PROPOSED,
  EU_NAME,
} from '../../../../../constants/actionType';
import ComboBox from '../../../../../components/molecules/ComboBox';

/**
 *
 * @param {object} props - required props in TAS 1 from
 * @returns {React.ReactElement} - returns the TAS1 form
 */
const Tas1 = (props) => {
  const { viewAction, Activity } = props;
  const childRef = useRef();
  const { getProposed1Data, getProposed2Data, getProposed3Data } = useSelector((state) => state.TASDetails);
  const [entries, setEntries] = useState(districtEntries);
  // const [dateProposed, setDateProposed] = React.useState('');
  const {
    control,
    watch,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tasBloodSurvey: [{ eu_name: '' }],
    },
  });
  const userAurhId = localStorage.getItem('UserLoginId');
  console.log(userAurhId, 'userAurhId');
  const InputActionValidation = localStorage.getItem('ActionAccess');
  const Actionvalue = JSON.parse(InputActionValidation);
  const { BulkUpload, Download, Edit, ManualDataInput, View } = Actionvalue?.input?.[0];
  console.log(BulkUpload, Download, Edit, ManualDataInput, View, 'dataview');
  const tasDateCompleted = watch('date_completed');
  const tasDatePlanned = watch('date_planned');
  const tasDateProposed = watch('date_proposed');
  const { EUName } = useSelector((state) => state?.preTas);
  const [disabled, setDisabled] = useState(true);
  const [count, setCount] = useState(1);
  const [euValue, setEuValue] = useState([]);
  const [state1, setState1] = useState();
  const [district1, setDistrict] = useState();
  const [strategy, setStrategy] = useState();
  const [error, setError] = React.useState({});
  const dispatch = useDispatch();
  const dropdownList = useSelector((state) => state.dropDownReducer);
  const watchStrategy = watch('block_strategy_id');
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tasBloodSurvey',
  });
  const watchField = watch();
  const values = getProposed1Data && getProposed1Data[0]?.date_proposed;
  const valuesTas2 = getProposed2Data && getProposed2Data[0]?.date_proposed;
  const valuesTas3 = getProposed3Data && getProposed3Data[0]?.date_proposed;
  React.useEffect(() => {
    if (values !== undefined) {
      setValue('date_proposed', values);
    }
    if (valuesTas2 !== undefined) {
      setValue('date_proposed', valuesTas2);
    }
    if (valuesTas3 !== undefined) {
      setValue('date_proposed', valuesTas3);
    }
  }, [getProposed1Data, getProposed2Data, getProposed3Data]);
  React.useEffect(() => {
    setValue(`tasBloodSurvey[${count - 1}].date_completed`, tasDateCompleted);
    setValue(`tasBloodSurvey[${count - 1}].date_planned`, tasDatePlanned);
    setValue(`tasBloodSurvey[${count - 1}].date_proposed`, tasDateProposed);
  }, [count, entries, setValue, tasDateCompleted, tasDatePlanned, tasDateProposed]);
  // const watchEU1 = watch(`Eu_Population`);
  const watchEU = watch(`tasBloodSurvey[${count - 1}]population_under_eu`);
  const watchEuValue = watch(`tasBloodSurvey[${count - 2}]eu_name`);
  const watchEuValue1 = watch(`tasBloodSurvey[${count - 1}]eu_name`);
  // const finalEU = parseInt(watchEU1, 10) + parseInt(watchEU, 10);
  useEffect(() => {
    if (watchEU !== '') {
      const arr = [];
      for (let i = 1; i <= count; i++) {
        const watchEUState = watch(`tasBloodSurvey[${i - 1}]population_under_eu`);
        arr.push(parseInt(watchEUState, 10));
        const sum = arr.reduce(function (a, b) {
          return a + b;
        }, 0);

        setValue('population_user_tas', sum);
      }
    }
  }, [watchEU]);
  const valueReault = euValue.includes(watchEuValue1);
  useEffect(() => {
    if (watchEuValue !== undefined) {
      setEuValue([...euValue, watchEuValue]);
    }
  }, [count]);
  useEffect(() => {
    if (valueReault) {
      setValue(`tasBloodSurvey[${count - 1}]eu_name`, '');
    }
  }, [watchEuValue1]);

  /**
   *
   */
  const getValue = useCallback(() => {
    watchField?.tasBloodSurvey?.map((val) => {
      let getValArr = 0;
      val?.Blocks?.map((item) => {
        if (!Number.isNaN(item?.population) && item?.population !== undefined) {
          getValArr += +item.population;
        }
        return getValArr;
      });

      return console.log(getValArr);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchField?.tasBloodSurvey]);
  useEffect(() => {
    getValue();
  }, [getValue, watchField]);
  useEffect(() => {
    setEntries(districtEntries);
  }, []);

  useEffect(() => {
    dispatch({ type: GET_STATE_DROPDOWN });
    dispatch({ type: GET_STRATEGY_DROPDOWN });
  }, [dispatch]);

  useEffect(() => {
    if (watchStrategy === 1) {
      setEntries(blockFormEntries);
    }
    if (watchStrategy === 2) {
      setEntries(districtEntries);
    }
  }, [watchStrategy]);
  /**
   *
   */
  const handleRandom = () => {
    for (let i = 1; i <= count; i++) {
      if (strategy === 1) {
        entries[2].data = _.assign(EUName);
      } else {
        entries[1].data = _.assign(EUName);
      }
      setEntries(entries);
    }
  };


  useEffect(() => {

    handleRandom();

  }, [EUName, entries, EUName]);
  /**
   *
   * @param {string} value - passing onchange value
   * @param {string} name -  passing name
   */
  const getDistrict = (value, name) => {
    if (name === 'state_id') {
      dispatch({ type: GET_DISTRICT_DROPDOWN, payload: { state: value } });
      setState1(value);
    }
    setDisabled(false);
  };
  /**
   *
   * @param {*} value
   * @param {*} name
   */
  const getDistrictRes = (value, name) => {
    if (name === 'district_id') {
      setDistrict(value);
      dispatch({
        type: EU_NAME,
        payload: { state_id: state1, district_id: value, EuName: '' },
      });
    }
  };
  /**
   *
   * @param {*} value
   * @param {*} name
   */
  const getStatergy = (value, name) => {
    if (name === 'block_strategy_id') {
      setStrategy(value);
      dispatch({
        type: GET_TAS_PROPOSED,
        payload: { type: Activity, data: { state: state1, district: district1, strategy: value } },
      });
    }
  };

  /**
   *
   */
  const handleAddPositive = () => {
    if (Object.keys(errors).length) {
      alert('All Fields are Required');
    } else {
      append({
        eu_name: '',
        population_under_eu: '',
        date_completed: '',
        date_planned: '',
        date_proposed: '',
        sample_size: '',
        number_of_persons_examined: '',
        number_of_persons_positive: '',
        number_of_invalid_test: '',
        critical_cut_off: '',
      });
      setCount(count + 1);
    }
  };
  /**
   *
   */
  const handleRemove = () => {
    if (count === 1) {
      alert('Atleast One TAS Blood Survey Should be filled');
    } else {
      remove(count - 1);
      setCount(count - 1);
    }
  };

  /**
   *
   */
  const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();

  /**
   *
   * @param {Int8Array} datas -  returns form data
   * @function handleFormSubmit
   */
  const onSubmit = async (datas) => {
    const queries = query.get('type');
    const submitData = { ...datas };

    submitData.proposed_program_activities = queries;
    submitData.population_user_tas = datas.population_user_tas;
    submitData.eu_name = datas.eu_name;
    submitData.eu_count = count;

    submitData.created_by = userAurhId && userAurhId;
    if (strategy === 1) {
      submitData.block_count = 1;
    }

    if (datas.block_strategy_id === 1) {
      dispatch({ type: INSERT_TAS_BLOCK_STRATEGY, payload: { Data: submitData, type: 'insert' } });
    } else {
      dispatch({ type: INSERT_TAS_DISRICT_STRATEGY, payload: { Data: submitData, type: 'insert' } });
    }
    reset();
  };

  // eslint-disable-next-line require-jsdoc
  const getPlaceholder = (name, placeholder, idx) => {
    return `${placeholder} ${idx + 1}`;
  };
  /**
   *
   * @param {*} e
   * @param {*} index
   * @param {*} keyValue
   * @param {*} pattern
   */
  const onChangeValue = (e, index, keyValue, pattern) => {
    if (keyValue.value !== undefined) {
      entries[index].value = e.target.value;
    }

    const patternReg = pattern && !pattern.test(e.target.value);
    setError({ ...error, [`err_${index}`]: patternReg, [`errmsg_${index}`]: keyValue.helperText });
  };

  const [totalPopulation, setTotalPopulation] = useState(0);


  const updateTotalPopulation = () => {
    console.log(watchField, 'watchField');
    let sum = 0;
    watchField?.tasBloodSurvey?.forEach((val) => {
      val?.Blocks?.forEach((item) => {
        if (!Number.isNaN(item?.population) && item?.population !== undefined) {
          sum += +item.population;
        }
      });
    });
    setTotalPopulation(sum);
  };


  useEffect(() => {
    updateTotalPopulation();
  }, [updateTotalPopulation]);

  const handleChangeDropDown = async (e) => {
    getStatergy(e.target.value, 'block_strategy_id');
    setTotalPopulation(0)
    if (handleChangeDropDown) {
      watchField.tasBloodSurvey.fill(0)
    }
  }

  // const handleChangeDistrict = async (e) => {
  //   getDistrictRes(e.target.value, 'district_id');
  //   setTotalPopulation(0)
  //   if (handleChangeDistrict) {
  //     watchField.tasBloodSurvey.fill(0)
  //   }
  // }

  useEffect(() => {
  }, [totalPopulation])

  const [toaster, setToaster] = React.useState(false);
  /**
     *  handleToasterClose used for toaster close func
     */

  const handleToasterClose = () => {
    setToaster(false);
  };
  const handleYesClick = () => {
    handleToasterClose();
  };
  const handleNoClick = () => {
    handleToasterClose();
  };

  const dateValidation = (e) => {
    const apiDateInput = new Date(getProposed1Data[0].date_proposed);
    const userDateInput = new Date(e);
    const oneYearBeforeApiDate = new Date(apiDateInput);
    oneYearBeforeApiDate.setFullYear(oneYearBeforeApiDate.getFullYear() - 1);

    if (userDateInput <= apiDateInput && userDateInput >= oneYearBeforeApiDate) {
      // Date is valid, no need to show a toast
      // You can add your logic here if needed
    } else {
      setToaster(true);
    }
  }
  const buttons = [
    <button key="yes-button" onClick={handleYesClick}>Yes</button>,
    <button key="no-button" onClick={handleNoClick}>No</button>,
  ];
  return (
    <Grid>

      {toaster && <Toaster
        open
        severity="error"
        message="Completed date is less than or equal to Proposed Date, Do you want to Continue?"
        close={handleToasterClose}
        buttons={buttons} />}
      <Grid container item md={12}>
        <Grid item md={11} pt={2} pl={4}>
          <FormTitle labelText="Activity Update" />
        </Grid>

      </Grid>
      <Grid container item md={12}>
        <Grid container item md={9} spacing={3} pt={2} pl={3.8}>
          <Grid item md={4} pt={1.5}>
            <Controller
              name="state_id"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  handleChange={async (e) => {
                    getDistrict(e.target.value, 'state_id');
                    onChange(e);
                  }}
                  value={value || ''}
                  data={dropdownList.stateData}
                  label="State"
                />
              )}
            />
            {errors?.state_id?.type === 'required' && (
              <Typography colorType="error" text="State is required" type="link" />
            )}
          </Grid>
          <Grid item md={4} pt={1.5}>
            <Controller
              name="district_id"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  handleChange={async (e) => {
                    onChange(e);
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
                    getDistrictRes(e.target.value, 'district_id');
                  }}
                  value={value || ''}
                  data={dropdownList.districtData}
                  label="District"
                  disabled={disabled}
                />
              )}
            />
            {errors?.district_id?.type === 'required' && (
              <Typography colorType="error" text="District is required" type="link" />
            )}
          </Grid>
          <Grid item md={4} pt={1.5}>
            <Controller
              name="block_strategy_id"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  handleChange={async (e) => {
                    onChange(e);
                    handleChangeDropDown(e)
                    getStatergy(e.target.value, 'block_strategy_id');
                  }}
                  value={value || ''}
                  data={dropdownList.strategy}
                  label="Strategy"
                />
              )}
            />
            {errors?.block_strategy_id?.type === 'required' && (
              <Typography colorType="error" text="Strategy is required" type="link" />
            )}
          </Grid>
        </Grid>
        <Grid container item md={9} spacing={3} pt={2} pl={3.8}>
          <Grid item md={4} pt={1.8}>
            <Controller
              name="date_completed"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  label="TAS Survey Date Completed"
                  onHandleChange={(tas1Date) => {
                    onChange(moment(tas1Date).format('YYYY-MM-DD'));
                    // dateValidation(selectedDate)
                  }}
                  date={value}
                />
              )}
            />
            {errors?.date_completed?.type === 'required' && (
              <Typography colorType="error" text="TAS Survey Date Completed is required" type="link" />
            )}
          </Grid>
          <Grid item md={4} pt={1.8}>
            <Controller
              name="date_planned"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  label="Date Planned by State"
                  onHandleChange={(tas1Date) => {
                    onChange(moment(tas1Date).format('YYYY-MM-DD'));
                    // dateValidation(selectedDate)
                  }}
                  date={value}
                />
              )}
            />
            {errors?.date_planned?.type === 'required' && (
              <Typography colorType="error" text="Date Planned by State is required" type="link" />
            )}
          </Grid>
          <Grid item md={4} pt={1.8}>
            <Controller
              name="date_proposed"  
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  label="Date Proposed by NVBDCP"
                  // onHandleChange={(tas1Date) => {
                  //   onChange(moment(tas1Date).format('YYYY-MM-DD'));
                  // }}
                  date={value}
                  disabled
                />
              )}
            />
            {errors?.date_proposed?.type === 'required' && (
              <Typography colorType="error" text="Date Proposed by NVBDCP is required" type="link" />
            )}
          </Grid>
          <Grid item md={4} pt={1.8}>
            <Controller
              name="population_user_tas"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <CustomTextField
                  label="Population Under TAS (Auto Populate)"
                  placeholder="Population Under TAS (Auto Populate)"
                  value={totalPopulation}
                  disabled
                  onChange={(e) => {
                    onChange(e);
                  }}
                  type="text"
                />
              )}
            />
            {/* {errors?.date_planned?.type === 'required' && (
              <Typography colorType="error" text="Date Planned by State is required" type="link" />
            )} */}
          </Grid>
        </Grid>
        <Grid item md={3} display="flex" justifyContent="center" alignItems="center">
          <Grid>
            <AddRemoveCount
              labelText="EU Count"
              PositiveCount={() => handleAddPositive()}
              NegativeCount={() => handleRemove()}
              count={count}
            />
          </Grid>
        </Grid>
        <Grid item md={12} pt={2.5} pl={4} pb={2}>
          <FormTitle labelText="TAS Blood Survey" />
        </Grid>

        <Grid container display="flex" flexDirection="row" item md={9} spacing={3} pt={2} pl={3.8}>
          {fields?.map((field, idx, index) =>
            entries?.map((keyValue) => {
              return (
                <Controller
                  key={field.id}
                  name={`tasBloodSurvey[${idx}].${keyValue?.entryName}`}
                  control={control}
                  rules={{

                    required: keyValue?.validation?.isRequired,
                    min: keyValue?.validation?.min,

                  }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      {keyValue?.textField && (
                        <Grid sx={{ width: '100%' }} item md={keyValue.isCombined ? 2 : 4} pt={1.5}>
                          <CustomTextField
                            label={getPlaceholder(keyValue?.entryName, keyValue?.placeholder, idx)}
                            placeholder={getPlaceholder(keyValue?.entryName, keyValue?.placeholder, idx)}
                            value={value}
                            // value={watchField.tasBloodSurvey
                            //   .find((value, index) => index === idx)
                            //   ?.Blocks?.reduce((accumulator, currentValue) => {
                            //     return currentValue?.population
                            //       ? accumulator + Number(currentValue?.population)
                            //       : accumulator;
                            //   }, 0)}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            type={keyValue.textInputType}
                            disabled={keyValue.disabled}
                            id={field.id}
                          />
                          {errors && errors?.tasBloodSurvey?.[idx][keyValue?.entryName]?.type === 'required' && (
                            <Typography colorType="error" text={`${keyValue?.placeholder} is Required`} type="link" />
                          )}
                          {errors && errors?.tasBloodSurvey?.[idx][keyValue?.entryName]?.type === 'min' && (
                            <Typography
                              colorType="error"
                              text={`${keyValue?.placeholder} should be greater than 1`}
                              type="link"
                            />
                          )}
                        </Grid>
                      )}
                      {keyValue?.populationField && (
                        <Grid sx={{ width: '100%' }} item md={keyValue.isCombined ? 2 : 4} pt={1.5}>
                          <CustomTextField
                            label={getPlaceholder(keyValue?.entryName, keyValue?.placeholder, idx)}
                            placeholder={getPlaceholder(keyValue?.entryName, keyValue?.placeholder, idx)}
                            // value={value}
                            value={watchField.tasBloodSurvey
                              .find((value, index) => index === idx)
                              ?.Blocks?.reduce((accumulator, currentValue) => {
                                return currentValue?.population
                                  ? accumulator + Number(currentValue?.population)
                                  : accumulator;
                              }, 0)}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            type={keyValue.textInputType}
                            disabled={keyValue.disabled}
                            id={field.id}
                          />
                          {errors && errors?.tasBloodSurvey?.[idx][keyValue?.entryName]?.type === 'required' && (
                            <Typography colorType="error" text={`${keyValue?.placeholder} is Required`} type="link" />
                          )}
                          {errors && errors?.tasBloodSurvey?.[idx][keyValue?.entryName]?.type === 'min' && (
                            <Typography
                              colorType="error"
                              text={`${keyValue?.placeholder} should be greater than 1`}
                              type="link"
                            />
                          )}
                        </Grid>
                      )}
                      {keyValue?.date && (
                        <Grid sx={{ width: '100%' }} item md={4} pt={1.5}>
                          <DatePicker
                            disabled={keyValue.disabled}
                            label={`${keyValue.placeholder}`}
                            onHandleChange={(tas1Date) => {
                              onChange(moment(tas1Date).format('YYYY-MM-DD'));
                            }}
                            date={value}
                          />
                          {errors && errors?.tasBloodSurvey?.[idx][keyValue?.entryName]?.type === 'required' && (
                            <Typography colorType="error" text={`${keyValue?.placeholder} is Required`} type="link" />
                          )}
                          {errors && errors?.tasBloodSurvey?.[idx][keyValue?.entryName]?.type === 'min' && (
                            <Typography
                              colorType="error"
                              text={`${keyValue?.placeholder} should be greater than 1`}
                              type="link"
                            />
                          )}
                        </Grid>
                      )}
                      {keyValue?.ComboBox && (
                        <Grid item>
                          <ComboBox
                            component="TAS"
                            datas={keyValue.data}
                            label={keyValue.placeholder && keyValue.placeholder}
                            value={keyValue.value !== undefined ? keyValue.value : value}
                            onChangeAutocomplete={(e, pattern) => {
                              onChange(e);
                              onChangeValue(e, index, keyValue, pattern);
                            }}
                          />
                        </Grid>
                      )}

                      {keyValue.divider && <Grid className="dividerStyle" md={12} />}
                      {keyValue.isEmptySpace && <Grid item md={keyValue.breakpoint} />}

                      {keyValue.blockCount && (
                        <Grid container display="flex" item flexDirection="row" md={12}>
                          <Block
                            key={field.id}
                            ref={childRef}
                            control={control}
                            index={idx}
                            val={field}
                            Controller={Controller}
                          />
                        </Grid>
                      )}
                    </>
                  )}
                />
              );
            }),
          )}
        </Grid>
        <Grid
          xs={12}
          sm={12}
          md={12}
          container
          justifyContent="flex-end"
          style={{ paddingTop: 25 }}
          pr={2}
          pb={2}
          columnGap={2}
        >
          <Grid item lg={1} md={1} sm={1} xs={1}>
            <Button
              variants="contained"
              btnTitle="Submit"
              ellipse
              color="info"
              onClickHandle={handleSubmit(onSubmit)}
              customClass="buttonWidth"
            />
          </Grid>
          {View === 1 && (
            <Grid item lg={1} md={1} sm={1} xs={1}>
              <Button
                variants="contained"
                btnTitle="View"
                ellipse
                color="quaternary"
                customClass="buttonWidth"
                onClickHandle={viewAction}
              />
            </Grid>
          )}
          <Grid item lg={1} md={1} sm={1} xs={1}>
            <Button
              variants="contained"
              btnTitle="Cancel"
              ellipse
              color="disable"
              customClass="buttonWidth"

            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Tas1;
Tas1.propTypes = {
  viewAction: PropTypes.func,
  Activity: PropTypes.string,
};
Tas1.defaultProps = {
  viewAction: () => { },
  Activity: '',
};
