/* eslint-disable no-lonely-if */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { Divider, Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomTypography from '../../../components/atoms/Typography';
import Form from './Form';
import CustomTabBar from '../../../components/molecules/CustomTabBar';
import {
  privilegeRightsHeader,
  privilegeRightsGridConfig,
  nationalDashboardHeader,
  nationalDashboardGridConfig,
  nationalSubDashboardGridConfig,
  nationalSubDashboardHeader,
  stateDashboardHeader,
  stateDashboardGridConfig,
  stateSubDashboardGridConfig,
  predictionSubDashboardGridConfig,
  predictionDashboardHeader,
  predictionDashboardGridConfig,
} from './dataEntries';
import UserRightRightTablePanel from '../../../components/molecules/UserRightPanel/UserRoleRightTable';
import CustomButton from '../../../components/atoms/CustomButton';
import './User.css';
import {
  CREATE_USERS_GROUP_AND_TYPE,
  UPDATE_USERS_GROUP_AND_TYPE,
  USER_ROLE_TOGGLE_FORM,
} from '../../../constants/actionType';
import { UserRolePrivilegePayload } from '../../../services/payload/userRole.payload';

/**
 *
 * @returns {React.ReactElement} --
 */
const UserRolePrivilege = (props) => {
  const { form } = props;
  const dispatch = useDispatch();
  const {
    isRoleEdit,
    roleReadOnly,
    updateResValue,
    nationalDashBoardById,
    stateDashBoardById,
    PredictionDashBoardById,
    roleRightAccessById,
    roleRightAccessId,
    ddValues,
    rolePrivilegeAccessDashboardId,
    rolePrivilegeId,
  } = useSelector((store) => store?.userRole);
  console.log(roleRightAccessId, '1111211');
  const [message, setMessage] = useState(false);
  const [msgShowedOnce, setMsgShowedOnce] = useState(false);
  const [dashboardDetails, setDashboardDetails] = useState(false);
  const [formValues, setFormValues] = useState();
  // updateResValue && !form
  //   ? ddValues
  //   : {
  //       userGroup: '',
  //       userType: '',
  //     },

  React.useEffect(() => {
    if (updateResValue && !form && ddValues !== undefined) {
      setFormValues(ddValues);
    } else {
      if (ddValues === undefined) {
        setFormValues({
          userGroup: '',
          userType: '',
        });
      }
    }
  }, [ddValues, form, updateResValue]);
  const [privilegeRightsData, setPrivilegeRightsData] = useState([]);
  console.log(form, 'slkdfjsir');

  const [fieldOverrideDashBoard, setFieldOverrideDashBoard] = useState({
    national: updateResValue ? nationalDashBoardById?.subArr : nationalSubDashboardGridConfig,
    state: updateResValue ? stateDashBoardById?.subArr : stateSubDashboardGridConfig,
    prediction: updateResValue ? PredictionDashBoardById?.subArr : predictionSubDashboardGridConfig,
  });

  /**
   *
   * @param {*} val
   * @param {*} key
   */
  const onFieldLvlOverrideHandler = (val, key) => {
    if (val && key) {
      setFieldOverrideDashBoard((prev) => ({ ...prev, [key]: val }));
    }
  };

  /**
   *
   * @param {*} key
   * @param e
   */
  const onFormChange = (key, e) => {
    const { value } = e.target;
    setFormValues((prev) => ({ ...prev, [key]: value }));
  };

  /**
   *
   * @name onFilterChangeHandler
   * @param {*} val
   * @param {*} key
   */
  const onFilterChangeHandler = useCallback(
    (val, key) => {
      if (!msgShowedOnce) {
        setMessage(true);
      }
      setMsgShowedOnce(true);
      const tempArr = [];
      if (val[0]) {
        Object.entries(val[0]).map(([k, v]) => {
          if (v === true) tempArr.push(k);
          return false;
        });
      }

      const tempOverArr = [];

      fieldOverrideDashBoard[key].map((item) => {
        return tempOverArr.push({
          ...item,
          screenName: { ...item.screenName, show: tempArr.includes(item?.screenName?.value) },
        });
      });
      setFieldOverrideDashBoard((prev) => ({ ...prev, [key]: tempOverArr }));
    },
    [fieldOverrideDashBoard, msgShowedOnce],
  );
  console.log(fieldOverrideDashBoard.prediction, 'sdfasdrrewaa');

  const dashboardContent = [
    {
      label: 'National Dashboard',
      value: 'National Dashboard',
      content: (
        <>
          <UserRightRightTablePanel
            header={nationalDashboardHeader}
            gridConfig={updateResValue ? nationalDashBoardById?.tabArr : nationalDashboardGridConfig}
            readOnly={roleReadOnly}
            onCheckValues={(val) => onFilterChangeHandler(val, 'national')}
          />

          {dashboardDetails && (
            <>
              <Divider sx={{ border: '1px solid gray', m: 2 }} />
              <UserRightRightTablePanel
                header={nationalSubDashboardHeader}
                gridConfig={fieldOverrideDashBoard.national}
                isSubType
                isFilter
                readOnly={roleReadOnly}
                onCheckValues={(e) => onFieldLvlOverrideHandler(e, 'national')}
              />
            </>
          )}
        </>
      ),
    },
    {
      label: 'State Dashboard',
      value: 'State Dashboard',
      content: (
        <>
          <UserRightRightTablePanel
            header={stateDashboardHeader}
            gridConfig={updateResValue ? stateDashBoardById?.tabArr : stateDashboardGridConfig}
            readOnly={roleReadOnly}
            onCheckValues={(val) => onFilterChangeHandler(val, 'state')}
          />
          {dashboardDetails && (
            <>
              <Divider sx={{ border: '1px solid gray', m: 2 }} />

              <UserRightRightTablePanel
                header={nationalSubDashboardHeader}
                gridConfig={fieldOverrideDashBoard.state}
                isFilter
                isSubType
                readOnly={roleReadOnly}
                onCheckValues={(e) => onFieldLvlOverrideHandler(e, 'state')}
              />
            </>
          )}
        </>
      ),
    },
    {
      label: 'Prediction Dashboard',
      value: 'Prediction Dashboard',
      content: (
        <>
          <UserRightRightTablePanel
            header={predictionDashboardHeader}
            gridConfig={updateResValue ? PredictionDashBoardById?.tabArr : predictionDashboardGridConfig}
            readOnly={roleReadOnly}
            onCheckValues={(val) => onFilterChangeHandler(val, 'prediction')}
          />
          {dashboardDetails && (
            <>
              <Divider sx={{ border: '1px solid gray', m: 2 }} />

              <UserRightRightTablePanel
                header={nationalSubDashboardHeader}
                gridConfig={fieldOverrideDashBoard.prediction}
                isFilter
                isSubType
                readOnly={roleReadOnly}
                onCheckValues={(e) => onFieldLvlOverrideHandler(e, 'prediction')}
              />
            </>
          )}
        </>
      ),
    },
  ];

  /**
   *
   */
  const onAgreeHandler = () => {
    setDashboardDetails(true);
    setMessage(false);
  };

  /**
   *
   */
  const onDisAgreeHandler = () => {
    setMessage(false);
  };

  /**
   *
   */
  const onSubmitHandler = async () => {
    const { userGroup, userType } = formValues;
    console.log(formValues, 'privilegeRightsData');
    dispatch({
      type: isRoleEdit ? UPDATE_USERS_GROUP_AND_TYPE : CREATE_USERS_GROUP_AND_TYPE,
      payload: UserRolePrivilegePayload(
        {
          userGroup,
          userType,
          privilegeRightsData,
          role_privilege_ids: rolePrivilegeId,
          fieldOverrideDashBoard,
          rolePrivilegeAccessDashboardId,
          roleRightAccessId,
        },
        isRoleEdit,
      ),
    });
    // setTimeout(() => {
    //   setPrivilegeRightsData([]);
    // }, 1000);
    setTimeout(() => {
      setPrivilegeRightsData([]);
    }, 2000);
  };
  console.log(updateResValue, !form, 'sdlkjirhtt');

  /**
   *
   */
  const onCancelHandler = () => {
    dispatch({
      type: USER_ROLE_TOGGLE_FORM,
      payload: {
        toggleForm: false,
      },
    });
  };

  return (
    <Grid className="userRole_container">
      <Grid sx={{ p: 0 }}>
        <CustomTypography
          text="Create User Role Privilege"
          type="caption"
          customStyle={{ fontWeight: 'bold' }}
          customClass="userText"
        />
      </Grid>
      <Grid>
        <Form
          grpValue={formValues?.userGroup}
          typeValue={formValues?.userType}
          onChangeHandler={onFormChange}
          readOnly={roleReadOnly}
        />
      </Grid>
      <UserRightRightTablePanel
        header={privilegeRightsHeader}
        gridConfig={updateResValue && !form ? roleRightAccessById : privilegeRightsGridConfig}
        readOnly={roleReadOnly}
        onCheckValues={(e) => setPrivilegeRightsData(e)}
        form={form}
      />{' '}
      {/*  */}
      <Grid>
        {formValues && formValues.userType === 5 && (
          <>
            <Grid item container direction="row" className="field">
              <CustomTypography text="Field Level Override" />
              <CustomTypography text="Dashboard" type="caption" />
            </Grid>
            <CustomTabBar TabDetails={dashboardContent} />
            <Divider sx={{ border: '1px solid gray', m: 2 }} />
          </>
        )}
      </Grid>
      {message && (
        <Grid item container className="messageContainer">
          <div className="alert_card_box">
            <div className="alert_card_background">
              <CustomTypography
                text="Warning! You are trying to give access  override on an Output field"
                type="link"
                colorType="tertiary"
                customClass="textStylesWarning"
              />

              <CustomTypography
                text="1) Alternatively a user could avoid Output field override by correcting the corresponding input fields."
                type="link"
                customClass="textStyles"
              />
              <CustomTypography
                text="2) Overriding an already calculated or  derived field will adversely impact the program outcome."
                type="link"
                customClass="textStyles"
              />
              <CustomTypography
                text="3) InCase of an emergency, An Audit log is maintained.This log will highlight
            values before and after override."
                type="link"
                customClass="textStyles"
              />

              <Grid
                className="Btn"
                item
                container
                direction="row"
                columnGap={5}
                sx={{ display: 'flex', justifyContent: 'center', p: '15px 0px 0px 0px' }}
              >
                <CustomButton
                  btnTitle="I Agree"
                  variants="contained"
                  color="info"
                  ellipse
                  onClickHandle={onAgreeHandler}
                  customStyle={{ width: '90px', height: '30px' }}
                />
                <CustomButton
                  btnTitle="Cancel"
                  variants="contained"
                  color="disable"
                  ellipse
                  onClickHandle={onDisAgreeHandler}
                  customStyle={{ width: '90px', height: '30px' }}
                />
              </Grid>
            </div>
          </div>
        </Grid>
      )}
      <Grid className="footBtn">
        <Grid sx={{ pr: 2 }}>
          <CustomButton
            btnTitle={roleReadOnly ? 'Back' : 'Submit'}
            variants="contained"
            color="info"
            ellipse
            onClickHandle={roleReadOnly ? onCancelHandler : onSubmitHandler}
            customClass="buttonStyle"
          />
        </Grid>
        <Grid>
          {!roleReadOnly && (
            <CustomButton
              btnTitle="Cancel"
              onClickHandle={onCancelHandler}
              variants="contained"
              color="disable"
              ellipse
              customClass="buttonStyle"
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserRolePrivilege;
