/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Button, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import './CustomButton.css';
import CustomIcons from '../../../utils/icons';

/**
 *
 * @param  {object} props - required props in custom button component
 * @returns {React.ReactElement} - returns the button component
 */
const CustomButton = (props) => {
  const {
    variants,
    color,
    btnTitle,
    disabled,
    float,
    customClass,
    onClickHandle,
    customStyle,
    startIcon,
    endIcon,
    iconType,
    ellipse,
  } = props;
  /**
   *
   * @returns {object} returns icon
   */
  const getIconType = () => {
    switch (iconType) {
      case 'upload':
        return CustomIcons.Upload;
      case 'download':
        return CustomIcons.Download;
      case 'previous':
        return CustomIcons.LeftArrow;
      case 'next':
        return CustomIcons.RightArrow;
      case 'plus':
        return CustomIcons.PlusIcon;
      case 'wordUpload':
        return CustomIcons.WordUpload;
      default:
        return '';
    }
  };
  return (
    <Button
      sx={customStyle}
      variant={variants}
      color={color}
      fullWidth
      disabled={disabled}
      className={`${variants === 'contained' && 'defaultClass'} ${float && 'float'} ${
        ellipse && 'ellipse'
      }  ${customClass}`}
      onClick={onClickHandle}
      endIcon={endIcon}
    >
      {startIcon && <img src={getIconType(iconType)} alt="upload" className="startIcon" />}
      {btnTitle}
      {float && (
        <Grid>
          <img src={CustomIcons.PlusIcon} alt="plus icon" className="plus" />
        </Grid>
      )}

      {endIcon && <img src={getIconType(iconType)} alt="upload" className="upload" />}
    </Button>
  );
};

export default CustomButton;
CustomButton.propTypes = {
  variants: PropTypes.string,
  color: PropTypes.string,
  btnTitle: PropTypes.string.isRequired,
  float: PropTypes.bool,
  customClass: PropTypes.string,
  customStyle: PropTypes.any,
  onClickHandle: PropTypes.func.isRequired,
  iconType: PropTypes.string,
  ellipse: PropTypes.bool,
  startIcon: PropTypes.bool,
  endIcon: PropTypes.string,
  disabled: PropTypes.bool,
};
CustomButton.defaultProps = {
  customClass: '',
  color: 'tertiary',
  customStyle: {},
  float: false,
  iconType: '',
  ellipse: false,
  variants: 'contained',
  startIcon: false,
  endIcon: '',
  disabled: false,
};
