import axios from 'axios';
import BaseUrl from '../../config/config';
// import { getDossier, insertDossier, updateDossier, bulkUploadDossier, bulkInsertDossier } from '../../utils/api';
import { getDossier, insertDossier, updateDossier, bulkInsertDossier } from '../../utils/api';

const userAurhId = localStorage.getItem('UserLoginId');

/**
 * @name getDossierRequest function which make api request to get Demographics.
 * @returns {*} returns axios response data
 */
export const getDossierRequest = () => {
  return axios.get(`${BaseUrl}/${getDossier}`);
};

/**
 * @param {object} data payload data
 * @name updateDossierRequest function which make api request to update Demographics.
 * @returns {*} returns axios response data
 */
export const updateDossierRequest = (data) => {
  return axios({
    method: 'put',
    url: `${BaseUrl}/${updateDossier}`,
    data: {
      updated_by: userAurhId && userAurhId,
      inputScreen: data,
    },
  });
};

/**
 * @param {object} data payload data
 * @name insertDossierRequest function which make api request to add  Demographics.
 * @returns {*} returns axios response data
 */
export const insertDossierRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${insertDossier}`,
    data: {
      state_id: data.state,
      district_id: data.district,
      certification_completed: data.certification_completed,
      date_planned: data.date_Planned,
      date_completed: data.date_Completed,
    },
  });
};
/**
 *
 * @param  {object} data payload data
 * @returns {*} returns axios response data
 */
export const bulkUploadDossierRequest = (data) => {
  // const formData = new FormData();
  // formData.append('file_upload', data);
  // formData.set('created_by', 1);
  return axios({
    method: 'post',
    url: `${BaseUrl}/${bulkInsertDossier}`,
    data: {
      bulkInsertDossier: data,
      created_by: userAurhId && userAurhId,
    },
  });
};
