/* eslint-disable react/prop-types */
import { TableHead, TableRow } from '@mui/material';
import React from 'react';
import MuiTableCell from '@mui/material/TableCell';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Typography from '../../../atoms/Typography';

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell);

/**
 *
 * @param {*} props all
 * @returns {React.JSXElementConstructor} header element
 */
const TableHeader = (props) => {
  const { heads, isSubType } = props;
  return (
    <TableHead>
      <TableRow>
        {heads &&
          heads.length > 0 &&
          heads.map((item, index) => {
            const key = index;
            return (
              <TableCell key={key} align={key !== 0 ? 'center' : 'left'}>
                <Typography
                  text={item.label}
                  type="link"
                  colorType={isSubType ? 'primary' : 'tertiary'}
                  customStyle={{ fontSize: '14px' }}
                />
              </TableCell>
            );
          })}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
TableHeader.propTypes = {
  // heads: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     label: PropTypes.string,
  //     value: PropTypes.string,
  //   }),
  // ).isRequired,
  isSubType: PropTypes.bool,
};

TableHeader.defaultProps = {
  isSubType: false,
};
