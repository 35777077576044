export const FormEnteries = [
  {
    count: 'true',
    entryName: 'state_id',
    placeholder: 'State',
    dropdown: true,
    data: [
      { id: 1, value: 'Kerala' },
      { id: 2, value: 'Goa' },
      { id: 3, value: 'TamilNadu' },
    ],
    validation: [{ isRequired: true }],
  },
  {
    count: 'true',
    entryName: 'district_id',
    placeholder: 'District',
    disabled: true,
    dropdown: true,
    data: [
      { id: 1, value: 'Kerala' },
      { id: 2, value: 'Goa' },
      { id: 3, value: 'TamilNadu' },
    ],
    validation: [{ isRequired: true }],
  },
  {
    count: 'true',
    textField: true,
    entryName: 'total_district_population',
    placeholder: 'Total District Population',
    validation: [{ isRequired: true }, { min: true, value: 1 }, { dateValidation: false }],
    textInputType: 'number',
  },
  {
    count: 'true',
    textField: true,
    entryName: 'total_district_endamic_population',
    placeholder: 'Total District Endemic Population',
    validation: [{ isRequired: true }, { min: true, value: 1 }, { dateValidation: false }],
    textInputType: 'number',
  },

  {
    count: 'true',
    date: true,
    entryName: 'from_date',
    placeholder: 'From Date',
    validation: [{ isRequired: true }, { min: false }, { dateValidation: true }],
  },
  {
    count: 'true',
    date: true,
    entryName: 'to_date',
    placeholder: ' To Date ',
    validation: [{ isRequired: true }],
  },

  {
    count: 'true',
    radioButton: true,
    entryName: 'status',
    placeholder: 'Certificate',
    labelText: 'Status',
    radioData: [{ name: 'Active' }, { name: 'Inactive' }],
    validation: [{ isRequired: true }],
  },
];
export const formDefaultValues = {
  state: '',
  district: '',
  total_district_endamic_population: '',
  total_district_population: '',
  from_date: '',
  to_date: '',
  status: 'Active',
};
