import { takeLatest } from 'redux-saga/effects';
import {
  GET_USERS_GROUP_AND_TYPE,
  DELETE_USERS_GROUP_AND_TYPE,
  USER_ROLE_TOGGLE_FORM,
  UPDATE_USERS_GROUP_AND_TYPE,
  READ_USERS_GROUP_AND_TYPE,
  CREATE_USERS_GROUP_AND_TYPE,
} from '../../constants/actionType/index';
import { INITIAL_LOAD } from '../../constants/common';
import {
  deleteUserRoleHandler,
  getUserRoleHandler,
  formToggleHandler,
  createUserRoleHandler,
  readUserRoleHandler,
  updateUserRoleHandler,
  statusClear,
} from '../handler/userRole.handler';

/**
 * @name userRoleSaga used for dashboard api call
 * @yields
 */
function* userRoleSaga() {
  yield takeLatest(INITIAL_LOAD, statusClear);
  yield takeLatest(GET_USERS_GROUP_AND_TYPE, getUserRoleHandler);
  yield takeLatest(CREATE_USERS_GROUP_AND_TYPE, createUserRoleHandler);
  yield takeLatest(READ_USERS_GROUP_AND_TYPE, readUserRoleHandler);
  yield takeLatest(UPDATE_USERS_GROUP_AND_TYPE, updateUserRoleHandler);
  yield takeLatest(DELETE_USERS_GROUP_AND_TYPE, deleteUserRoleHandler);
  yield takeLatest(USER_ROLE_TOGGLE_FORM, formToggleHandler);
}
export default userRoleSaga;
