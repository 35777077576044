export const CreateFormEntries = [
  {
    count: true,
    entryName: 'User_Group',
    placeholder: 'User Group',
    dropdown: true,
    // data: [
    //   { id: 1, value: 'Block Level' },
    //   { id: 2, value: 'State Level' },
    //   { id: 3, value: 'District Leve' },
    //   { id: 4, value: 'Division at National Level' },
    //   { id: 5, value: 'Officers at National Level' },
    //   { id: 6, value: 'NTD Partners' },
    // ],
    data: [],
    validation: { isRequired: true },
  },
  {
    count: true,
    entryName: 'User_Types',
    placeholder: 'User Type',
    dropdown: true,
    // data: [
    //   { id: 1, value: 'Data Entry Operator' },
    //   { id: 2, value: 'Executive' },
    //   { id: 3, value: 'Monitor' },
    // ],
    data: [],
    validation: { isRequired: true },
  },

  {
    count: true,
    textField: true,
    entryName: 'Name',
    placeholder: 'Name',
    textInputType: 'default',
    pattern: 'text',
    helperText: 'Invalid Name',
    validation: { isRequired: true },
  },

  {
    count: true,
    textField: true,
    entryName: 'Email',
    placeholder: 'Email',
    textInputType: 'default',
    pattern: 'email',
    helperText: 'This email address is invalid.',
    validation: [{ isRequired: true }],
  },
  {
    count: true,
    textField: true,
    multiline: true,
    entryName: 'Address',
    placeholder: 'Address',
    textInputType: 'default',
    pattern: 'address',
    helperText: 'Invalid Address',
    validation: [{ isRequired: true }],
  },
  {
    count: true,
    entryName: 'state',
    placeholder: 'State',
    dropdown: true,
    // data: [
    //   { id: 1, value: 'Data Entry Operator' },
    //   { id: 2, value: 'Executive' },
    //   { id: 3, value: 'Monitor' },
    // ],
    data: [],
    validation: { isRequired: true },
  },
  {
    count: true,
    radioButton: true,
    entryName: 'Status',
    placeholder: 'Status',
    labelText: 'Status',
    radioData: [{ name: 'Active' }, { name: 'Inactive' }],
    validation: [{ isRequired: true }],
  },
];
export const CreateFormDefaultValues = {
  User_Group: '',
  UserType: '',
  Name: '',
  Email: '',
  Address: '',
  Status: 'Active',
};

/* eslint-disable import/prefer-default-export */
export const tableHeader = [
  'S.No',
  'User Group',
  'User Type',
  'Name',
  'Email',
  'Address',
  'Status',
  'Action',
  'UserId',
  'UserTypeId',
  'UserGroupId',
];
export const tableData = [
  {
    S_no: 1,
    User_Group: 'Utter Pradesh',
    User_Types: 'Kanpur',
    Name: 'District',
    Email: '09 Mar 2020',
    Address: '09 Jan 2020',
  },
  {
    S_no: 2,
    User_Group: 'Kerala',
    User_Types: 'Kanpur',
    Name: 'District',
    Email: '09 Mar 2020',
    Address: '09 Jan 2020',
  },
];
export const headerData = ['Screen Names', 'All', 'View', 'Download', 'Edit', 'Manual Input', 'Bulk Upload'];
export const rowData = [
  {
    Screen: 'Data Input',
    All: 'c',
    view: '&#10004;',
    download: '&#10004;',
    edit: '&#10004;',
    manual: '&#10004;',
    Bulk: '&#10004;',
  },
  {
    Screen: 'OutPut Dashboard',
    All: 'c',
    view: '&#10004;',
    download: '&#10004;',
    edit: '&#10004;',
    manual: '&#10004;',
    Bulk: '&#10004;',
  },
  {
    Screen: 'Master Setup',
    All: 'c',
    view: '&#10004;',
    download: '&#10004;',
    edit: '&#10004;',
    manual: '&#10004;',
    Bulk: '&#10004;',
  },
  {
    Screen: 'Bulk Upload',
    All: 'c',
    view: '&#10004;',
    download: '&#10004;',
    edit: '&#10004;',
    manual: '&#10004;',
    Bulk: '&#10004;',
  },
];
export const NationalDashboard = [
  {
    id: 1,
    header: '',
    labels: true,
    values: ['National Dashboard'],
  },
  {
    id: 2,
    header: 'Country Profile',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 3,
    header: 'Progress Status',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 4,
    header: 'Activity Calendar',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 5,
    header: 'Pre-TAS/TAS OverView',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 6,
    header: 'TAS Decision Matrix',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 7,
    header: 'TAS Summary',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 8,
    header: 'MDA Coverage',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 9,
    header: 'Data Quality',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
];
export const StateDashboard = [
  {
    id: 1,
    header: '',
    labels: true,
    values: ['State Dashboard'],
  },
  {
    id: 2,
    header: 'State Profile',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 3,
    header: 'Progress Status',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 4,
    header: 'Activity Calendar',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 5,
    header: 'TAS Decision Matrix',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 6,
    header: 'District Profile Widget',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 7,
    header: 'District MDA Profile',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 8,
    header: 'District TAS Profile',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 9,
    header: 'District Decision Matrix',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 9,
    header: 'Data Quality',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
];
export const PredictionDashboard = [
  {
    id: 1,
    header: '',
    labels: true,
    values: ['Prediction Dashboard'],
  },
  {
    id: 2,
    header: 'Country Profile',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 3,
    header: 'Expected Activities',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 4,
    header: 'Activity Calendar',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
];
export const CountryProfile = [
  {
    id: 1,
    header: '',
    labels: true,
    values: ['Country Profile'],
  },
  {
    id: 2,
    header: 'Endemic Districts',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 3,
    header: 'DA',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 4,
    header: 'IDA',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 5,
    header: 'Under TAS1',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 6,
    header: 'Under TAS2',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 7,
    header: 'Under TAS3',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 8,
    header: 'TAS Cleared 1',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 9,
    header: 'TAS Cleared 2',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 10,
    header: 'TAS Cleared 3',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 11,
    header: 'Dossier Prep',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
];
export const ProgressStatus = [
  {
    id: 1,
    header: '',
    labels: true,
    values: ['Progress Status'],
  },
  {
    id: 2,
    header: 'Endemic Districts',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 3,
    header: 'Activity Planned',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 4,
    header: 'Activity Delayed',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 5,
    header: 'Activity Completed',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 6,
    header: 'Activity Pending',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
];
export const PreTasOverview = [
  {
    id: 1,
    header: '',
    labels: true,
    values: ['Pre-TAS/TAS Overview'],
  },
  {
    id: 2,
    header: 'Status',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 3,
    header: 'Pre-TAS',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 4,
    header: 'TAS 1',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 5,
    header: 'TAS 2',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 6,
    header: 'TAS 3',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
];
export const TasDecisionMatrix = [
  {
    id: 1,
    header: '',
    labels: true,
    values: ['TAS Decision Matrix'],
  },
  {
    id: 2,
    header: 'EU',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 3,
    header: 'Constituent Blocks',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 4,
    header: 'Activity',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 5,
    header: 'Current Status',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 6,
    header: 'Next Year Activity',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
];
export const TasSummary = [
  {
    id: 1,
    header: '',
    labels: true,
    values: ['TAS Summary'],
  },
  {
    id: 2,
    header: 'EU (Total)',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 3,
    header: 'TAS Stage',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 4,
    header: 'EU Passed TAS',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 5,
    header: 'EU Falied TAS',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 6,
    header: 'Delayed TAS',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 7,
    header: '% of Population Under TAS',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },

  {
    id: 8,
    header: '% EU Passed in a District',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
];
export const MdaCoverage = [
  {
    id: 1,
    header: '',
    labels: true,
    values: ['MDA Coverage'],
  },
  {
    id: 2,
    header: 'Completed MDA',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 3,
    header: 'Pending MDA',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 4,
    header: 'Delayed MDA',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 5,
    header: '% Completed',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 6,
    header: 'Coverage (%) (Abs. #)',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
];
export const DataQuality = [
  {
    id: 1,
    header: '',
    labels: true,
    values: ['Data Quality'],
  },
  {
    id: 2,
    header: 'Total Report Count',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 3,
    header: 'Expected Report Count',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 4,
    header: 'Delayed Report Count',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 5,
    header: 'On-Track',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
];
export const StateProfile = [
  {
    id: 1,
    header: '',
    labels: true,
    values: ['State Profile'],
  },
  {
    id: 2,
    header: 'Activity Planned',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 3,
    header: 'Planned Date',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 4,
    header: 'Activity Status',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 5,
    header: 'Date Conducted',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 6,
    header: 'Block Strategy Implemented',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 7,
    header: 'Population under MDA/TAS (%)',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 8,
    header: 'Data status',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
];
export const StateProgressStatus = [
  {
    id: 1,
    header: '',
    labels: true,
    values: ['Progress Status'],
  },
  {
    id: 2,
    header: 'Activity Planned',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 3,
    header: 'Date Planned',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 4,
    header: 'Date Completed',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 5,
    header: 'Data Status',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
];
export const ActivityCalendar = [
  {
    id: 1,
    header: '',
    labels: true,
    values: ['Activity Calendar'],
  },
  {
    id: 2,
    header: 'Activity',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 3,
    header: 'Current Status',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 4,
    header: 'Date Proposed by NCVBDC',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 5,
    header: 'Date Proposed by State',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
];
export const StateTasDecisionMatrix = [
  {
    id: 1,
    header: '',
    labels: true,
    values: ['TAS Decision Matrix'],
  },
  {
    id: 2,
    header: 'EU',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 3,
    header: 'Constituent Blocks',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 4,
    header: 'Activity',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 5,
    header: 'Current Status',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 6,
    header: 'Next Year Activity',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
];
export const DistrictProfileWidget = [
  {
    id: 1,
    header: '',
    labels: true,
    values: ['District Profile Widget'],
  },
  {
    id: 2,
    header: 'Block IU Strategy',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 3,
    header: 'Block Name',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 4,
    header: 'Current Year Activity',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 5,
    header: 'Previous Year Activity',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 6,
    header: `${new Date().getFullYear()}`,
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
];
export const DistrictMdaProfile = [
  {
    id: 1,
    header: '',
    labels: true,
    values: ['District MDA Profile'],
  },
  {
    id: 2,
    header: 'Blocks',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 3,
    header: 'Activity Planned',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 4,
    header: 'Planned Date',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 5,
    header: 'Current Status',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 6,
    header: 'Completion Date',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
];
export const DistrictTasProfile = [
  {
    id: 1,
    header: '',
    labels: true,
    values: ['District TAS Profile'],
  },
  {
    id: 2,
    header: 'EU Name',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 3,
    header: 'Mapped Blocks',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 4,
    header: 'Current Year Activity',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 5,
    header: 'Previous Year Activity',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 6,
    header: `${new Date().getFullYear()}`,
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
];
export const DistrictDecisionMatrix = [
  {
    id: 1,
    header: '',
    labels: true,
    values: ['District Decision Matrix'],
  },
  {
    id: 2,
    header: 'Population of District',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 3,
    header: 'No of Blocks',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 4,
    header: 'No of EU',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 5,
    header: 'Current Year Activity',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 6,
    header: 'Population Under Current Activity',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 7,
    header: 'Next Year Activity',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 8,
    header: 'Population Under Next Year Activity',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
];
export const StateDataQuality = [
  {
    id: 1,
    header: '',
    labels: true,
    values: ['Data Quality'],
  },
  {
    id: 2,
    header: 'District Name',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 3,
    header: 'Activity Type/Count',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 4,
    header: 'Report Count',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 5,
    header: 'Expected Date',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 6,
    header: 'Delayed Report Count',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
];
export const ExpectedActivity = [
  {
    id: 1,
    header: '',
    labels: true,
    values: ['Data Quality'],
  },
  {
    id: 2,
    header: 'District',
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 3,
    header: `Expected Activity Due (${new Date().getFullYear()}+1)`,
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 4,
    header: `Expected Activity Due Date (${new Date().getFullYear()}+1)`,
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 5,
    header: `Current Year Activity ${new Date().getFullYear()}`,
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 6,
    header: `Current Year Status ${new Date().getFullYear()}`,
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
];
export const PredictionActivity = [
  {
    id: 1,
    header: '',
    labels: true,
    values: ['Activity Calendar'],
  },
  {
    id: 2,
    header: `Expected Activity (${new Date().getFullYear()}+1)`,
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 3,
    header: `Current Activity ${new Date().getFullYear()}`,
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 4,
    header: `Current Year Status ${new Date().getFullYear()}`,
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
  {
    id: 5,
    header: `Expected Due Date for Activity (${new Date().getFullYear()}+1)`,
    values: [{ checked: false, placeholder: 'Data Input' }],
  },
];
export const NationalDashWidgets = [
  { checked: false, panel: CountryProfile },
  { checked: false, panel: ProgressStatus },
  { checked: false, panel: ActivityCalendar },
  { checked: false, panel: PreTasOverview },
  { checked: false, panel: TasDecisionMatrix },
  { checked: false, panel: TasSummary },
  { checked: false, panel: MdaCoverage },
  { checked: false, panel: DataQuality },
];
export const StateDashWidgets = [
  { checked: false, panel: StateProfile },
  { checked: false, panel: StateProgressStatus },
  { checked: false, panel: ActivityCalendar },
  { checked: false, panel: StateTasDecisionMatrix },
  { checked: false, panel: DistrictProfileWidget },
  { checked: false, panel: DistrictMdaProfile },
  { checked: false, panel: DistrictTasProfile },
  { checked: false, panel: DistrictDecisionMatrix },
  { checked: false, panel: StateDataQuality },
];
export const PredictionDashWidgets = [
  { checked: false, panel: CountryProfile },
  { checked: false, panel: ExpectedActivity },
  { checked: false, panel: PredictionActivity },
];
export const privilegeRightsHeader = [
  {
    label: 'Screen Name',
    value: 'screenName',
  },
  {
    label: 'View',
    value: 'view',
  },
  {
    label: 'Download',
    value: 'download',
  },
  {
    label: 'Edit',
    value: 'edit',
  },
  {
    label: 'Manual Data Input',
    value: 'manual_data_input',
  },
  {
    label: 'Bulk Upload',
    value: 'bulk_upload',
  },
];
