/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable require-jsdoc */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable dot-notation */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable prefer-destructuring */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable new-cap */
/* eslint-disable no-shadow */
/* eslint-disable no-unreachable */
/* eslint-disable spaced-comment */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import { LatLngBounds } from 'leaflet';
import { ImageOverlay, MapContainer } from 'react-leaflet';
import DashboardCard from '../../../components/atoms/DashboardCard';
import Typography from '../../../components/atoms/Typography';
import HeaderSection from '../../../components/molecules/HeaderSection';
import ActivityCalendar from '../../../components/organisms/Schedule/index';
import GroupBarChart from '../../../components/molecules/GroupedBarChart';
import DynChartBlock from '../../../components/organisms/DynChartBlock';
// import DistrictMap from '../../../components/organisms/Map';
import CommonTitledTable from '../../../components/organisms/CommonTitledTable';
import LineGraph from '../../../components/molecules/linGraph';
import FileGenerator from '../../../components/organisms/FileGenerator';
import StackedBarLineChart from '../../../components/molecules/StackedBarLineChart';
import BarLineChart from '../../../components/molecules/BarLineChart';
import TooltipTitle from '../../../components/atoms/Tooltip/TooltipContent';
import Loader from '../../../components/atoms/Loader';
import { range } from '../../../utils/common';
import {
  DistrictTableHeader,
  activityTableHead,
  // activityTableContent,
  decisionMatrixHeader,
  DataQualityHeader,
  // DistrictProfileHeader,
  DistrictMDAHeaderDis,
  DistrictMDAHeader,
  // districtTasProfileHeader,
  BlockTableHeader,
  activityHeaderState,
  DistrictDecisionHeader,
  BlockDecisionHeader,
} from '../DummyConstants';
import {
  COUNTRY_PROFILE_MAP,
  NATIONAL_STATES_DASHBOARD_CARD,
  NATIONAL_PROGRESS_STATUSBAR_GRAPH,
  ACTIVITY_CALENDAR,
  GET_TAS_DECISION_MATRIX,
  GET_STATE_PROGRESS_STATUS,
  GET_DATA_QUALITY_GRAPH,
  GET_STATE_PROFILE,
  DISTRICT_MDA_PROFILE,
  GET_DISTRICT_PROFILE_WIDGET,
  GET_DISTRICT_PROFILE_WIDGET_GRAPH,
  GET_DISTRICT_TAS_PROFILE,
  GET_DISTRICT_DECISION_MATRIX,
  GET_STATE_CODE,
  GET_COUNTRY_PROFILE_CHART,
  GET_DISTRICT_DROPDOWN,
  STATE_PROFILE_CHART,
  STATE_DATA_QUALITY_TABLE,
  // GET_DISTRICT_MDA_BLOCKS,
  ACTIVITY_CALENDAR_TABLE,
  GET_DISTRICT_MOVEDTO,
  SET_GET_DASHBOARD_FILTER_DATA,
} from '../../../constants/actionType/index';
import { getStateCode } from '../../../helperFunctions';
import '../Dashboard.css';
import { stateCoordinates, stateOptions } from '../../../components/mapcomponent/mapData';
//import MapComponent from '../../../components/mapcomponent/mapcomponent';
import MapComponent from '../../../components/mapcomponent/mapcomponent2';

/**
 * @name StateDashboard
 * @param  {object} props - required props
 * @param {Array} props.showDisplay - showDisplay
 * @returns {React.ReactElement} return the national dashboard page
 */
const StateDashboard = ({ showDisplay }) => {
  const dispatch = useDispatch();
  const [DistrictProfileHeader, setDistrictProfileHeader] = useState([]);
  const [districtTasProfileHeader, setDistrictTasProfileHeader] = useState([]);

  const [getMapLegends, setGetMapLegends] = useState([]);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState('');
  const [checkstates, setCheckstates] = useState('');

  // const [selectedState, setSelectedState] = useState('');
  const [bboxstates, setbboxstates] = useState();
  const { DistrictMovedToData } = useSelector((state) => state.nationalDashboardDetails);
  const dropdownList = useSelector((state) => state.dropDownReducer);
  const {
    countryProfileMap,
    nationalStateDashboardCard,
    nationalStateProgressStatusbar,
    activityCalendar,
    activityCalendarTable,
    tasDecisionMatrix,
    dataQualityGraph,
    getCountryProfileChart,
    stateCode,
    activityLegends,
    filterData,
  } = useSelector((state) => state.dashboardDetail);
  console.log(countryProfileMap, 'countryProfileMap');
  const vr = useSelector((state) => state.dashboardDetail);
  const {
    StateProfile,
    districtMdaProfile,
    districtProfileWidget,
    districtProfileWidgetGraph,
    districtTasProfile,
    districtDecisionMatrix,
    stateProfileChart,
    stateDataQuality,
    districtMDABlocks,
    S,
  } = useSelector((state) => state.stateDashboardDetails);

  const [globalFilter, setGlobalFilter] = useState({
    Year: new Date().getFullYear().toString(),
    State: '',
    Strategy: 'District Strategy',
    Type: '1',
    Sort: 'A-Z',
  });

  const [selectedStateYear, setSelectedData] = useState({ Year: globalFilter.Year, State: 'Odisha' });
  const [selectedStrategy, setSelectedStrategy] = useState('');

  const [selectedDistrict, setSelectedDistrict] = useState('');
  console.log(selectedDistrict, 'selectedDistrict');

  const [districtProfileWidgetData, setDistrictProfileWidgetData] = useState([]);
  const [tasDecisionMatrixData, setTasDecisionMatrixData] = useState([]);
  const [districtTasProfileData, setDistrictTasProfileData] = useState([]);
  const [districtMdaProfileData, setDistrictMdaProfileData] = useState([]);
  const [districtMovedtoData, setDistrictMovedtoData] = useState([]);

  const [profileWidgetRowData, setProfileWidgetRowData] = useState(null);
  const [MDAProfileRowData, setMDAProfileRowData] = useState(null);
  const [getProgressLegends, setGetProgressLegends] = useState([]);
  const [blockNames, setBlockNames] = useState([]);
  const [statergy, setStatergy] = useState();
  const [districtList, setDistrictList] = useState({
    selectedKey: '',
    cards: [],
    countryProfile: [],
    progressStatus: [],
    activity: [],
    districtProfile: [],
    tasProfile: [],
    districtDecision: [],
    dataQuality: [],
    tasDecision: [],
  });
  const commonFilter = {
    Year: globalFilter.Year,
    State: 'Odisha',
    Activity: '',
    Status: '',
    District: '',
    Type: 1,
  };
  const decisionFilter = {
    Year: globalFilter.Year,
    State: '',
    District: '',
    Activity: '',
    Strategy: globalFilter.Strategy,
    Sort: globalFilter.Sort,
  };
  const [filterValues, setFilterValues] = useState({
    DistrictTasProfile: { ...decisionFilter },
    decisionMatrixData: decisionFilter,
    // dataQualityTable: decisionFilter,
    nationalDetails: { ...commonFilter, Strategy: globalFilter.Strategy },
    progressDetails: { ...commonFilter, Strategy: globalFilter.Strategy },
    TasMatrixDetails: {
      Year: globalFilter.Year,
      Sort: globalFilter.Sort,
      Activity: '',
      District: '',
      Status: '',
      State: '',
      Strategy: globalFilter.Strategy,
    },
    ProgressSortType: {
      Sort: globalFilter.Sort,
    },
    dataQualityGraphDetails: {
      Year: globalFilter.Year,
      State: '',
      District: '',
      Activity: '',
      Strategy: globalFilter.Strategy,
    },
    DistrictMdaProfile: {
      // Year: globalFilter.Year,
      Sort: globalFilter.Sort,
      // Activity: '',
      Status: '',
      Strategy: globalFilter.Strategy,
      Block: '',
    },
    DistrictProfile: {
      Year: globalFilter.Year,
      State: '',
      District: '',
      Activity: '',
      Sort: globalFilter.Sort,
      Strategy: globalFilter.Strategy,
    },
    ActivityDetails: {
      Year: globalFilter.Year,
      State: '',
      Activity: '',
      District: '',
      'Calendar Status': '',
      Month: '',
      type: 'state',
      Strategy: globalFilter.Strategy,
    },
    ActivityTableDetails: {
      Activity: '',
      Status: '',
      Sort: globalFilter.Sort,
    },
    dataQualityTable: {
      Sort: globalFilter.Sort,
    },
  });
  console.log(globalFilter, 'globalFilterState');

  const removeDuplicates = (arr) => [...new Set(arr)];
  console.log(MDAProfileRowData, statergy, 'filterValues');
  // eslint-disable-next-line default-param-last
  const districtProfileHeader = (currentYear = new Date().getFullYear(), Strategy) => {
    const rangeCount = currentYear - 2004;
    const YEAR = range(currentYear - 2, currentYear - rangeCount, -1);
    const DistrictProfileHeaderArr = [
      'State',
      'District',
      'Block Count',
      `Current Year Activity (${currentYear})`,
      `Previous Year Activity  (${currentYear - 1})`,
      ...YEAR,
    ];
    if (Strategy === 'District Strategy') {
      DistrictProfileHeaderArr.splice(2, 1);
    }
    setDistrictProfileHeader(DistrictProfileHeaderArr);
  };

  useEffect(() => {
    const fetchData = async () => {
      const url = 'https://outpost.mappls.com/api/security/oauth/token';
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
      };

      const data = new URLSearchParams();
      data.append('grant_type', 'client_credentials');
      data.append(
        'client_id',
        '33OkryzDZsKu_9QAwWqMx1uTNVkRDhd1TIDHT-hFQMbsDLjrMHOED3XVaX066LhTDoj_w75hvyRXRg3FGB6liw==',
      );
      data.append(
        'client_secret',
        'lrFxI-iSEg_i4UCfSGyBKhJaIPayfTu2TNK2lOsbrDYCcsWJ3RfC9oeLamRAESCETWdAZtKH8bj8XcK_HaHAez2c1HgqWR53',
      );

      try {
        const response = await axios.post(url, data, headers);
        console.log(response?.data, 'response');

        setToken(response?.data?.access_token);
      } catch (error) {
        console.error('Error:', error);
        // alert('catch');
      }
    };

    fetchData();
  }, []);

  console.log(token, 'Accesstoken');

  // eslint-disable-next-line default-param-last
  const districtTasProfileHeaders = (currentYear = new Date().getFullYear(), Strategy) => {
    console.log(currentYear, 'currentYear');
    const rangeCount = currentYear - 2004;
    const YEAR = range(currentYear - 2, currentYear - rangeCount, -1);
    const DistrictProfileHeaderArr = [
      'State',
      'District',
      'EU Name',
      'Mapped Blocks',
      `Current Year Activity (${currentYear})`,
      `Previous Year Activity  (${currentYear - 1})`,
      ...YEAR,
    ];
    // if (Strategy === 'District Strategy') {
    //   DistrictProfileHeaderArr.splice(2, 1);
    // }
    setDistrictTasProfileHeader(DistrictProfileHeaderArr);
  };

  useEffect(() => {
    return () => {
      dispatch({
        type: SET_GET_DASHBOARD_FILTER_DATA,
        payload: { data: { ...filterData, state: { initialLoad: true } } },
      });
    };
  }, []);
  console.log(filterData, 'filterData');
  useEffect(() => {
    if (!filterData?.state?.initialLoad) {
      dispatch({ type: NATIONAL_STATES_DASHBOARD_CARD, payload: globalFilter, dashboardType: 'state' });
      dispatch({ type: GET_STATE_CODE });
      dispatch({ type: COUNTRY_PROFILE_MAP, payload: filterValues.nationalDetails, dashboardType: 'state' });
      console.log(filterValues, 'filterValues');
      dispatch({ type: GET_COUNTRY_PROFILE_CHART, payload: filterValues.nationalDetails, dashboardType: 'state' });
      dispatch({
        type: NATIONAL_PROGRESS_STATUSBAR_GRAPH,
        payload: filterValues.progressDetails,
        dashboardType: 'state',
      });
      dispatch({ type: ACTIVITY_CALENDAR, payload: filterValues.ActivityDetails, dashboardType: 'state' });
      dispatch({ type: GET_TAS_DECISION_MATRIX, payload: filterValues.TasMatrixDetails, dashboardType: 'state' });
      dispatch({
        type: GET_STATE_PROGRESS_STATUS,
        payload: { ...filterValues.ProgressSortType, ...filterValues.progressDetails },
      });
      dispatch({ type: GET_DATA_QUALITY_GRAPH, payload: filterValues.dataQualityGraphDetails, dashboardType: 'state' });
      dispatch({
        type: GET_STATE_PROFILE,
        payload: { ...filterValues.ProgressSortType, ...filterValues.progressDetails },
      });
      dispatch({ type: DISTRICT_MDA_PROFILE, payload: filterValues.DistrictMdaProfile });
      dispatch({ type: GET_DISTRICT_PROFILE_WIDGET, payload: filterValues.DistrictProfile });
      dispatch({ type: GET_DISTRICT_TAS_PROFILE, payload: filterValues.DistrictTasProfile });
      dispatch({ type: GET_DISTRICT_MOVEDTO, payload: filterValues.nationalDetails, dashboardType: 'state' });
      dispatch({
        type: STATE_DATA_QUALITY_TABLE,
        payload: { ...filterValues.dataQualityGraphDetails, ...filterValues.dataQualityTable },
      });
      dispatch({ type: GET_DISTRICT_DECISION_MATRIX, payload: filterValues.decisionMatrixData });
      // dispatch({ type: STATE_PROFILE_CHART, payload: filterValues.DistrictProfile });
    }
  }, []);

  useEffect(() => {
    if (!filterData?.state?.initialLoad) {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, []);

  useEffect(() => {
    setDistrictMovedtoData(DistrictMovedToData?.state);
  }, [DistrictMovedToData]);

  useEffect(() => {
    if (countryProfileMap?.state && countryProfileMap?.state.length > 0) {
      const getActivities = [];
      countryProfileMap?.state.map((parentData) => {
        getActivities.push(parentData.type);
        return true;
      });
      setGetMapLegends(removeDuplicates(getActivities));
    } else {
      setGetMapLegends([]);
    }
  }, [countryProfileMap?.state]);

  React.useMemo(() => {
    if (districtList.selectedKey !== 'cards') {
      setDistrictList({ ...districtList, [districtList.selectedKey]: dropdownList.districtData });
    } else if (districtList.selectedKey === 'cards') {
      setDistrictList({
        selectedKey: '',
        cards: dropdownList.districtData,
        countryProfile: dropdownList.districtData,
        progressStatus: dropdownList.districtData,
        activity: dropdownList.districtData,
        districtProfile: dropdownList.districtData,
        tasProfile: dropdownList.districtData,
        districtDecision: dropdownList.districtData,
        dataQuality: dropdownList.districtData,
        tasDecision: dropdownList.districtData,
      });
    }
  }, [dropdownList?.districtData]);

  /**
   * @param {Array} data info
   * @param {string} key key
   */
  const getSelectValue = (data, key) => {
    console.log(key, 'GET KEY');
    console.log(data, 'GET DATA');
    if (key === 'map') {
      var dataValue = data.value;
      console.log(dataValue,"dataValue")
      const fieldName =
        data.fieldName === 'Country Profile Activity' || data.fieldName === 'Country Profile Status'
          ? 'Activity'
          : data.fieldName;
      if (fieldName === 'State') {
        dataValue = data.value !== '' ? data.value : 'Odisha';
      }

      if (fieldName === 'District') {
        // dataValue = data.value !== "" ? data.value :
        //  ((dropdownList.districtData !== undefined && dropdownList.districtData !== null) ? dropdownList.districtData.toString : "");
        dataValue = data.value !== '' ? data.value : '';
      }

      setDistrictMovedtoData([]);
      dispatch({
        type: COUNTRY_PROFILE_MAP,
        payload: { ...filterValues.nationalDetails, [fieldName]: dataValue },
        dashboardType: 'state',
      });

      if (fieldName === 'State') {
        setCheckstates(dataValue);
        setSelectedDistrict('');
      }

      if (fieldName === 'District') {
        setSelectedDistrict(dataValue);
      }

      if (data.fieldName === 'Strategy') {
        setSelectedStrategy(dataValue);
      }

      dispatch({
        type: GET_COUNTRY_PROFILE_CHART,
        payload: { ...filterValues.nationalDetails, [fieldName]: dataValue },
        dashboardType: 'state',
      });

      setFilterValues({
        ...filterValues,
        nationalDetails: { ...filterValues.nationalDetails, [data.fieldName]: dataValue },
      });
      if (data.fieldName === 'Year') setSelectedData({ ...selectedStateYear, [data.fieldName]: dataValue });
      if (data.fieldName === 'State') setSelectedData({ ...selectedStateYear, [data.fieldName]: dataValue });
      dispatch({
        type: GET_DISTRICT_MOVEDTO,
        payload: { ...filterValues.nationalDetails, [fieldName]: dataValue },
        dashboardType: 'state',
      });
    }
    if (key === 'progress') {
      dispatch({
        type: NATIONAL_PROGRESS_STATUSBAR_GRAPH,
        payload: { ...filterValues.ProgressSortType, ...filterValues.progressDetails, [data.fieldName]: data.value },
        dashboardType: 'state',
      });
      dispatch({
        type: GET_STATE_PROFILE,
        payload: { ...filterValues.ProgressSortType, ...filterValues.progressDetails, [data.fieldName]: data.value },
      });
      setFilterValues({
        ...filterValues,
        progressDetails: { ...filterValues.progressDetails, [data.fieldName]: data.value },
      });
    }
    if (key === 'activity') {
      dispatch({
        type: ACTIVITY_CALENDAR,
        payload: { ...filterValues.ActivityDetails, [data.fieldName]: data.value },
        dashboardType: 'state',
      });
      setFilterValues({
        ...filterValues,
        ActivityDetails: { ...filterValues.ActivityDetails, [data.fieldName]: data.value },
      });
    }
    if (key === 'activityCalendarClick') {
      dispatch({
        type: ACTIVITY_CALENDAR_TABLE,
        payload: { ...filterValues.ActivityDetails, ...filterValues.ActivityTableDetails, ...data },
        dashboardType: 'state',
      });
    }
    if (key === 'activityTable') {
      dispatch({
        type: ACTIVITY_CALENDAR_TABLE,
        payload: {
          ...filterValues.ActivityDetails,
          ...filterValues.ActivityTableDetails,
          [data.fieldName]: data.value,
        },
        dashboardType: 'state',
      });
      setFilterValues({
        ...filterValues,
        ActivityTableDetails: {
          ...filterValues.ActivityDetails,
          ...filterValues.ActivityTableDetails,
          [data.fieldName]: data.value,
        },
      });
    }
    if (key === 'tasMatrix') {
      dispatch({
        type: GET_TAS_DECISION_MATRIX,
        payload: { ...filterValues.TasMatrixDetails, [data.fieldName]: data.value },
        dashboardType: 'state',
      });
      setFilterValues({
        ...filterValues,
        TasMatrixDetails: { ...filterValues.TasMatrixDetails, [data.fieldName]: data.value },
      });
    }
    if (key === 'progressSort') {
      dispatch({
        type: GET_STATE_PROFILE,
        payload: { ...filterValues.ProgressSortType, ...filterValues.progressDetails, [data.fieldName]: data.value },
      });
      setFilterValues({
        ...filterValues,
        ProgressSortType: { ...filterValues.ProgressSortType, [data.fieldName]: data.value },
      });
    }
    if (key === 'dataQuality') {
      dispatch({
        type: GET_DATA_QUALITY_GRAPH,
        payload: { ...filterValues.dataQualityGraphDetails, [data.fieldName]: data.value },
        dashboardType: 'state',
      });
      dispatch({
        type: STATE_DATA_QUALITY_TABLE,
        payload: {
          ...filterValues.dataQualityGraphDetails,
          ...filterValues.dataQualityTable,
          [data.fieldName]: data.value,
        },
      });
      console.log(data.value, 'statavalue');
      setFilterValues({
        ...filterValues,
        dataQualityGraphDetails: { ...filterValues.dataQualityGraphDetails, [data.fieldName]: data.value },
      });
    }

    if (key === 'districtMdaProfile') {
      if (data.fieldName === 'Block') {
        dispatch({
          type: GET_DISTRICT_PROFILE_WIDGET_GRAPH,
          payload: {
            district: profileWidgetRowData.district,
            strategy: filterValues.DistrictMdaProfile.Strategy || '',
            [data.fieldName]: data.value,
          },
        });
      }
      dispatch({
        type: DISTRICT_MDA_PROFILE,
        payload: {
          Year: filterValues.DistrictProfile.Year,
          State: profileWidgetRowData.state,
          District: profileWidgetRowData.district,
          Strategy:
            (filterValues.DistrictProfile.Strategy !== '' && filterValues.DistrictProfile.Strategy,
            filterValues.DistrictMdaProfile.Strategy !== '' && filterValues.DistrictMdaProfile.Strategy),
          Sort:
            (filterValues.DistrictProfile.Sort !== '' && filterValues.DistrictProfile.Sort) ||
            (filterValues.DistrictMdaProfile.Sort !== '' && filterValues.DistrictMdaProfile.Sort),
          Block: filterValues.DistrictMdaProfile.Block,
          Status: filterValues.DistrictMdaProfile.Status,
          [data.fieldName]: data.value,
        },
      });
      setFilterValues({
        ...filterValues,
        DistrictMdaProfile: { ...filterValues.DistrictMdaProfile, [data.fieldName]: data.value },
      });
    }
    if (key === 'districtProfile') {
      if (data?.fieldName === 'Strategy') setStatergy(data.value);
      setMDAProfileRowData({});
      // setProfileWidgetRowData(null);
      // if (data.fieldName === 'District') {
      //   dispatch({ type: GET_DISTRICT_MDA_BLOCKS, payload: { [data.fieldName]: data.value } });
      // }
      dispatch({
        type: GET_DISTRICT_PROFILE_WIDGET,
        payload: { ...filterValues.DistrictProfile, [data.fieldName]: data.value },
      });
      setFilterValues({
        ...filterValues,
        DistrictProfile: { ...filterValues.DistrictProfile, [data.fieldName]: data.value },
        DistrictMdaProfile: {
          ...filterValues.DistrictMdaProfile,
          ...filterValues.DistrictProfile,
          [data.fieldName]: data.value,
        },
      });
    }
    if (key === 'districtTasProfile') {
      dispatch({
        type: GET_DISTRICT_TAS_PROFILE,
        payload: { ...filterValues.DistrictTasProfile, [data.fieldName]: data.value },
      });
      console.log(data.value, 'data.value ');
      setFilterValues({
        ...filterValues,
        DistrictTasProfile: { ...filterValues.DistrictTasProfile, [data.fieldName]: data.value },
        DistrictMdaProfile: {
          ...filterValues.DistrictMdaProfile,
          ...filterValues.DistrictTasProfile,
          [data.fieldName]: data.value,
        },
      });
    }
    if (key === 'districtDecisionMatrix') {
      dispatch({
        type: GET_DISTRICT_DECISION_MATRIX,
        payload: { ...filterValues.decisionMatrixData, [data.fieldName]: data.value },
      });
      setFilterValues({
        ...filterValues,
        decisionMatrixData: { ...filterValues.decisionMatrixData, [data.fieldName]: data.value },
      });
    }
    if (key === 'dataQualityTable') {
      dispatch({
        type: STATE_DATA_QUALITY_TABLE,
        payload: {
          ...filterValues.dataQualityGraphDetails,
          ...filterValues.dataQualityTable,
          [data.fieldName]: data.value,
        },
      });
      setFilterValues({
        ...filterValues,
        dataQualityTable: { ...filterValues.dataQualityTable, [data.fieldName]: data.value },
      });
    }
    if (key === 'cards') {
      setDistrictMovedtoData([]);
      setFilterValues({
        ...filterValues,
        nationalDetails: { ...filterValues.nationalDetails, [data.fieldName]: data.value },
        progressDetails: { ...filterValues.progressDetails, [data.fieldName]: data.value },
        ActivityDetails: { ...filterValues.ActivityDetails, [data.fieldName]: data.value },
        TasMatrixDetails: { ...filterValues.TasMatrixDetails, [data.fieldName]: data.value },
        DistrictProfile: { ...filterValues.DistrictProfile, [data.fieldName]: data.value },
        DistrictMdaProfile: { ...filterValues.DistrictMdaProfile, [data.fieldName]: data.value },
        decisionMatrixData: { ...filterValues.decisionMatrixData, [data.fieldName]: data.value },
        dataQualityGraphDetails: { ...filterValues.dataQualityGraphDetails, [data.fieldName]: data.value },
        DistrictTasProfile: { ...filterValues.DistrictTasProfile, [data.fieldName]: data.value },
      });
      // if (data.fieldName !== 'Year') {
      //   setFilterValues((prev) => ({
      //     ...prev,
      //     DistrictTasProfile: { ...prev.DistrictTasProfile, [data.fieldName]: data.value },
      //   }));
      // }
      dispatch({
        type: NATIONAL_STATES_DASHBOARD_CARD,
        payload: { ...globalFilter, [data.fieldName]: data.value },
        dashboardType: 'state',
      });
      dispatch({
        type: COUNTRY_PROFILE_MAP,
        payload: { ...filterValues.nationalDetails, [data.fieldName]: data.value },
        dashboardType: 'state',
      });
      dispatch({
        type: GET_COUNTRY_PROFILE_CHART,
        payload: { ...filterValues.nationalDetails, [data.fieldName]: data.value },
        dashboardType: 'state',
      });
      dispatch({
        type: NATIONAL_PROGRESS_STATUSBAR_GRAPH,
        payload: { ...filterValues.progressDetails, [data.fieldName]: data.value },
        dashboardType: 'state',
      });
      dispatch({
        type: ACTIVITY_CALENDAR,
        payload: { ...filterValues.ActivityDetails, [data.fieldName]: data.value },
        dashboardType: 'state',
      });
      dispatch({
        type: GET_TAS_DECISION_MATRIX,
        payload: { ...filterValues.TasMatrixDetails, [data.fieldName]: data.value },
        dashboardType: 'state',
      });
      dispatch({
        type: GET_STATE_PROGRESS_STATUS,
        payload: { ...filterValues.ProgressSortType, ...filterValues.progressDetails, [data.fieldName]: data.value },
      });
      dispatch({
        type: GET_DATA_QUALITY_GRAPH,
        payload: { ...filterValues.dataQualityGraphDetails, [data.fieldName]: data.value },
        dashboardType: 'state',
      });
      dispatch({
        type: GET_STATE_PROFILE,
        payload: { ...filterValues.ProgressSortType, ...filterValues.progressDetails, [data.fieldName]: data.value },
      });
      dispatch({
        type: GET_DISTRICT_PROFILE_WIDGET,
        payload: { ...filterValues.DistrictProfile, [data.fieldName]: data.value },
      });
      if (data.fieldName !== 'Year') {
        dispatch({
          type: GET_DISTRICT_TAS_PROFILE,
          payload: { ...filterValues.DistrictTasProfile, [data.fieldName]: data.value },
        });
      }
      dispatch({
        type: GET_DISTRICT_MOVEDTO,
        payload: { ...filterValues.nationalDetails, [data.fieldName]: data.value },
        dashboardType: 'state',
      });
      dispatch({
        type: STATE_DATA_QUALITY_TABLE,
        payload: {
          ...filterValues.dataQualityGraphDetails,
          ...filterValues.dataQualityTable,
          [data.fieldName]: data.value,
        },
      });
      dispatch({
        type: GET_DISTRICT_DECISION_MATRIX,
        payload: { ...filterValues.decisionMatrixData, [data.fieldName]: data.value },
      });
      setSelectedData({ ...globalFilter, [data.fieldName]: data.value });
      setGlobalFilter({ ...globalFilter, [data.fieldName]: data.value });
    }
  };
  /**
   * @param {number} stateId selected state id
   * @param {string} key selected state id
   */
  const getDistrictList = (stateId, key) => {
    dispatch({ type: GET_DISTRICT_DROPDOWN, payload: { state: stateId } });
    districtList.selectedKey = key;
  };

  /**
   * @param {object} rowData selected row
   */
  const onClickDistrictProfileData = (rowData) => {
    setProfileWidgetRowData(rowData);
    dispatch({
      type: DISTRICT_MDA_PROFILE,
      payload: {
        ...filterValues.DistrictMdaProfile,
        ...filterValues.DistrictProfile,
        District: rowData.district || '',
        State: rowData.state || '',
      },
    });
    dispatch({
      type: STATE_PROFILE_CHART,
      payload: { ...filterValues.DistrictProfile, District: rowData.district },
    });
    // dispatch({ type: GET_DISTRICT_MDA_BLOCKS, payload: { District: rowData.district || '' } });
  };

  React.useMemo(() => {
    if (districtProfileWidget && districtProfileWidget.length > 0) {
      const districtProfileWidgetTooltip = districtProfileWidget.map((data) => {
        return {
          ...data,
          district: (
            <div className="hyperlink" onClick={() => onClickDistrictProfileData(data)}>
              {data?.district}
            </div>
          ),
          site_names:
            data?.site_names !== 'NA' ? (
              <Tooltip
                title={<TooltipTitle contentTitle="Block Names" districts={data?.site_names?.split(',')} />}
                placement="right"
                className="toolHeader"
              >
                <div>{data?.site_names?.split(',').length}</div>
              </Tooltip>
            ) : (
              data?.site_names
            ),
        };
      });
      setDistrictProfileWidgetData(districtProfileWidgetTooltip);
      // dispatch items
      dispatch({
        type: DISTRICT_MDA_PROFILE,
        payload: {
          ...filterValues.DistrictProfile,
          State: districtProfileWidget?.[0]?.state || filterValues.DistrictProfile.State,
          District: districtProfileWidget?.[0]?.district || filterValues.DistrictProfile.District,
          Block: '',
        },
      });
      // dispatch({
      //   type: GET_DISTRICT_MDA_BLOCKS,
      //   payload: { District: districtProfileWidget?.[0]?.district || filterValues.DistrictProfile.District },
      // });
      dispatch({
        type: STATE_PROFILE_CHART,
        payload: { ...filterValues.DistrictProfile, District: districtProfileWidget?.[0]?.district },
      });
      setProfileWidgetRowData({
        district: districtProfileWidget?.[0]?.district || filterValues.DistrictProfile.District,
        site_name: districtProfileWidget?.[0]?.site_name || '',
        state: districtProfileWidget?.[0]?.state || filterValues.DistrictProfile.State,
      });

      districtProfileHeader(
        filterValues.DistrictProfile.Year === '' ? undefined : filterValues.DistrictProfile.Year,
        filterValues.DistrictProfile.Strategy,
      );
    } else {
      setDistrictProfileWidgetData(districtProfileWidget);
    }
  }, [districtProfileWidget, districtTasProfile]);

  React.useMemo(() => {
    if (tasDecisionMatrix?.state && tasDecisionMatrix?.state.length > 0) {
      const tasDecisionMatrixTooltip = tasDecisionMatrix?.state.map((data) => {
        return {
          ...data,
          site_names:
            data?.site_names !== 'NA' ? (
              <Tooltip
                title={<TooltipTitle contentTitle="Block Names" districts={data?.site_names?.split(',')} />}
                placement="right"
                className="toolHeader"
              >
                <div>{data?.site_names?.split(',').length}</div>
              </Tooltip>
            ) : (
              data?.site_names
            ),
        };
      });
      setTasDecisionMatrixData(tasDecisionMatrixTooltip);
    } else {
      setTasDecisionMatrixData(tasDecisionMatrix?.state);
    }
  }, [tasDecisionMatrix?.state]);

  React.useMemo(() => {
    console.log(filterValues.DistrictTasProfile, 'sldkfjier');
    if (districtTasProfile && districtTasProfile.length > 0) {
      const districtDecisionMatrixTooltip = districtTasProfile.map((data) => {
        return {
          ...data,
          site_names:
            data?.site_names !== 'NA' ? (
              <Tooltip
                title={<TooltipTitle contentTitle="Block Names" districts={data?.site_names?.split(',')} />}
                placement="right"
                className="toolHeader"
              >
                <div>{data?.site_names?.split(',').length}</div>
              </Tooltip>
            ) : (
              data?.site_names
            ),
        };
      });
      districtTasProfileHeaders(
        filterValues.DistrictTasProfile.Year === '' ? undefined : filterValues.DistrictTasProfile.Year,
        filterValues.DistrictTasProfile.Strategy,
      );
      setDistrictTasProfileData(districtDecisionMatrixTooltip);
    } else {
      setDistrictTasProfileData(districtTasProfile);
    }
  }, [districtTasProfile]);

  const onClickMdaProfileData = (data) => {
    dispatch({
      type: GET_DISTRICT_PROFILE_WIDGET_GRAPH,
      payload: {
        district: profileWidgetRowData.district,
        block_name: data.block,
        strategy: filterValues.DistrictMdaProfile.Strategy || '',
      },
    });
    setMDAProfileRowData(data);
  };

  React.useMemo(() => {
    if (districtMdaProfile && districtMdaProfile.length > 0) {
      const blockList = [];
      const districtDecisionMatrixTooltip = districtMdaProfile.map((data) => {
        blockList.push({ id: data.mda_sites_blocks_id, value: data.block });
        return {
          ...data,
          block: (
            <div className="hyperlink" onClick={() => onClickMdaProfileData(data)}>
              {data?.block || '-'}
            </div>
          ),
        };
      });
      setDistrictMdaProfileData(districtDecisionMatrixTooltip);
      setBlockNames(blockList);
      if (districtMdaProfile && districtMdaProfile.length > 0 && profileWidgetRowData?.district) {
        const obj = districtMdaProfile.find((o) => o.block !== '' && o.block != null);
        if (obj) {
          setMDAProfileRowData(obj);
          dispatch({
            type: GET_DISTRICT_PROFILE_WIDGET_GRAPH,
            payload: {
              district: profileWidgetRowData.district,
              block_name: obj.block,
              strategy: filterValues.DistrictMdaProfile.Strategy || '',
            },
          });
        }
      }
    } else {
      setDistrictMdaProfileData(districtMdaProfile);
      setBlockNames([]);
    }
  }, [districtMdaProfile]);
  const datass = [
    {
      arcs: [
        [[0]],
        [[1]],
        [[2]],
        [[3]],
        [[4]],
        [[5]],
        [[6]],
        [[7]],
        [[8]],
        [[9]],
        [[10]],
        [[11]],
        [[12]],
        [[13]],
        [[14]],
        [[15]],
        [[16]],
        [[17]],
        [[18]],
        [[19]],
        [[20]],
        [[21]],
        [[22]],
        [[23]],
        [[24]],
        [[25]],
        [[26]],
        [[27]],
        [[28]],
        [[29]],
        [[30]],
        [[31]],
        [[32]],
        [[33]],
        [[34]],
        [[35]],
        [[36]],
        [[37]],
        [[38]],
        [[39]],
        [[40]],
        [[41]],
        [[42]],
        [[43]],
        [[44]],
        [[45]],
        [[46]],
        [[47]],
        [[48]],
        [[49]],
        [[50]],
        [[51]],
        [[52]],
        [[53]],
        [[54]],
        [[55]],
        [[56, 57, 58, 59, 60]],
        [[61]],
        [[62]],
        [[63]],
        [[64]],
        [[65]],
        [[66]],
        [[67]],
        [[68]],
        [[69]],
        [[70]],
        [[71]],
        [[72]],
        [[73]],
        [[74]],
        [[75]],
        [[76]],
        [[77]],
        [[78]],
        [[79]],
        [[80]],
      ],
      type: 'MultiPolygon',
      properties: {
        dt_code: '343',
        district: 'South 24 Parganas',
        st_nm: 'West Bengal',
        st_code: '19',
        year: '2011_c',
      },
    },
    {
      arcs: [[81, 82, 83, 84, 85, 86]],
      type: 'Polygon',
      properties: {
        dt_code: '344',
        district: 'Paschim Medinipur',
        st_nm: 'West Bengal',
        st_code: '19',
        year: '2011_c',
      },
    },
    {
      arcs: [[-84, 87, 88]],
      type: 'Polygon',
      properties: {
        dt_code: '345',
        district: 'Purba Medinipur',
        st_nm: 'West Bengal',
        st_code: '19',
        year: '2011_c',
      },
    },
    {
      arcs: [[-86, 89, 90, 91]],
      type: 'Polygon',
      properties: {
        dt_code: '776',
        district: 'Jhargram',
        st_nm: 'West Bengal',
        st_code: '19',
        year: '2017_c',
      },
    },
    {
      arcs: [[92, 93, 94, 95, 96, 97]],
      type: 'Polygon',
      properties: {
        dt_code: '327',
        district: 'Darjeeling',
        st_nm: 'West Bengal',
        st_code: '19',
        year: '2011_c',
      },
    },
    {
      arcs: [[98, 99, 100, 101, 102, -94]],
      type: 'Polygon',
      properties: {
        dt_code: '328',
        district: 'Jalpaiguri',
        st_nm: 'West Bengal',
        st_code: '19',
        year: '2011_c',
      },
    },
    {
      arcs: [[103, 104, 105, 106]],
      type: 'Polygon',
      properties: {
        dt_code: '331',
        district: 'Dakshin Dinajpur',
        st_nm: 'West Bengal',
        st_code: '19',
        year: '2011_c',
      },
    },
    {
      arcs: [[-106, 107, 108, 109, 110]],
      type: 'Polygon',
      properties: {
        dt_code: '332',
        district: 'Malda',
        st_nm: 'West Bengal',
        st_code: '19',
        year: '2011_c',
      },
    },
    {
      arcs: [[-109, 111, 112, 113, 114, 115]],
      type: 'Polygon',
      properties: {
        dt_code: '333',
        district: 'Murshidabad',
        st_nm: 'West Bengal',
        st_code: '19',
        year: '2011_c',
      },
    },
    {
      arcs: [[-115, 116, 117, 118]],
      type: 'Polygon',
      properties: {
        dt_code: '334',
        district: 'Birbhum',
        st_nm: 'West Bengal',
        st_code: '19',
        year: '2011_c',
      },
    },
    {
      arcs: [[-113, 119, 120, 121, 122]],
      type: 'Polygon',
      properties: {
        dt_code: '336',
        district: 'Nadia',
        st_nm: 'West Bengal',
        st_code: '19',
        year: '2011_c',
      },
    },
    {
      arcs: [[-114, -123, 123, 124, 125, -117]],
      type: 'Polygon',
      properties: {
        dt_code: '335',
        district: 'Purba Bardhaman',
        st_nm: 'West Bengal',
        st_code: '19',
        year: '2011_c',
      },
    },
    {
      arcs: [[126, 127, -91, 128]],
      type: 'Polygon',
      properties: {
        dt_code: '340',
        district: 'Purulia',
        st_nm: 'West Bengal',
        st_code: '19',
        year: '2011_c',
      },
    },
    {
      arcs: [[-128, 129, -125, 130, -87, -92]],
      type: 'Polygon',
      properties: {
        dt_code: '339',
        district: 'Bankura',
        st_nm: 'West Bengal',
        st_code: '19',
        year: '2011_c',
      },
    },
    {
      arcs: [[-60, 131, 132, 133, -121, 134]],
      type: 'Polygon',
      properties: {
        dt_code: '337',
        district: 'North 24 Parganas',
        st_nm: 'West Bengal',
        st_code: '19',
        year: '2011_c',
      },
    },
    {
      arcs: [[-82, -131, -124, -122, -134, 135]],
      type: 'Polygon',
      properties: {
        dt_code: '338',
        district: 'Hooghly',
        st_nm: 'West Bengal',
        st_code: '19',
        year: '2011_c',
      },
    },
    {
      arcs: [[-58, 136, -88, -83, -136, -133, 137]],
      type: 'Polygon',
      properties: {
        dt_code: '341',
        district: 'Howrah',
        st_nm: 'West Bengal',
        st_code: '19',
        year: '2011_c',
      },
    },
    {
      arcs: [[-59, -138, -132]],
      type: 'Polygon',
      properties: {
        dt_code: '342',
        district: 'Kolkata',
        st_nm: 'West Bengal',
        st_code: '19',
        year: '2011_c',
      },
    },
    {
      arcs: [[-101, 138, 139]],
      type: 'Polygon',
      properties: {
        dt_code: '774',
        district: 'Alipurduar',
        st_nm: 'West Bengal',
        st_code: '19',
        year: '2014_c',
      },
    },
    {
      arcs: [[-118, -126, -130, -127, 140]],
      type: 'Polygon',
      properties: {
        dt_code: '777',
        district: 'Paschim Bardhaman',
        st_nm: 'West Bengal',
        st_code: '19',
        year: '2017_c',
      },
    },
    {
      arcs: [[-93, -98, 141, -99]],
      type: 'Polygon',
      properties: {
        dt_code: '775',
        district: 'Kalimpong',
        st_nm: 'West Bengal',
        st_code: '19',
        year: '2017_c',
      },
    },
    {
      arcs: [
        [[142]],
        [[143]],
        [[144]],
        [[145]],
        [[146]],
        [[147]],
        [[148]],
        [[149]],
        [[150]],
        [[151]],
        [[152]],
        [[153]],
        [[154]],
        [[155]],
        [[156]],
        [[157]],
        [[158]],
        [[159]],
        [[160]],
        [[161]],
        [[162]],
        [[163]],
        [[164]],
        [[165]],
        [[166]],
        [[167]],
        [[168]],
        [[169]],
        [[170]],
        [[171]],
        [[172]],
        [[173]],
        [[174]],
        [[175]],
        [[176]],
        [[177]],
        [[178]],
        [[179]],
        [[180]],
        [[181]],
        [[182]],
        [[183]],
        [[184]],
        [[185]],
        [[-102, -140, 186]],
      ],
      type: 'MultiPolygon',
      properties: {
        dt_code: '329',
        district: 'Cooch Behar',
        st_nm: 'West Bengal',
        st_code: '19',
        year: '2011_c',
      },
    },
    {
      arcs: [[187, -104, -107, -111, 188, -96]],
      type: 'Polygon',
      properties: {
        dt_code: '330',
        district: 'Uttar Dinajpur',
        st_nm: 'West Bengal',
        st_code: '19',
        year: '2011_c',
      },
    },
  ];
  const arr = [];
  console.log(arr, 'skjdfusr');
  datass.forEach((item) => {
    arr.push(item?.properties?.district);
  });
  // useEffect(() => {
  //   if (districtMdaProfile && districtMdaProfile.length > 0) {
  //     const obj = districtMdaProfile.find((o) => o.block !== '' && o.block != null);
  //     if (obj) {
  //       setMDAProfileRowData(obj);
  //       dispatch({
  //         type: GET_DISTRICT_PROFILE_WIDGET_GRAPH,
  //         payload: {
  //           district: profileWidgetRowData.district,
  //           block_name: obj.block,
  //           strategy: filterValues.DistrictMdaProfile.Strategy || '',
  //         },
  //       });
  //     }
  //   }
  // }, [districtMdaProfile]);

  useEffect(() => {
    if (getCountryProfileChart?.state && getCountryProfileChart?.state.length > 0) {
      const getActivities = [];
      console.log(getActivities, 'getActiviqqties');

      getCountryProfileChart?.state.map((parentData) => {
        parentData.data.map((childData) => {
          if (childData.value && Number(childData.value) !== 0) {
            getActivities.push(childData.name);
          }
          return true;
        });
        return true;
      });
      setGetProgressLegends(removeDuplicates(getActivities));
    } else {
      setGetProgressLegends([]);
    }
  }, [getCountryProfileChart?.state]);

  if (loading) return <Loader />;
  console.log(districtProfileWidgetData, 'lsljdffseet');

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
  };
  console.log(vr, 'countryProfileMap20');
  console.log(countryProfileMap, 'countryProfileMap21');

  return (
    <Grid style={{ display: showDisplay ? '' : 'none' }}>
      {/* <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <Grid item xs={12} className="title-national-dash" direction="row" justifyContent="space-between" container>
        <Grid item xs={7}>
          <Typography text="State Dashboard" type="subHeading" customStyle="" />
        </Grid>
        <Grid item xs={5} sm={12} direction="row" justifyContent="flex-end" className="fileGerator">
          <FileGenerator
            DropdownList={['Year', 'State', 'Strategy']}
            DropdownSelectedValues={globalFilter}
            showExporter={false}
            districtDropdown={districtList.cardsitem?.typ}
            getDistrictList={(stateId) => getDistrictList(stateId, 'cards')}
            getSelectValue={(data) => getSelectValue(data, 'cards')}
          />
          <label className="Note">Note: Block strategy has been initiated from 2022</label>
        </Grid>
      </Grid>
      <Grid container className="GridView">
        {nationalStateDashboardCard?.state?.map((item, index) => {
          const key = index;
          return (
            <Grid item xs={12} md={3} sm={4} className="gridWidth">
              <DashboardCard
                key={key}
                firstValue={item?.count}
                firstContent={item?.name}
                secondValue={item?.population_at_risk}
                secondContent={item?.type}
                cardTitle={globalFilter.Year}
                backgroundColor={item?.type}
                blockContent={item?.block_name}
                blockValue={item?.block_count}
                // districtPassContent={item.Districts_pass_Name}
                // districtPassValue={item.Districts_pass_Count}
                districtPassContent={item.Districts_pass_Name}
                districtPassValue={item.Districts_pass_Count}
                districtFailContent={item.Districts_fail_Name}
                districtFailValue={item.Districts_fail_Count}
                districtPendingContent={item.Districts_pending_Name}
                districtPendingValue={item.Districts_pending_Count}
                stateView
                NationalDashboard
              />
            </Grid>
          );
        })}
      </Grid>
      <Grid item md={12} xs={12} sm={12} className="section-bar">
        <DynChartBlock
          title="State Profile"
          labelText="LF Endemicity Map"
          progressBarGraph={
            <StackedBarLineChart
              LineKeys={[{ label: 'New Endemic District', color: '#2d99c5' }]}
              BarKeys={[
                { label: 'Endemic Districts yet to reach TAS status', color: '#fd6767' },
                { label: 'TAS 1 Cleared', color: '#48e1fe' },
                { label: '', color: 'white' },
              ]}
              XKey="years"
              title="District Moved to TAS Vs Total Endemic District"
              chartData={districtMovedtoData}
              XLabel="Years"
              Y1Label="District Count"
              Y2Label="New Endemic District"
              Legends
              LineValuePosition="right"
              BarValuePosition="center"
            />
          }
          progressLine={12}
          table={false}
          getSelectValue={(data) => getSelectValue(data, 'map')}
          DropdownList={['Year', 'State', 'District', 'Strategy', 'Country Profile Activity', 'Country Profile Status']}
          ChartDropdownSelectedValues={filterValues.nationalDetails}
          chartDatas={getCountryProfileChart?.state}
          districtDropdown={districtList.countryProfile}
          getDistrictList={(stateId) => getDistrictList(stateId, 'countryProfile')}
          progressChartType="state"
          getProgressLegends={getProgressLegends}
          strategyType={filterValues.nationalDetails.Strategy}
        >
          {/* <DistrictMap
            activityStatusArr={countryProfileMap?.state}
            individualState={getStateCode(stateCode, selectedStateYear?.State)}
            stateInfo={
              selectedStateYear.State &&
              selectedStateYear.Year &&
              `${selectedStateYear.State} ${selectedStateYear.Year}`
            }
            getMapLegends={getMapLegends}
            // activityInfo={selectedStateYear.Year && `Activity due in ${selectedStateYear.Year}`}
          /> */}

          {/* <DropDown selectedState={selectedState} stateOptions={stateOptions} handleStateChange={handleStateChange} /> */}

          <MapComponent
            selectedState={
              checkstates !== undefined && checkstates !== null && checkstates !== '' ? checkstates : 'Odisha'
            }
            stateCoordinates={stateCoordinates}
            token={token}
            selectedStateYear={selectedStateYear}
            selectedDistrict={selectedDistrict}
            selectedStrategy={selectedStrategy}
            countryProfileMap={countryProfileMap}
          />
        </DynChartBlock>
      </Grid>

      <Grid item md={12} xs={12} sm={12}>
        <CommonTitledTable
          title="District Decision Matrix"
          tableSortList={['Year', 'State', 'District', 'Activity', 'Strategy', 'Sort']}
          TableDropdownSelectedValues={filterValues.decisionMatrixData}
          tableContent={districtDecisionMatrix}
          tableHeader={
            filterValues?.decisionMatrixData?.Strategy === 'Block Strategy'
              ? BlockDecisionHeader
              : DistrictDecisionHeader
          }
          getSelectValue={(data) => getSelectValue(data, 'districtDecisionMatrix')}
          hideKeyFields={['nextYearPopulation']}
          districtDropdown={districtList.districtDecision}
          getDistrictList={(stateId) => getDistrictList(stateId, 'districtDecision')}
        />
      </Grid>

      <Grid item md={12} xs={12} sm={12}>
        <DynChartBlock
          title="Progress Status"
          tableContent={StateProfile} // Changed by sriram suggestion
          tableHeader={
            filterValues?.progressDetails?.Strategy === 'Block Strategy' ? BlockTableHeader : DistrictTableHeader
          }
          tableSortList={['Sort']}
          getTableSelect={(data) => getSelectValue(data, 'progressSort')}
          getSelectValue={(data) => getSelectValue(data, 'progress')}
          DropdownList={['Year', 'State', 'District', 'Activity', 'Strategy']}
          ChartDropdownSelectedValues={filterValues.progressDetails}
          TableDropdownSelectedValues={filterValues.ProgressSortType}
          districtDropdown={districtList.progressStatus}
          getDistrictList={(stateId) => getDistrictList(stateId, 'progressStatus')}
        >
          <StackedBarLineChart
            BarKeys={[
              { label: 'Completed', color: '#348FDC' },
              // { label: 'Delayed', color: '#C9CAC9' },
              { label: 'Pending', color: '#FD6767' },
            ]}
            XKey="label"
            chartData={nationalStateProgressStatusbar?.state}
            XLabel="Program Activity"
            Y1Label="Status"
            BarValuePosition="insideTop"
            Legends
            percent
            stacked
          />
        </DynChartBlock>
      </Grid>
      <Grid item md={12} xs={12} sm={12}>
        <HeaderSection label="Activity Calendar" />
        <ActivityCalendar
          tableSortList={['Sort', 'Activity']}
          tableContent={activityCalendarTable?.state}
          tableHeader={activityTableHead}
          legends={activityLegends?.calendarLegends && JSON.parse(JSON.stringify(activityLegends?.calendarLegends))}
          calendarHeader={activityHeaderState}
          calendarData={activityCalendar?.state}
          getSelectValue={(data, ele) => getSelectValue(data, ele)}
          onCalenderClick={(data, ele) => getSelectValue(data, ele)}
          getTableSelectValue={(data, ele) => getSelectValue(data, ele)}
          DropdownList={['Year', 'Month', 'State', 'District', 'Activity', 'Calendar Status', 'Strategy']}
          ChartDropdownSelectedValues={filterValues.ActivityDetails}
          districtDropdown={districtList.activity}
          getDistrictList={(stateId) => getDistrictList(stateId, 'activity')}
        />
      </Grid>

      <Grid item md={12} xs={12} sm={12} sx={{ paddingBottom: 3 }}>
        <CommonTitledTable
          title="District Profile"
          tableSortList={['Year', 'State', 'District', 'Sort', 'Activity', 'Strategy']} // removed year filter for UAT purpose
          TableDropdownSelectedValues={filterValues.DistrictProfile}
          tableContent={districtProfileWidgetData}
          tableHeader={DistrictProfileHeader}
          getSelectValue={(data) => getSelectValue(data, 'districtProfile')}
          // rowClick={(data) => {
          //   setProfileWidgetRowData(data);
          //   dispatch({
          //     type: GET_DISTRICT_PROFILE_WIDGET_GRAPH,
          //     payload: { district: data.district, block_name: data.site_name },
          //   });
          //   dispatch({
          //     type: DISTRICT_MDA_PROFILE,
          //     payload: { ...filterValues.DistrictMdaProfile, District: data.district || '', State: data.state || '' },
          //   });
          //   dispatch({ type: GET_DISTRICT_MDA_BLOCKS, payload: { District: data.district || '' } });
          // }}
          hideKeyFields={filterValues.DistrictProfile.Strategy === 'District Strategy' ? ['site_names'] : []}
          districtDropdown={districtList.districtProfile}
          getDistrictList={(stateId) => getDistrictList(stateId, 'districtProfile')}
        />

        <Grid container md={12} xs={12} sm={12}>
          {/* <Grid item md={6} xs={12} sm={12}>
            <StackedBarLineChart
              BarKeys={[{ label: 'value', color: '#348FDC' }]}
              XKey="name"
              title={profileWidgetRowData ? `${profileWidgetRowData.district} District, MDA Coverage` : ''}
              chartData={districtProfileWidget?.length > 0 ? districtProfileWidgetGraph?.mda_district_coverage : []}
              percent
              Y1Label="MDA Coverage"
              XLabel="Years"
            />
          </Grid>
          <Grid item md={6} xs={12} sm={12}>
            <LineGraph
              chartData={districtProfileWidget?.length > 0 ? districtProfileWidgetGraph?.mda_district_mf_rate : []}
              Xkey="name"
              title={profileWidgetRowData ? `${profileWidgetRowData.district} District, Mf Rate` : ''}
              XGridLine={false}
              Y1Label="Mf Rate"
            />
          </Grid> */}

          <BarLineChart
            chartData={stateProfileChart}
            LineKeys={[
              { label: 'No. of sites with > 1mF rate', axis: 1, color: '#ff715b' },
              { label: 'Pre-TAS', axis: 1, color: '#2C992D' },
              { label: 'TAS1', axis: 1, color: '#b65bfc' },
              { label: 'TAS2', axis: 1, color: '#FED700' },
              { label: 'TAS3', axis: 1, color: '#5C4033' },
            ]}
            XKey="years"
            barKey="MDA Coverage"
            selectedLabel={`- ${filterValues.DistrictProfile.District || profileWidgetRowData?.district || ''}`}
            title="District wise Progress"
            Y1Label="MDA Coverage Percentage"
            Y2Label="No. of Districts"
            percent
          />
        </Grid>
      </Grid>

      <Grid item md={12} xs={12} sm={12}>
        <CommonTitledTable
          title="MDA Profile"
          getSelectValue={(data) => getSelectValue(data, 'districtMdaProfile')}
          tableSortList={['Sort', 'Block', 'Status', 'Strategy']}
          TableDropdownSelectedValues={filterValues.DistrictMdaProfile}
          tableContent={districtMdaProfileData}
          tableHeader={
            filterValues?.DistrictProfile?.Strategy === 'District Strategy' ? DistrictMDAHeaderDis : DistrictMDAHeader
          }
          subTitle={profileWidgetRowData ? `${profileWidgetRowData.district}  District Profile` : ''}
          blockDropdown={blockNames}
          hideKeyFields={['mda_sites_blocks_id']}
        />

        <Grid container md={12} xs={12} sm={12} sx={{ paddingBottom: 3 }}>
          <Grid item md={6} xs={12} sm={12}>
            {/* <StackedBarLineChart
              BarKeys={[{ label: 'value', color: '#348FDC' }]}
              XKey="name"
              title={`${
                filterValues.DistrictMdaProfile?.Strategy === 'District Strategy'
                  ? profileWidgetRowData?.district
                  : filterValues.DistrictMdaProfile?.Block !== ''
                  ? filterValues.DistrictMdaProfile?.Block
                  : MDAProfileRowData && MDAProfileRowData?.block
                  ? MDAProfileRowData?.block
                  : ''
              } ${
                filterValues.DistrictMdaProfile?.Strategy === 'District Strategy' ? 'District' : 'Block'
              }, MDA Coverage`}
              chartData={districtMdaProfile?.length > 0 ? districtProfileWidgetGraph?.mda_block_coverage : []}
              percent
              Y1Label="MDA Coverage"
              XLabel="Years"
            /> */}
          </Grid>
          <Grid item md={6} xs={12} sm={12}>
            <LineGraph
              chartData={districtMdaProfile?.length > 0 ? districtProfileWidgetGraph?.mda_block_mf_rate : []}
              Xkey="years"
              title={`${
                filterValues.DistrictMdaProfile?.Block !== ''
                  ? filterValues.DistrictMdaProfile?.Block
                  : MDAProfileRowData && MDAProfileRowData?.block
                  ? MDAProfileRowData?.block
                  : ''
              }  ${statergy === 'Block Strategy' ? 'Block, Mf Rate' : 'Site, Mf Rate'}  `}
              legends={false}
              labelKey="percent"
              Y1Label="Mf Rate"
              XLabel="Years"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={12} xs={12} sm={12}>
        <CommonTitledTable
          title="TAS Profile"
          getSelectValue={(data) => getSelectValue(data, 'tasMatrix')}
          tableSortList={['Year', 'State', 'District', 'Activity_PreTAS_TAS', 'Sort', 'Strategy']}
          TableDropdownSelectedValues={filterValues.TasMatrixDetails}
          tableContent={tasDecisionMatrixData}
          tableHeader={decisionMatrixHeader}
          // tableType="mdaCoverage" // UAT changes
          districtDropdown={districtList.tasDecision}
          getDistrictList={(stateId) => getDistrictList(stateId, 'tasDecision')}
          hideKeyFields={['data']} // UAT changes
        />
      </Grid>

      <Grid item md={12} xs={12} sm={12}>
        <CommonTitledTable
          title="EU TAS Profile"
          tableSortList={['Year', 'State', 'District', 'Sort', 'Activity_TAS', 'Strategy']}
          TableDropdownSelectedValues={filterValues.DistrictTasProfile}
          tableContent={districtTasProfileData}
          tableHeader={districtTasProfileHeader}
          getSelectValue={(data) => getSelectValue(data, 'districtTasProfile')}
          districtDropdown={districtList.tasProfile}
          getDistrictList={(stateId) => getDistrictList(stateId, 'tasProfile')}
        />
      </Grid>

      <Grid item md={12} xs={12} sm={12} container direction="row">
        {/* <DynChartBlock
          title="Report Status"
          tableContent={stateDataQuality}
          tableHeader={DataQualityHeader}
          tableSortList={['Sort']}
          getSelectValue={(data) => getSelectValue(data, 'dataQuality')}
          DropdownList={['Year', 'State', 'District', 'Activity', 'Strategy']}
          ChartDropdownSelectedValues={filterValues.dataQualityGraphDetails}
          TableDropdownSelectedValues={filterValues.dataQualityTable}
          districtDropdown={districtList.dataQuality}
          getDistrictList={(stateId) => getDistrictList(stateId, 'dataQuality')}
          getTableSelect={(data) => getSelectValue(data, 'dataQualityTable')}
        >
          <GroupBarChart barData={dataQualityGraph?.state} />
        </DynChartBlock> */}
      </Grid>
    </Grid>
  );
};
StateDashboard.propTypes = {
  showDisplay: PropTypes.bool.isRequired,
};
export default React.memo(StateDashboard);