export const thData = [
  'S.No',
  'State',
  'District',
  'Strategy',
  'Date Proposed',
  'Date Planned',
  'Date completed',
  // 'Covered Population',
  'Total Population',
  // 'BlockName',
  'Test Type',
  'NBS Survey Date',
  // 'S1 Name',
  // 'BSE S1',
  // 'Positive S1',
  'BlockName',
  'R1 Name',
  'BSE R1',
  'Positive R1',
  'BlockName',
  // 'Test Type',
  // 'NBS Survey Date',
  // 'S2 Name',
  // 'BSE S2',
  // 'Positive S2',
  'R2 Name',
  'BSE R2',
  'Positive R2',
  'BlockName',
  // 'Test Type',
  // 'NBS Survey Date',
  // 'S3 Name',
  // 'BSE S3',
  // 'Positive S3',
  'R3 Name',
  'BSE R3',
  'Positive R3',
  'BlockName',
  // 'Test Type',
  // 'NBS Survey Date',
  // 'S4 Name',
  // 'BSE S4',
  // 'Positive S4',
  'R4 Name',
  'BSE R4',
  'Positive R4',
  'BlockName',
  // 'Test Type',
  // 'NBS Survey Date',
  // 'S5 Name',
  // 'BSE S5',
  // 'Positive S5',
  'R5 Name',
  'BSE R5',
  'Positive R5',
  'BlockName',
  // 'Test Type',
  // 'NBS Survey Date',
  // 'S6 Name',
  // 'BSE S6',
  // 'Positive S6',
  'R6 Name',
  'BSE R6',
  'Positive R6',
  'BlockName',
  // 'Test Type',
  // 'NBS Survey Date',
  // 'S7 Name',
  // 'BSE S7',
  // 'Positive S7',
  'R7 Name',
  'BSE R7',
  'Positive R7',
  'BlockName',
  // 'Test Type',
  // 'NBS Survey Date',
  // 'S8 Name',
  // 'BSE S8',
  // 'Positive S8',
  'R8 Name',
  'BSE R8',
  'Positive R8',
  'BlockName',
  // 'Test Type',
  // 'NBS Survey Date',
  // 'S9 Name',
  // 'BSE S9',
  // 'Positive S9',
  'R9 Name',
  'BSE R9',
  'Positive R9',
  'BlockName',
  // 'Test Type',
  // 'NBS Survey Date',
  // 'S10 Name',
  // 'BSE S10',
  // 'Positive S10',
  'R10 Name',
  'BSE R10',
  'Positive R10',
];
export const thDataDis = [
  'S.No',
  'State',
  'District',
  'Strategy',
  'Date Proposed',
  'Date Planned',
  'Date completed',
  // 'Covered Population',
  // 'Total Population',
  'Test Type',
  'NBS Survey Date',
  'BlockName',
  'R1 Name',
  'BSE R1',
  'Positive R1',
  'BlockName',
  'R2 Name',
  'BSE R2',
  'Positive R2',
  'BlockName',
  'R3 Name',
  'BSE R3',
  'Positive R3',
  'BlockName',
  'R4 Name',
  'BSE R4',
  'Positive R4',
  'BlockName',
  'R5 Name',
  'BSE R5',
  'Positive R5',
  'BlockName',
  'R6 Name',
  'BSE R6',
  'Positive R6',
  'BlockName',
  'R7 Name',
  'BSE R7',
  'Positive R7',
  'BlockName',
  'R8 Name',
  'BSE R8',
  'Positive R8',
  'BlockName',
  'R9 Name',
  'BSE R9',
  'Positive R9',
  'BlockName',
  'R10 Name',
  'BSE R10',
  'Positive R10',
  'BlockName',
  'R11 Name',
  'BSE R11',
  'Positive R11',
  'BlockName',
  'R12 Name',
  'BSE R12',
  'Positive R12',

  'BlockName',
  'R13 Name',
  'BSE R13',
  'Positive R13',
  'BlockName',
  'R14 Name',
  'BSE R14',
  'Positive R14',
  'BlockName',
  'R15 Name',
  'BSE R15',
  'Positive R15',
];
export const tdData = [
  {
    S_no: 1,
    State: 'Uttar Pradesh',
    district: 'Kanpur',
    strategy: 'District',
    Date_Proposed: '09 Mar 2020',
    Date_Planned: '09 Jan 2020',
    Date_completed: '09 Mar 2020',
    covered_population: ' 20,00,000',
    population: '20,00,000',
    Test_type: 'NBS',
    NBS_Survey_Date: '09 Mar 2020',
    S1_Name: 'Amaur',
    BSE_S1: '133',
    Positive_S1: '12',
    R1_Name: 'Amaur',
    BSE_R1: '133',
    Positive_R1: '12',
    Positive_R2: '12',
    Positive_R3: '12',
    Positive_R4: '12',
    Positive_R5: '12',
    Positive_R6: '12',
    Positive_R7: '12',
    Positive_R8: '12',
    Positive_R12: '12',
    Positive_R9: '12',
    Positive_R10: '12',
    Positive_R11: '12',
  },
  {
    S_no: 2,
    State: 'Utter Pradesh',
    district: 'Kanpur',
    strategy: 'District',
    Date_Proposed: '09 Mar 2020',
    Date_Planned: '09 Jan 2020',
    Date_completed: '09 Mar 2020',
    covered_population: ' 20,00,000',
    population: '20,00,000',
    Test_type: 'NBS',
    NBS_Survey_Date: '09 Mar 2020',
    S1_Name: 'Amaur',
    BSE_S1: '133',
    Positive_S1: '12',
    R1_Name: 'Amaur',
    BSE_R1: '133',
    Positive_R1: '12',
    Positive_R2: '12',
    Positive_R3: '12',
    Positive_R4: '12',
    Positive_R5: '12',
    Positive_R6: '12',
    Positive_R7: '12',
    Positive_R8: '12',
    Positive_R12: '12',
    Positive_R9: '12',
    Positive_R10: '12',
    Positive_R11: '12',
  },
  {
    S_no: 3,
    State: 'Utter Pradesh',
    district: 'Kanpur',
    strategy: 'District',
    Date_Proposed: '09 Mar 2020',
    Date_Planned: '09 Jan 2020',
    Date_completed: '09 Mar 2020',
    covered_population: ' 20,00,000',
    population: '20,00,000',
    Test_type: 'NBS',
    NBS_Survey_Date: '09 Mar 2020',
    S1_Name: 'Amaur',
    BSE_S1: '133',
    Positive_S1: '12',
    R1_Name: 'Amaur',
    BSE_R1: '133',
    Positive_R1: '12',
    Positive_R2: '12',
    Positive_R3: '12',
    Positive_R4: '12',
    Positive_R5: '12',
    Positive_R6: '12',
    Positive_R7: '12',
    Positive_R8: '12',
    Positive_R12: '12',
    Positive_R9: '12',
    Positive_R10: '12',
    Positive_R11: '12',
  },
  {
    S_no: 4,
    State: 'Utter Pradesh',
    district: 'Kanpur',
    strategy: 'District',
    Date_Proposed: '09 Mar 2020',
    Date_Planned: '09 Jan 2020',
    Date_completed: '09 Mar 2020',
    covered_population: ' 20,00,000',
    population: '20,00,000',
    Test_type: 'NBS',
    NBS_Survey_Date: '09 Mar 2020',
    S1_Name: 'Amaur',
    BSE_S1: '133',
    Positive_R2: '12',
    Positive_R3: '12',
    Positive_R4: '12',
    Positive_R5: '12',
    Positive_R6: '12',
    Positive_R7: '12',
    Positive_R8: '12',
    Positive_R12: '12',
    Positive_R9: '12',
    Positive_R10: '12',
    Positive_R11: '12',
  },
];
