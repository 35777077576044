import axios from 'axios';
import BaseUrl from '../../config/config';
import {
  getRolePrivilege,
  getRolePrivilegeById,
  insertRolePrivilege,
  updateRolePrivilege,
  deleteRolePrivilege,
} from '../../utils/api';

/**
 * @name getUserRoles used for user role call
 * @returns {object} returns the axios output
 */
export const getUserRoles = () => {
  return axios.get(`${BaseUrl}/${getRolePrivilege}`);
};

/**
 * @name createUserRole used for dashboard api call
 * @param {object} data payload data
 * @returns {object} returns the axios output
 */
export const createUserRole = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${insertRolePrivilege}`,
    data,
  });
};

/**
 * @name updateUserRole used for dashboard api call
 * @param {object} data payload data
 * @returns {object} returns the axios output
 */
export const updateUserRole = (data) => {
  return axios({
    method: 'put',
    url: `${BaseUrl}/${updateRolePrivilege}`,
    data,
  });
};

/**
 * @name readUserRole used for dashboard api call
 * @param {string} data payload data
 * @returns {object} returns the axios output
 */
export const readUserRole = (data) => {
  console.log(data, 'kdkkjrkjrt');
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getRolePrivilegeById}`,
    data: {
      role_privilege_id: data,
    },
  });
};

/**
 * @name deleteUserRole used for dashboard api call
 * @param {number} id payload id
 * @returns {object} returns the axios output
 */
export const deleteUserRole = (id) => {
  return axios({
    method: 'put',
    url: `${BaseUrl}/${deleteRolePrivilege}`,
    data: {
      role_privilege_id: id || '',
    },
  });
};
