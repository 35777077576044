import { takeLatest } from 'redux-saga/effects';
import {
  GET_GEOGRAPHIC_DIMENSION,
  UPDATE_GEOGRAPHIC_DIMENSION,
  INSERT_GEOGRAPHIC_DIMENSION,
  BULK_UPLOAD_GEOGRAPHIC_DIMENSION,
} from '../../constants/actionType';
import {
  getGeographicHandler,
  insertGeographicHandler,
  updateGeographicHandler,
  bulkUploadGeographicHandler,
} from '../handler/geographicHandler';

/**
 * @name geographicSaga used for program activities api call
 * @yields geographicSaga
 */
function* geographicSaga() {
  yield takeLatest(GET_GEOGRAPHIC_DIMENSION, getGeographicHandler);
  yield takeLatest(UPDATE_GEOGRAPHIC_DIMENSION, updateGeographicHandler);
  yield takeLatest(INSERT_GEOGRAPHIC_DIMENSION, insertGeographicHandler);
  yield takeLatest(BULK_UPLOAD_GEOGRAPHIC_DIMENSION, bulkUploadGeographicHandler);
}
export default geographicSaga;
