/* eslint-disable default-param-last */
import {
  INSERT_DRUG,
  INSERT_DRUG_RESULT,
  GET_DRUG_AVILABILITY_RESULT,
  GET_DRUG_AVILABILITY,
  UPDATE_DRUG_AVILABILITY,
  UPDATE_DRUG_AVILABILITY_RESULT,
  UPLOAD_DRUG_AVILABILITY_RESULT,
  DRUG_STATUS,
} from '../../constants/actionType/index';
import { insertDrug } from '../initialStates/index';

/**
 * @param {string} state defines the state
 * @param {object} action used for payload and type
 * @returns {Array} action response state
 */
const insertDrugReducer = (state = insertDrug, action) => {
  const { type, payload } = action;
  switch (type) {
    case INSERT_DRUG:
      return {
        ...state,
        payload,
      };
    case INSERT_DRUG_RESULT:
      return {
        ...state,
        status: payload.data,
      };
    case GET_DRUG_AVILABILITY:
      return {
        ...state,
        payload,
      };

    case GET_DRUG_AVILABILITY_RESULT:
      return {
        ...state,
        getDrug: payload.data,
      };
    case UPDATE_DRUG_AVILABILITY:
      return {
        ...state,
        payload,
      };
    case DRUG_STATUS:
      return {
        ...state,
        status: payload,
        loading: false,
      };

    case UPDATE_DRUG_AVILABILITY_RESULT:
      return {
        ...state,
        updateDrug: payload.data,
      };
    case UPLOAD_DRUG_AVILABILITY_RESULT:
      return {
        ...state,
        uploadDrugAvilability: payload.data,
      };
    default:
      return state;
  }
};
export default insertDrugReducer;
