import axios from 'axios';
import BaseUrl from '../../config/config';
import { getProgramActivity, insertProgramActivites, updateProgramActivites } from '../../utils/api';

const userAurhId = localStorage.getItem('UserLoginId');
/**
 * @name getProgramActivitesRequest function which make api request to get program activities
 * @returns {*} returns axios response data
 */
export const getProgramActivitesRequest = () => {
  return axios.get(`${BaseUrl}/${getProgramActivity}`);
};

/**
 * @param {object} data payload data
 * @name updateProgramActivitesRequest function which make api request to update program activities
 * @returns {*} returns axios response data
 */
export const updateProgramActivitesRequest = (data) => {
  return axios({
    method: 'put',
    url: `${BaseUrl}/${updateProgramActivites}`,
    data: {
      program_activities: data.Program_Activity_Type,
      from_date: data.from_date,
      to_date: data.to_date,
      active_status: data.Status === 'Active' ? 1 : 0,
      updated_by: userAurhId && userAurhId,
      program_activities_id: data.program_activities_id,
      // old_program_activities: data.Program_Activity_Type,
      old_program_activities: data.Program_Activity_Type === data.program_activities ? '' : data.program_activities,
    },
  });
};

/**
 * @param {object} data payload data
 * @name insertProgramActivitesRequest function which make api request to add program activity
 * @returns {*} returns axios response data
 */
export const insertProgramActivitesRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${insertProgramActivites}`,
    data: {
      program_activities: data.Program_Activity_Type,
      from_date: data.from_date,
      to_date: data.to_date,
      active_status: data.Status === 'Active' ? 1 : 0,
      created_by: userAurhId && userAurhId,
    },
  });
};
