import {
    GET_DISTRICT_BY_SEARCH_RESULT,
    GET_DISTRICT_BY_SEARCH,
    STATE_BY_SEARCH_FAILED,
    GET_STATE_BY_SEARCH_RESULT
  } from '../../constants/actionType';
  import { dropdownSearch } from '../initialStates';
  
  /**
   * @param {string} state defines the state
   * @param {object} action used for payload and type
   * @returns {Array} action response state
   */
  const dropDownSearchReducer = (state = dropdownSearch, action = {}) => {
    const { type, payload } = action;
    switch (type) {
      case GET_DISTRICT_BY_SEARCH_RESULT:
        return {
          ...state,
          getDistrictSearch: payload.data,
        };
      case GET_DISTRICT_BY_SEARCH:
        return {
          ...state,
          getStateSearch: payload.data,
        };
  
        case GET_STATE_BY_SEARCH_RESULT:
        return {
          ...state,
          getStateSearch: payload.data,
        };
      default:
        return state;
    }
  };
  export default dropDownSearchReducer;