/* eslint-disable import/prefer-default-export */
export const tableHeader = ['S.No', 'Year', 'State', 'District', 'Strategy', 'From Date', 'To Date', 'Action'];
export const tableData = [
  {
    S_no: 1,
    Activity_Type: 'MDA',
    Year: '2022',
    State: 'TamilNadu',
    District: 'Chennai',
    Strategy: 'District Strategy',
    Form_Date: '01 Mar 2021',
    To_Date: '20 Mar 2022',
  },
  {
    S_no: 2,
    Activity_Type: 'Pre-Tas',
    Year: '2022',
    State: 'Kerala',
    District: 'Chennai',
    Strategy: 'District Strategy',
    Form_Date: '01 Mar 2021',
    To_Date: '20 Mar 2022',
  },
  {
    S_no: 3,
    Activity_Type: 'MDA',
    Year: '2022',
    State: 'Uttar Pradesh',
    District: 'Chennai',
    Strategy: 'Block Strategy',
    Form_Date: '01 Mar 2021',
    To_Date: '20 Mar 2022',
  },
];
