import React, { forwardRef, useImperativeHandle, useEffect } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import CustomTextField from '../../../../../components/atoms/TextField';
import CountRange from '../../../../../components/atoms/CountRange';

/**
 *@param {object} props - required props in Block
 */
const Block = forwardRef((props, ref) => {
  const { control, Controller, index } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: `tasBloodSurvey[${index}].Blocks`,
  });

  useImperativeHandle(ref, () => ({
    /**
     *
     */
    appendable() {
      append();
    },
  }));
  const entries2 = [
    {
      name: 'block_name',
      label: 'Block',
      placeholder: 'Block',
      type: 'text',
      validation: { isRequired: true },
    },
    {
      name: 'population',
      label: 'Population',
      placeholder: 'Population',
      type: 'number',
      validation: { isRequired: true, min: 0 },
    },
  ];
  useEffect(() => {
    append();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line require-jsdoc
  const getPlaceholder = (name, placeholder, idx) => {
    return `${placeholder} ${idx + 1}`;
  };

  return (
    <Grid container display="flex" item flexDirection="row" md={12} spacing={3}>
      {fields?.map((field, idx) =>
        entries2?.map((keyValue) => {
          return (
            <Controller
              key={field.id}
              name={`tasBloodSurvey[${index}].Blocks[${idx}].${keyValue.name}`}
              control={control}
              rules={{
                required: keyValue.validation?.isRequired,
                min: keyValue.validation?.min,
              }}
              render={({ field: { onChange, value } }) => (
                <Grid item md={2}>
                  <CustomTextField
                    label={getPlaceholder(keyValue?.entryName, keyValue?.placeholder, idx)}
                    placeholder={getPlaceholder(keyValue?.entryName, keyValue?.placeholder, idx)}
                    type={keyValue.type}
                    onChange={(e) => onChange(e)}
                    value={value}
                    // min={keyValue.min}
                  />
                </Grid>
              )}
            />
          );
        }),
      )}
      <Grid pt={2} pl={2.3} mt="-8px">
        <CountRange
          labelText="Block Count"
          PCount={() => append()}
          NCount={(count) => remove(count)}
          initialCount={1}
          defaultCount={1}
        />
      </Grid>
    </Grid>
  );
});

export default Block;

Block.propTypes = {
  Controller: PropTypes.node,
  control: PropTypes.objectOf,
  index: PropTypes.number,
};
Block.defaultProps = {
  Controller: <> </>,
  control: {},
  index: '',
};
