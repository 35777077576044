import { call, put } from 'redux-saga/effects';
import {
  DEMOGRAPHIC_LISTED_DATA,
  INSERT_DEMOGRAPHIC_DIMENSIONS_FAILED,
  DEMO_STATUS,
  DEMOGRAPHIC_LIST,
} from '../../constants/actionType';
import {
  getDemoghrapicDimensions,
  updateDemoghrapicDimensions,
  insertDemoghrapicDimensions,
  uploadDemographicDimensionRequest,
} from '../request/demographicsRequest';
import { CATCH, ERROR, UPDATED, SUBMITTED, SUCCESS, BULK_UPLOAD_SUCCESS } from '../../constants/common';
/**
 * @function getDemographicDimensionsHandler handler function to handle program activity get request and dispatch response data to reducer state
 * @yields getDemographicDimensionsHandler
 */
export function* getDemographicDimensionsHandler() {
  try {
    const response = yield call(getDemoghrapicDimensions);
    yield put({
      type: DEMOGRAPHIC_LISTED_DATA,
      payload: response?.data,
    });
  } catch (err) {
    yield put({
      type: INSERT_DEMOGRAPHIC_DIMENSIONS_FAILED,
      payload: { Message: 'Something Went Wrong' },
    });
  }
}
/**
 * @param {object} action payload data
 * @function insertDemographicDimensionsHandler handler function to handle program activity post request
 * @yields insertDemographicDimensionsHandler
 */
export function* insertDemographicDimensionsHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(insertDemoghrapicDimensions, payload);
    if (response.status === 200)
      yield put({
        type: DEMO_STATUS,
        payload: { type: SUCCESS, message: SUBMITTED },
      });
  } catch (err) {
    yield put({
      type: DEMO_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
/**
 * @param {object} action payload data
 * @function updateDemographicDimensionsHandler handler function to handle program activity update request
 * @yields updateDemographicDimensionsHandler
 */
export function* updateDemographicDimensionsHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(updateDemoghrapicDimensions, payload);
    if (response.status === 200)
      yield put({
        type: DEMO_STATUS,
        payload: { type: SUCCESS, message: UPDATED },
      });
  } catch (err) {
    yield put({
      type: DEMO_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
/**
 * @param {object} action payload data
 * @function updateDemographicDimensionsHandler handler function to handle program activity update request
 * @yields updateDemographicDimensionsHandler
 */
export function* uploadDemographicDimensionsHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(uploadDemographicDimensionRequest, payload);
    if (response.status === 200)
      yield put({
        type: DEMO_STATUS,
        payload: { type: SUCCESS, message: BULK_UPLOAD_SUCCESS },
      });
    else
      yield put({
        type: DEMO_STATUS,
        payload: { type: ERROR, message: CATCH },
      });
  } catch (err) {
    yield put({
      type: DEMO_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
  yield put({
    type: DEMOGRAPHIC_LIST,
  });
}
