import React from 'react';
import CustomIcons from '../../../../utils/icons';

export const thDatas = [
  'S.No',
  'Bulk Upload Filename',
  'Bulk Upload By',
  'Threshold Configuration Attachments',
  'From Date',
  'To Date',
  'Status',
  'Action',
];

export const tdDatas = [
  {
    sno: '1',
    BulkUploadFilename: 'Formula Configuration-1997',
    BulkUploadby: 'John',
    FormulaConfiguration: <img src={CustomIcons.Threshold} alt="configuration" />,
    ToDate: '22 Mar 1999',
    FromDate: '22 Mar 1999',
    Status: 'Active',
  },
  {
    sno: '2',
    BulkUploadFilename: 'Formula level',
    BulkUploadby: 'John',
    FormulaConfiguration: <img src={CustomIcons.Threshold} alt="configuration" />,
    ToDate: '22 Mar 1999',
    FromDate: '22 Mar 1999',
    Status: 'Active',
  },
  {
    sno: '3',
    BulkUploadFilename: 'Formula level',
    BulkUploadby: 'Smith',
    FormulaConfiguration: <img src={CustomIcons.Threshold} alt="configuration" />,
    ToDate: '22 Mar 1999',
    FromDate: '22 Mar 1999',
    Status: 'Active',
  },
];
