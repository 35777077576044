export const geoGraphicDimensionEntries = [
  {
    default: true,
    entryName: 'Year',
    placeholder: 'Year',
    dropdown: true,
    data: [
      { id: 1, value: '2000' },
      { id: 2, value: '2001' },
      { id: 3, value: '2002' },
    ],
    validation: { isRequired: true },
    returnValue: true,
  },
  {
    default: true,
    entryName: 'state_id',
    placeholder: 'State',
    dropdown: true,
    data: [
      { id: 1, value: 'Kerala' },
      { id: 2, value: 'Goa' },
      { id: 3, value: 'Tamil Nadu' },
    ],
    validation: { isRequired: true },
  },
  {
    default: true,
    entryName: 'district_id',
    placeholder: 'District',
    disabled: true,
    dropdown: true,
    data: [
      { id: 1, value: 'Selam' },
      { id: 2, value: 'Tiruvallur' },
      { id: 3, value: 'Erode' },
    ],
    validation: { isRequired: true },
  },
  {
    default: true,
    entryName: 'Strategy',
    placeholder: 'Strategy',
    dropdown: true,
    disabled: true,
    data: [
      { id: 1, value: 'Block Strategy' },
      { id: 2, value: 'District Strategy' },
    ],
    validation: { isRequired: true },
  },
  {
    count: 'true',
    date: true,
    entryName: 'from_date',
    placeholder: 'From Date',
    validation: [{ isRequired: true }, { min: false }, { dateValidation: true }],
  },
  {
    count: 'true',
    date: true,
    entryName: 'to_date',
    placeholder: ' To Date ',
    validation: [{ isRequired: true }],
  },
  {
    default: true,
    radioButton: true,
    entryName: 'status',
    placeholder: 'Status',
    labelText: 'Status',
    radioData: [{ name: 'Active' }, { name: 'Inactive' }],
    validation: { isRequired: true },
  },
];
export const geoGraphicDimensionDefaultValues = {
  Activity_Type: '',
  Year: '',
  state_id: '',
  Strategy: '',
  district_id: '',
  From_Date: '',
  To_Date: '',
  S1_Block_Name: '',
  S1_Site_Name: '',
  R1_Block_Name: '',
  R1_Site_Name: '',
  status: 'Active',
};
