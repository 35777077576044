import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import './Table.css';
import { thDatas } from './Enteries';
import Table from '../../../../components/organisms/Table/index';
import Toaster from '../../../../components/atoms/ToastMessage';
import { GET_FORMULA_CONFIG } from '../../../../constants/actionType';

/**
 * @returns {React.ReactElement} return the user role component
 */
const FormulaTable = () => {
  const [formulaTable, setFormulaTable] = useState([]);
  const [toaster, setToaster] = React.useState(null);
  const dispatch = useDispatch();
  const { getFormula, status } = useSelector((state) => state.formulaConfig);

  React.useEffect(() => {
    dispatch({ type: GET_FORMULA_CONFIG });
  }, [dispatch]);

  useEffect(() => {
    if (getFormula?.length) {
      const thresholdArr = [];
      getFormula?.map((value, index) => {
        return thresholdArr.push({
          sno: index + 1,
          BulkUploadFilename: value.file_name,
          BulkUploadby: '',
          FormulaConfiguration: value.file_attachment,
          FromDate: value.from_date,
          ToDate: value.to_date,
          Status: value.status,
        });
      });

      setFormulaTable(thresholdArr);
    }
  }, [dispatch, getFormula]);

  useEffect(() => {
    if (status && status?.message !== '') setToaster(true);
    else setToaster(false);
  }, [status]);

  return (
    <Grid>
      <Grid md={12}>
        {toaster && <Toaster open severity={status?.type} message={status?.message} close={() => setToaster(false)} />}
        <Table
          header={thDatas}
          rows={formulaTable}
          masterSetup={['Downloader', 'Search']}
          userActivity="Create Formula Configuration"
          threshold
          proved
          hideKeyFields={['created_by']}
          Activity="Formula Config"
          screen="masters"
        />
      </Grid>
    </Grid>
  );
};
export default FormulaTable;
