/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Grid, Paper, Divider } from '@mui/material';
import { useDispatch } from 'react-redux';
import Typography from '../../components/atoms/Typography';
import Loader from '../../components/atoms/Loader';
import ListingItem from '../../components/molecules/ListItems/ListItems';
import NationalDashboard from '../Dashboard/NationalDashboard/index';
import PredictionDashboard from './PredictionDashboard';
import StateDashboard from '../Dashboard/StateDashboard/index';
import CustomReport from './CustomReport';
import './Dashboard.css';
import { GET_ACTIVITY_LEGENDS } from '../../constants/actionType/index';

/**
 *@returns {React.ReactElement} - return
 */
const App = () => {
  const dispatch = useDispatch();
  const [dashboardType, setDashboardType] = React.useState(0);
  const [loading, setloading] = React.useState(false);
  const [datas, setDatas] = React.useState([
    {
      dashBoardText: 'National Dashboard',
      initialLoad: true,
    },
    {
      dashBoardText: 'State Dashboard',
      initialLoad: false,
    },
    // {
    //   dashBoardText: 'Prediction Dashboard',
    //   initialLoad: false,
    // },
    {
      dashBoardText: 'Custom Report',
      initialLoad: false,
    },
  ]);
  useEffect(() => {
    dispatch({ type: GET_ACTIVITY_LEGENDS });
  }, []);

  // const DashBoardPanel = React.useMemo(() => {
  //   switch (dashboardType) {
  //     case 0:
  //       return <NationalDashboard />;
  //     case 1:
  //       return <StateDashboard />;
  //     case 2:
  //       return <PredictionDashboard />;
  //     default:
  //       return <CustomReport />;
  //   }
  // }, [dashboardType]);

  /**
   * @param {*} value value
   */
  const ChangeDashboard = (value) => {
    // setloading(true);
    const newArr = [...datas]; // copying the old datas array
    newArr[value] = { ...datas[value], initialLoad: true };
    setDatas(newArr);
    setDashboardType(value);
  };
  return loading ? (
    <Loader />
  ) : (
    <div>
      <div className="dash-grid">
        <div className="heading">
          <Typography type="subHeading" text="Dashboard" customClass="divider" colorType="quinary" />
        </div>
        <Divider orientation="vertical" flexItem sx={{ border: 1, opacity: 0.1 }} />
        <div className="list-items">
          <ListingItem dashboardContent={datas} handleChange={(value) => ChangeDashboard(value)} />
        </div>
      </div>
      <div className="body_content">
        {datas[0]?.initialLoad && <NationalDashboard showDisplay={dashboardType === 0} />}
        {datas[1]?.initialLoad && <StateDashboard showDisplay={dashboardType === 1} />}
        {/* {datas[2]?.initialLoad && <PredictionDashboard showDisplay={dashboardType === 2} />} */}
        {datas[2]?.initialLoad && <CustomReport showDisplay={dashboardType === 2} />}
      </div>
    </div>
  );
};

export default App;
