import {
  SET_TOGGLE_FORM,
  USERS_GROUP_AND_TYPE_RESULT,
  USERS_GROUP_AND_TYPE_CREATE_RESULT,
  USERS_GROUP_AND_TYPE_READ_RESULT,
  USERS_GROUP_AND_TYPE_UPDATE_RESULT,
  USERS_GROUP_AND_TYPE_DELETE_RESULT,
  STATUS,
} from '../../constants/actionType';
import { userRoleState } from '../initialStates';

/**
 * @param {string} state defines the state
 * @param {object} action used for payload and type
 * @returns {Array} action response state
 */
const userRoleReducer = (state = userRoleState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case USERS_GROUP_AND_TYPE_RESULT:
      return {
        ...state,
        ...payload,
      };
    case USERS_GROUP_AND_TYPE_CREATE_RESULT:
      return {
        ...state,
        ...payload,
      };
    case USERS_GROUP_AND_TYPE_READ_RESULT:
      return {
        ...state,
        ...payload,
      };
    case USERS_GROUP_AND_TYPE_UPDATE_RESULT:
      return {
        ...state,
        ...payload,
      };
    case USERS_GROUP_AND_TYPE_DELETE_RESULT:
      return {
        ...state,
        ...payload,
      };
    case SET_TOGGLE_FORM:
      return {
        ...state,
        ...payload,
      };
    case STATUS:
      return {
        ...state,
        status: payload,
        loading: false,
      };
    default:
      return state;
  }
};
export default userRoleReducer;
