import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import CustomIcons from '../../../utils/icons';
import CustomTypography from '../Typography';
import './ProgressBar.css';
/**
 *
 * @param {object} props - required props in Linear progress bar component
 * @returns {React.ReactElement} - returns the linear progress  component
 */
const ProgressBar = (props) => {
  const { onCancel, progress, fileName, wordUploader } = props;

  return (
    <Grid className="main flexPosition">
      <Grid>
        <Grid className="pdfView">
          <img
            src={wordUploader ? CustomIcons.WordIcon : CustomIcons.ExcelIcon}
            alt="excel"
            className="fileFormatPic"
          />
        </Grid>
      </Grid>
      <Grid className="progressView">
        <Grid className="progress">
          <CustomTypography type="title" customClass="fileName" text={fileName} />
          <Grid className="flexPosition">
            <Grid className="progressSet">
              <LinearProgress variant="determinate" value={progress} color="error" />
            </Grid>
            <Grid>
              <Box onClick={onCancel} sx={{ cursor: 'pointer' }}>
                <img src={CustomIcons.RedCross} alt="cross" />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProgressBar;
ProgressBar.propTypes = {
  fileName: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  progress: PropTypes.number.isRequired,
  wordUploader: PropTypes.bool,
};
ProgressBar.defaultProps = {
  fileName: '',
  wordUploader: false,
};
