/* eslint-disable no-empty */
import { call, put } from 'redux-saga/effects';
import { GET_FORMULA_CONFIG_RESULT, FORMULA_STATUS, APPROVAL_STATUS } from '../../constants/actionType';

import {
  getFormulaConfigRequest,
  insertFormulaConfigRequest,
  approvalAttachmentFormula,
} from '../request/formulaConfigRequest';
import { CATCH, ERROR, BULK_UPLOAD_SUCCESS, SUCCESS, APPROVAL_SUCCESS } from '../../constants/common';

/**
 * @function getFormulaHandler handler function to handle program activity get request and dispatch response data to reducer state
 * @yields  getFormulaHandler
 */
export function* getFormulaHandler() {
  try {
    const response = yield call(getFormulaConfigRequest);
    yield put({
      type: GET_FORMULA_CONFIG_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}
/**
 * @param {object} action payload data
 * @function insertFormulaHandler handler function to handle program activity post request
 * @yields insertFormulaHandler
 */
export function* insertFormulaHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(insertFormulaConfigRequest, payload);

    if (response.status === 200)
      yield put({
        type: FORMULA_STATUS,
        payload: { type: SUCCESS, message: BULK_UPLOAD_SUCCESS },
      });
  } catch (err) {
    yield put({
      type: FORMULA_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
/**
 * @param {object} action payload data
 * @function formulaApprovalHandler handler function to handle program activity post request
 * @yields formulaApproval
 */
export function* formulaApprovalHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(approvalAttachmentFormula, payload);

    if (response.status === 200)
      yield put({
        type: APPROVAL_STATUS,
        payload: { type: SUCCESS, message: APPROVAL_SUCCESS },
      });
  } catch (err) {
    yield put({
      type: APPROVAL_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
