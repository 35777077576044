export const dossierFormEntries = [
  {
    count: true,
    entryName: 'state',
    placeholder: 'State',
    dropdown: true,
    data: ['Kerala', 'TamilNadu', 'Karnataka'],
    validation: { isRequired: true },
  },
  {
    count: true,
    entryName: 'district',
    placeholder: 'District',
    dropdown: true,
    disabled: true,
    data: ['Chennai', 'Salem', 'Thiruvallur'],
    validation: { isRequired: true },
  },
  {
    count: true,
    date: true,
    entryName: 'date_Completed',
    placeholder: 'Date Completed',
    validation: { isRequired: true },
  },
  {
    count: true,
    date: true,
    entryName: 'date_Planned',
    placeholder: 'Date Planned',
    validation: { isRequired: true },
  },
  {
    count: true,
    radioButton: true,
    entryName: 'certification_completed',
    placeholder: 'Certification completed',
    labelText: 'Certification completed',
    radioData: [{ name: 'Yes' }, { name: 'No' }],
    validation: { isRequired: true },
  },
];
export const formDefaultValues = {
  state: '',
  district: '',
  Strategy: '',
  date_Proposed: '',
  date_Planned: '',
  date_Completed: '',
  certification_completed: 'No',
};
