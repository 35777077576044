import { takeLatest } from 'redux-saga/effects';
import {
  GET_THRESHOLD_CONFIG,
  INSERT_THRESHOLD_CONFIG,
  THRESHOLD_APPROVAL_ATTACHMENT,
} from '../../constants/actionType';
import { getThresholdHandler, insertThresholdHandler, thresholdApprovalHandler } from '../handler/thresholdHandler';

/**
 * @name thresholdConfigSaga used for program activities api call
 * @yields thresholdConfigSaga
 */
function* thresholdConfigSaga() {
  yield takeLatest(GET_THRESHOLD_CONFIG, getThresholdHandler);
  yield takeLatest(INSERT_THRESHOLD_CONFIG, insertThresholdHandler);
  yield takeLatest(THRESHOLD_APPROVAL_ATTACHMENT, thresholdApprovalHandler);
}
export default thresholdConfigSaga;
