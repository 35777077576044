/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import './ActivityCalendar.css';
import Tooltip from '@mui/material/Tooltip';
import { Grid } from '@mui/material';
import moment from 'moment';
import colors from '../../../utils/colors';
import CustomTypography from '../../atoms/Typography';
import TooltipTitle from '../../atoms/Tooltip/TooltipContent';
import CustomIcons from '../../../utils/icons';

/**
 *
 * @param {object} props required props
 * @returns {React.ReactElement} return the activity calendar component
 */
const ActivityCalendar = (props) => {
  const [mouseOver, setMouseOver] = useState(null);
  const [mouseOverRow, setMouseOverRow] = useState(null);
  const [RowID, setRowID] = useState(null);
  const [activityLegendsColor, setActivityLegendsColor] = useState({});
  const { activityLegends } = useSelector((state) => state.dashboardDetail);

  useEffect(() => {
    if (activityLegends && activityLegends?.calendarLegends && activityLegends?.activityHover) {
      const legends = {
        calendarLegends: activityLegends?.calendarLegends,
        calendarHover: activityLegends?.activityHover,
      };
      setActivityLegendsColor(legends);
    }
  }, [activityLegends]);

  const { heading, data, onCalenderClick } = props;
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
    'Total',
  ];

  /**
   *@name getBackgroundColor
   * @param {string} type - passing the type
   * @param {number} rowId - rowId
   * @param {number} index index
   * @param {number} id id
   * @returns {React.ReactElement} - background color
   */
  const getBackgroundColor = (type, rowId, index, id) => {
    if (RowID === rowId && mouseOverRow === index && mouseOver === id) {
      return activityLegendsColor?.calendarHover && activityLegendsColor?.calendarHover[type];
    }
    return activityLegendsColor?.calendarLegends && activityLegendsColor?.calendarLegends[type];
  };

  /**
   * @name onOverMouse
   * @param {number} rowId -rowId
   * @param {number} index - passing the month
   * @param {number} id id
   * @returns {React.ReactElement} - background color
   */
  const onOverMouse = (rowId, index, id) => {
    setMouseOver(id);
    setMouseOverRow(index);
    setRowID(rowId);
    return null;
  };

  /**
   *@name onBtnClick
   * @param {object} clickedData id
   * @returns {React.ReactElement} - background color
   */
  const onBtnClick = (clickedData) => {
    onCalenderClick(clickedData);
    return null;
  };

  /**
   *@name onLeaveMouse
   * @returns {React.ReactElement} - background color
   */
  const onLeaveMouse = () => {
    setMouseOver(null);
    setMouseOverRow(null);
    return null;
  };

  return (
    <Grid item md={12} xs={12} sm={12}>
      <Grid>
        <Grid className="rowHeader fixed-header">
          {heading.map((header) => {
            return (
              <Grid className="monthTd">
                <CustomTypography
                  text={header}
                  type="body1"
                  // eslint-disable-next-line prettier/prettier
                  colorType={
                    header === 'State' || header === 'District' || header === 'Block' || header === 'Total'
                      ? 'tertiary'
                      : 'text'
                  }
                />
              </Grid>
            );
          })}
        </Grid>
        {data?.length > 0 ? (
          data.map((val, rowId) => {
            return (
              <Grid className="rowState">
                <Grid rowSpan={5} className="states">
                  <CustomTypography
                    text={val.name}
                    type="body1"
                    colorType="primary"
                    customStyle={{ textAlign: 'center' }}
                  />
                </Grid>
                <Grid className={`rowValue ${val.data?.length === 0 && 'empty-padding'}`}>
                  {val.data?.map((activityData, index) => {
                    return (
                      <Grid item md={12} xs={12} sm={12} className="monthHeader">
                        {months.map((item, id) => {
                          const monthData = activityData?.data?.find((obj) => obj.month === item);
                          return monthData ? (
                            <Tooltip
                              title={
                                <TooltipTitle
                                  contentTitle="District"
                                  // singleRow
                                  districts={monthData?.details?.split(',')}
                                />
                              }
                              placement="right"
                              className="toolHeader"
                            >
                              <Grid
                                className="activityNum"
                                style={
                                  monthData
                                    ? {
                                        backgroundColor: getBackgroundColor(activityData.type, rowId, index, id),
                                        padding: '18px 0px',
                                        textAlign: 'center',
                                        color: colors.white.primary,
                                        fontSize: '1vw',
                                        marginLeft: 1,
                                      }
                                    : 'monthNumber'
                                }
                                onMouseEnter={() => onOverMouse(rowId, index, id)}
                                onMouseLeave={() => onLeaveMouse()}
                                onClick={() =>
                                  onBtnClick({
                                    [`${typeof monthData.countDate === 'number' ? 'State' : 'District'}`]: val.name,
                                    Month: item,
                                    Activity: activityData.type,
                                  })
                                }
                              >
                                {typeof monthData.countDate === 'number'
                                  ? monthData.countDate
                                  : moment(monthData.countDate).format('DD-MM-YYYY')}
                              </Grid>
                            </Tooltip>
                          ) : item === 'Total' ? (
                            <Grid className="monthNumber">&nbsp;{activityData.total}</Grid>
                          ) : (
                            <Grid className="monthNumber">&nbsp;&nbsp;</Grid>
                          );
                        })}
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            );
          })
        ) : (
          <center>
            <div className="nodata-img">
              <img style={{ width: '100%' }} src={CustomIcons.NoDataFound} alt="No Data Found" />
            </div>
          </center>
        )}
      </Grid>
    </Grid>
  );
};

export default ActivityCalendar;
ActivityCalendar.propTypes = {
  heading: PropTypes.arrayOf.isRequired,
  data: PropTypes.arrayOf.isRequired,
  onCalenderClick: PropTypes.func,
};
ActivityCalendar.defaultProps = {
  onCalenderClick: () => {},
};
