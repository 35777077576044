import axios from 'axios';
import BaseUrl from '../../config/config';
import {
  getDistrictDropdown,
  getStateDropdown,
  getProgramActivityType,
  getStrategyType,
  getTestKitType,
  getStateBySearch,
  getDistrictBySearch
} from '../../utils/api';

/**
 * @name getDistrictDropdownList function which make api request to get State list.
 * @returns {*} returns axios response data
 */
export const getStateDropdownList = () => {
  return axios.get(`${BaseUrl}/${getStateDropdown}`);
};

/**
 * @param {object} data payload data -- MOSTLY ID.
 * @name getDistrictDropdownList function which make api request to update district list.
 * @returns {*} returns axios response data
 */
export const getDistrictDropdownList = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getDistrictDropdown}`,
    data,
  });
};

/**
 * @name getStateSearch function which make api request to get State list.
 * @returns {*} returns axios response data
 */
export const getStateSearchRequest = () => {
  return axios.post(`${BaseUrl}/${getStateBySearch}`);
};

/**
 * @param {object} data payload data -- MOSTLY ID.
 * @name getDistrictSearch function which make api request to update district list.
 * @returns {*} returns axios response data
 */
export const getDistrictSearchRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getDistrictBySearch}`,
    data,
  });
};

/**
 * @param {object} data payload data -- MOSTLY ID.
 * @name getProgramActivityType function which make api request to update district list.
 * @returns {*} returns axios response data
 */
export const getProgramActivityTypes = (data) => {
  return axios({
    method: 'get',
    url: `${BaseUrl}/${getProgramActivityType}`,
    data,
  });
};
/**
 * @name getStrategy function which make api request to update strategies list.
 * @returns {*} returns axios response data
 */
export const getStrategy = () => {
  return axios.get(`${BaseUrl}/${getStrategyType}`);
};
/**
 * @name getTestKitTypeRequest function which make api request to update testkittype list.
 * @returns {*} returns axios response data
 */
export const getTestKitTypeRequest = () => {
  return axios.get(`${BaseUrl}/${getTestKitType}`);
};
