// const BaseUrl = 'http://122.165.52.124:8157/api/v1';
// const BaseUrl = 'http://122.165.52.124:8080/api/v1';

// export default BASE_PATH;

// const BaseUrl = 'http://13.235.75.0:8157/api/v1';
const BaseUrl = 'https://api.filaria.in';
// const BaseUrl = ' http://192.168.0.211:3000';
// const BaseUrl = 'http://164.52.212.214:3000';
// const BaseUrl = 'http://13.232.226.55:3000';
// const BaseUrl = 'http://localhost:8158/api/v1';

export default BaseUrl;
