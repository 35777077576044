/* eslint-disable react/prop-types */
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../../../components/atoms/CustomButton';
import CustomTypography from '../../../../components/atoms/Typography';
import Toaster from '../../../../components/atoms/ToastMessage';
import CustomIcons from '../../../../utils/icons';
import UserRightPanel from '../../../../components/molecules/UserRightPanel';
import { GET_USER_MASTER, INSERT_USER_MASTER, UPDATE_USER_MASTER } from '../../../../constants/actionType';
import { headerData } from '../entries';
import TabContent from './TabContent';
import './stylesheet.css';

/**
 * @param {*} props - props
 * @name UserCreateViewPage
 * @returns {React.ReactElement} --
 */
const UserCreateViewPage = (props) => {
  const { userInfo, onClose } = props;
  const dispatch = useDispatch();
  const { userMaster } = useSelector((state) => state.newUserDetails);
  const [userRole, setUserRole] = useState(false);
  const [privilegeList, setPrivilegeList] = useState({});
  const [Tabs, setTabs] = useState({ Tab0: 0 });
  const [rowData, setRowData] = useState([]);
  const [toaster, setToaster] = React.useState(false);

  const userCreation = [
    { label: 'Name', value: 'John', key: 'user_name' },
    { label: 'UserGroup', value: 'BlockLevel', key: 'user_group' },
    { label: 'UserType', value: 'Operator', key: 'user_type' },
    {
      label: 'Password',
      value: (
        <Grid className="resetBtn">
          <CustomButton
            variants="contained"
            btnTitle="Reset"
            ellipse
            color="secondary"
            customStyle={{
              ':hover': {
                bgcolor: '#0093ff',
                color: 'white',
              },
              padding: 0.2,
              fontSize: '12px !important',
            }}
          />
        </Grid>
      ),
      key: 'password',
    },
    {
      label: '',
      value: (
        <img src={CustomIcons.Edit} alt="" className="edit" aria-hidden="true" onClick={() => setUserRole(!userRole)} />
      ),
      key: 'img',
    },
  ];

  useEffect(() => {
    const userPrivilegeRow = [];
    if (userMaster)
      userMaster[0]?.input_screen?.forEach((items) => {
        userPrivilegeRow.push({
          screen_name: items.screen_name,
          // select_all: items.select_all === 1,
          select_all:
            items.view === 1 &&
            items.download === 1 &&
            items.edit === 1 &&
            items.manual_data_input === 1 &&
            items.bulk_upload === 1 &&
            true,
          view: items.view === 1,
          download: items.download === 1,
          edit: items.edit === 1,
          manual_data_input: items.manual_data_input === 1,
          bulk_upload: items.bulk_upload === 1,
          user_access_id: items.user_access_id,
        });
      });
    setRowData(userPrivilegeRow);
    setUserRole(false);
  }, [userMaster]);

  useEffect(() => {
    dispatch({ type: GET_USER_MASTER, payload: userInfo });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
   *
   * @param {*} index index
   * @param {*} key key
   * @param {*} check check
   */
  const handlePrivilege = (index, key, check) => {
    if (key === 'select_all') {
      ['view', 'download', 'edit', 'manual_data_input', 'bulk_upload'].map((k) => {
        console.log(rowData, 'downloaddownload');
        rowData[index][k] = check;
        return null;
      });
    }
    rowData[index][key] = check;
    setRowData([...rowData]);
  };
  /**
   * function onSubmit
   */
  const onSubmit = () => {
    const userType = userMaster && userMaster[0]?.dashboard_screen?.length > 0 ? Object.keys(Tabs)?.length === 3 : true;
    if (userType) {
      const inputScreens = rowData.map((items) => {
        const inputs = {
          screen_name: items.screen_name,
          select_all:
            items.view === 1 &&
            items.download === 1 &&
            items.edit === 1 &&
            items.manual_data_input === 1 &&
            items.bulk_upload === 1
              ? 1
              : 0,
          view: items.view ? 1 : 0,
          download: items.download ? 1 : 0,
          edit: items.edit ? 1 : 0,
          manual_data_input: items.manual_data_input ? 1 : 0,
          bulk_upload: items.bulk_upload ? 1 : 0,
        };
        if (userMaster && userMaster[0]?.input_screen[0]?.role_privilege_access_id) return inputs;
        return {
          ...inputs,
          user_access_id: items.user_access_id,
        };
      });
      const userPrivilege =
        userMaster && userMaster[0]?.dashboard_screen?.length > 0
          ? {
              dashboard: [privilegeList.national],
              state_dashboard: [privilegeList.state],
              prediction_dashboard: [privilegeList.prediction],
            }
          : { dashboard: [] };
      if (userMaster && userMaster[0]?.input_screen[0]?.role_privilege_access_id) {
        dispatch({ type: INSERT_USER_MASTER, payload: { inputScreens, userPrivilege, userMaster } });
      }
      if (userMaster && userMaster[0]?.input_screen[0]?.user_access_id)
        dispatch({
          type: UPDATE_USER_MASTER,
          payload: {
            inputScreens,
            userPrivilege,
            userMaster,
          },
        });
    } else {
      setToaster(true);
    }
  };
  return (
    <Grid>
      {toaster && (
        <Toaster open severity="error" message="Please check all tabs privilege" close={() => setToaster(false)} />
      )}
      <Grid sx={{ p: 0 }}>
        <CustomTypography
          text="User Creation"
          type="caption"
          customStyle={{ fontWeight: 'bold' }}
          customClass="userText"
        />
      </Grid>
      <Grid item container md={12} xs={12} sm={12} columnGap={27} sx={{ pt: 2, pl: 1 }}>
        {userCreation?.map((item, index) => {
          const key = index;
          return (
            <Grid sx={{ pl: 3 }} key={key}>
              <Grid>
                <CustomTypography text={item.label} type="link" colorType="senary" />
              </Grid>
              <Grid>
                {item.key === 'password' && <CustomTypography text={item.value} type="link" customClass="value" />}
                {item.key === 'img' && <CustomTypography text={item.value} type="link" customClass="value" />}
                <CustomTypography
                  text={userMaster?.length > 0 ? userMaster[0][item?.key] : ''}
                  type="link"
                  customClass="value"
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Grid>
        <UserRightPanel
          headerData={headerData}
          rowData={rowData}
          onChangeCheckbox={userRole}
          onCheckValues={handlePrivilege}
          fullWidth
          hideButton
          hideFields={['user_access_id']}
        />
      </Grid>
      {userMaster && userMaster[0]?.dashboard_screen?.length > 0 && (
        <TabContent
          userMasterData={userMaster}
          showCheckbox={userRole}
          nationalList={(list) => setPrivilegeList({ ...privilegeList, ...list })}
          stateList={(list) => setPrivilegeList({ ...privilegeList, ...list })}
          predictionList={(list) => setPrivilegeList({ ...privilegeList, ...list })}
          selectedTabs={(keys) => setTabs({ ...Tabs, [`Tab${keys}`]: keys })}
        />
      )}
      <Grid container item md={12} className="footBtn" sx={{ display: 'flex' }}>
        <Grid sx={{ pr: 2 }}>
          <CustomButton btnTitle="Submit" color="info" ellipse onClickHandle={onSubmit} customClass="buttonStyle" />
        </Grid>
        <Grid>
          <CustomButton btnTitle="Close" color="disable" ellipse customClass="buttonStyle" onClickHandle={onClose} />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default UserCreateViewPage;
UserCreateViewPage.propTypes = {
  userInfo: PropTypes.objectOf,
  onClose: PropTypes.func,
};
UserCreateViewPage.defaultProps = {
  userInfo: {},
  onClose: () => {},
};