/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Line,
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid,
  Legend,
  Bar,
  XAxis,
  YAxis,
  LabelList,
  Tooltip,
  ReferenceLine,
  Label,
} from 'recharts';
import Typography from '../../atoms/Typography';
import colors from '../../../utils/colors';

/**
 * @param {string} value legend text
 * @returns {React.Component} customized legend span
 */
const LegendText = (value) => {
  return <span className="legend-text">{value}</span>;
};

/**
 * @param {string} value legend text
 * @param {boolean} percent add percent symbol when true
 * @returns {React.Component} customized legend span
 */
const CustomYaxisLabel = (value, percent) => {
  if (Number(value) !== 0) return `${value}${percent ? '%' : ''}`;
  return null;
};

/**
 * @param {*} props props
 * @returns {React.ReactComponent} ComposedBarChart
 */
const ComposedBarChart = (props) => {
  const {
    title,
    chartData,
    XKey,
    LineKeys,
    BarKeys,
    XLabel,
    Y1Label,
    Y2Label,
    BarValuePosition,
    Legends,
    percent,
    stacked,
    ToolTip,
    LineValuePosition,
  } = props;
  const [tooltip, setToolTip] = React.useState(false);
  const [districtCount, setDistrictCount] = React.useState(null);
  /**
   * @param {*} entry entry name
   * @param {*} stackIndex stackIndex
   */
  const handleMouseOver = (entry, stackIndex) => {
    let count;
    if (stackIndex === 0) count = entry.completedDistricts;
    // if (stackIndex === 1) count = entry.delayedDistricts;
    if (stackIndex === 1) count = entry.pendingDistricts;
    setDistrictCount(count);
    setToolTip(true);
  };
  return (
    <center>
      {title && <Typography type="header" colorType="text" text={title} customStyle={{ marginBottom: 2 }} />}
      <Typography
        type="title"
        colorType="text"
        customStyle={{ visibility: tooltip ? 'visible' : 'hidden' }}
        text={`No of Districts : ${districtCount}`}
      />
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart data={chartData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <CartesianGrid stroke="transparent" vertical={false} horizontal={false} />
          <ReferenceLine y={0} stroke="#000000" />
          <XAxis dataKey={XKey} tickLine={false} tick={chartData?.length > 0} style={{ fill: colors.graph }}>
            <Label value={XLabel} dy={10} style={{ fill: colors.graph }} />
          </XAxis>
          <YAxis yAxisId={0} tickLine={false} tick={chartData?.length > 0} style={{ fill: colors.graph }}>
            <Label value={Y1Label} angle={-90} dx={-9} style={{ fill: colors.graph }} />
          </YAxis>
          {Y2Label && (
            <YAxis
              yAxisId={1}
              tickLine={false}
              orientation="right"
              tick={chartData?.length > 0}
              axisLine
              style={{ fill: colors.graph }}
              allowDecimals={false}
            >
              <Label value={Y2Label} angle={-90} dx={7} style={{ fill: colors.graph }} />
            </YAxis>
          )}
          {ToolTip && <Tooltip cursor={false} />}
          {Legends && <Legend formatter={LegendText} wrapperStyle={{}} />}
          {BarKeys.map((key, index) => (
            <Bar
              isAnimationActive={false}
              yAxisId={0}
              dataKey={key.label}
              barSize={40}
              stackId="a"
              fill={key.color}
              onMouseOver={(entry) => stacked && handleMouseOver(entry, index)}
              onMouseLeave={() => setToolTip(false)}
              // minPointSize={12}
            >
              <LabelList
                isAnimationActive={false}
                dataKey={key.label}
                position={BarValuePosition}
                style={{ fill: stacked ? 'white' : 'black', fontSize: 9 }}
                formatter={(value) => CustomYaxisLabel(value, percent)}
                dy={2}
              />
            </Bar>
          ))}
          {LineKeys?.map((key) => (
            <Line
              isAnimationActive={false}
              yAxisId={1}
              dataKey={key.label}
              stroke={key.color}
              strokeWidth={2}
              dot={{ strokeWidth: 2, fill: key.color }}
            >
              <LabelList
                isAnimationActive={false}
                dataKey={key.label}
                position={LineValuePosition}
                style={{ fill: stacked ? 'white' : 'black', fontSize: 9 }}
              />
            </Line>
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    </center>
  );
};

export default ComposedBarChart;
ComposedBarChart.propTypes = {
  title: PropTypes.string,
  chartData: PropTypes.arrayOf.isRequired,
  XKey: PropTypes.string.isRequired,
  LineKeys: PropTypes.array,
  BarKeys: PropTypes.arrayOf.isRequired,
  XLabel: PropTypes.string,
  Y1Label: PropTypes.string,
  Y2Label: PropTypes.string,
  BarValuePosition: PropTypes.string,
  Legends: PropTypes.bool,
  percent: PropTypes.bool,
  stacked: PropTypes.bool,
  ToolTip: PropTypes.bool,
  LineValuePosition: PropTypes.string,
};
ComposedBarChart.defaultProps = {
  title: '',
  XLabel: '',
  Y1Label: '',
  Y2Label: '',
  LineKeys: null,
  BarValuePosition: 'top',
  Legends: null,
  percent: false,
  stacked: false,
  ToolTip: false,
  LineValuePosition: 'top',
};
