/* eslint-disable no-nested-ternary */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import BaseUrl from '../../config/config';
import {
  TASInsert,
  TASGet,
  TASUpdate,
  TAS1Proposed,
  TAS2Proposed,
  TAS3Proposed,
  TASUpdateById,
  bulkUploadTas,
  insertTASDistrictStrategy,
  insertTasBlockStrategy,
  bulkInsertTasBlock,
} from '../../utils/api';

const userAurhId = localStorage.getItem('UserLoginId');
console.log(userAurhId, 'userAurhId');

/**
 * @param {object} data payload data
 * @name insertTASRequest function which make api request to update program activities
 * @returns {*} returns axios response data
 */
export const insertTAS1Request = (data) => {
  delete data.data?.overAllTasUpdate?.[0]?.s_no;
  delete data.data?.overAllTasUpdate?.[0]?.Eu_Name0;
  delete data.data?.overAllTasUpdate?.[0]?.block_date_proposed0;
  delete data.data?.overAllTasUpdate?.[0]?.block_date_planned0;
  delete data.data?.overAllTasUpdate?.[0]?.block_date_completed0;
  delete data.data?.overAllTasUpdate?.[0]?.population_under_eu0;
  delete data.data?.overAllTasUpdate?.[0]?.sample_size0;
  delete data.data?.overAllTasUpdate?.[0]?.number_of_persons_examined0;
  delete data.data?.overAllTasUpdate?.[0]?.number_of_persons_positive0;
  delete data.data?.overAllTasUpdate?.[0]?.number_of_invalid_test0;
  delete data.data?.overAllTasUpdate?.[0]?.critical_cut_off0;

  return axios({
    method: `${data.type === 'update' || data.type === 'update_by_id' ? 'put' : 'post'}`,
    url: `${BaseUrl}/${data.type === 'update' ? TASUpdate : data.type === 'update_by_id' ? TASUpdateById : TASInsert}`,
    data: { ...data.data },
  });
};
/**
 * @param {object} data payload data
 * @param obj
 * @name insertTASRequest function which make api request to update program activities
 * @returns {*} returns axios response data
 */
export const insertTAS1DistrictRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${insertTASDistrictStrategy}`,
    data: data?.Data,
  });
};
/**
 * @param {object} data payload data
 * @param obj
 * @name insertTASRequest function which make api request to update program activities
 * @returns {*} returns axios response data
 */
export const insertTAS1BlockRequest = (data) => {
  // delete UpdateData.s_no;
  return axios({
    method: 'post',
    url: `${BaseUrl}/${insertTasBlockStrategy}`,
    data: data?.Data,
  });
};

/**
 * @param {object} data payload data
 * @name getTAS1Request function which make api request to update program activities
 * @returns {*} returns axios response data
 */
export const getTAS1Request = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${TASGet}`,
    data,
  });
};

/**
 * @param {object} data payload data
 * @name getTAS1Request function which make api request to update program activities
 * @returns {*} returns axios response data
 */
export const getTASProposedRequest = (data) => {
  console.log("data: ", data);
  return axios({
    method: 'post',
    url: `${BaseUrl}/${data.type === 'TAS1' ? TAS1Proposed : data.type === 'TAS2' ? TAS2Proposed : TAS3Proposed}`,
    data: {
      ...data.data,
    },
  });
};

// /**
//  *
//  * @param  {object} data payload data
//  * @returns {*} returns axios response data
//  */
// export const bulkUploadTasRequest = (data) => {
//   const formData = new FormData();
//   formData.append('file_upload', data);
//   formData.set('created_by', 1);
//   return axios({
//     method: 'post',
//     url: `${BaseUrl}/${bulkUploadTas}`,
//     data: formData,
//   });
// };
/**
 *
 * @param  {object} data payload data
 * @returns {*} returns axios response data
 */
export const bulkUploadTasRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${data?.statergy === 'District Strategy' ? bulkUploadTas : bulkInsertTasBlock}`,
    data: {
      created_by: userAurhId && userAurhId,
      bulk_insert_tas: data?.data,
    },
  });
};
